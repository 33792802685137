import React from 'react'

export default function GridData(props) {

  const {columns, list, sortBy, sortByAscending, handleHeaderClick, toggleFavorite, trackRecent} = props

  return (
    <table>
      <tr>
        {columns.map((column, j) =>
          <Header
            key={j}
            column={column}
            sortBy={sortBy}
            sortByDefaultColumn={props.sortByDefaultColumn}
            sortByAscending={sortByAscending}
            handleHeaderClick={handleHeaderClick}
          />
        )}
      </tr>
      {list.items.map((item, i) =>
        <tr key={i}>
          {columns.map((column, j) =>
              <Attribute key={j} item={item} column={column} toggleFavorite={toggleFavorite} trackRecent={trackRecent}/>
          )}
        </tr>
      )}
    </table>
  )
}

function Header(props) {
  const {column, sortBy, sortByDefaultColumn, sortByAscending, handleHeaderClick} = props

  let label = column.label
  let arrow
  if (column.key === sortBy[0]) {// ||
//      column.key === sortByDefaultColumn && sortBy == <the props value from function, not Header but the Component>) {
//  if (sortBy[0] === column.key) {
    if (sortByAscending === 1) {
      arrow = <i className="fas fa-long-arrow-alt-down"></i>
    } else {
      arrow = <i className="fas fa-long-arrow-alt-up"></i>
    }
  }

  return (
    <th
      onClick={() => handleHeaderClick(column.key)}
      style={{cursor: 'pointer'}}
    >
      {label}{arrow}
    </th>
  )


}

function Attribute(props) {
  const {item, column, trackRecent} = props
  const style = {} //column.style ?? {}
  if (column.key === 'pk') {
    const email = item[column.key].slice(item[column.key].indexOf('-') + 1)
    if (column.linkTo === 'person') {
      return <td style={style}><a target="_default" href={"/admin/account/" + email} onClick={()=> trackRecent(item.pk)}>{email}</a></td>
    }
    return <td style={style}>email</td>
  }

  if (column.isBoolean) {
    return <td style={style}>{item[column.key] ? 'Yes' : 'No'}</td>
  }
  if (column.key === 'favorite') {
    let icon = item.favorite ? <i className="fas fa-star"></i> : <i className="far fa-star"></i>
      return <td onClick={() => props.toggleFavorite(item.pk)}>{icon}</td>
  }

  if (column.key === 'recent') {
    let icon = item.recent ? <i className="fas fa-clock"></i> : null
    return <td>{icon}</td>
  }
  return <td style={style}>{item[column.key]}</td>
}
