import React, { Component } from 'react';
import { API } from 'aws-amplify';
import {
  useParams,
  Redirect,
  Route
} from "react-router-dom";

import Account from '../account/Account'
import Company from '../company/Company'
import Chapter from '../chapter/Chapter'
import Admin from './Admin'
import Cart from '../Cart'
import Catalog from '../catalog/Catalog'
import Checkout from '../checkout/Checkout'
import AddIid from '../checkout/AddIid'
import Coupon from '../Coupon'
import Grid from './Grid'
import GridControl from './GridControl'
import {Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import ErrorContainer from '../../ErrorContainer';
import Loading from '../../Loading';
import {loadStripe} from '@stripe/stripe-js';
import Payment from '../payment/Payment'
import Person from '../../../classes/Person'
import CompanyClass from '../../../classes/Company'
import ChapterClass from '../../../classes/Chapter'
import {PersonContext, CompanyContext, ChapterContext} from '../../../classes/context'

var moment = require('moment')
const hostname = window && window.location && window.location.hostname
const stripePromise = (hostname === 'www.atalink.org' ||
                       hostname === 'atalink.org') ?
                       loadStripe('pk_live_0NIWTgcAcd9TuJKNmoCqxsX8') :
                       loadStripe('pk_test_TdYkfN3C0hFR8JvDmp5sDCbk')

const DATA_POC = 0

const gridDef = {
  source: "/registration",
  baseData: "Convention Registrations",
  pageHeader: "Registrations",
  description: "The default registration view",
  pageSize: 100,
  initialStopAfterPage: 1,
  sortBy: ['lastName', 'firstName'],
  sortByDefaultColumn: "fullname", // usually the same as sortBy, but name is a compound
  filterColumn: "fullname",
  availableColumns: [
    {includeInCsv: true, isVisible: true, alwaysVisible: true, label: 'Email', key: 'pk', linkTo: 'person', style: 'width: 10%', mayFilter: true},
    {includeInCsv: true, isVisible: true, label: 'Name', key: 'fullname', mayFilter: true},
    {includeInCsv: false, isVisible: false, label: 'First Name', key: 'firstName', mayFilter: true},
    {includeInCsv: false, isVisible: false, label: 'Last Name', key: 'lastName', mayFilter: true},
    {includeInCsv: true, isVisible: false, label: 'Registration', key: 'registrationTypeName', mayFilter: true},
    {includeInCsv: true, isVisible: false, label: 'Registration Status', key: 'registrationStatus', mayFilter: true},
    {includeInCsv: true, isVisible: false, label: 'Active Member', key: 'isActiveMember', isBoolean: false},
    {includeInCsv: true, isVisible: false, label: 'Exhibitor', key: 'exhibitorOrganization', mayFilter: true},
  ],
  recents: [],
  favorites: []
}

class AdminRoutes extends Component {

    constructor(props) {
      super(props);

      let emptyData = {
        items: [],
        csv: [],
        isLoading: false,
        isLoaded: false,
        pages: 0,
        currentPage: false,
        lastEvaluatedKey: false,
        moreDataAvailable: true,
      }
      // Indexed for various data items

      this.state = {
        error: null,
        isLoaded: false,
        page: null,
        username: this.props.username,
        person: new Person(),
        company: new CompanyClass(),
        chapter: new ChapterClass(),
        loadFailed: false,

        receipts: [],
        receiptsLoaded: false,
        // counts: [],
        // countsLoaded: false,

        people: [],
        peopleCsv: [],
        peopleLoading: false,
        peopleLoaded: false,
        peoplePages: 0,
        peoplePagesCurrent: false,
        peopleLastEvaluatedKey: false,
        peopleMoreDataAvailable: true,

        registration: [],
        registrationCsv: [],
        registrationLoading: false,
        registrationLoaded: false,
        registrationPages: 0,
        registrationPagesCurrent: false,
        registrationLastEvaluatedKey: false,
        registrationMoreDataAvailable: true,

        chapters: [],
        chaptersCsv: [],
        chaptersLoading: false,
        chaptersLoaded: false,
        chaptersPages: 0,
        chaptersPagesCurrent: false,
        chaptersLastEvaluatedKey: false,
        chaptersMoreDataAvailable: true,

        companies: [],
        companiesCsv: [],
        companiesLoading: false,
        companiesLoaded: false,
        companiesPages: 0,
        companiesPagesCurrent: false,
        companiesLastEvaluatedKey: false,
        companiesMoreDataAvailable: true,

        banquet: [],
        banquetCsv: [],
        banquetLoading: false,
        banquetLoaded: false,
        banquetPages: 0,
        banquetPagesCurrent: false,
        banquetLastEvaluatedKey: false,
        banquetMoreDataAvailable: true,

        badge: [],
        badgeStatus: null,
        badgeCsv: [],
        badgeLoading: false,
        badgeLoaded: false,
        badgePages: 0,
        badgePagesCurrent: false,
        badgeLastEvaluatedKey: false,
        badgeMoreDataAvailable: true,

        counts: [],
        countsCsv: [],
        countsLoading: false,
        countsLoaded: false,
        countsPages: 0,
        countsPagesCurrent: false,
        countsLastEvaluatedKey: false,
        countsMoreDataAvailable: true,

        items: [emptyData],
      };
      if (this.props.username) {
        this.fetchData(this.props.username);
      }
      if (this.props.uuid && this.props.entity === 'company') {
        this.fetchCompanyData(this.props.uuid);
      }
      if (this.props.uuid && this.props.entity === 'chapter') {
        this.fetchChapterData(this.props.uuid);
      }

      this.pagination = this.pagination.bind(this)

      // Badges
      this.toggleReviewed = this.toggleReviewed.bind(this)
      this.togglePrinted = this.togglePrinted.bind(this)
      this.updateData = this.updateData.bind(this)
      this.filterDataSet = this.filterDataSet.bind(this)
    }

    componentDidMount() {
      this.pagination('registration', 'next')
      this.pagination('people', 'next')
      this.pagination('chapters', 'next')
      this.pagination('companies', 'next')
      this.pagination('banquet', 'next')
      this.refreshBadges(this.state.badgeLastEvaluatedKey, 'false')
      this.pagination('counts', 'next')
      this.pagination(DATA_POC, 'next')
    }

    filterDataSet(filter) {
      this.setState({
        badge: [],
        badgeStatus: null,
        badgeCsv: [],
        badgeLoading: false,
        badgeLoaded: false,
        badgePages: 0,
        badgePagesCurrent: false,
        badgeLastEvaluatedKey: false,
        badgeMoreDataAvailable: true,
      })
      this.refreshBadges(true, filter) // true forces a load to start even though the state change probably had not happened yet
    }

    pagination(dataset, action) {

      let items = this.state.items
      switch (dataset) {
        case DATA_POC:
          if (action === 'next') {
            if (items[dataset].currentPage + 1 < items[dataset].pages) {
                items[dataset].currentPage += 1
                this.setState({items: items})
            } else {
              this.refreshItem(dataset)
            }
          }
          if (action === 'first') {
            items[dataset].currentPage = 0
            this.setState({items: items})
          }
        break
        case 'registration':
          if (action === 'next') {
            if (this.state.registrationPagesCurrent + 1 < this.state.registrationPages) {
                this.setState({registrationPagesCurrent: this.state.registrationPagesCurrent + 1})
            } else {
              this.refreshRegistration(this.state.registrationLastEvaluatedKey)
            }
          }
          if (action === 'first') {
            this.setState({registrationPagesCurrent: 0})
          }
          break
        case 'people':
          if (action === 'next') {
            if (this.state.peoplePagesCurrent + 1 < this.state.peoplePages) {
                this.setState({peoplePagesCurrent: this.state.peoplePagesCurrent + 1})
            } else {
              this.refreshPeople(this.state.peopleLastEvaluatedKey)
            }
          }
          if (action === 'first') {
            this.setState({peoplePagesCurrent: 0})
          }
          break
        case 'chapters':
          if (action === 'next') {
            if (this.state.chaptersPagesCurrent + 1 < this.state.chaptersPages) {
                this.setState({chaptersPagesCurrent: this.state.chaptersPagesCurrent + 1})
            } else {
              this.refreshChapters(this.state.chaptersLastEvaluatedKey)
            }
          }
          if (action === 'first') {
            this.setState({chaptersPagesCurrent: 0})
          }
          break
        case 'banquet':
          if (action === 'next') {
            if (this.state.banquetPagesCurrent + 1 < this.state.banquetPages) {
                this.setState({banquetPagesCurrent: this.state.banquetPagesCurrent + 1})
            } else {
              this.refreshBanquet(this.state.banquetLastEvaluatedKey)
            }
          }
          if (action === 'first') {
            this.setState({banquetPagesCurrent: 0})
          }
          break
        case 'companies':
          if (action === 'next') {
            if (this.state.companiesPagesCurrent + 1 < this.state.companiesPages) {
                this.setState({companiesPagesCurrent: this.state.companiesPagesCurrent + 1})
            } else {
              this.refreshCompanies(this.state.companiesLastEvaluatedKey)
            }
          }
          if (action === 'first') {
            this.setState({companiesPagesCurrent: 0})
          }
          break
        case 'badge':
          if (action === 'next') {
            if (this.state.badgePagesCurrent + 1 < this.state.badgePages) {
                this.setState({badgePagesCurrent: this.state.badgePagesCurrent + 1})
            } else {
              this.refreshBadges(this.state.badgeLastEvaluatedKey, 'false')
            }
          }
          if (action === 'first') {
            this.setState({badgePagesCurrent: 0})
          }
          break
        case 'counts':
          if (action === 'next') {
            if (this.state.countsPagesCurrent + 1 < this.state.countsPages) {
                this.setState({countsPagesCurrent: this.state.countsPagesCurrent + 1})
            } else {
              this.refreshCounts(this.state.countsLastEvaluatedKey)
            }
          }
          if (action === 'first') {
            this.setState({countsPagesCurrent: 0})
          }
          break
        default:
//          this.refresh()
      }
    }

    refreshItem(dataset) {
      let items = this.state.items
      items[dataset].isLoading = true
      this.setState({items: items})

      if (items[dataset].lastEvaluatedKey || items[dataset].items.length === 0) {
        const paramater = items[dataset].lastEvaluatedKey ? '/list/' + dataset + '?l=' + items[dataset].lastEvaluatedKey : '/list/' + dataset
        API.get('general', paramater)
        .then(response => {

          let items = this.state.items
          let csv = response.items.map(item => {
            return [item.pk.slice(item.pk.indexOf('-') + 1),
                    item.companyName,
                    item.primary_email,
                    item.alternate_email,
                    item.full_name,
                    item.role_name,
                    item.type,
                    item.addressLine1,
                    item.addressLine2,
                    item.city,
                    item.state,
                    item.zipcode,
                    item.phone_number]
          })
          if (items[dataset].csv.length === 0) {
            csv.unshift(['CompanyId',
                         'Company Name',
                         'Primary Email',
                         'Alternate Email',
                         'Fullname',
                         'Role',
                         'Type',
                         'Line 1',
                         'Line 2',
                         'City',
                         'State',
                         'Zip',
                         'Phone'])
          }

          items[dataset].items.push(response.items.sort((a, b) => {
              if (a.full_name.toLowerCase() < b.full_name.toLowerCase()) {return -1}
              if (a.full_name.toLowerCase() > b.full_name.toLowerCase()) {return 1}
              return 0
            }))
          items[dataset].pages += 1
          items[dataset].currentPage = items[dataset].pages
          items[dataset].csv = items[dataset].csv.concat(csv)
          items[dataset].isLoaded = true
          items[dataset].isLoading = false
          items[dataset].lastEvaluatedKey = response.lastEvaluatedKey
          items[dataset].moreDataAvailable = (response.lastEvaluatedKey && response.lastEvaluatedKey !== null )
          this.setState({ items: items })
        })
        .catch(error => {
          let items = this.state.items
          items[dataset].isLoading = false
          this.setState({
            items: items,
            error: error
          })
        })
      }
    }

    refreshBadges(lastEvaluatedKey, printed) {
      this.setState({badgeLoading: true})

      if (lastEvaluatedKey || this.state.badge.length === 0) {
        const paramater = lastEvaluatedKey ? '/badges?printed=' + printed + '&l=' + lastEvaluatedKey : '/badges?printed=' + printed

        API.get('general', paramater)
        .then(response => {

          let csv = response.items.map(item => {

            return [item.pk.slice(item.pk.indexOf('-') + 1),
                    item.fullname,
                    item.firstName,
                    item.lastName,
                    item.nickname,
                    item.private_organization,
                    item.organization,
                    item.base_name,
                    item.rank_name,
                    item.rank_abbreviation,
                    item.status_name,
                    item.chapterName,
                    item.includesGuest,
                    item.guestFirstName,
                    item.guestLastName,
                    item.registrationStatus,
                    item.badgeShowAsExhibitorOnBadge,
                    item.badgeHasSecurityClearance,
                    item.badgeShowRankOnBadge,
                    item.isExhibitor,
                    item.exhibitorOrganization,
                    item.badgeGuestAdminContent,
                    item.badgeAdminContent,
                    item.badgeAwardContent,
                    item.badgeOverrideBaseChapter
                  ]
          })
          if (this.state.badgeCsv.length === 0) {
            csv.unshift(['email',
                         'fullname',
                         'firstName',
                         'lastName',
                         'nickname',

                         'private_organization',
                         'organization',
                         'base_name',
                         'rank_name',
                         'rank_abbreviation',
                         'status_name',

                         'chapterName',
                         'includesGuest',
                         'guestFirstName',

                         'guestLastName',
                         'registrationStatus',
                         'badgeShowAsExhibitorOnBadge',
                         'badgeHasSecurityClearance',

                         'badgeShowRankOnBadge',
                         'isExhibitor',

                         'exhibitorOrganization',
                         'badgeGuestAdminContent',
                         'badgeAdminContent',
                         'badgeAwardContent',
                         'badgeOverrideBaseChapter'
                       ]
                         )
          }

          let badge = this.state.badge
          let i = 0
          let pages = 0
          do {
            badge.push(response.items.slice(i, i + 60))
            i += 60
            pages += 1
          } while (i < response.items.length)


          // This is a special case were we get the complete list initially
          // and split it into pages.

          this.setState({
            badge: badge,
            badgeStatus: response.badgeSummary,
            badgePages: pages,
            badgePagesCurrent: this.state.badgePages,
            badgeCsv: this.state.badgeCsv.concat(csv),
            badgeLoaded: true,
            badgeLoading: false,
            badgeLastEvaluatedKey: null,
            badgeMoreDataAvailable: false
          })
        })
        .catch(error => {
          this.setState({
            badgeLoading: false,
            error: error
          })
        })
      }
    }



    refreshBanquet(lastEvaluatedKey) {
      this.setState({banquetLoading: true})
      if (lastEvaluatedKey || this.state.banquet.length === 0) {
        const paramater = lastEvaluatedKey ? '/banquet?l=' + lastEvaluatedKey : '/banquet'
        API.get('banquet', paramater)
        .then(response => {

          let csv = response.items.map(item => {

            return [item.pk.slice(item.pk.indexOf('-') + 1),
                    item.willAttendBanquet,
                    item.firstTimeAttendee,

                    item.service_name,
                    item.rank_abbreviation,
                    item.status_name,
                    item.fullname,
                    item.firstName,
                    item.lastName,

                    item.guestOfFullname,
                    item.guestOfEmail,
                    item.guestFullname,
                    item.spouse_first_name,
                    item.spouse_last_name,

                    item.chapterName,
                    item.base_name,
                    item.organization,
                    item.role,
                    item.exhibitorOrganization,

                    item.seatingRequest,
                    item.dietaryPreference,

                    item.requestedTable,
                    item.assignedGroup,
                    item.isVip
                  ]
          })
          if (this.state.banquetCsv.length === 0) {
            csv.unshift(['Email',
                         'Will Attend Banquet',
                         'First Time Attendee',

                         'Service Name',
                         'Rank Abbreviation',
                         'Service Status',
                         'Fullname',
                         'First Name',
                         'Last Name',

                         'Guest of Fullname',
                         'Guest of Email',
                         'Guest Fullname',
                         'Spouse First Name',
                         'Spouse Last Name',

                         'Chapter Name',
                         'Base Name',
                         'Organization',
                         'Role',
                         'Exhibitor Org',

                         'Seating Request',
                         'Dietary Peference',

                         'Requested Table',
                         'Assigned Group',
                         'VIPs'
                       ]
                         )
          }

          let banquet = this.state.banquet
          banquet.push(response.items)

          this.setState({
            banquet: banquet,
            banquetPages: this.state.banquetPages + 1,
            banquetPagesCurrent: this.state.banquetPages,
            banquetCsv: this.state.banquetCsv.concat(csv),
            banquetLoaded: true,
            banquetLoading: false,
            banquetLastEvaluatedKey: response.lastEvaluatedKey,
            banquetMoreDataAvailable: (response.lastEvaluatedKey && response.lastEvaluatedKey !== null )
          })
        })
        .catch(error => {
          this.setState({
            banquetLoading: false,
            error: error
          })
        })
      }
    }


    refreshRegistration(lastEvaluatedKey) {
      this.setState({registrationLoading: true})
      if (lastEvaluatedKey || this.state.registration.length === 0) {
        const paramater = lastEvaluatedKey ? '/registration?l=' + lastEvaluatedKey : '/registration'
        API.get('general', paramater)
        .then(response => {

          let csv = response.items.map(item => {
            let willAttendBanquet = item.willAttendBanquet ?? true

            return [item.pk.slice(item.pk.indexOf('-') + 1),
                    item.alternate_email_address,
                    item.fullname,
                    item.firstName,
                    item.lastName,
                    item.nickname,

                    item.suffix,
                    item.nationality_name,
                    item.spouse_first_name,
                    item.spouse_last_name,
                    item.includesGuest,
                    item.guestFirstName,
                    item.guestLastName,

                    item.receive_atq,

                    item.rank_abbreviation,
                    item.rank,
                    item.date_of_rank,
                    item.service_name,
                    item.status_name,
                    item.organization,
                    item.role,

                    item.base_name,
                    item.civilan_rank,
                    item.civilan_rank_abbreviation,
                    item.civilan_service_name,
                    item.civil_service_non_standard_rank,

                    item.civilan_organization,
                    item.civilan_status,
                    item.civilan_grade,
                    item.civilan_role,
                    item.private_title,

                    item.private_organization,
                    item.membershipTypeName,
                    item.lastDayOfMembership ? moment(item.lastDayOfMembership).format('YYYY-MM-DD') : null,
                    item.registrationTypeName,
                    item.registrationStatus,
                    item.includesIid,
                    item.firstTimeAttendee,
                    item.hotelStatus,
                    item.exhibitorOrganization,

                    item.registrationTotalPrice,
                    willAttendBanquet ? 'Yes' : 'No',
                    item.registrationUpdatedAt,

                    item.badgeHasSecurityClearance,
                    item.badgeShowAsExhibitorOnBadge,
                    item.badgeAdminContent,
                    item.badgeGuestAdminContent]
          })
          if (this.state.registrationCsv.length === 0) {
            csv.unshift(['Email',
                         'Alternate Email',
                         'Fullname',
                         'First Name',
                         'Last Name',
                         'Nickname',

                         'Suffix',
                         'Nationality',
                         'Spouse First Name',
                         'Spouse Last Name',
                         'Includes Guest',
                         'Guest First Name',
                         'Guest Last Name',
                         'Receive ATQ',

                         'Rank Abbreviation',
                         'Rank',
                         'Date of Rank',
                         'Service Name',
                         'Service Status',
                         'Organization',
                         'Role',

                         'Base Name',
                         'Civilian Rank',
                         'Civilian Rank Abbreviation',
                         'Civilian Service',
                         'Civilian Non Standard Rank',

                         'Civilian Org',
                         'Civilian Status',
                         'Civilian Grade',
                         'Civilian Role',
                         'Private Title',

                         'Private Org',
                         'Membership',
                         'Membership End',
                         'Registration Type',
                         'Registration Status',
                         'Includes IID',
                         'First Time Attendee',
                         'Hotel Status',
                         'Exhibitor Org',

                         'Registration Price',
                         'Will Attend Banquet',
                         'Last Update',

                         'Badge Security',
                         'Badge Show As Exhibitor',
                         'Badge Admin Line',
                         'Badge Admin Line (Guest)']
                         )
          }

          let registration = this.state.registration
          registration.push(response.items)
          this.setState({
            registration: registration,
            registrationPages: this.state.registrationPages + 1,
            registrationPagesCurrent: this.state.registrationPages,
            registrationCsv: this.state.registrationCsv.concat(csv),
            registrationLoaded: true,
            registrationLoading: false,
            registrationLastEvaluatedKey: response.lastEvaluatedKey,
            registrationMoreDataAvailable: (response.lastEvaluatedKey && response.lastEvaluatedKey !== null )
          })
        })
        .catch(error => {
          this.setState({
            registrationLoading: false,
            error: error
          })
        })
      }
    }

    refreshPeople(lastEvaluatedKey) {
      this.setState({peopleLoading: true})
      if (lastEvaluatedKey || this.state.people.length === 0) {
        const paramater = lastEvaluatedKey ? '/people?l=' + lastEvaluatedKey : '/people'
        API.get('general', paramater)
        .then(response => {

          let csv = response.items.map(item => {
            return [item.pk.slice(item.pk.indexOf('-') + 1),
                    item.alternate_email_address,
                    item.fullname,
                    item.firstName,
                    item.lastName,
                    item.suffix,
                    item.nationality_name,
                    item.spouse_first_name,
                    item.spouse_last_name,
                    item.receive_atq,
                    item.rank_abbreviation,
                    item.rank,
                    item.date_of_rank,
                    item.service_name,
                    item.status_name,
                    item.base_name,
                    item.civilan_rank,
                    item.civilan_rank_abbreviation,
                    item.civilan_service_name,
                    item.civil_service_non_standard_rank,
                    item.civilan_organization,
                    item.civilan_status,
                    item.civilan_grade,
                    item.civilan_role,
                    item.private_title,
                    item.private_organization,
                    item.membershipTypeName,
                    item.lastDayOfMembership ? moment(item.lastDayOfMembership).format('YYYY-MM-DD') : null,
                    item.registrationTypeName,
                    item.registrationStatus,
                    item.registrationTotalPrice
                  ]
          })
          if (this.state.people.length === 0) {
            csv.unshift(['Email',
                         'Alternate Email',
                         'Full Name',
                         'First Name',
                         'Last Name',
                         'Suffix',
                         'Nationality',
                         'Spouse First Name',
                         'Spouse Last Name',
                         'Receive ATQ',
                         'Rank Abbreviation',
                         'Rank',
                         'Date of Rank',
                         'Service Name',
                         'Service Status',
                         'Base Name',
                         'Civilian Rank',
                         'Civilian Rank Abbreviation',
                         'Civilian Service',
                         'Civilian Non Standard Rank',
                         'Civilian Org',
                         'Civilian Status',
                         'Civilian Grade',
                         'Civilian Role',
                         'Private Title',
                         'Private Org',
                         'Membership',
                         'Membership End',
                         'Registration Type',
                         'Registration Status',
                         'Registration Price'
                        ])
          }

          let people = this.state.people
          people.push(response.items)
          this.setState({
            people: people,
            peoplePages: this.state.peoplePages + 1,
            peoplePagesCurrent: this.state.peoplePages,
            peopleCsv: this.state.peopleCsv.concat(csv),
            peopleLoaded: true,
            peopleLoading: false,
            peopleLastEvaluatedKey: response.lastEvaluatedKey,
            peopleMoreDataAvailable: (response.lastEvaluatedKey && response.lastEvaluatedKey !== null )
          })
        })
        .catch(error => {
          this.setState({
            peopleLoading: false,
            error: error
          })
        })
      }
    }

    refreshChapters(lastEvaluatedKey) {
      this.setState({chaptersLoading: true})
      if (lastEvaluatedKey || this.state.chapters.length === 0) {
        const paramater = lastEvaluatedKey ? '/chapters?l=' + lastEvaluatedKey : '/chapters'
        API.get('general', paramater)
        .then(response => {

          let csv = response.items.map(item => {
            return [item.name,
                    item.presidentName,
                    item.presidentEmail,
                    item.base
                  ]
          })
          if (this.state.registrationCsv.length === 0) {
            csv.unshift(['Chapter',
                         'President',
                         'President Email',
                         'Base'
                        ])
          }

          let chapters = this.state.chapters
          chapters.push(response.items.sort((a, b) => {
              if (a.name.toLowerCase() < b.name.toLowerCase()) {return -1}
              if (a.name.toLowerCase() > b.name.toLowerCase()) {return 1}
              return 0
            }))
          this.setState({
            chapters: chapters,
            chaptersPages: this.state.chaptersPages + 1,
            chaptersPagesCurrent: this.state.chaptersPages,
            chaptersCsv: this.state.chaptersCsv.concat(csv),
            chaptersLoaded: true,
            chaptersLoading: false,
            chaptersLastEvaluatedKey: response.lastEvaluatedKey,
            chaptersMoreDataAvailable: (response.lastEvaluatedKey && response.lastEvaluatedKey !== null )
          })
        })
        .catch(error => {
          this.setState({
            chaptersLoading: false,
            error: error
          })
        })
      }
    }


    refreshCompanies(lastEvaluatedKey) {
      this.setState({companiesLoading: true})
      if (lastEvaluatedKey || this.state.companies.length === 0) {
        const paramater = lastEvaluatedKey ? '/companies?l=' + lastEvaluatedKey : '/companies'
        API.get('general', paramater)
        .then(response => {

          let csv = response.items.map(item => {
            return [item.name,
                    item.isIndustryPartner ? 'Yes' : 'No',
                    item.industryPartnershipEndDate ? moment(item.industryPartnershipEndDate).format('YYYY-MM-DD') : '',
                    item.full_address,
                    item.primary_poc_full_name,
                    item.primary_poc_phone_number,
                    item.primary_poc_primary_email,
                    item.primary_alternate_email,
                    item.line_1,
                    item.line_2,
                    item.city,
                    item.state_or_province,
                    item.postal_code,
                  ]
          })
          if (this.state.companiesCsv.length === 0) {
            csv.unshift(['Company',
                         'Is Industry Partner',
                         'Partnership End Date',
                         'Address',
                         'POC Name',
                         'POC Phone',
                         'POC Email',
                         'POC Alternate Email',
                         'Address Line 1',
                         'Address Line 2',
                         'City',
                         'State',
                         'Zipcode'
                        ])
          }

          let companies = this.state.companies
          companies.push(response.items.sort((a, b) => {
              let aName = a.name ?? ''
              let bName = b.name ?? ''
              if (aName.toLowerCase() < bName.toLowerCase()) {return -1}
              if (aName.toLowerCase() > bName.toLowerCase()) {return 1}
              return 0
            })
          )
          this.setState({
            companies: companies,
            companiesPages: this.state.companiesPages + 1,
            companiesPagesCurrent: this.state.companiesPages,
            companiesCsv: this.state.companiesCsv.concat(csv),
            companiesLoaded: true,
            companiesLoading: false,
            companiesLastEvaluatedKey: response.lastEvaluatedKey,
            companiesMoreDataAvailable: (response.lastEvaluatedKey && response.lastEvaluatedKey !== null )
          })
        })
        .catch(error => {
          this.setState({
            companiesLoading: false,
            error: error
          })
        })
      }
    }

    refreshCounts(lastEvaluatedKey) {
      this.setState({countsLoading: true})
      if (lastEvaluatedKey || this.state.counts.length === 0) {
        const paramater = lastEvaluatedKey ? '/counts?l=' + lastEvaluatedKey : '/counts'
        API.get('general', paramater)
        .then(response => {

          let counts = this.state.counts
          counts.push(response.items)
          this.setState({
            counts: counts,
            countsPages: this.state.countsPages + 1,
            countsPagesCurrent: this.state.countsPages,
            countsCsv: {},
            countsLoaded: true,
            countsLoading: false,
            countsLastEvaluatedKey: response.lastEvaluatedKey,
            countsMoreDataAvailable: (response.lastEvaluatedKey && response.lastEvaluatedKey !== null )
          })
        })
        .catch(error => {
          this.setState({
            countsLoading: false,
            error: error
          })
        })
      }
    }

    refresh(lastEvaluatedKey) {
      this.setState({refreshing: true})


      API.get('general', '/cart')
      .then(response => {
        // Sorting and filter not required here.
        // was item.status
        const items = response.items.filter(item => item.primaryRegistrationType && item.updated_at).sort(function(a, b) {
          if (a.updated_at > b.updated_at) {return -1}
          if (a.updated_at < b.updated_at) {return 1}
          return 0
        })

        let counts = {
          complete: {virtualOnOrdersAttendee: 0, virtualAttendee: 0},
          "pending-payment": {virtualOnOrdersAttendee: 0, virtualAttendee: 0},
          "cancellation-confirmed": {virtualOnOrdersAttendee: 0, virtualAttendee: 0},
          "cancellation-requested": {virtualOnOrdersAttendee: 0, virtualAttendee: 0},
          "none": {virtualOnOrdersAttendee: 0, virtualAttendee: 0},
        }

        for (let i = 0; i <items.length; i += 1) {
          if (items[i].status) {
            counts[items[i].status][items[i].primaryRegistrationType.sk] += 1
          } else {
            if (items[i].primaryRegistrationType.sk) {
              counts.none[items[i].primaryRegistrationType.sk] += 1
            }
          }
        }

        let csv = response.items.filter(item => item.primaryRegistrationType).map(item => {
          return [item.pk.slice(item.pk.indexOf('-') + 1),
                  item.primaryRegistrationType.name,
                  typeof(item.secondaryItems) !== 'undefined' ? 'IID' : '',
                  item.status,
                  '$' + item.totalPrice,
                  moment(item.updated_at).format('YYYY-MM-DD')]
        })
        csv.unshift(['email', 'registration type', 'Includes IID', 'status', 'price', 'last updated'])

        this.setState({
          registrations: items,
          csvRegistrations: csv,
          registrationsLoaded: true,
          registrationCounts: counts,
          refreshing: false,
        })
      })
      .catch(error => {
        this.setState({
          registrationsLoaded: false,
          error: error,
          refreshing: false
        })
      })

      API.get('general', '/receipt')
      .then(response => {
        // Sorting and filter not required here.
        const items = response.items
          .sort(function(a, b) {
          if (a.created_at > b.created_at) {return -1}
          if (a.created_at < b.created_at) {return 1}
          return 0
        })
        this.setState({
          receipts: items,
          receiptsLoaded: true,
        })
      })
      .catch(error => {
        this.setState({
          receiptsLoaded: false,
          error: error
        })
      })

      API.get('general', '/counts')
      .then(response => {
        // Sorting and filter not required here.
        this.setState({
          counts: response.items,
          countsLoaded: true,
        })
      })
      .catch(error => {
        this.setState({
          countsLoaded: false,
          error: error
        })
      })
    }

    // Start badge section


    // componentDidUpdate(prevProps, prevState, snapshot) {
    //   if (prevState.badgeStatus.verified !== this.state.badgeStatus.verified || prevState.printed !== this.state.badgeStatus.printed) {
    //     this.updateData()
    //   }
    // }

    updateData() {
      API.put('general',
              '/badges',
              {body:  {versionTimestamp: this.state.badgeStatus.versionTimestamp, printed: this.state.badgeStatus.printed, verified: this.state.badgeStatus.verified}})
      .then(response => {

        this.setState({badgeStatus: {versionTimestamp: response.versionTimestamp, printed: this.state.badgeStatus.printed, verified: this.state.badgeStatus.verified}})
      })
      .catch(error => {
        if (error.response.status === 409) {
          alert('The status has been updated from another window or browser. Your request was not saved. Refresh you page to continue.')
        }
        console.log(error)
      })
    }


    toggleReviewed(pk) {

      let reviewed = this.state.badgeStatus.verified.slice()
      if (reviewed.includes(pk)) {
        reviewed = reviewed.filter(item => item !== pk)
      } else {
        reviewed.push(pk)
      }
      this.setState({
        badgeStatus: {versionTimestamp: this.state.badgeStatus.versionTimestamp, verified: reviewed, printed: this.state.badgeStatus.printed}
      })

    }

    togglePrinted(pk) {
      let printed = this.state.badgeStatus.printed.slice()
      if (printed.includes(pk)) {
        printed = printed.filter(item => item !== pk)
      } else {
        printed.push(pk)
      }
      this.setState({
        badgeStatus: {versionTimestamp: this.state.badgeStatus.versionTimestamp, verified: this.state.badgeStatus.verified, printed: printed}
      })

    }



    // End badge section

    componentDidUpdate(prevProps, prevState) {

      if (this.props.username && this.props.username !== prevProps.username) {
        this.fetchData(this.props.username);
      }

      // For badges
      if (prevState.badgeStatus && this.state.badgeLoaded) {
        if (prevState.badgeStatus.verified !== this.state.badgeStatus.verified || prevState.badgeStatus.printed !== this.state.badgeStatus.printed) {
          this.updateData()
        }

      }
    }

    fetchData(username) {
      this.state.person.get(username.toLowerCase())
      .then(() => {
        this.setState({isLoaded: true, loadFailed: false, username: username})
      })
      .catch(error => {
        this.setState({isLoaded: false, loadFailed: true, error: error, username: username})
        console.log(error)
      })
    }

    fetchCompanyData(uuid) {
      this.state.company.get(uuid)
      .then(() => {
        this.setState({isLoaded: true, loadFailed: false})
      })
      .catch(error => {
        this.setState({isLoaded: false, loadFailed: true, error: error})
        console.log(error)
      })
    }

    fetchChapterData(uuid) {
      this.state.chapter.get(uuid)
      .then(() => {
        this.setState({isLoaded: true, loadFailed: false})
      })
      .catch(error => {
        this.setState({isLoaded: false, loadFailed: true, error: error})
        console.log(error)
      })
    }

    render() {

      const { error, isLoaded, loadFailed } = this.state;
      const {username} = this.props

      if (error && username) {
        return <ErrorContainer title="Error" error={error} />
      } else if (loadFailed && username) {
        return <ErrorContainer title="Error" error={{message:'The account information failed to load.'}} />
      } else if (!isLoaded && username) {
        return <Loading title="Individual Profile"
                        subtitle=""
                        size="h1" />
      }

      if (!this.props.adminMenusPending && !this.props.adminMenus) {return <Redirect to="/" />}

      return (
        <>
        <PersonContext.Provider value={{person: this.state.person}}>
          <Route exact path="/admin" >
            <Admin
              registration={{
                registration: this.state.registration,
                registrationPages: this.state.registrationPages,
                registrationPagesCurrent: this.state.registrationPagesCurrent,
                registrationCsv: this.state.registrationCsv,
                registrationLoaded: this.state.registrationLoaded,
                registrationLoading: this.state.registrationLoading,
                registrationLastEvaluatedKey: this.state.registrationLastEvaluatedKey,
                registrationMoreDataAvailable: this.state.registrationMoreDataAvailable
              }}
              people={{
                people: this.state.people,
                peoplePages: this.state.peoplePages,
                peoplePagesCurrent: this.state.peoplePagesCurrent,
                peopleCsv: this.state.peopleCsv,
                peopleLoaded: this.state.peopleLoaded,
                peopleLoading: this.state.peopleLoading,
                peopleLastEvaluatedKey: this.state.peopleLastEvaluatedKey,
                peopleMoreDataAvailable: this.state.peopleMoreDataAvailable
              }}
              chapters={{
                chapters: this.state.chapters,
                chaptersPages: this.state.chaptersPages,
                chaptersPagesCurrent: this.state.chaptersPagesCurrent,
                chaptersCsv: this.state.chaptersCsv,
                chaptersLoaded: this.state.chaptersLoaded,
                chaptersLoading: this.state.chaptersLoading,
                chaptersLastEvaluatedKey: this.state.chaptersLastEvaluatedKey,
                chaptersMoreDataAvailable: this.state.chaptersMoreDataAvailable
              }}
              badge={{
                badge: this.state.badge,
                badgeStatus: this.state.badgeStatus,
                badgePages: this.state.badgePages,
                badgePagesCurrent: this.state.badgePagesCurrent,
                badgeCsv: this.state.badgeCsv,
                badgeLoaded: this.state.badgeLoaded,
                badgeLoading: this.state.badgeLoading,
                badgeLastEvaluatedKey: this.state.badgeLastEvaluatedKey,
                badgeMoreDataAvailable: this.state.badgeMoreDataAvailable
              }}
              banquet={{
                banquet: this.state.banquet,
                banquetPages: this.state.banquetPages,
                banquetPagesCurrent: this.state.banquetPagesCurrent,
                banquetCsv: this.state.banquetCsv,
                banquetLoaded: this.state.banquetLoaded,
                banquetLoading: this.state.banquetLoading,
                banquetLastEvaluatedKey: this.state.banquetLastEvaluatedKey,
                banquetMoreDataAvailable: this.state.banquetMoreDataAvailable
              }}
              companies={{
                companies: this.state.companies,
                companiesPages: this.state.companiesPages,
                companiesPagesCurrent: this.state.companiesPagesCurrent,
                companiesCsv: this.state.companiesCsv,
                companiesLoaded: this.state.companiesLoaded,
                companiesLoading: this.state.companiesLoading,
                companiesLastEvaluatedKey: this.state.companiesLastEvaluatedKey,
                companiesMoreDataAvailable: this.state.companiesMoreDataAvailable
              }}
              items={this.state.items}
              receipts={this.state.receipts}
              receiptsLoaded={this.state.receiptsLoaded}
              registrations={this.state.registrations}
              csvRegistrations={this.state.csvRegistrations}
              registrationsLoaded={this.state.registrationsLoaded}
              registrationCounts={this.state.registrationCounts}
              countsLoaded={this.state.countsLoaded}
              counts={this.state.counts}
              pagination={this.pagination}
              refreshing={this.state.refreshing}
              toggleReviewed={this.toggleReviewed}
              togglePrinted={this.togglePrinted}
              filterDataSet={this.filterDataSet}
            />
          </Route>
          <Route exact path="/admin/peoplelist" >
            <Grid
              gridDef={gridDef}
            />
          </Route>
          <Route exact path="/admin/coupon" >
            <Coupon />
          </Route>
          <Route exact path="/admin/account/:username">
            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({elements, stripe}) => (
                  <AdminAccount elements={elements} stripe={stripe} />
                )}
              </ElementsConsumer>
            </Elements>
          </Route>
          <Route exact path="/admin/iid/:username">
            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({elements, stripe}) => (
                  <AdminIid elements={elements} stripe={stripe} />
                )}
              </ElementsConsumer>
            </Elements>
          </Route>
          <Route exact path="/admin/catalog/:username">
            <AdminCatalog />
          </Route>
          <Route exact path="/admin/cart/:username">
            <AdminCart receipt={false}/>
          </Route>
          <Route exact path="/admin/receipt/:username">
            <AdminCart receipt={true}/>
          </Route>
          <Route exact path="/admin/checkout/:username">
            <AdminCheckout />
          </Route>
          <Route exact path="/admin/payment/:username">
            <AdminPayment />
          </Route>
        </PersonContext.Provider>
        <CompanyContext.Provider value={{company: this.state.company}}>
          <Route exact path="/admin/company/:uuid">
            <AdminCompany />
          </Route>
        </CompanyContext.Provider>
        <ChapterContext.Provider value={{chapter: this.state.chapter}}>
          <Route exact path="/admin/chapter/:uuid">
            <AdminChapter />
          </Route>
        </ChapterContext.Provider>

        </>
      )
    }

}

export default AdminRoutes

function AdminAccount(props) {
  let {username} = useParams()
  username=username.toLowerCase()

  return (
    <Account username={username} isAdmin={true} elements={props.elements} stripe={props.stripe}/>
  )
}
function AdminIid(props) {
  let {username} = useParams();
  return (
    <AddIid uuid={username} isAdmin={true}  elements={props.elements} stripe={props.stripe}/>
  )
}
function AdminCatalog() {
  let {username} = useParams();
  return (
    <Catalog username={username}  isAdmin={true} />
  )
}
function AdminCart(props) {
  let {username} = useParams();
  return (
    <Cart username={username} receipt={props.receipt} isAdmin={true} />
  )
}
function AdminCheckout() {
  let {username} = useParams();
  return (
    <Checkout username={username} isAdmin={true} />
  )
}
function AdminPayment() {
  let {username} = useParams();
  return (
    <Payment username={username} isAdmin={true} />
  )
}
function AdminCompany() {
  let {uuid} = useParams();
  return (
    <Company uuid={uuid} isAdmin={true} />
  )
}
function AdminChapter() {
  let {uuid} = useParams();
  return (
    <Chapter uuid={uuid} isAdmin={true} />
  )
}
