import React, { Component } from 'react'

class ErrorMessage extends Component {

  render() {

    if (typeof(this.props.message) === 'undefined' || this.props.message === null || this.props.message === '' ) {
      return null
    }
    return (
      <div className="mt error-message" name="errorMessageText">
        {this.props.message}
      </div>
    )
  }
}

export default ErrorMessage
