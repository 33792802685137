import { CSVLink } from "react-csv"
import Loading from '../../Loading'
import PersonWithActions from './common/PersonWithActions'
import React, { Component } from 'react'
import cookie from 'react-cookies'

var moment = require('moment')

class AdminPeople extends Component {

  constructor(props) {
    super(props)

    this.state = {
      showActive: cookie.load('filter-people-showActive'),
      showGrace: false,
      showExpired: false,
      showNon: false,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    console.log('showActive', cookie.load('filter-people-showActive'))
    // this.setState({
    //   showActive: cookie.load('filter-people-showActive'),
    //   showGrace: cookie.load('filter-people-showGrace'),
    //   showExpired: cookie.load('filter-people-showExpired'),
    //   showNon: cookie.load('filter-people-showNon')
    // })
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

    cookie.save('filter-people-' + name, value, { path: '/' })
  }

render() {
  if (this.props.filter !== 'people') {return null}

  const {people, peoplePages, peoplePagesCurrent,
         peopleCsv, peopleLoading,
         peopleMoreDataAvailable} = this.props.people

  const {deletedPeople, deletePerson} = this.props
  const totalPeople = (people.length > 0)  ?
        people.map(page => {return page.length}).reduce((a, b) => {return a+b}) :
        0
  return (
    <>
    <h2>Detail
    <span style={{marginLeft: 10, fontSize: 'smaller'}}>
       <CSVLink
         style={{verticalAlign: 'bottom'}}
         data={peopleCsv}
       >
        <i className="fas fa-file-spreadsheet fa-2x" style={{fontSize: 'smaller'}}> </i>
       </CSVLink>
     </span>
     <span>
       {peoplePagesCurrent === 0 || peopleLoading ?
         <span style={{marginLeft: 5, fontSize: 'small', color: 'grey', cursor: 'pointer'}} >
           <i className="fas fa-fast-backward"></i>
         </span> :
         <span style={{marginLeft: 5, fontSize: 'small', color: '#b50909', cursor: 'pointer'}} onClick={() => this.props.pagination('people', 'first')}>
           <i className="fas fa-fast-backward"></i>
         </span>
       }
       {(peopleMoreDataAvailable || peoplePagesCurrent + 1 !== peoplePages) && !peopleLoading ?
         <span>
           <span style={{fontSize: 'small', fontWeight: 'normal', paddingLeft: 5}}>Page {peoplePagesCurrent + 1} of {peopleMoreDataAvailable ?
                <span>unknown</span> :
                <span>{peoplePages}</span>}
           </span>
           <span style={{fontSize: 'small', fontWeight: 'normal'}}> ({totalPeople} items)</span>
           <span style={{marginLeft: 5, fontSize: 'small', color: '#b50909', cursor: 'pointer'}} disabled={!peopleMoreDataAvailable} onClick={() => this.props.pagination('people', 'next')}>
             <i className="fas fa-forward"></i>
           </span>
        </span> :
        <span>
          <span style={{fontSize: 'small', fontWeight: 'normal', paddingLeft: 5}}>Page {peoplePagesCurrent + 1} of {peopleMoreDataAvailable ?
            <span>unknown</span> :
            <span>{peoplePages}</span>} </span>
          <span style={{fontSize: 'small', fontWeight: 'normal'}}>({totalPeople} items)</span>
          <span style={{marginLeft: 5, fontSize: 'small', color: 'grey', cursor: 'pointer'}}>
            <i className="fas fa-forward"></i>
          </span>
        </span>
       }
     </span>
     {peopleLoading &&
       <i className="fas fa-sync-alt fa-spin" style={{marginLeft: 5, fontSize: 'small', color: '#b50909'}} ></i>
     }
     </h2>
    <div className="grid-row grid-gap" style={{marginBottom: 6, fontSize: 'smaller'}}>
      <div className="tablet:grid-col-4" style={{marginTop: '0.5rem', textAlign: 'right'}}>
      </div>
      <div className="tablet:grid-col-8" style={{marginTop: '0.5rem', textAlign: 'right'}}>
        <div className="usa-checkbox" style={{display: 'inline-block', marginLeft: 30}}>
           <input
             className="usa-checkbox__input"
             id="showActive"
             type="checkbox"
             name="showActive"
             value="showActive"
             checked={this.state.showActive}
             onChange ={this.handleChange}/>
           <label className="usa-checkbox__label" htmlFor="showActive">Active</label>
        </div>
        <div className="usa-checkbox" style={{display: 'inline-block', marginLeft: 30}}>
          <input
            className="usa-checkbox__input"
            id="showGrace"
            type="checkbox"
            name="showGrace"
            value="showGrace"
            checked={this.state.showGrace}
            onChange ={this.handleChange}/>
          <label className="usa-checkbox__label" htmlFor="showGrace">Grace</label>
        </div>
        <div className="usa-checkbox" style={{display: 'inline-block', marginLeft: 30}}>
          <input
            className="usa-checkbox__input"
            id="showExpired"
            type="checkbox"
            name="showExpired"
            value="showExpired"
            checked={this.state.showExpired}
            onChange ={this.handleChange}/>
          <label className="usa-checkbox__label" htmlFor="showExpired">Expired</label>
        </div>
        <div className="usa-checkbox" style={{display: 'inline-block', marginLeft: 30}}>
          <input
            className="usa-checkbox__input"
            id="showNon"
            type="checkbox"
            name="showNon"
            value="showNon"
            checked={this.state.showNon}
            onChange ={this.handleChange}/>
          <label className="usa-checkbox__label" htmlFor="showNon">Never</label>
        </div>
      </div>
    </div>


    { peopleLoading && people.length === 0 ?
      <Loading title="" subtitle=""/> :
      <table className="usa-table usa-table--borderless" style={{width:'100%', marginBottom: 32, marginTop: 0, fontSize: 'smaller'}}>
        <thead>
          <tr>
            <th>Email Address</th>
            <th>Contact Info</th>
            <th>Membership</th>
            <th>Current Membership End</th>
            <th>Last Updated</th>
          </tr>
        </thead>
        <tbody>
          {people.length > 0 && people[peoplePagesCurrent].map(item =>
            <PeopleItem key={item.pk} item={item} deletedPeople={deletedPeople} deletePerson={deletePerson}
                        showActive={this.state.showActive} showGrace={this.state.showGrace}
                        showNon={this.state.showNon} showExpired={this.state.showExpired}
                        />
          )}
        </tbody>
      </table>
    }

    </>
  )
}
}

function PeopleItem(props) {
  const {deletePerson, deletedPeople, item, showActive, showGrace, showNon, showExpired} = props

  if (deletedPeople.includes(item.pk)) {return null}

  const state = (typeof(item.stateOrProvince) === 'undefined' || item.stateOrProvince === 'none') ? '' : ', ' + item.stateOrProvince
  const fullname = item.fullname ? item.fullname : item.firstName + ' ' + item.middleInitial + ' ' + item.lastName

  let color = 'default'
  if (item.isInGracePeriodMember) {color = 'darkgoldenrod'}
  if (item.isExpiredMember) {color = 'grey'} //'#b50909'

  if (!(showActive || showGrace || showNon || showExpired)) {return null}
  let show = false

  if (!item.membershipTypeName && showNon) {show = true}
  if (item.isInGracePeriodMember && showGrace) {show = true}
  if (item.isExpiredMember && showExpired) {show = true}
  if (item.isActiveMember && showActive) {show = true}

  if (!show) {return null}
  return (
    <tr  style={{verticalAlign: 'top'}}>
      <td>
        <PersonWithActions
          item={item}
          deletePerson={deletePerson}
        />
      </td>
      <td>
        <span style={{fontWeight: '600'}}>{item.rank_name && item.rank_name} {fullname.replaceAll('undefined', '')} {item.status === 'retired' && <span> (Ret)</span>} </span><br/>
        <span style={{fontSize: 'small'}}><em>{item.addressLine1} {item.addressLine2} {item.city}{state} {item.postalCode} {item.country}</em></span>
      </td>
      <td style={{color: color}}>
        {item.membershipTypeName} <br />
        <span style={{fontSize: 'small'}}>{item.associatedCompany}</span>
      </td>
      <td style={{color: color}}>{item.lastDayOfMembership && moment(item.lastDayOfMembership).format('YYYY-MM-DD')}</td>
      <td>{moment(item.gsi1_sk).format('YYYY-MM-DD')}</td>
    </tr>
  )
}

export default AdminPeople;
