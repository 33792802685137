import React from 'react'
import { Link } from "react-router-dom"

export default function Hero()  {

  return (
    <section className="usa-hero" style={{backgroundImage: 'url(/images/hero-hof-1.jpg)'}}>
      <div className="grid-container">
        <div className="usa-hero__callout">
          <h1 className="usa-hero__heading">
          <span className="usa-hero__heading--alt">Convention 2021 Replay</span>
          </h1>
          <p>Recordings of the 2021 Convention Keynote Seminars are now available.</p>
          <Link
            to="/about-the-convention/media"
            title="Watch"
            aria-label="Attend the Convention"
            className="usa-button"
          >
          View
          </Link>
        </div>
      </div>
    </section>
  )
}
