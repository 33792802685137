import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'

import {PersonContext} from '../../../classes/context.js';

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class BadgeInformation extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  nn(field) {
    let person = this.context.person
    let item = person.editItem ? person.editItem : person.item
    return item.cart[field] ? item.cart[field] : -1
  }

  handleChange(event) {
    let person = this.context.person
    if (!person.editItem) {person.beginEdit()}
    const handleChange = person.handleChange.bind(person)
    handleChange(event)
  }

  render() {

    let person = this.context.person

    if (!person.item.cart) {return null}


    return (
      <>
      <h2 className="margin-y-5" style={{marginBottom: 0}}>Badge Information</h2>

      <div className="grid-row grid-gap margin-y-0">
        <div className="grid-col-4">
          <div className="usa-label">Has Security Clearance</div>
          <Form.Control
            className="usa-select"
            style={editingStyle}
            as="select"
            name={'cart.badgeHasSecurityClearance'}
            defaultValue={this.nn('badgeHasSecurityClearance')}
            onChange={this.handleChange}
          >
            <option key="-1" value="">Select One</option>
            <option key="Yes" value="Yes">Yes</option>
            <option key="No" value="No">No</option>
          </Form.Control>
        </div>
        <div className="grid-col-4">
          <div className="usa-label">Show Exhibitor Designation on Badge</div>
          <Form.Control
            className="usa-select"
            style={editingStyle}
            as="select"
            name={'cart.badgeShowAsExhibitorOnBadge'}
            defaultValue={this.nn('badgeShowAsExhibitorOnBadge')}
            onChange={this.handleChange}
          >
            <option key="-1" value="">Select One</option>
            <option key="Yes" value="Yes">Yes</option>
            <option key="No" value="No">No</option>
            <option key="useRegistration" value="useRegistration">Use Registration</option>
          </Form.Control>
          <div className="usa-prose" style={{fontSize: 'smaller'}}>Override registration type.</div>
        </div>
        <div className="grid-col-4">
          <div className="usa-label">Show Rank on Badge</div>
          <Form.Control
            className="usa-select"
            style={editingStyle}
            as="select"
            name={'cart.badgeShowRankOnBadge'}
            defaultValue={this.nn('badgeShowRankOnBadge')}
            onChange={this.handleChange}
          >
            <option key="-1" value="">Select One</option>
            <option key="Yes" value="Yes">Yes</option>
            <option key="No" value="No">No</option>
            <option key="useProfile" value="useProfile">Use Profile</option>
          </Form.Control>
          <div className="usa-prose" style={{fontSize: 'smaller'}}>Rank appears by default unless there is non-government employment.</div>
        </div>
      </div>
      <div className="grid-row grid-gap margin-y-0">
        <div className="grid-col-6">
          <div className="usa-label">Administrator Line</div>
          <Form.Control className="usa-input" name="cart.badgeAdminContent" plaintext defaultValue={this.props.keyValue('cart', 'badgeAdminContent')}  style={editingStyle} onChange={this.handleChange}/>
        </div>
        <div className="grid-col-6">
          <div className="usa-label">Administrator Line (Guest)</div>
          <Form.Control className="usa-input" name="cart.badgeGuestAdminContent" plaintext defaultValue={this.props.keyValue('cart', 'badgeGuestAdminContent')}  style={editingStyle} onChange={this.handleChange}/>
        </div>
      </div>
      <div className="grid-row grid-gap margin-y-0">
        <div className="grid-col-6">
          <div className="usa-label">Award Entry</div>
          <Form.Control className="usa-input" name="cart.badgeAwardContent" plaintext defaultValue={this.props.keyValue('cart', 'badgeAwardContent')}  style={editingStyle} onChange={this.handleChange}/>
        </div>
        <div className="grid-col-6">
          <div className="usa-label">Override Base/Chapter</div>
          <Form.Control className="usa-input" name="cart.badgeOverrideBaseChapter" plaintext defaultValue={this.props.keyValue('cart', 'badgeOverrideBaseChapter')}  style={editingStyle} onChange={this.handleChange}/>
        </div>
      </div>
      </>
    )
  }
}

BadgeInformation.contextType = PersonContext
export default BadgeInformation
