import React, { Component } from 'react';
import { Link } from "react-router-dom";

import Loading from '../Loading';
import ErrorContainer from '../ErrorContainer';

import * as contentful from 'contentful';

class HallOfFameList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      page: null,
    };
  }

  componentDidMount() {
    var contentfulClient = contentful.createClient({
      space: 'bg11zawujrgr',
      accessToken: 'c86395435846a2c448defbf0c59f24677fa2a58d522e6b2fe3e6c4b57c083caf' })

    contentfulClient.getEntries({'content_type': 'pageBasic','fields.hallOfFameYear[exists]': true, 'order': '-fields.hallOfFameYear', 'limit': 500})
    .then(entries => {
      this.setState({
        isLoaded: true,
        items: entries.items
      });
    })
    .catch(error => {
      console.log(error)
      this.setState({
        error: error
      })
    })
  }

  render() {
    const { error, isLoaded, items } = this.state;

    if (error) {
      return <ErrorContainer title='Error' error={error} />
    } else if (!isLoaded) {
      return <Loading title=''
                      subtitle=''/>;
    } else {

      return (
        <section className="usa-section" style={{paddingTop: 10}}>
          <div className="grid-container margin-y-0">
            {items.map(item =>
              <h3>
                <Link
                  to={"/hall-of-fame/" + item.sys.id}
                  title={"Hall of Fame " + item.fields.hallOfFameYear}
                  aria-label={"hall-of-fame" + item.fields.hallOfFameYear}
                  style={{textDecoration: 'none'}}
                >
                  {item.fields.headline}
                </Link>
              </h3>
            )}
          </div>
        </section>
      )
    }
  }
}

export default HallOfFameList;
