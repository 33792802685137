import React from 'react'
import { CSVLink } from "react-csv"

var moment = require('moment')

function AdminCounts(props) {
  if (props.filter !== 'counts') {return null}
  if (!props.isLoaded) {return null}

  if (!props.items || props.items[0].length === 0) {
    return (
      <>
      <h2>Summary</h2>
      <div className="usa-prose">There are no counts at the moment</div>
      </>
    )
  }

  const countStatus = props.items[0].filter(item => item.sk === 'count-status')
  const countBases = props.items[0].filter(item => item.sk === 'count-bases')
  const countMembership = props.items[0].filter(item => item.sk === 'membership')
  let csvStatus = false
  if (countStatus.length > 0) {
    csvStatus = countStatus[0].value.map(item => {
      return [item.registrationStatus,
              item.gov,
              item.nonGov,
              item.total]
    })
    csvStatus.unshift(['RegistrationStatus',
                 'Military/Gov',
                 'Non Gov',
                 'Total'])
   }

  let csvBases = false
  if (countBases.length > 0) {
    csvBases = countBases[0].value.map(item => {
      return [item.base_name,
              item.count]
    })
    csvBases.unshift(['Base',
                 'Registrations'])
   }
   let csvMembership = false
   if (countMembership.length > 0) {
     csvMembership = countMembership[0].value.map(item => {
       return [item.membershipStatus,
               item.total]
     })
     csvBases.unshift(['Status',
                  'Count'])
    }

   let lifeMembers = 0 // used later for membership counts
   let individualCorporateMembers = 0;

   let bases = []
   let total = 0
   const entries = countBases[0].value.length
   const half = Math.trunc(entries / 2)
   for (let i = 0; i < entries / 2; i+=1 ) {
     let col2 = i + half === countBases[0].value.length ? {} : countBases[0].value[i + half]
     bases.push({col1: countBases[0].value[i], col2: col2})
     total += countBases[0].value[i].count + col2.count
   }

  return (
    <>
    <h2>Registrations</h2>
    <div className="grid-row grid-gap">
      <div className="tablet:grid-col-8">
        <h3>Status Summary
          {csvStatus &&
            <CSVLink
              style={{fontSize: 'small', verticalAlign: 'bottom', paddingLeft: 10}}
              data={csvStatus}
            >
             <i className="fas fa-file-spreadsheet fa-2x" > </i>
            </CSVLink>
          }
        </h3>
        <table className="usa-table usa-table--borderless" style={{marginBottom: 5, marginTop: 0}}>
          <thead>
            <tr>
              <th>Status</th>
              <th>Military/Gov</th>
              <th>Non Gov</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <CountsRegistrationHeaderRow status="Started" counts={countStatus[0].value.filter(item => item.registrationStatus === 'none')} />
            <CountsRegistrationHeaderRow status="Payment Pending" counts={countStatus[0].value.filter(item => item.registrationStatus === 'pending-payment')}  />
            <CountsRegistrationHeaderRow status="Complete" counts={countStatus[0].value.filter(item => item.registrationStatus === 'complete')} />
            <CountsRegistrationHeaderRow status="Cancellation Requested" counts={countStatus[0].value.filter(item => item.registrationStatus === 'cancellation-requested')}  />
            <CountsRegistrationHeaderRow status="Cancellation Confirmed" counts={countStatus[0].value.filter(item => item.registrationStatus === 'cancellation-confirmed')} />
          </tbody>
        </table>
        <div className="usa-prose margin-y-0" style={{fontSize: 'smaller'}}>Updated {moment(countStatus[0].updated_at).fromNow()}. Updated at least every 2 hours.</div>
      </div>
      <div className="tablet:grid-col-12">
        <h3>Base Counts
          {csvBases &&
            <CSVLink
              style={{fontSize: 'small', verticalAlign: 'bottom', paddingLeft: 10}}
              data={csvBases}
            >
             <i className="fas fa-file-spreadsheet fa-2x" > </i>
            </CSVLink>
          }
        </h3>
        <table className="usa-table usa-table--borderless" style={{marginBottom: 5, marginTop: 0}}>
          <thead>
            <tr>
              <th width="40%">Base</th>
              <th width="10%" style={{textAlign: 'right'}}>Total Registrations</th>
              <th width="40%">Base</th>
              <th width="10%" style={{textAlign: 'right'}}>Total Registrations</th>
            </tr>
          </thead>
          <tbody>
            {bases.map((row, i) => {
              return (
                <tr key={i}>
                  <td>{row.col1.base_name}</td>
                  <td style={{textAlign: 'right', paddingRight: 30}}>{row.col1.count}</td>
                  <td>{row.col2.base_name}</td>
                  <td style={{textAlign: 'right', paddingRight: 30}}>{row.col2.count}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="usa-prose"><strong>Total: {total}</strong></div>
        <div className="usa-prose margin-y-0" style={{fontSize: 'smaller'}}>Updated {moment(countBases[0].updated_at).fromNow()}. Updated at least every 2 hours.</div>
      </div>
    </div>

    <hr style={{marginTop: 20}}/>
    <h2>Memberships</h2>
    <h3>Counts
      {csvMembership &&
        <CSVLink
          style={{fontSize: 'small', verticalAlign: 'bottom', paddingLeft: 10}}
          data={csvMembership}
        >
         <i className="fas fa-file-spreadsheet fa-2x" > </i>
        </CSVLink>
      }
    </h3>
    <table className="usa-table usa-table--borderless" style={{marginBottom: 5, marginTop: 0}}>
      <thead>
        <tr>
          <th>Status</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>
        {countMembership[0].value.map(count => {
          if (count.membershipStatus === 'life-members') {lifeMembers = count; return null}
          if (count.membershipStatus === 'individual-corporate-members') {
            individualCorporateMembers = count; return null
          }
          return (
            <tr key={count.membershipStatus}>
              <td>{
                count.membershipStatus.split("-").map((word) => {
                      return word[0].toUpperCase() + word.substring(1);
                  }).join(" ")
              }</td>
              <td style={{textAlign: 'right', paddingRight: 30}}>{count.total}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
    <p>There are {lifeMembers.total} life members.
    There are {individualCorporateMembers.total} Individual Corporate Members</p>
    <div className="usa-prose margin-y-0" style={{fontSize: 'smaller'}}>Updated {moment(countMembership[0].updated_at).fromNow()}. Updated at least every 2 hours.</div>

    </>
  )
}

function CountsRegistrationHeaderRow(props) {
  if (props.counts.length === 0) {return null}
  return (
    <tr key={props.counts[0].registrationStatus}>
      <td>{props.status}</td>
      <td style={{textAlign: 'right', paddingRight: 30}}>{props.counts[0].gov}</td>
      <td style={{textAlign: 'right', paddingRight: 30}}>{props.counts[0].nonGov}</td>
      <td style={{textAlign: 'right', paddingRight: 30}}>{props.counts[0].total}</td>
    </tr>
  )
}

export default AdminCounts
