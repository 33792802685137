import { useState, useEffect } from 'react'
import { API } from 'aws-amplify'

export default function useList(props) {

  const [items, setItems] = useState([])
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null)
  const [pagesLoadedCount, setPagesLoadedCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isErrored, setIsErrored] = useState(false)
  const [moreData, setMoreData] = useState(true)

  async function loadData() {

    let response
    if ((lastEvaluatedKey || items.length === 0) && pagesLoadedCount < props.stopAfterPage) {
      const parameters = {queryStringParameters: {}}
      if (lastEvaluatedKey) {parameters.queryStringParameters.l = lastEvaluatedKey}
      if (props.pageSize) {parameters.queryStringParameters.p = Number(props.pageSize)}

      setIsLoading(true)
      setIsErrored(false)
      try {
        response = await API.get('general', props.source, parameters)
        setMoreData((response.lastEvaluatedKey !== null || response.items.length === 0))
        setItems(items.concat(response.items))
        setPagesLoadedCount(pagesLoadedCount + 1)
        setLastEvaluatedKey(response.lastEvaluatedKey)
        setIsLoading(false)
      }
      catch {
        setIsLoading(false)
        setIsErrored(true)
      }

    }
  }

  useEffect(() => {
    console.log('useEffect')
    loadData()
}, [lastEvaluatedKey, props.stopAfterPage])


  return {isErrored, isLoading, moreData, items, pagesLoadedCount}
}
