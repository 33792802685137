/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d03646b6-4d56-4ede-b39f-0c6f3306756b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_bgkQ6JApw",
    "aws_user_pools_web_client_id": "3veqegbei3k7njmo2sb7ik6nri",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "registrationType",
            "endpoint": "https://rgdphv079e.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "coupon",
            "endpoint": "https://b9n29xalac.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "hotel",
            "endpoint": "https://67n2qt20o3.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "execsupport",
            "endpoint": "https://9qvn2ltip2.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "general",
            "endpoint": "https://fwst9sjani.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://xube8wp9vh.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "stripeProcessing",
            "endpoint": "https://x1j76717oc.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "activity",
            "endpoint": "https://svclmu2si9.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "search",
            "endpoint": "https://sdu6c4c2f4.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "passkey",
            "endpoint": "https://o65ms84ioe.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "banquet",
            "endpoint": "https://jblnfufa6c.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "web259bdb729ea547d9a0b3396595e03232-test",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "global-test",
            "region": "us-east-1"
        },
        {
            "tableName": "summary-test",
            "region": "us-east-1"
        },
        {
            "tableName": "activity-test",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
