/* jshint camelcase: false */
import { API } from 'aws-amplify';
import Data, {ITEM_SK_ROOT, ITEM_SK_MEMBERSHIP, ITEM_SK_ROLE, ITEM_PK_PERSON, ITEM_PK_COMPANY} from './Data'

/* jshint asi: true */

class CompanyClass extends Data {

  async store() {
    let path = this.item.align ? '/company/membership/' : '/company/'
    this.storeItem('general', path + this.id)
  }

  async delete() {
    this.deleteItem('general', '/company')
  }


  async get(uuid) {

    this.id = uuid
    this.isInitialized = false
    this.isLoading = true

    let that = this
    return new Promise(function(resolve, reject) {

      API.get('general', '/company/' + uuid)
        .then(response => {

          that.rawItems = response.items

          // a null response like this indicates that the record does not exist
          // in the database.
          if (response.items.length === 0) {
            that.isLoading = false
            that.isInitialized = true
            that.loadFailed = false
            that.isNew = true
            that.account = false
            that.item.root = {id: that.item.id, pk: 'company-' + that.item.id, sk: 'root'}
            resolve(that.item)
          }

          let root = that.filterItem(ITEM_SK_ROOT)[0]
          let roles = that.filterItem(ITEM_SK_ROLE)
          let memberships = that.filterItem(ITEM_SK_MEMBERSHIP, ITEM_PK_COMPANY)
          let individuals = that.filterItem(ITEM_SK_MEMBERSHIP, ITEM_PK_PERSON)

          let isNew = false
          if (typeof(root) === 'undefined') {
            root = {id: that.item.id, pk: 'company-' + that.item.id, sk: 'root'}
            isNew = true
          }

          that.isLoading = false
          that.isInitialized = true
          that.loadFailed = false
          that.isNew = isNew
          that.item =
           {root: root,
            roles: roles,
            memberships: memberships,
            individuals: individuals}

          resolve(that.item)
        })
        .catch(error => {
          that.isLoading = false
          that.loadFailed = true
          console.log(error)
          reject(error)
      })
    })
  }

  // Handles a synthetic form event
   handleChange(event) {

    const target = event.target
    const name = target.name.split('.')
    let value = target.type === 'checkbox' ? target.checked : target.value

    if (name[0].substring(0, 4) === 'role') {
      const i = this.editItem.roles.findIndex(item => item.sk === name[0])
      if (i === -1) {return}
      this.editItem.roles[i][name[1]] = value

    } else if (name[0].substring(0, 10) === 'membership') {
        const i = this.editItem.memberships.findIndex(item => item.sk === name[0])
        if (i === -1) {return}
        this.editItem.memberships[i][name[1]] = value
    } else {
      if (typeof(this.editItem[name[0]]) === 'undefined') {
        this.editItem[name[0]] = {}
      }
      this.editItem[name[0]][name[1]] = value

    }
  }

}

export default CompanyClass;
