import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Form from 'react-bootstrap/Form'

class Service extends Component {

  constructor(props) {
    super(props)

    // By default we provide the current value as the only option. We need to
    // wait for the picklist to appear form the componentDidMount below.
    this.state = {
      options: <option value={props.defaultValue} selected='true'>{props.defaultDisplayValue}</option>,
      defaultValue: props.defaultValue
    }

    this.onChange = this.onChange.bind(this)

  }

  onChange(event) {
    this.props.onChange(event)

    const serviceInfo = this.state.services.filter(item => item.sk === event.target.value)

    if (this.props.name === 'military.service') {
      // After changing the service_name, set the rank and secondary fields to blank
      this.props.onChange({target: {name: 'military.service_name',  value: serviceInfo[0].name, type: 'select-one'}})
      this.props.onChange({target: {name: 'military.status',  value: 'none', type: 'select-one'}})
      this.props.onChange({target: {name: 'military.status_name',  value: 'None', type: 'select-one'}})
      this.props.onChange({target: {name: 'military.rank_abbreviation',  value: 'none', type: 'select-one'}})
      this.props.onChange({target: {name: 'military.rank',  value: 'none', type: 'select-one'}})
      this.props.onChange({target: {name: 'military.rank_name',  value: 'None', type: 'select-one'}})
      this.props.onChange({target: {name: 'military.role',  value: ''}})
      this.props.onChange({target: {name: 'military.organization',  value: ''}})
      this.props.onChange({target: {name: 'military.date_of_rank',  value: ''}})
      this.props.onChange({target: {name: 'military.base',  value: ''}})
      this.props.onChange({target: {name: 'military.base_name',  value: ''}})
      this.props.onChange({target: {name: 'military.base_other',  value: ''}})
    }

    if (this.props.name === 'civilian.service') {
      // After changing the service_name, set the rank and secondary fields to blank
      this.props.onChange({target: {name: 'civilian.service_name',  value: serviceInfo[0].name, type: 'select-one'}})
      this.props.onChange({target: {name: 'civilian.status',  value: 'none', type: 'select-one'}})
      this.props.onChange({target: {name: 'civilian.status_name',  value: 'None', type: 'select-one'}})
      this.props.onChange({target: {name: 'civilian.rank_abbreviation',  value: 'none', type: 'select-one'}})
      this.props.onChange({target: {name: 'civilian.rank',  value: 'none', type: 'select-one'}})
      this.props.onChange({target: {name: 'civilian.rank_name',  value: 'None', type: 'select-one'}})
      this.props.onChange({target: {name: 'civilian.role',  value: ''}})
      this.props.onChange({target: {name: 'civilian.organization',  value: ''}})
      this.props.onChange({target: {name: 'civilian.date_of_rank',  value: ''}})
      this.props.onChange({target: {name: 'civilian.base',  value: ''}})
      this.props.onChange({target: {name: 'civilian.base_name',  value: ''}})
      this.props.onChange({target: {name: 'civilian.base_other',  value: ''}})
    }

    this.props.stateChange()
  }

  componentDidMount() {
    API.get('general', '/reference/' + this.props.service)
    .then(response => {

      const items = response.items.sort(function(a,b) {
        if (a.sk === 'none') return -1
        if (b.sk === 'none') return 1
        if (a.sk < b.sk) return -1
        if (a.sk > b.sk) return 1
        return 0
      })
      const options = items.map((item) =>
        <option value={item.sk} selected={item.sk === this.state.defaultValue}>{item.name}</option>
      )
      this.setState({
        services: response.items,
        options: options
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  render() {
    return (
      <Form.Control className='usa-select' style={{backgroundColor: '#eeeeee'}} as="select" name={this.props.name}  onChange={this.onChange} >
        {this.state.options}
      </Form.Control>
    )
  }
}

export default Service
