import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Form from 'react-bootstrap/Form'

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class State extends Component {

  constructor(props) {
    super(props)

    this.state = {
      options: <option>{props.defaultValue}</option>,
      defaultValue: props.defaultValue
    }
  }

  componentDidMount() {
    API.get('general', '/reference/state')
      .then(response => {

        const options = response.items.map((item) =>
              <option key={item.sk} value={item.sk} selected={item.sk === this.state.defaultValue}>{item.name}</option>
            )
        this.setState({
          options: options
        })
      })
      .catch(error => {
        console.log(error)

    })
  }

  render() {

    const name = this.props.name ?? "address.state_or_province"

    return (

      <Form.Control className="usa-select" style={editingStyle} as="select" name={name}  onChange={this.props.onChange} >
        {this.state.options}
      </Form.Control>
    )
  }
}

export default State
