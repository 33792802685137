import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Form from 'react-bootstrap/Form'

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class Nationality extends Component {

  constructor(props) {
    super(props)

    const defaultValue = props.defaultValue ? props.defaultValue : 'none'
    const defaultDisplayValue = props.defaultValue ? props.defaultDisplayValue : 'Not specified'

    this.state = {
      options: <option value={defaultValue}>{defaultDisplayValue}</option>,
      defaultValue: defaultValue,
      key: defaultValue
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange(event) {
    const countryName = this.state.countries.filter(item => item.sk === event.target.value)

    this.props.onChange(event)
    this.props.onChange({target: {name: 'nationality.name',  value: countryName[0].name, type: 'input'}})

  }

  componentDidMount() {
    API.get('general', '/reference/country')
      .then(response => {

        const countries = response.items.sort((a, b) => {
          if (a.sk === 'none') {return -1}
          if (b.sk === 'none') {return 1}
          if (a.sk === 'US') {return -1}
          if (b.sk === 'US') {return 1}
          if (a.name < b.name) {return -1}
          if (a.name > b.name) {return 1}
          return 0
        })
        const options = countries.map((item) =>
              <option key={item.sk} value={item.sk} selected={item.sk === this.state.defaultValue}>{item.name}</option>
            )
        this.setState({
          countries: countries,
          options: options
        })
      })
      .catch(error => {
        console.log(error)

    })
  }


  render() {
    return (
      <Form.Control className="usa-select" style={editingStyle} as="select" name="nationality.code"  onChange={this.onChange} >
        {this.state.options}
      </Form.Control>
    )
  }
}

export default Nationality
