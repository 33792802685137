import { CSVLink } from "react-csv"
import { API } from 'aws-amplify'
import Loading from '../../Loading'
import PersonWithActions from './common/PersonWithActions'
import React from 'react'
import { Redirect } from "react-router-dom"
import ButtonActive from '../../ButtonActive'

var moment = require('moment')

class AdminRegistration extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      selected: [],
      printAllSelected: false,
      batchRequest: false
    }

    this.togglePrint = this.togglePrint.bind(this)
    this.toggleAllPrint = this.toggleAllPrint.bind(this)
    this.printSelected = this.printSelected.bind(this)
  }

  togglePrint(pk) {
    let selected = this.state.selected
    if (selected.includes(pk)) {
      selected = selected.filter(item => item !== pk)
    } else {
      selected.push(pk)
    }
    this.setState({
      selected: selected
    })
  }

  toggleAllPrint() {
    let printAllSelected = !this.state.printAllSelected
    this.setState({printAllSelected: printAllSelected})

    if (printAllSelected) {
      const {registration, registrationPagesCurrent} = this.props.registration
      let selected = registration[registrationPagesCurrent]
              .filter(reg => reg.registrationStatus === 'complete')
              .map(reg => reg.pk)
      this.setState({selected: selected})
    } else {
      this.setState({selected: []})
    }
  }


  printSelected() {

    const {registration, registrationPagesCurrent} = this.props.registration
    const batchId = uuidv4()
    let batchItems = []

    for (let i in this.state.selected) {
      const matches = registration[registrationPagesCurrent].filter(reg => reg.pk === this.state.selected[i])
      if (matches[0]) {
          batchItems.push(getBadgeData(matches[0]))
      }
    }

    this.setState({batchRequest: true})
    API.put('general', '/batch/' + batchId, {body: {items : batchItems}})
    .then(response => {
      this.setState({redirect: true, selected: [], batchId: batchId, batchRequest: false})

    })
    .catch(error => {
      console.log(error)
    })
  }

  render() {
  if (this.props.filter !== 'registration') {return null}

  if (this.state.redirect) {
    return (
      <Redirect to={"/preview/" + this.state.batchId} push={true} />
    )
  }

  const {registration, registrationPages, registrationPagesCurrent,
         registrationCsv, registrationLoading,
         registrationMoreDataAvailable} = this.props.registration

  const {deletedPeople, deletePerson, deleteRegistration} = this.props
  const badgePrintEnabled = (this.state.selected.length > 0)

  return (
    <>
    <h2>Detail

      <span style={{marginLeft: 10, fontSize: 'smaller'}}>
        <CSVLink
          style={{verticalAlign: 'center'}}
          data={registrationCsv}
        >
          <i className="fas fa-file-spreadsheet fa-2x" style={{fontSize: 'smaller'}}> </i>
        </CSVLink>
      </span>
      <span>
       {registrationPagesCurrent === 0 || registrationLoading ?
         <span style={{marginLeft: 5, fontSize: 'small', color: 'grey', cursor: 'pointer'}} >
           <i className="fas fa-fast-backward"></i>
         </span> :
         <span style={{marginLeft: 5, fontSize: 'small', color: '#b50909', cursor: 'pointer'}} onClick={() => this.props.pagination('registration', 'first')}>
           <i className="fas fa-fast-backward"></i>
         </span>
       }
       {(registrationMoreDataAvailable || registrationPagesCurrent + 1 !== registrationPages) && !registrationLoading ?
         <span>
           <span style={{fontSize: 'small', fontWeight: 'normal', paddingLeft: 5}}>Page {registrationPagesCurrent + 1} of {registrationMoreDataAvailable ? <span>unknown</span> : <span>{registrationPages}</span>} </span>
           <span style={{marginLeft: 5, fontSize: 'small', color: '#b50909', cursor: 'pointer'}} disabled={!registrationMoreDataAvailable} onClick={() => this.props.pagination('registration', 'next')}>
             <i className="fas fa-forward"></i>
           </span>
        </span> :
        <span>
          <span style={{fontSize: 'small', fontWeight: 'normal', paddingLeft: 5}}>Page {registrationPagesCurrent + 1} of {registrationMoreDataAvailable ? <span>unknown</span> : <span>{registrationPages}</span>} </span>
          <span style={{marginLeft: 5, fontSize: 'small', color: 'grey', cursor: 'pointer'}}>
            <i className="fas fa-forward"></i>
          </span>
        </span>
       }
     </span>
     {registrationLoading &&
       <i className="fas fa-sync-alt fa-spin" style={{marginLeft: 5, fontSize: 'small', color: '#b50909'}} ></i>
     }
     <span style={{marginLeft: 10, fontSize: 'small'}}>
       <ButtonActive
         onClick={this.printSelected}
         label={(this.state.selected.length > 0) ? "Print Badge (" +  this.state.selected.length + ")" : "Badge Print"}
         aria-label="print"
         className="usa-button"
         style={{textDecoration: 'none', marginTop: 20}}
         active={this.state.batchRequest}
         disabled={!badgePrintEnabled || this.state.batchRequest}
       />
     </span>

    </h2>
    { registrationLoading && registration.length === 0 ?
      <Loading title="" subtitle=""/> :
      <table className="usa-table usa-table--borderless" style={{width:'100%', marginBottom: 32, marginTop: 0, fontSize: 'smaller'}}>
        <thead>
          <tr>
            <th>Email Address <br />
            <div className="usa-checkbox" style={{display: 'inline', fontSize: 'smaller', marginLeft: 20}}>
              <input className="usa-checkbox__input" id="printAll" checked={this.state.printAllSelected} name="printAll" type="checkbox" onChange={this.toggleAllPrint}/>
              <label className="usa-checkbox__label" htmlFor="printAll" style={{paddingLeft: 0}}></label>
            </div>

            </th>
            <th>Name</th>
            <th>Primary</th>
            <th>Status</th>
            <th>Total Price</th>
            <th>Base</th>
            <th>Last Updated</th>
          </tr>
        </thead>
        <tbody>
          {registration.length > 0 && registration[registrationPagesCurrent].map(item =>
            <>
            {!deletedPeople.includes(item.pk) &&
              <tr key={item.pk} style={{verticalAlign: 'top'}}>

                <td>
                <PersonWithActions
                  item={item}
                  deletePerson={deletePerson}
                  deleteRegistration={deleteRegistration}
                  showPrint={true}
                  togglePrint={this.togglePrint}
                  printSelected={this.state.selected.includes(item.pk)}
                />
                </td>
                <td>{item.rank_name && item.rank_name} {item.fullname && item.fullname.replace('undefined', '')} {item.status === 'retired' && <span> (Ret)</span>}</td>
                <td>{item.registrationTypeName && item.registrationTypeName}</td>
                <td>{item.registrationStatus}</td>
                <td>{item.registrationTotalPrice && <span>${item.registrationTotalPrice}</span>}</td>
                <td>{item.base_name}</td>
                <td>{item.registrationUpdatedAt && moment(item.registrationUpdatedAt).format('YYYY-MM-DD')}</td>
              </tr>
            }
            </>
          )}
        </tbody>
      </table>
    }

    </>
  )
}
}

export default AdminRegistration

// ---------------------------------------------------------------------------------


function getBadgeData(summary) {

  return {
    root: {
      first_name: summary.firstName,
      last_name: summary.lastName,
      nickname: summary.nickname,
      private_organization: summary.private_organization,
    },
    military: {
      organization: summary.organization,
      base_name: summary.base_name,
      rank: summary.rank_name,
      rank_abbreviation: summary.rank_abbreviation,
      status: summary.status
    },
    civilian: {
      organization: summary.civilan_organization
    },
    address: {
      chapterName: summary.chapterName
    },
    cart: {
      includesGuest: summary.includesGuest,
      secondaryGuestFirstName: summary.guestFirstName,
      secondaryGuestLastName: summary.guestLastName,
      badgeShowAsExhibitorOnBadge: summary.badgeShowAsExhibitorOnBadge, // person.cart.badgeShowAsExhibitorOnBadge,
      badgeHasSecurityClearance: summary.badgeHasSecurityClearance, /// person.cart.badgeHasSecurityClearance,
      badgeShowRankOnBadge: summary.badgeShowRankOnBadge, // person.cart.badgeShowRankOnBadge,
      primaryRegistrationType: summary.primaryRegistrationType, // {isExhibitor: person.cart.primaryRegistrationType.isExhibitor},
      exhibitorOrganization: summary.exhibitorOrganization,
      badgeGuestAdminContent: summary.badgeGuestAdminContent, // person.cart.badgeGuestAdminContent,
      badgeAdminContent: summary.badgeAdminContent,
      badgeAwardContent: summary.badgeAwardContent,
      badgeOverrideBaseChapter: summary.badgeOverrideBaseChapter
    },
  }
  //
  // return {
  //   root: {
  //     first_name: summary.firstName,
  //     last_name: summary.lastName,
  //     nickname: summary.nickname,
  //     private_organization: summary.private_organization,
  //   },
  //   military: {
  //     organization: summary.organization,
  //     base_name: summary.base_name,
  //     rank: summary.rank_name,
  //     rank_abbreviation: summary.rank_abbreviation,
  //     status: summary.status_name
  //   },
  //   civilian: {
  //     organization: summary.civilan_organization
  //   },
  //   address: {
  //     chapterName: summary.chapterName
  //   },
  //   cart: {
  //     includesGuest: summary.includesGuest,
  //     secondaryGuestFirstName: summary.guestFirstName,
  //     secondaryGuestLastName: summary.guestLastName,
  //     badgeShowAsExhibitorOnBadge: summary.badgeShowAsExhibitorOnBadge, // person.cart.badgeShowAsExhibitorOnBadge,
  //     badgeHasSecurityClearance: summary.badgeHasSecurityClearance, /// person.cart.badgeHasSecurityClearance,
  //     badgeShowRankOnBadge: summary.badgeShowRankOnBadge, // person.cart.badgeShowRankOnBadge,
  //     primaryRegistrationType: summary.primaryRegistrationType, // {isExhibitor: person.cart.primaryRegistrationType.isExhibitor},
  // 
  //     exhibitorOrganization: summary.exhibitorOrganization,
  //     badgeGuestAdminContent: summary.badgeGuestAdminContent, // person.cart.badgeGuestAdminContent,
  //     badgeAdminContent: summary.badgeAdminContent,
  //     badgeAwardContent: summary.badgeAwardContent,
  //     badgeOverrideBaseChapter: summary.badgeOverrideBaseChapter
  //   },
  // }
}

function uuidv4() {
  return 'xxxxxxxx-xxxx'.replace(/[xy]/g, function(c) { // eslint-disable-next-line
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
