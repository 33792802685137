import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'

import { DotLoader } from 'react-spinners';
import { css } from '@emotion/core';

class ButtonActive extends Component {

  render() {
    const override = css`
        display: inline-block;
        margin: 0 auto;
        border-color: white;
    `;

    const color = this.props.variant === 'link' ? 'blue' : 'white'

    return (
      <Button variant={this.props.variant}
              disabled={this.props.disabled}
              className={this.props.className}
              onClick={this.props.onClick}
              style={this.props.style}
              name={this.props.name}
      >
        {this.props.active &&
          <span style={{paddingRight: '6px', verticalAlign: 'text-top'}}>
            <DotLoader
              css={override}
              sizeUnit={"px"}
              size={12}
              color={color}
              loading={this.props.active}
            />
          </span>
        }
        {this.props.label}
      </Button>
    )
  }
}
export default ButtonActive;
