import React, { Component } from 'react'

import Button from 'react-bootstrap/Button'
var moment = require('moment')

class ButtonTimed extends Component {

  constructor(props) {
    super(props)

    this.state = {
      mode: 'none',
    }

    this.tick = this.tick.bind(this);
    this.intervalHandle = setInterval(this.tick, 1000);
  }

  tick() {

    const minutesToStart =
        moment().diff(moment(this.props.startTime), 'minutes')

    let mode = this.state.mode
    if (minutesToStart < -this.props.preSessionLinkAvailable) {
      mode = 'countdown'
    } else if (minutesToStart < this.props.length) {
      mode = 'live'
    } else {
      mode = 'complete'
      clearInterval(this.intervalHandle)

    }

    this.setState({mode: mode})

    // if (minutesToStart < 0 && minutesToStart < -this.props.duration) {
    //   active = true
    // }
    // if (minutesToStart > 0 && minutesToStart < this.props.preSessionLinkAvailable) {
    //   countdown = true
    // }

    // const secondsRemaining = moment(this.props.liveTime).diff(moment(), 'seconds')
    // var min = Math.floor(secondsRemaining / 60);
    // var sec = secondsRemaining - (min * 60);
    // this.setState({
    //   minutes: min,
    //   seconds: sec
    // })
    // if (sec < 10) {
    //   this.setState({
    //     seconds: "0" + this.state.seconds,
    //   })
    // }
    // if (min < 10) {
    // this.setState({
    //   value: "0" + min,
    //  })
    // }
    // if (min === 0 & sec === 0) {
    // clearInterval(this.intervalHandle);
    // }
//    this.secondsRemaining--
  }

  render() {

    return (
      <>
      { this.state.mode === 'live' &&
        <Button className='usa-button' {...this.props}>
          Join Live
        </Button>
      }
      { this.state.mode === 'countdown' &&
        <Button className='usa-button' disabled={true} {...this.props}>
          Starting in<br /><small>{moment(this.props.startTime).fromNow()}</small>
        </Button>
      }
      { this.state.mode === 'complete' &&
        <Button className='usa-button' disabled={true} {...this.props}>
          Complete
        </Button>
      }
      </>
    )
  }

}

export default ButtonTimed;
