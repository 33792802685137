import { Link } from "react-router-dom"
import React from 'react'

function CompanyWithActions(props) {

  const {deleteCompany, name, pk} = props

  const id = pk.slice(pk.indexOf('-') + 1)

  return (
    <>
    <Link
      to={"/admin/company/" + id}
      className="usa-button usa-button--unstyled usa-tooltip" data-position="top" title="View Industry Partner"
      style={{textDecoration: 'none', fontSize: 'small'}}
    >
      {name}
    </Link> <br />
    {deleteCompany &&
      <Link
        className="usa-tooltip" data-position="top" title="Delete Company"
        style={{marginRight: 10}}
        onClick={() => deleteCompany(id)}
      >
        <i className="fas fa-user-minus"></i>
      </Link>
    }
    </>
  )
}

export default CompanyWithActions;
