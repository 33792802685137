import React, { Component } from 'react'
import {CustomLink} from '../common'

class EmptyCart  extends Component {

  render() {
    return (
      <section
        className="usa-section"
        style={{ paddingTop: 10, paddingBottom: 10, borderTopWidth: 0 }}
      >
        <div className="grid-container margin-y-0">
          <h2 className="margin-y-0">{this.props.title}</h2>
          <p className="usa-prose">{this.props.subtitle}</p>

          <div className="usa-prose margin-y-5">
            <div className="usa-alert usa-alert--info">
              <div className="usa-alert__body">
                <h3 className="usa-alert__heading">The shopping cart is empty</h3>
                <p className="usa-alert__text">
                  To get started with your registration, choose your registration type
                  from the{" "}
                  <CustomLink
                    className="usa-button usa-button--unstyled"
                    to={this.props.to}
                    adminTo={this.props.adminTo}
                    isAdmin={this.props.isAdmin}
                    label="catalog"
                    style={{ textDecoration: "none" }}
                  />
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    )
  }
}
export default EmptyCart
