import React, { Component } from 'react';

import Form from 'react-bootstrap/Form'

import BanquetInformation from '../checkout/BanquetInformation'
import BanquetConfirmation from '../checkout/BanquetConfirmation'
import GuestAttendeeInformation from '../checkout/GuestAttendeeInformation'
import FirstTimeAttendee from '../checkout/FirstTimeAttendee'
import BadgeInformation from '../checkout/BadgeInformation'

import {PersonContext} from '../../../classes/context.js'

var moment = require('moment');

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class EditorRegistrationInfo extends Component {

  constructor(props) {
    super(props)

    this.handlePrivateChange = this.handlePrivateChange.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.stateChange = this.stateChange.bind(this)
    this.keyValue = this.keyValue.bind(this)

    this.state = {
      changed: moment()
    }
  }

  keyValue(item, key, format) {
      const {person} = this.context

      switch (item) {
        case 'root':
        case 'address':
        case 'military':
        case 'civilian':
        case 'nationality':
        case 'phone-primary':
        case 'phone-secondary':
        case 'cart':
          if (!person.editItem) {return null}
          if (!person.editItem[item]) {return null}

          if (format) {
            return (typeof(person.editItem[item][key]) === 'undefined' ||
                    !person.editItem[item][key] ?
                    null :
                    moment(person.editItem[item][key]).format(format))
          }

          return (typeof(person.editItem[item][key]) === 'undefined' ? null : person.editItem[item][key])

        default:
         return null
      }
    }

  handlePrivateChange(event) {
    const handleChange = this.context.person.handleChange.bind(this.context.person)
    handleChange(event)

    if (!event.target.checked) {
      // After changing non-government employment (private) set the current values to blank
      handleChange({target: {name: 'root.private_organization',  value: '', type: 'input'}})
      handleChange({target: {name: 'root.private_title',  value: '', type: 'input'}})
    }
    // This is really a hack to force a state change and a rerender. The context
    // change does not appear to do that automatically. Although it seems like
    // it should.
    this.setState({
      changed: moment()
    })
  }

  handleCheckboxChange(event) {
    const handleChange = this.context.person.handleChange.bind(this.context.person)
    handleChange(event)

    // This is really a hack to force a state change and a rerender. The context
    // change does not appear to do that automatically. Although it seems like
    // it should.
    this.stateChange()
  }

  stateChange() {
    this.setState({
      changed: moment()
    })
  }

  render() {

    if (this.props.editMode.mode !== 'edit-registration-info') {return null}

    const {person} = this.context
    if (!person.editItem) {return null}

    let hasGuest = false
    let hasGuestBanquet = false
    if (person.item.cart.secondaryItems) {
      person.item.cart.secondaryItems.forEach((item) => {
        hasGuest = hasGuest || item.isGuestActivity
        hasGuestBanquet = hasGuestBanquet || (item.isGuestActivity && item.includesGuestBanquet)
      })
    }

    const includesGuest = person.item.cart.secondaryItems && person.item.cart.secondaryItems.filter(item => item.isGuestActivity).length > 0

    return (

      <Form>
        <BanquetInformation />
        <BanquetConfirmation hasGuestBanquet={hasGuestBanquet}/>
        <GuestAttendeeInformation includesGuest={includesGuest}/>
        <BadgeInformation keyValue={this.keyValue}/>
        <FirstTimeAttendee />
      </Form>
      )
    }
}

EditorRegistrationInfo.contextType = PersonContext
export default EditorRegistrationInfo;
