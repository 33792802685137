import React from 'react'

export default function GridConfig(props) {

  const { gridDef, setColumns, setAvailableFilterColumns, setCsvColumns, fixedColumns } = props

  function toggleVisibility(column) {
    column.isVisible = !column.isVisible || column.alwaysVisible
    setColumns(gridDef.availableColumns.filter(column => column.isVisible).concat(fixedColumns))
    setAvailableFilterColumns(gridDef.availableColumns.filter(column => column.mayFilter && column.isVisible).concat(fixedColumns))
  }

  function toggleCsv(column) {
    column.includeInCsv = !column.includeInCsv
    setCsvColumns(gridDef.availableColumns.filter(column => column.includeInCsv))
  }

  function toggleMayFilter(column) {
    column.mayFilter = !column.mayFilter
    setAvailableFilterColumns(gridDef.availableColumns.filter(column => column.mayFilter && column.isVisible).concat(fixedColumns))
  }

  return (
    <table>
      <tr>
        <th>Column</th>
        <th>Visible</th>
        <th>Included in CSV</th>
        <th>Filter By</th>
      </tr>
      {gridDef.availableColumns.map(column => {
        return (
          <tr key={column.key}>
            <td>{column.label}</td>
            <td onClick={() => toggleVisibility(column)}>{column.isVisible ? 'Yes' : 'No'}</td>
            <td onClick={() => toggleCsv(column)}>{column.includeInCsv ? 'Yes' : 'No'}</td>
            <td onClick={() => toggleMayFilter(column)}>{column.mayFilter ? 'Yes' : 'No'}</td>
          </tr>
        )
      })}
    </table>
  )
}
