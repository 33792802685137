import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'

export default function GridControl(props) {

  const {list, stopAfterPage,
         setStopAfterPage, setTableFilter,
         tableFilter,
         tableFilterColumn,
         availableFilterColumns,  setTableFilterColumn,
         viewFavorites, setViewFavorites,
         viewRecents, setViewRecents,
         requestCsv,
         configMode, setConfigMode} = props

  function handleChange(event) {
    setTableFilter(event.target.value)
  }
  function clearFilter() {
    setTableFilter('')
  }

  function handleFilterColumnChange(event) {
    setTableFilterColumn(event.target.value)
  }

  function toggleConfigMode() {
    setConfigMode(!configMode)
  }

  function toggleViewFavorites() {
    setViewFavorites(!viewFavorites)
  }

  function toggleViewRecents() {
    setViewRecents(!viewRecents)
  }

  return (
    <div>
      <div>Pages Loaded: {list.pagesLoadedCount}</div>
      <div>Asked to Stop After: {stopAfterPage}</div>
      <div>Visible Items: {list.items.length} of {list.totalItems}</div>
      <div>Is Loading: {list.isLoading ? 'Yes' : 'No'}</div>
      <div>Is Errored: {list.isErrored ? 'Yes' : 'No'}</div>
      <div>More Data: {list.moreData ? 'Yes' : 'No'}</div>
      <button onClick={toggleConfigMode}><i className="fas fa-cog"></i></button>
      {!configMode &&
        <>
        <button onClick={() => setStopAfterPage(list.pagesLoadedCount + 1)}>Next Page</button>
        <button onClick={() => setStopAfterPage(999)}>All Pages</button>
        <button onClick={requestCsv}>Request CSV</button>
        <input placeholder="Filter available items" value={tableFilter} onChange={handleChange}></input>
        <button onClick={clearFilter}>Clear Filter</button>
        <select onChange={handleFilterColumnChange}>
          {availableFilterColumns.map(column =>
            <option key={column.key} value={column.key} selected={column.key === tableFilterColumn}>{column.label}</option>
          )}
        </select>
        <button onClick={toggleViewFavorites}>{viewFavorites ? <i className="fas fa-star"></i> : <i className="far fa-star"></i>}</button>
        <button onClick={toggleViewRecents}>{viewRecents ? <i className="fas fa-clock"></i> : <i className="far fa-clock"></i>}</button>
        </>
      }

    </div>
  )
}
