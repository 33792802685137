import React, { Component } from 'react'
import { API } from 'aws-amplify'

import Form from 'react-bootstrap/Form'

class Base extends Component {

  constructor(props) {
    super(props)

    // By default we provide the current value as the only option. We need to
    // wait for the picklist to appear form the componentDidMount below.
    this.state = {
      options: <option value={props.defaultValue} selected='true'>{props.defaultDisplayValue}</option>,
      defaultValue: props.defaultValue
    }

    this.onChange = this.onChange.bind(this)

  }

  onChange(event) {
    const baseInfo = this.state.bases.filter(item => item.sk === event.target.value)

    this.props.onChange(event)
    this.props.onChange({target: {name: this.props.type + '.base_name',  value: baseInfo[0].name, type: 'input'}})
    this.props.onChange({target: {name: this.props.type + '.base_other',  value: ''}})

    this.props.stateChange()
  }

  componentDidMount() {
    API.get('general', '/reference/base-military')
    .then(response => {

      const items = response.items.sort(function(a,b) {
        if (a.sk === 'none' || a.sk === 'other') return -1
        if (b.sk === 'none' || b.sk === 'other') return 1
        if (a.sk < b.sk) return -1
        if (a.sk > b.sk) return 1
        return 0
      })
      const options = items.map((item) =>
        <option value={item.sk} selected={item.sk === this.state.defaultValue}>{item.name}</option>
      )
      this.setState({
        bases: items,
        options: options
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  componentDidUpdate(prevProps) {
    // If the default value has changed (which usually means that the service
    // has changed) update the state. Make sure that the state update has been
    // completed before redoing the picklist.

    if (prevProps.defaultValue !== this.props.defaultValue) {
      const options = this.state.bases.map((item) =>
        <option value={item.sk} selected={item.sk === this.props.defaultValue}>{item.name}</option>
      )
      this.setState({options: options})
    }
  }

  render() {
    return (
      <div className="grid-col-4">
        <div className='usa-label'>Base or Location</div>
        <Form.Control className='usa-select' style={{backgroundColor: '#eeeeee'}} as="select" name={this.props.type + '.base'}  onChange={this.onChange} >
          {this.state.options}
          </Form.Control>
      </div>
    )
  }
}

export default Base
