
import React, { Component } from 'react';
import {CompanyContext} from '../../../classes/context.js'

import Form from 'react-bootstrap/Form'

var moment = require('moment');

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class EditorPoc extends Component {

  keyValue(item, key, format) {
    const {company} = this.context

    if (!company.editItem) {return null}

    const i = company.editItem.roles.findIndex(item => item.sk === this.props.editMode.sk)
    if (i === -1) {return null}
    if (format) {
      return (typeof(company.editItem.roles[i][key]) === 'undefined' ||
              !company.editItem.roles[i][key] ?
              null :
              moment(company.editItem.roles[i][key]).format(format))
    }

    return (typeof(company.editItem.roles[i][key]) === 'undefined' ? null : company.editItem.roles[i][key])

  }

  render() {

    const {company} = this.context
    if (this.props.editMode.mode !== 'editPoc') {return null}
    if (!company.editItem) {return null}

    const handleChange = company.handleChange.bind(company)

    const sk = this.props.editMode.sk

    return (
      <Form>
        <h2 className="margin-y-5" style={{marginBottom: 0}}>Point of Contact</h2>
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-12">
            <div className="usa-label">Full Name</div>
            <Form.Control className="usa-input" name={sk + ".full_name"} plaintext defaultValue={this.keyValue(sk, 'full_name')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-5">
            <div className="usa-label">POC Primary Email</div>
            <Form.Control className="usa-input" name={sk + ".primary_email"} plaintext defaultValue={this.keyValue(sk, 'primary_email')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-5">
            <div className="usa-label">POC Alternate Email</div>
            <Form.Control className="usa-input" name={sk + ".alternate_email"} plaintext defaultValue={this.keyValue(sk, 'alternate_email')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>

        <div className="tablet:grid-col-5">
          <div className="usa-label">POC Phone</div>
          <Form.Control className="usa-input" name={sk + ".phone_number"} plaintext defaultValue={this.keyValue(sk, 'phone_number')}  style={editingStyle} onChange={handleChange}/>
        </div>



        <div className="tablet:grid-col-5">
          <div className="usa-label">Role</div>
          <Form.Control className="usa-select" style={{backgroundColor: '#eeeeee'}} as="select" name={sk + ".role_name"}  onChange={handleChange} >
            <option key="Advertising Point of Contact" value="Advertising Point of Contact" selected={this.keyValue(sk, 'role_name') === "Advertising Point of Contact"}>Advertising Point of Contact</option>
            <option key="CEO Point of Contact" value="CEO Point of Contact" selected={this.keyValue(sk, 'role_name') === "CEO Point of Contact"}>CEO Point of Contact</option>
            <option key="Exhibits Point of Contact" value="Exhibits Point of Contact" selected={this.keyValue(sk, 'role_name') === "Exhibits Point of Contact"}>Exhibits Point of Contact</option>
            <option key="Principal Backup and Support" value="Principal Backup and Support" selected={this.keyValue(sk, 'role_name') === "Principal Backup and Support"}>Principal Backup and Support</option>
            <option key="Principal Point of Contact" value="Principal Point of Contact" selected={this.keyValue(sk, 'role_name') === "Principal Point of Contact"}>Principal Point of Contact</option>
          </Form.Control>
        </div>
      </Form>
      )
    }
}

EditorPoc.contextType = CompanyContext
export default EditorPoc;
