import React from 'react'

import { Link } from "react-router-dom"
import Loading from '../../Loading'

var moment = require('moment')

function AdminReceipt(props) {
  if (props.filter !== 'receipts') {return null;}

  if (!props.isLoaded) {
    return (<Loading title="" subtitle=""/>)
   }
  return (
    <table className="usa-table usa-table--borderless" style={{width:'100%', marginBottom: 32}}>
      <thead>
        <tr>
          <th>Email Address</th>
          <th>Type</th>
          <th>Amount</th>
          <th>Card Type</th>
          <th>Created At</th>
          <th>Receipt</th>
        </tr>
      </thead>
      <tbody>
        {props.items.map(item =>
          <tr key={item.pk}>
            <td>
              <Link
                to={"/admin/account/" + item.pk.slice(item.pk.indexOf('-') + 1)}
                className="usa-button usa-button--unstyled"
                style={{textDecoration: 'none'}}
              >
                {item.pk.slice(item.pk.indexOf('-') + 1)}
              </Link>
            </td>
            <td>{item.typeName}</td>
            <td>${item.amountReceived}</td>
            <td>{item.cardType}</td>
            <td>{moment(item.created_at).format('YYYY-MM-DD')}</td>
            <td><a href={item.receiptUrl}>Receipt</a></td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default AdminReceipt
