import { CSVLink } from "react-csv"
import Loading from '../../Loading'
import ChapterWithActions from './common/ChapterWithActions'
import React from 'react'


function AdminChapters(props) {
  if (props.filter !== 'chapters') {return null}

  const {chapters, chaptersPages, chaptersPagesCurrent,
         chaptersCsv, chaptersLoading,
         chaptersMoreDataAvailable} = props.chapters

  return (
    <>
    <h2>Detail
    <span style={{marginLeft: 10, fontSize: 'smaller'}}>
       <CSVLink
         style={{verticalAlign: 'bottom'}}
         data={chaptersCsv}
       >
        <i className="fas fa-file-spreadsheet fa-2x" style={{fontSize: 'smaller'}}> </i>
       </CSVLink>
     </span>
     <span>
       {chaptersPagesCurrent === 0 || chaptersLoading ?
         <span style={{marginLeft: 5, fontSize: 'small', color: 'grey', cursor: 'pointer'}} >
           <i className="fas fa-fast-backward"></i>
         </span> :
         <span style={{marginLeft: 5, fontSize: 'small', color: '#b50909', cursor: 'pointer'}} onClick={() => props.pagination('chapters', 'first')}>
           <i className="fas fa-fast-backward"></i>
         </span>
       }
       {(chaptersMoreDataAvailable || chaptersPagesCurrent + 1 !== chaptersPages) && !chaptersLoading ?
         <span>
           <span style={{fontSize: 'small', fontWeight: 'normal', paddingLeft: 5}}>Page {chaptersPagesCurrent + 1} of {chaptersMoreDataAvailable ? <span>unknown</span> : <span>{chaptersPages}</span>} </span>
           <span style={{marginLeft: 5, fontSize: 'small', color: '#b50909', cursor: 'pointer'}} disabled={!chaptersMoreDataAvailable} onClick={() => props.pagination('chapters', 'next')}>
             <i className="fas fa-forward"></i>
           </span>
        </span> :
        <span>
          <span style={{fontSize: 'small', fontWeight: 'normal', paddingLeft: 5}}>Page {chaptersPagesCurrent + 1} of {chaptersMoreDataAvailable ? <span>unknown</span> : <span>{chaptersPages}</span>} </span>
          <span style={{marginLeft: 5, fontSize: 'small', color: 'grey', cursor: 'pointer'}}>
            <i className="fas fa-forward"></i>
          </span>
        </span>
       }
     </span>
     {chaptersLoading &&
       <i className="fas fa-sync-alt fa-spin" style={{marginLeft: 5, fontSize: 'small', color: '#b50909'}} ></i>
     }

    </h2>
    { chaptersLoading && chapters.length === 0 ?
      <Loading title="" subtitle=""/> :
      <table className="usa-table usa-table--borderless" style={{width:'100%', marginBottom: 32, marginTop: 0, fontSize: 'smaller'}}>
        <thead>
          <tr>
            <th>Chapter</th>
            <th>Base</th>
            <th>Address</th>
            <th>Membership Radius (miles)</th>
          </tr>
        </thead>
        <tbody>
          {chapters.length > 0 && chapters[chaptersPagesCurrent].map(item =>
            <ChapterItem key={item.pk} item={item}/>
          )}
        </tbody>
      </table>
    }

    </>
  )
}

function ChapterItem(props) {
  const { item} = props

  return (
    <tr  style={{verticalAlign: 'top'}}>
      <td>
         <ChapterWithActions
            pk={item.pk}
            name={item.name}
          />
      </td>
      <td>
        <span style={{fontSize: 'small'}}>{item.base}</span>
      </td>
      <td>
        <span style={{fontSize: 'small'}}>{item.full_address}</span>
      </td>
      <td style={{fontSize: 'small', textAlign: 'center', paddingRight: 40, width: 175}}>
        <span>{item.standard_member_distance}</span>
      </td>
    </tr>
  )
}

export default AdminChapters;
