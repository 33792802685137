import { CSVLink } from "react-csv"
import Loading from '../../Loading'
import PersonWithActions from './common/PersonWithActions'
import CompanyWithActions from './common/CompanyWithActions'
import React from 'react'


function AdminGeneric(props) {
  if (props.filter !== 'poc') {return null}

  const {items, pages, currentPage,
         csv, isLoading,
         moreDataAvailable} = props.items

  return (
    <>
    <h2>Detail
    <span style={{marginLeft: 10, fontSize: 'smaller'}}>
       <CSVLink
         style={{verticalAlign: 'bottom'}}
         data={csv}
       >
        <i className="fas fa-file-spreadsheet fa-2x" style={{fontSize: 'smaller'}}> </i>
       </CSVLink>
     </span>
     <span>
       {currentPage === 0 || isLoading ?
         <span style={{marginLeft: 5, fontSize: 'small', color: 'grey', cursor: 'pointer'}} >
           <i className="fas fa-fast-backward"></i>
         </span> :
         <span style={{marginLeft: 5, fontSize: 'small', color: '#b50909', cursor: 'pointer'}} onClick={() => props.pagination('poc', 'first')}>
           <i className="fas fa-fast-backward"></i>
         </span>
       }
       {(moreDataAvailable || currentPage !== pages) && !isLoading ?
         <span>
           <span style={{fontSize: 'small', fontWeight: 'normal', paddingLeft: 5}}>Page {currentPage} of {moreDataAvailable ? <span>unknown</span> : <span>{pages}</span>} </span>
           <span style={{marginLeft: 5, fontSize: 'small', color: '#b50909', cursor: 'pointer'}} disabled={!moreDataAvailable} onClick={() => props.pagination(0, 'next')}>
             <i className="fas fa-forward"></i>
           </span>
        </span> :
        <span>
          <span style={{fontSize: 'small', fontWeight: 'normal', paddingLeft: 5}}>Page {currentPage} of {moreDataAvailable ? <span>unknown</span> : <span>{pages}</span>} </span>
          <span style={{marginLeft: 5, fontSize: 'small', color: 'grey', cursor: 'pointer'}}>
            <i className="fas fa-forward"></i>
          </span>
        </span>
       }
     </span>
     {isLoading &&
       <i className="fas fa-sync-alt fa-spin" style={{marginLeft: 5, fontSize: 'small', color: '#b50909'}} ></i>
     }

    </h2>
    { isLoading && items.length === 0 ?
      <Loading title="" subtitle=""/> :
      <table className="usa-table usa-table--borderless" style={{width:'100%', marginBottom: 32, marginTop: 0, fontSize: 'smaller'}}>
        <thead>
          <tr>
            <th>Company</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Alternate Email</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {items.length > 0 && items[currentPage - 1].map(item =>
            <PocItem key={item.pk} item={item}/>
          )}
        </tbody>
      </table>
    }

    </>
  )
}

function PocItem(props) {
  const { item } = props

  return (
    <tr  style={{verticalAlign: 'top'}}>
      <td>
        <CompanyWithActions
           pk={item.pk}
           name={item.companyName ?? 'missing'}
         />
      </td>
      <td>
         <PersonWithActions
            email={item.primary_email}
            name={item.full_name}
          />
      </td>
      <td>
        <span style={{fontSize: 'small'}}>{item.phone_number}</span>
      </td>
      <td>
        <span style={{fontSize: 'small'}}>{item.alternate_email}</span>
      </td>
      <td>
        <span style={{fontSize: 'small'}}>{item.role_name}</span>
      </td>
    </tr>
  )
}

export default AdminGeneric;
