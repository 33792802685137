import React, { Component } from 'react';

import {ContentfulContext} from '../../classes/context'

class Sponsors extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      sponsors: null,
    };
  }

  componentDidMount() {
    if (!this.state.isLoaded) {
      const contentfulClient = this.context.contentfulClient

      contentfulClient.getEntries({'content_type': 'sectionSponsor', 'limit': 4, 'order': 'fields.position'})
      .then(entries => {
        this.setState({
          isLoaded: true,
          sponsors: entries.items,
        });
      })
      .catch(error => {
        console.log(error)
        this.setState({
          error: error
        })
      })
    }
  }

  render() {

    if (!this.state.isLoaded) return null

    const format = this.props.dark ? "usa-section usa-section--dark" : "usa-section"
    return (

    <section id="test-section-id" class={format} style={{paddingTop: 40}}>
      <div className="grid-container">
        <h2 className="font-heading-xl margin-y-0">Our A/TQ Sponsors</h2>
        <div className='usa-intro'>We are grateful to all our Corporate Sponsors for their support of A/TQ Magazine.</div>
      </div>

      <div class="grid-container">
        <h2 class="font-heading-xl"> </h2>
        <div class="usa-graphic-list__row grid-row grid-gap">

          {this.state.sponsors.map((item) =>
            <div class="grid-col-3" >
              <a
                href={item.fields.externalUrl}
                className="usa-button usa-button--unstyled"
                style={{textDecoration: 'none'}}
              >

              { item.fields.image &&
                <div style={{minHeight: 100}}>
              <img alt='sponsor' src={item.fields.image.fields.file.url} style={{verticalAlign: 'middle'}}/>
              </div>
              }
              </a>
            </div>
          )}


        </div>
      </div>
    </section>
    )
  }
}

Sponsors.contextType = ContentfulContext
export default Sponsors;
