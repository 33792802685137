import { API } from 'aws-amplify';
import React, { Component } from 'react'
import { Redirect, useLocation } from "react-router-dom"
import AdminChapters from './AdminChapters'
import AdminCompanies from './AdminCompanies'
import AdminCounts from './AdminCounts'
import AdminPeople from './AdminPeople'
import AdminBanquet from './AdminBanquet'
import AdminBadges from './AdminBadges'
import AdminReceipt from './AdminReceipt'
import AdminRegistration from './AdminRegistration'
import AdminGeneric from './AdminGeneric'
import ErrorContainer from '../../ErrorContainer'
import Form from 'react-bootstrap/Form'
import Person from '../../../classes/Person'
import ToolbarCoupon from '../../controls/ToolbarCoupon'
import {isValidEmail} from '../../common'

import {Section, PageHeader} from '../../sections/Section'
import PeopleSearch from '../../controls/PeopleSearch'
import cookie from 'react-cookies'

class Admin extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleMatchClick = this.handleMatchClick.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.deletePerson = this.deletePerson.bind(this)
    this.deleteRegistration = this.deleteRegistration.bind(this)
    this.search = this.search.bind(this)

    this.state = {
      error: null,
      redirect: false,
      emailSearch: '',
      filter: cookie.load('filter'),
      deletedPeople: [],
      deletedRegistrations: [],
      matches: [],
      matchSelected: '',
      matchSelectedIndex: -1,
      searching: false,
      searchError: false,
      hideMatches: false,
    };
  }


  handleChange(event) {
    const target = event.target
    const name = target.name.split('.')
    let value = target.type === 'checkbox' ? target.checked : target.value

    if (name[0] === 'filter') {
      cookie.save('filter', value.toLowerCase(), { path: '/' })
    }

    this.setState({
      [name[0]]: value.toLowerCase()
    })

    if (name[0] === 'emailSearch') {
      if (value.toLowerCase().length < 3) {
        this.setState({matches: []})
      } else  { //if (!this.state.searching)
        if (this.state.searchTimer) {
          clearInterval(this.state.searchTimer)
        }
        // prevent a cascade of search requests. Wait until the user
        // stops typing
        this.setState({
          searchTimer: setInterval(() => this.search(value), 300)
        })
      }

    }
  }

  search(value) {
    clearInterval(this.state.searchTimer)
    this.setState({searching: true})
    API.get('search', '/search/' + encodeURI(value.toLowerCase()))
    .then(response => {
      this.setState({
        searching: false,
        matches: response.hits,
        total: response.total,
        searchError: false,
        hideMatches: false,
      })
    })
    .catch(error => {
      this.setState({
        searching: false,
        searchError: true,
        hideMatches: true
      })
      console.log(error)
    })

  }

  handleKeyDown(event) {
    switch (event.key) {
      case 'Enter':
        if (isValidEmail(this.state.emailSearch)) {
          this.setState({ redirect: true })
        } else if (this.state.matchSelectedIndex > -1) {
          this.setState({
            emailSearch: this.state.matches[this.state.matchSelectedIndex].email,
            redirect: true
          })
        }
        break
      case 'ArrowDown':
        this.setState({
          matchSelectedIndex: Math.min(this.state.matchSelectedIndex + 1, this.state.matches.length - 1)
        })
        break
      case 'ArrowUp':
        this.setState({
          matchSelectedIndex: Math.max(this.state.matchSelectedIndex - 1, -1)
        })
        break
      case 'Escape':
        this.setState({ hideMatches: true })
        break
      default:
        // do nothing
    }
  }

  handleSearch() {
    this.setState({ redirect: true })
  }

  handleMatchClick(email, matchedSelectedIndex) {
    this.setState({
      emailSearch: email,
      matchSelected: email,
      matchedSelectedIndex: matchedSelectedIndex,
      redirect: true
    })
  }

  deletePerson(email) {

    let deletedPeople = this.state.deletedPeople
    deletedPeople.push('person-' + email)
    this.setState({
      deletedPeople: deletedPeople
    })

    Person.delete(email, {person: true})

  }

  deleteRegistration(id) {
    let deletedRegistrations = this.state.deletedRegistrations
    deletedRegistrations.push(id)
    this.setState({
      deletedRegistrations: deletedRegistrations
    })

    Person.delete(id.slice(id.indexOf('-') + 1), {person: false, registration: true})

  }

  render() {

    const { error } = this.state;

    if (this.state.redirect) {
      return <Redirect to={"/admin/account/" + this.state.emailSearch} />
    }

   const emailSearchActive = isValidEmail(this.state.emailSearch)
   const filter = this.state.filter ?? 'people'
   const gridContainer = filter === 'banquet' ? '' : "grid-container margin-y-0"
    return (
      <Section>
        <div className="grid-container margin-y-0">
          <PageHeader title="Administration" />
            <div className="grid-row">
            <div className="mobile-lg:grid-col">
              <ToolbarCoupon isAdmin/>
              <span style={{display: "inline-block"}}>

                <Form.Control
                  className="usa-select"
                  style={{backgroundColor: '#eeeeee'}}
                  as="select"
                  name="filter"
                  onChange={this.handleChange}
                  defaultValue={filter}
                >
                  <option key="chapters" value="chapters" >Chapters</option>
                  <option key="company" value="companies" >Industry Partners</option>
                  <option key="poc" value="poc" >Industry Partner POCs</option>
                  <option disabled key="members" value="members" >Membership</option>
                  <option key="people" value="people" >People</option>
                  <option disabled key="eeg" value="people" >Enlisted Education Grants</option>
                  <option disabled key="awards" value="people" >Awards</option>
                  <option key="registration" value="registration" >Registrations</option>
                  <option key="badges" value="badges" >Badges</option>
                  <option key="banquet" value="banquet" >Banquet</option>
                  <option disabled key="receipts" value="receipts" >Receipts</option>
                  <option key="counts" value="counts" >Counts</option>
                </Form.Control>

              </span>
            </div>

            <div className="mobile-lg:grid-col" style={{textAlign: 'right', paddingRight: 0, display: "inline-block"}}>
              <PeopleSearch
                handleSearch={this.handleSearch}
                handleChange={this.handleChange}
                handleKeyDown={this.handleKeyDown}
                handleMatchClick={this.handleMatchClick}
                searching={this.state.searching}
                emailSearchActive={emailSearchActive}
                matches={this.state.matches}
                matchSelectedIndex={this.state.matchSelectedIndex}
                hideMatches={this.state.hideMatches}
                searchError={this.state.searchError}
              />

            </div>
          </div>
        </div>

        <SearchRequest />
        {error &&
          <ErrorContainer title="Error" error={error} />
        }
        {!error &&
            <div className={gridContainer}>

          <AdminCounts
            filter={filter}
            items={this.props.counts}
            isLoaded={this.props.countsLoaded}
          />
          <AdminGeneric
            filter={filter}
            items={this.props.items[0]}
            isLoaded={this.props.items[0].isLoaded}
            pagination={this.props.pagination}
          />
          <AdminRegistration
            filter={filter}
            registration={this.props.registration}
            pagination={this.props.pagination}
            deletedPeople={this.state.deletedPeople}
            deletedRegistrations={this.state.deletedRegistrations}
            deletePerson={this.deletePerson}
            deleteRegistration={this.deleteRegistration}
          />
          <AdminPeople
            filter={filter}
            people={this.props.people}
            pagination={this.props.pagination}
            deletedPeople={this.state.deletedPeople}
            deletePerson={this.deletePerson}
          />
          <AdminChapters
            filter={filter}
            chapters={this.props.chapters}
            pagination={this.props.pagination}
          />
          <AdminBadges
            filter={filter}
            registration={this.props.badge}
            pagination={this.props.pagination}
            deletedPeople={this.state.deletedPeople}
            deletedRegistrations={this.state.deletedRegistrations}
            deletePerson={this.deletePerson}
            deleteRegistration={this.deleteRegistration}
            toggleReviewed={this.props.toggleReviewed}
            togglePrinted={this.props.togglePrinted}
            filterDataSet={this.props.filterDataSet}
          />
          <AdminBanquet
            filter={filter}
            banquet={this.props.banquet}
            pagination={this.props.pagination}
          />
          <AdminCompanies
            filter={filter}
            companies={this.props.companies}
            pagination={this.props.pagination}
          />
          <AdminReceipt
            filter={filter}
            items={this.props.receipts}
            isLoaded={this.props.receiptsLoaded}
          />
      </div>
        }
      </Section>
    )
  }
}

function SearchRequest() {
  const {search, pathname} = useLocation();

  if (!search) {return null}
  const keywords = search.split('=');

  // If there are other parameters on the URL, this means somebody (facebook)
  // added the parameter. Remove.
  if (keywords[0] !== '?search') {return <Redirect to={pathname} />}

  return <Redirect to={"/?search=" + keywords[1]} />
}


export default Admin;
