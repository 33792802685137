import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Link } from "react-router-dom";

import Loading from '../Loading';
import ErrorContainer from '../ErrorContainer';

class IndustryPartnerList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      page: null,
    };
  }

  componentDidMount() {
    if (this.state.isLoaded) {return}

    API.get('general', '/industry-partner-list')
    .then(response => {


      this.setState({
        isLoaded: true,
        items: response.items
      });
    })
    .catch(error => {
      console.log(error)
      this.setState({
        error: error
      })
    })
  }

  render() {
    const { error, isLoaded, items } = this.state;

    if (error) {
      return <ErrorContainer title="Error" error={error} />
    } else if (!isLoaded) {
      return <Loading title=""  subtitle=""/>;
    }

    const itemsSorted = items.sort((a, b) => {
      if (a.name < b.name) {return -1}
      if (a.name > b.name) {return 1}
      return 0
    })

    const regex = / /g
    return (
      <section className="usa-section" style={{paddingTop: 10}}>
        <div className="grid-container margin-y-0">
          {itemsSorted.map(partner =>
            <h3 key={partner.pk} >
              <Link
                to={"/industry-partners/partners/" + partner.name.toLowerCase().replace(regex, '-')}
                title="Industry Partners"
                aria-label="industry-partner"
                style={{textDecoration: 'none'}}
              >
                {partner.name}
              </Link>
            </h3>
          )}
        </div>
      </section>
    )

  }
}

export default IndustryPartnerList;
