/* jshint camelcase: false */

import { API } from 'aws-amplify'

var moment = require('moment')

const nullDonation = {
  id: null, // required
  root:
    { pk: null,
      sk: 'donation' },
  donation: {
    fund: 'general-fund',
    fund_other: null,
    fund_name: 'General Fund',
    in_honor_of: null,
    amount: null,
    message: null,
    your_email: null,
    donation_made_by: null,
    paymentIntentId: 'papymentIntentId-missing'
  }
};

class Donation {

  constructor() {
    this.item = nullDonation
    this.isInitialized = false
    this.isLoading = false
  }

  async initialize() {
    this.item.id = moment().format(); // Updated at store time.
    this.isInitialized = true
  }

  async store() {

    this.item.root.pk = 'person-' + this.item.donation.your_email
    this.item.root.sk = 'root'
    this.item.donation.id = this.item.donation.paymentIntentId;
    this.item.donation.created_at = moment().format();

    return new Promise((resolve, reject) => {
      API.put('general', '/donation', {body: this.item})
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
    })
  }
}

export default Donation;
