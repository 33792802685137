import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'

class BaseOther extends Component {

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(event) {
    this.props.onChange(event)
    this.props.onChange({target: {name: this.props.type + '.base',  value: 'other', type: 'select-one'}})
    this.props.onChange({target: {name: this.props.type + '.base_name',  value: 'Other', type: 'select-one'}})

    this.props.stateChange()
  }

  render() {
    return (
      <div className="tablet:grid-col-4">
        <div className="usa-label">Other Base or Location Name</div>
        <Form.Control
          className="usa-input"
          name={this.props.type + '.base_other'}
          plaintext
          value={this.props.defaultValue}
          defaultValue={this.props.defaultValue}
          style={{backgroundColor: '#eeeeee'}}
          onChange={this.onChange}
        />
      </div>
    )
  }
}

export default BaseOther
