import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { useLocation, Redirect } from "react-router-dom";

import {CardElement} from '@stripe/react-stripe-js';

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Fund from '../controls/Fund'
import FieldError from '../controls/FieldError'
import Donation from '../../classes/Donation'
import { DotLoader } from 'react-spinners';
import { css } from '@emotion/core';

import {Formik, useFormik} from 'formik'

const editingStyle = {
  backgroundColor: '#eeeeee',
};

const override = css`
    display: inline-block;
    margin: 0 auto;
    border-color: white;
`;

class Donate extends Component {

  constructor(props) {
    super(props)

    this.state = {
      id: null,
      donation: new Donation,
      confirmation: false,
      initialized: true,
      stripeError: null,
      allowSubmit: true,
      connectionError: false,
    }

    this.state.donation.initialize()

    this.endEdit = this.endEdit.bind(this)
  }

  endEdit(values) {

    const {stripe, elements} = this.props;
    if (!stripe || !elements) {
      return false;
    }

    let {donation} = this.state
    donation.item.donation.your_email = values.donation_your_email
    donation.item.donation.in_honor_of = values.donation_in_honor_of
    donation.item.donation.message = values.donation_message
    donation.item.donation.donation_made_by = values.donation_donation_made_by
    donation.item.donation.amount = values.donation_amount
    donation.item.donation.fund = values.donation_fund
    donation.item.donation.fund_name = values.donation_fund_name
    donation.item.donation.fund_other = values.donation_fund_other
    this.setState({donation: donation, allowSubmit: false, connectionError: false})

    // const cardElement = elements.getElement(CardElement)
    // stripe.createPaymentMethod()
    // const {error, paymentMethod} = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: cardElement,
    // });

    // if (error) {
    //   errors.donation_fund_other = error;
    // } else {
    //   console.log('[PaymentMethod]', paymentMethod);
    // }

    // Start by getting a purchase intent from Stripe
    API.post('stripeProcessing', '/intent', {body: {'donation': donation.item.donation}})
    .then(response => {

      donation.item.donation.paymentIntentId = response.paymentIntentId
      donation.item.donation.signature = response.signature

      this.setState({donation: donation})
      this.state.donation.store()
      .catch(error => {
        this.setState({stripeError: error, allowSubmit: true})
      })

      const {clientSecret} = response

      const name = donation.item.donation.donation_made_by ? donation.item.donation.donation_made_by : 'anonymous'

      stripe.confirmCardPayment(clientSecret, {
        receipt_email: donation.item.donation.your_email,
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            email: donation.item.donation.your_email,
            name: name,
            }
        }
      })
      .then(result => {
        if (result.error) {
          this.setState({stripeError: result.error, allowSubmit: true, confirmation: true})
          return false
        }
          this.setState({confirmation: true})
      })
      .catch(error => {
        // may not be a stripe error
        this.setState({allowSubmit: true, stripeError: error.raw})
      });
    })
    .catch(error => {
      if (error.type === 'StripeConnectionError') {
        this.setState({allowSubmit: true, stripeError: error, connectionError: true})
      } else {
        this.setState({allowSubmit: true, stripeError: error})
      }
    })
  }

  render() {

    const {stripe, elements} = this.props;
    const {donation, confirmation} = this.state
    const stripeReady = (stripe && elements ? true : false)

    if (confirmation) {
      return (
        <>
          <SearchRequest />
          <main id="main-content" class="main-content">
            <section id="introduction-section-id" class="usa-section usa-section--dark">
              <div class="grid-container">
                <h2 name="donation-confirmation" class="font-heading-xl margin-y-0">Donation Confirmation</h2>
                <p class="usa-prose">
                Thank you for your donation. We have emailed your receipt to {donation.item.donation.your_email}</p>
                <p>If you don't receive an email receipt, please contact us at ata@atalink.org for help</p>
              </div>
            </section>
          </main>
        </>
      )
    }

    return (
      <>
        <SearchRequest />
        <main id="main-content" class="main-content">
          <section id="introduction-section-id" class="usa-section usa-section--dark">
            <div class="grid-container">
              <h2  name="donation-entry" class="font-heading-xl margin-y-0">Donate to the A/TA</h2>
              <p class="usa-prose">
              The Airlift/Tanker Association is a Section 501(c)(3)e tax-exempt organization. As an education-based nonprofit, we depend on our member’s generosity to sustain a variety of community outreach and professional development initiatives. Our AFROTC scholarship and Enlisted Education Grant programs alone helped more than 700 young leaders achieve their educational goals. Your donations also support a robust awards program recognizing Air Mobility’s exceptional performers as well as preserving our heritage through the Air Mobility Museum at Dover AFB. We appreciate anything you can do to support us.</p>
              <p class="usa-prose">Provide your details and the amount of your donation below. We will charge the card the full amount of your donation immediately. </p>
            </div>
          </section>

          <section class="usa-section margin-y-0" style={{paddingTop: 20, paddingBottom: 20}}>
            <FormDefinition endEdit={this.endEdit}
                            stripeError={this.state.stripeError}
                            connectionError={this.state.connectionError}
                            allowSubmit={this.state.allowSubmit && stripeReady}
                            elements={elements} />
          </section>
        </main>
      </>
    )
  }
}

export default Donate

function SearchRequest() {
  const {search, pathname} = useLocation();

  if (!search) return null
  const keywords = search.split('=');

  // If there are other parameters on the URL, this means somebody (facebook)
  // added the parameter. Remove.
  if (keywords[0] !== '?search') return <Redirect to={pathname} />

  return <Redirect to={"/?search=" + keywords[1]} />
}

function Payment(props) {
  const {stripeError, connectionError, onBlur, changeHandler} = props

  return (
  <div class="grid-row grid-gap">
    <div class="tablet:grid-col-6">
      <div className='usa-label'>Card Details</div>
        <CardElement style={{base: {fontSize: '18px'}}}
            className='usa-input'
            id='card_info'
            name='card_info'
            onBlur={onBlur}
            onChange={changeHandler}/>
        <div id='card-errors' role='alert'></div>
        {stripeError &&
        <FieldError name='stripe_error' message={stripeError.message} />
        }
        {connectionError &&
        <FieldError  name='stripe_connection_error' message="There was a connection error, your card was NOT charged. Please try again." /> }
      </div>
  </div>
)
}

const FormDefinition = (props) => {

  const {endEdit, stripeError, connectionError, allowSubmit, elements} = props

  const validate = values => {
    const errors = {}

    if (!values.donation_your_email) {
      errors.donation_your_email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.donation_your_email)) {
      errors.donation_your_email = 'Invalid email address';
    }
    if (!values.donation_in_honor_of) {
      errors.donation_in_honor_of = 'Required'
    }
    if (!Number.isInteger(values.donation_amount)) {
      errors.donation_amount = 'Expect whole dollars'
    } else if (!values.donation_amount) {
      errors.donation_amount = 'Required'
    }
    if (values.donation_fund === 'other' && values.donation_fund_other === '') {
      errors.donation_fund_other = 'Required'
    }

    const cardNumber = elements.getElement(CardElement)
    if (!cardNumber._complete) {
      errors.card_info = 'Card information is incomplete'
    }

    return errors
  }

  function handlePaymentChange() {
//    formik.touched.card_info = false
    formik.validateForm()
  }

  const formik = useFormik({
    initialValues: {
      donation_donation_made_by: '',
      donation_your_email: '',
      donation_in_honor_of: '',
      donation_amount: 25,
      donation_fund: 'general-fund',
      donation_fund_name: 'General Fund',
      donation_fund_other: '',
      donation_message: '',
      card_info: ''
    },
    validate,
    onSubmit: values => {
      endEdit(values)
    }
  })

  return (
    <Form onSubmit={formik.handleSubmit}>

      <div class="grid-container">
        <h2 class="margin-y-5" style={{marginBottom: 0}}>About You</h2>
        <div class="grid-row grid-gap">
          <div class="tablet:grid-col-6">
            <div className='usa-label'
                 for='donation_donation_made_by' >
              Your Name (leave blank for an anonymous donation)
            </div>
            <Form.Control className='usa-input'
                          id='donation_donation_made_by'
                          name='donation_donation_made_by'
                          plaintext maxlength='50' placeholder="anonymous"
                          style={editingStyle}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.donation_donation_made_by}
            />
            {formik.touched.donation_donation_made_by &&
             formik.errors.donation_donation_made_by ? <FieldError  name='donation_made_by_error' message={formik.errors.donation_donation_made_by} /> : null}
          </div>

          <div class="tablet:grid-col-6">
            <div className='usa-label'
                 for='donation_your_email' >
              Your Email (for your receipt)
            </div>
            <Form.Control className='usa-input'
                          id='donation_your_email'
                          name='donation_your_email'
                          plaintext maxlength='50'
                          style={editingStyle}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.donation_your_email}
            />

            {formik.touched.donation_your_email &&
             formik.errors.donation_your_email ? <FieldError name='your_email_error' message={formik.errors.donation_your_email} /> : null}
          </div>

        </div>
      </div>
      <div class="grid-container">
        <h2 class="margin-y-5" style={{marginBottom: 0}}>Your Donation</h2>
          <div class="grid-row grid-gap">
            <div class="tablet:grid-col-6">
              <div className='usa-label'
                   for='donation_in_honor_of' >
                Donate in Honor or Memory Of
              </div>
              <Form.Control className='usa-input'
                            id='donation_in_honor_of'
                            name='donation_in_honor_of'
                            plaintext maxlength='100'
                            style={editingStyle}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.donation_in_honor_of}
              />
              {formik.touched.donation_in_honor_of &&
               formik.errors.donation_in_honor_of ? <FieldError name='in_honor_of_error' message={formik.errors.donation_in_honor_of} /> : null}
            </div>
          </div>

          <div class="grid-row grid-gap">
            <div class="tablet:grid-col-3">
              <div className='usa-label'
                   for='donation_amount' >
                Donation Amount (USD)
              </div>
              <Form.Control className='usa-input'
                            id='donation_amount'
                            name='donation_amount'
                            plaintext maxlength='6'
                            type='number'
                            style={editingStyle}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.donation_amount}
              />
              {formik.touched.donation_amount &&
               formik.errors.donation_amount ? <FieldError name='amount_error' message={formik.errors.donation_amount} />  : null}
            </div>

            <Fund formik={formik} />

            <div class="tablet:grid-col-6">
              <div className='usa-label'
                   for='donation_message' >
                Your Message
              </div>
              <Form.Control className='usa-textarea'
                            id='donation_message'
                            name='donation_message'
                            plaintext maxlength='500'
                            as='textarea' rows='3'
                            style={editingStyle}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.donation_message}
              />
              {formik.touched.donation_message &&
               formik.errors.donation_message ? <FieldError name='message_error' message={formik.errors.donation_message} /> : null}
            </div>
          </div>
          <Payment
            stripeError={stripeError}
            connectionError={connectionError}
            onBlur={() => {formik.touched.card_info = true; formik.validateForm()}}
            onChange={formik.handleChange}
            changeHandler={handlePaymentChange} />
          {formik.touched.card_info &&
           formik.errors.card_info ? <FieldError name='card_info_error' message={formik.errors.card_info} /> : null}

          <div class="grid-row grid-gap margin-y-4">
            <div class="tablet:grid-col-6">
            <Button className='usa-button'
                    name='donate'
                    disabled={!allowSubmit}
                    type='submit' >
              <span style={{paddingRight: '4px', verticalAlign: 'text-top'}}>
                <DotLoader
                  css={override}
                  sizeUnit={"px"}
                  size={20}
                  color='white'
                  loading={!allowSubmit}
                />
              </span>
              Donate
            </Button>
            </div>
          </div>
      </div>
    </Form>
  )
}
