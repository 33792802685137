import React, { Component } from 'react'
import { NavLink, Link, Redirect, useLocation } from "react-router-dom"
import Loading from '../../Loading'
import ErrorContainer from '../../ErrorContainer'
import Bio from '../../controls/Bio'
import IndustryPartnerList from '../../controls/IndustryPartnerList'
import HallOfFameList from '../../controls/HallOfFameList'
import ChapterList from '../../controls/ChapterList'
import {navigation} from '../../controls/menus'

import {ContentfulContext} from '../../../classes/context'

import  {Section, PageHeader, ButtonStatic} from '../../sections/Section';

import ButtonTimed from '../../sections/ButtonTimed'

const ReactMarkdown = require('react-markdown/with-html')
var moment = require('moment')

function isConventionNews(item) {
  if (!item.fields.tags) return false;
  return item.fields.tags.includes('convention2020');
}

class Sidebar extends Component {

    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        amcPriorities: false,
        page: null,
      };
    }

    componentDidMount() {
      if (!this.state.isLoaded) {
        const contentfulClient = this.context.contentfulClient

        contentfulClient.getEntries({'content_type': 'pageNews', 'limit': 500, 'order': '-fields.originalCreatedDate'})
        .then(entries => {
          const items = entries.items.filter(isConventionNews)
          this.setState({
            isLoaded: true,
            items: items,
          });
        })
        .catch(error => {
          console.log(error)
          this.setState({
            error: error
          })
        })

        contentfulClient.getEntry('5clarKEi4Xbzi0NjzX42KQ')
        .then(entry => {
          this.setState({
            amcPriorities: entry,
          });
        })
        .catch(error => {
          console.log(error)
          this.setState({
            error: error
          })
        })
      }
    }

    render() {
      return (
        <SidebarContent
          isLoaded={this.state.isLoaded}
          items={this.state.items}
          amcPriorities={this.state.amcPriorities}
          showContent={this.props.showContent}
      />
      )
    }
}


function SidebarContent(props) {

  const {pathname} = useLocation()
  const pathComponents = pathname.split('/')
  let location = '/' + pathComponents[1]

  // Hard coded for partner pages. If part 2 is 'partners', then don't add
  if (pathComponents[2] && pathComponents[2] !== 'partners') {
    location += '/' + pathComponents[2]
  }

  let sidebar = navigation.filter(topMenu => (
    topMenu.items.filter(menuItem => (menuItem.link === location)).length > 0  )
  )[0]

  // Check to see if any of the second tier items match
  if (!sidebar) {
    navigation.forEach(topMenu => {
      topMenu.items.forEach(item => {
        if (item.children && !sidebar) {
          if (item.children.filter(child => (child.link === location)).length > 0) {
            sidebar = topMenu
          }
        }
      })
    })
  }

  if (!sidebar) {
    return null
  }
  return (
  <>
    {props.amcPriorities &&
      <Link to="/virtual2020/amc-priorities">

       { props.amcPriorities.fields.image && props.amcPriorities.fields.image.fields.file &&
        <img alt={props.amcPriorities.fields.headline} style={{float: 'left', marginBottom: '20px', marginRight: '20px'}} src={props.amcPriorities.fields.image.fields.file.url} width='100%' />
      }
      </Link>
    }
    <h5 class="usa-heading-alt">{sidebar.parentLabel}</h5>
    <nav aria-label="Secondary navigation">
    <ul class="usa-sidenav">
    {sidebar.items.map((item, i) => {
      return(
      <>
      <li class="usa-sidenav__item">
        {item.link.substring(0,4) === 'http'
        ?
          <a href={item.link} activeClassName="usa-current">{item.label}</a>
        :
          <NavLink to={item.link} activeClassName="usa-current">
            {item.label} {item.auth && <i class="fad fa-lock-alt"></i>}
          </NavLink>
        }
        {item.children && item.children.map((child, j) => {
          return (
            <>
            <li class="usa-sidenav__sublist">
            <NavLink to={child.link} activeClassName="usa-current">
              {child.label}
            </NavLink>
            </li>
            </>
          )
        })
        }
      </li>
      </>)
    })}
    </ul>
    </nav>
    {props.isLoaded && false &&
      <>
      <h5 class="usa-heading-alt">Convention News</h5>
      <nav aria-label="Secondary navigation">
      <ul class="usa-sidenav">
        {props.items.map(item => {
          return (
            <li class="usa-sidenav__item">
              <a
                onClick={() => props.showContent('news', item)}
                style={{cursor: 'pointer'}}
              >
                {item.fields.headline}
              </a>
            </li>
          )
        })}
      </ul>
    </nav>
    </>
    }
  </>
)
}



Sidebar.contextType = ContentfulContext
export default Sidebar;
