
import React, { Component } from 'react'
import { Link } from "react-router-dom"

import {ChapterContext} from '../../../classes/context'
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = 'pk.eyJ1IjoiaG93ZnRlbmdpbmVlcmluZyIsImEiOiJ3Z2tzSHF3In0.p74IySfo4uGHm4HLq5JyUw';

var moment = require('moment')
var map

class ChapterSummary extends Component {

  constructor(props) {
    super(props)

    this.mapContainer = React.createRef();
    this.state = {update: true}
  }

  componentDidUpdate() {
    const {chapter} = this.context

   let position = [-77.03238901390978, 38.913188059745586] // defaults to DC
    if (chapter.item.root.latitude && chapter.item.root.longitude) {
      position = [chapter.item.root.longitude, chapter.item.root.latitude]
    }
    if (this.mapContainer.current && this.state.update) {
      this.setState({update: false})
      map = new mapboxgl.Map({
       container: this.mapContainer.current,
       style: 'mapbox://styles/mapbox/streets-v11',
       center: position,
       zoom: 8
       });

       map.scrollZoom.disable();

       map.loadImage(
         'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
         function (error, image) {
           if (error) {throw error}
           map.addImage('custom-marker', image);
           // Add a GeoJSON source with 2 points
           map.addSource('points', {
           'type': 'geojson',
           'data': {
               'type': 'FeatureCollection',
               'features': [
                 { 'type': 'Feature',
                   'geometry': {
                     'type': 'Point',
                     'coordinates': position
                     },
                   'properties': {
                     'title': chapter.item.root.name
                   }},
               ]
             }
           });

           map.addLayer({
             'id': 'points',
             'type': 'symbol',
             'source': 'points',
             'layout': {
               'icon-image': 'custom-marker',
               // get the title name from the source's "title" property
               'text-field': ['get', 'title'],
               'text-font': [
                 'Open Sans Semibold',
                 'Arial Unicode MS Bold'
                 ],
               'text-offset': [0, 1.25],
               'text-anchor': 'top'
             }
           });
       })
    }

  }

  render() {

    if (this.props.editMode.mode !== 'view') {return null}

    const {chapter} = this.context
    const {startEdit, removeItem} = this.props
    return (

      <div className="margin-y-5">
        <h2 className="usa-heading-alt margin-y-0">
          {chapter.item.root.name}
        </h2>

        <div className="usa-prose">
          <hr />
          <div className="usa-prose">
            <h3>General Information</h3>
            <div className="usa-prose" style={{ marginLeft: 20 }}>
              <div><strong>Base</strong>: {chapter.item.root.base}</div>
              <div><strong>ICAO</strong>: {chapter.item.root.icao}</div>
              <div><strong>Chapter Website</strong>: {chapter.item.root.url}</div>
              <div><strong>Contact Email</strong>: {chapter.item.root.contact_email}</div>
              <Address address={chapter.item.root.full_address} />
              <div><strong>Location</strong>: ( {chapter.item.root.latitude}, {chapter.item.root.longitude} )</div>
              <div><strong>Membership Radius</strong>: {chapter.item.root.standard_member_distance} km</div>
            </div>

            <div className="map-container">
              <div ref={this.mapContainer} style={{height: '100%'}}/>
            </div>
        </div>


        <div className="usa-prose">
          <hr />
          <div className="usa-line-length-example usa-prose">
            <h3>Personnel Information</h3>

            <PocList
              chapter={chapter}
              startEdit={startEdit}
              removeItem={removeItem}
            />

            <MembershipList
              chapter={chapter}
              startEdit={startEdit}
              removeItem={removeItem}
            />
        </div>

      </div>
    </div>
  </div>

    )
  }
}

function editPoc(startEdit, sk) {
  startEdit({mode: 'editPoc', sk: sk, adminMode: 'view'}, null)
}


function PocList(props) {
  const {chapter, startEdit, removeItem} = props

  if (!chapter || !chapter.item || !chapter.item.roles) {return null}

  return (
    <div className="usa-accordion">

      <h3 className="usa-accordion__heading">
        <button className="usa-accordion__button"
          aria-expanded="true"
          aria-controls="mh">
          Points of Contact
        </button>
      </h3>
      <div id="mh" className="usa-accordion__content" >
        <table  className="usa-table usa-table--borderless " style={{width:'100%', marginTop: 0, fontSize: 'small'}}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {chapter.item.roles.map(role => {
              return (
                <tr key={role.sk}>
                  <td>
                    <span
                      className="usa-button-unstyled"
                      style={{textDecoration: "none", paddingRight: 8, color: "#b40808", cursor: "pointer"}}
                      onClick={() => removeItem(role.pk, role.sk, 'roles')}
                    >
                      <i className="fas fa-trash-alt"> </i>
                    </span>
                    <span
                      className="usa-button-unstyled"
                      style={{textDecoration: "none", paddingRight: 8, color: "#b40808", cursor: "pointer"}}
                      onClick={() => editPoc(startEdit, role.sk)}
                    >
                      <i className="fas fa-pencil-alt"> </i>
                    </span>
                    {role.full_name}
                  </td>
                  <td><Link to={'/admin/account/' + role.primary_email} >
                    {role.primary_email}
                  </Link></td>
                  <td>{role.phone_number}</td>
                  <td>{role.role_name}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

function MembershipList(props) {
  const {chapter} = props

  if (!chapter || !chapter.item.members || !chapter.item.members || !chapter.item.members.hits) {return null}

  const membershipsSorted = chapter.item.members.hits.sort((a, b) => {
    if (a.lastname + a.firstname < b.lastname + b.firstname) {return 1}
    if (a.lastname + a.firstname > b.lastname + b.firstname) {return -1}
    return 0
  })

  return (
    <div className="usa-accordion">

      <h3 className="usa-accordion__heading">
        <button className="usa-accordion__button"
          aria-expanded="true"
          aria-controls="ipmh">
          Chapter Membership
        </button>
      </h3>

      <div className="usa-prose" style={{ marginLeft: 20, marginTop: 10 }}>Membership: {chapter.item.members.total.value}</div>
      <div id="ipmh" className="usa-accordion__content" >
        <table  className="usa-table usa-table--borderless " style={{width:'100%', marginTop: 0, fontSize: 'small'}}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Contact</th>
              <th>Membership Type</th>
              <th>Membership End</th>
            </tr>
          </thead>
          <tbody>
            {membershipsSorted.map(membership => {
              if (membership._source.pk === 'counts') {return null}
              return (
                <tr key={membership._source.pk}>
                  <td>{membership._source.fullname}</td>
                  <td><Link to={'/admin/account/' + membership._source.email} >
                    {membership._source.email}
                  </Link></td>
                  <td><AddressMember membership={membership._source} />
                      <br />{membership._source.phone}
                  </td>
                  <td>{membership._source.membershipTypeName}</td>
                  <td>{membership._source.lastDayOfMembership && moment(membership._source.lastDayOfMembership).format('YYYY-MM-DD')}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

function AddressMember(props) {
  const {membership} = props

  if (!membership || !membership.addressLine1) {return null}

  const fullAddress =
    (membership.addressLine1 ? membership.addressLine1 : '') +
    (membership.addressLine2 ? membership.addressLine2 + ', ' : ', ') +
    (membership.city ? membership.city + ' ' : '') +
    (membership.stateOrProvince ? membership.stateOrProvince + ' ' : '') +
    (membership.postal_code ? membership.postal_code + ' ' : '') +
    (membership.country ? membership.country + ' ' : '')

  return <address><em>{fullAddress}</em> </address>
}


function Address(props) {
  const {address} = props

  return <address><strong>Address:</strong> {address}</address>

}


ChapterSummary.contextType = ChapterContext
export default ChapterSummary
