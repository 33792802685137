import React from 'react'
import { Link } from "react-router-dom";

export default function RegistrationDetail(props) {

  const {person, hasCancellationRequest, hasCancellationConfirmed,
        includesIid, hasCart, isAdmin,
        showHotelModal, showHotelConfirmationModal, handleHotelCancellation,
        removeConfirmationCode} = props

  const toHotel = hasCart ?
    'https://api.passkey.com/httpapi/RegLink?' +
    'PartnerID=10567978&' +
    'OP=GetForRedirectResWeb&' +
    'Version=4.00.02&' +
    'Mode=S&' +
    'Destination=02&' +
    'LastName=' + person.item.root.last_name + '&' +
    'BridgeID=' + person.item.cart.hotelBridgeId + '&' +
      'Locale=EN_US' :
    null

// https://book.passkey.com/entry?utm_source=landing&utm_medium=none&utm_term=OFMC9IXH&utm_campaign=GuestDeck&landing=default_page
//
// https://book.passkey.com/event/50166496/owner/100/r/edit/OFMC9IXH
// https://uat-book.passkey.com/event/151592/owner/100/r/328T73L2
//
// https://api.passkey.com/httpapi/RegLink?PartnerID=10567978&OP=ModifyReservationResWeb&Version=4.00.02&Mode=S&resAckNum=OFMC9IXH&lastName=Galamond&Destination=2
// https://uat-api.passkey.com/httpapi/RegLink?PartnerID=136260&OP=ModifyReservationResWeb&Version=4.00.02&Mode=S&resAckNum=328T73L2&lastName=Smith&Destination=2
  const modifyReservation = 'https://book.passkey.com/event/50166496/owner/100/r/edit' + person.item.cart.hotelConfirmationCode;

// https://book.passkey.com/entry?utm_source=landing&utm_medium=none&utm_term=OFMC9IXH&utm_campaign=GuestDeck&landing=default_page
// IiwidmFsdWUiOiJPRk1DOUlYSCJ9XX19.ITHOxAHAWpEX4wX8ddfdK1e5PouDAf6k0qqWfyEXx9I&utm_source=landing&utm_medium=none&utm_term=OFMC9IXH&utm_campaign=GuestDeck&landing=default_page

  if (!hasCart) {
    return (
      <div className="usa-prose" style={{ marginLeft: 20, marginBottom: 10}}>
        No registration for the upcoming Convention.
      </div>
    )
  }

  // Normally we can rely on person.item.cart.includesGuest to say whether
  // a guest should be displayed but this does not work after a guest is
  // added or removed as a secondary item. Server sets the flag, but the record
  // is not reloaded so we have to calculate it.

  const includesGuest = person.item.cart.secondaryItems && person.item.cart.secondaryItems.filter(item => item.isGuestActivity).length > 0

  let pendingReceipt = false
  if (person.item['cart-personal'] && Object.entries(person.item['cart-personal']).length !== 0) {
    if (!person.item['cart-personal'].receiptUrl || person.item['cart-personal'].receiptUrl === "") {
      pendingReceipt = true
    }
  }
  if (person.item['cart-gov'] && Object.entries(person.item['cart-gov']).length !== 0) {
    if (!person.item['cart-gov'].receiptUrl || person.item['cart-gov'].receiptUrl === "") {
      pendingReceipt = true
    }
  }
  if (person.item['cart-iid'] && Object.entries(person.item['cart-iid']).length !== 0) {
    if (!person.item['cart-iid'].receiptUrl || person.item['cart-iid'].receiptUrl === "") {
      pendingReceipt = true
    }
  }
  return (
    <>
    {hasCancellationRequest &&
      <div className="usa-alert usa-alert--info usa-alert--slim" style={{ marginLeft: 20, marginBottom: 10 }} >
        <div className="usa-alert__body">
          <p className="usa-alert__text">We are processing the request to cancel your registration. Contact us by email or phone if you no longer want to cancel.</p>
        </div>
      </div>
    }
    {hasCancellationConfirmed &&
      <div className="usa-alert usa-alert--warning usa-alert--slim" style={{ marginLeft: 20, marginBottom: 10 }} >
        <div className="usa-alert__body">
          <p className="usa-alert__text">Your registration has been cancelled. Contact us by email or phone if you no longer want to cancel.</p>
        </div>
      </div>
    }
    <div className="usa-prose" style={{ marginLeft: 20, marginBottom: 20 }}>
      <div><strong>Registration:</strong> {person.item.cart.primaryRegistrationType.name}</div>
      <div><strong>IID Attendee:</strong> {includesIid ? <span>Yes</span> : <span>No</span>}</div>
      <div><strong>Price:</strong> ${person.item.cart.totalPrice}</div>
      {(person.item.cart.secondaryAttendeeBanquetDietaryPreference ||
       person.item.cart.secondaryAttendeeBanquetSeatingRequest) &&
       <>
        <div><strong>Attending the Banquet:</strong> Yes</div>

        {person.item.cart.secondaryAttendeeBanquetDietaryPreference &&
          <div style={{marginLeft: 12}}><strong>Dietary Restriction:</strong> {person.item.cart.secondaryAttendeeBanquetDietaryPreference}</div>
        }
        {person.item.cart.secondaryAttendeeBanquetSeatingRequest &&
          <div style={{marginLeft: 12}}><strong>Seating Request:</strong> {person.item.cart.secondaryAttendeeBanquetSeatingRequest}</div>
        }
        </>
      }

      <HotelSummary
        cart={person.item.cart}
        showHotelModal={showHotelModal}
        showHotelConfirmationModal={showHotelConfirmationModal}
        handleHotelCancellation={handleHotelCancellation}
        removeConfirmationCode={removeConfirmationCode}
      />
      {isAdmin &&
        <BadgeInformation
          cart={person.item.cart}
        />
      }

      {person.item.cart.willAttendBanquet === 'No' &&
      <div><strong>Attending the Banquet:</strong> No. Call us if you change your mind.</div>
      }
      {includesGuest &&
        <>
        <div><strong>Guest:</strong> {person.item.cart.secondaryGuestFirstName} {person.item.cart.secondaryGuestLastName}</div>
        {person.item.cart.secondaryGuestBanquetDietaryPreference &&
          <div style={{marginLeft: 12}}><strong>Dietary Restriction:</strong> {person.item.cart.secondaryGuestBanquetDietaryPreference}</div>
        }
        {person.item.cart.secondaryGuestBanquetSeatingRequest &&
          <div style={{marginLeft: 12}}><strong>Seating Request:</strong> {person.item.cart.secondaryGuestBanquetSeatingRequest}</div>
        }
        </>
      }
      {person.item.cart.execAssistantCode &&
        <div><strong>Exec Assistant Code:</strong> {person.item.cart.execAssistantCode}</div>
      }
      {person.item.cart.status === 'payment-submitted' &&
        <div><strong>Payment Status:</strong> Payment Submitted</div>
      }
      {(person.item.cart.status === 'complete' || person.item.cart.status === 'cancellation-requested') &&
        <div><strong>Payment Status:</strong> Payment Complete (
          <CustomLink
            className="usa-button usa-button--unstyled"
            to="/receipt"
            adminTo={"/admin/receipt/" + person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1)}
            isAdmin={isAdmin}
            label="Itemized Receipt"
            style={{textDecoration: 'none'}}
          />
        )
      </div>
      }

      {person.item['cart-personal'] && person.item['cart-personal'].receiptUrl &&
        <div style={{marginLeft: 12}}><a href={person.item['cart-personal'].receiptUrl}>Personal or Corporate Card Receipt</a></div>
      }
      {person.item['cart-gov'] && person.item['cart-gov'].receiptUrl &&
        <div style={{marginLeft: 12}}><a href={person.item['cart-gov'].receiptUrl}>Government Charge Card Receipt</a></div>
      }
      {person.item['cart-iid'] && person.item['cart-iid'].receiptUrl &&
        <div style={{marginLeft: 12}}><a href={person.item['cart-iid'].receiptUrl}>IID Attendance Receipt</a></div>
      }
    </div>
    </>
  )
}

function HotelSummary(props) {
  const {cart, showHotelModal, showHotelConfirmationModal, handleHotelCancellation, removeConfirmationCode} = props

  let offerDouble = false
  if (cart.primaryRegistrationType.roomDoubleCode &&
      cart.primaryRegistrationType.roomDoubleCode.length > 0) {
    offerDouble = true
  }

  const start = (cart.hotelStatus === 'not-required' || cart.hotelStatus === 'cancelled' || cart.hotelStatus === 'using-double-from-another-attendee') ? 'Start' : 'Restart'
  return (
    <>
    {(cart.hotelStatus === 'not-required' || cart.hotelStatus === 'cancelled') &&
      <div>
        <strong>Hotel:</strong> You told us that you do not need a hotel reservation.
      </div>
    }
    {(cart.hotelStatus === 'using-double-from-another-attendee') &&
      <div>
        <strong>Hotel:</strong> You told us that you are sharing a room with someone else.
      </div>
    }


    {(cart.hotelStatus === 'requested-not-confirmed' ||
      cart.hotelStatus === 'requested-double-not-confirmed') &&
      <>
      <div>
        <strong>Hotel:</strong> You have previous started a hotel reservation. If you finished, please provide us with your confirmation number. If you did not finish the registration, you can start again.
      </div>
      <div style={{marginLeft: 12}}>
        <a style={{cursor: 'pointer'}} onClick={showHotelConfirmationModal}>Confirm Your Reservation</a>
      </div>
      </>
    }

    {(cart.hotelStatus === 'requested-confirmed' ||
      cart.hotelStatus === 'requested-double-confirmed') &&
    <div>
      <strong>Hotel:</strong> Thank you for confirming your reservation at the Convention hotel (confirm code: {cart.hotelConfirmationCode}). <br />
      <div style={{marginLeft: 12}}>
        <a style={{cursor: 'pointer'}} onClick={removeConfirmationCode}>Remove Your Confirmation Code</a>
      </div>
      <div style={{marginLeft: 12}}>
        <a style={{cursor: 'pointer'}} onClick={handleHotelCancellation}>I Cancelled My Hotel Reservation</a>
      </div>
    </div>
    }

    {(cart.hotelStatus === 'requested-double-not-confirmed' || cart.hotelStatus === 'using-double-from-another-attendee' || cart.hotelStatus === 'requested-not-confirmed' || cart.hotelStatus === 'not-required' || cart.hotelStatus === 'cancelled' || !cart.hotelStatus) &&
    <div style={{marginLeft: 12}}>
      <a style={{cursor: 'pointer'}} onClick={() => showHotelModal(false)}>{start} a Single Room Reservation</a> <br />
      {offerDouble &&
        <a style={{cursor: 'pointer'}} onClick={() => showHotelModal(true)}>{start} a Double Room Reservation</a>
      }
      </div>
    }
    </>
  )
}

function BadgeInformation(props) {
  const {cart} = props

  return (
    <>
    <div>
      <strong>Badge Security:</strong> {cart.badgeHasSecurityClearance === 'Yes' ? <span>Yes</span> : <span>No</span>}
    </div>
    <div>
      <strong>Badge Always Show As Exhibitor:</strong> {cart.badgeShowAsExhibitorOnBadge === 'Yes' ? <span>Yes</span> : <span>No</span>}
    </div>
    <div className="usa-prose" style={{fontSize: 'smaller', marginLeft: 12}}>If No, the Registration Type determines whether a Exhibitor badge is produced.</div>
    <div>
      <strong>Badge Admin Line:</strong> {cart.badgeAdminContent}
    </div>
    <div>
      <strong>Badge Admin Line (Guest):</strong> {cart.badgeGuestAdminContent}
    </div>
    </>
  )
}

function CustomLink(props) {
  let to = props.isAdmin ? props.adminTo : props.to
  return (
    <Link
      className={props.className}
      to={to}
      style={props.style}
      >
      {props.label}
    </Link>
  )
}
