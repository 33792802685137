import React, { Component } from 'react';
import { Redirect, useLocation } from "react-router-dom";

class AtaGreetings extends Component {


  render() {
    const {authState} = this.props
    if (authState !== 'signedin') {return null}

    return (
      <RedirectAfterSignIn />

    )
  }
}

function RedirectAfterSignIn() {
  const location = useLocation()

  return (
    <Redirect to={location.state.from.pathname} />
  )
}

export default AtaGreetings
