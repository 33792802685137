import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Form from 'react-bootstrap/Form'
import FieldError from './FieldError'

class Fund extends Component {

  constructor(props) {
    super(props)

    // By default we provide the current value as the only option. We need to
    // wait for the picklist to appear from the componentDidMount below.
    this.state = {
      options: <option value={props.formik.values.donation_fund} selected="true">{props.formik.values.donation_fund_name}</option>,
      defaultValue: props.formik.values.donation_fund
    }

    this.handleChange = this.handleChange.bind(this)

  }

  handleChange(event, setFieldValue) {

    if (event.target.name === 'donation_fund') {
      const fundInfo = this.state.funds.filter(item => item.sk === event.target.value)

      setFieldValue('donation_fund', event.target.value);
      setFieldValue('donation_fund_name', fundInfo[0].name);
      setFieldValue('donation_fund_other', '');
    } else if (event.target.name === 'donation_fund_other') {
      setFieldValue('donation_fund_other', event.target.value);
      setFieldValue('donation_fund_name', '');
    }

  }

  componentDidMount() {
    API.get('general', '/reference/donation-fund')
    .then(response => {

      const items = response.items.sort(function(a, b) {
        if (a.sk === 'none' || a.sk === 'other') {return -1}
        if (b.sk === 'none' || b.sk === 'other') {return 1}
        if (a.sk < b.sk) {return -1}
        if (a.sk > b.sk) {return 1}
        return 0
      })
      const options = items.map((item) =>
        <option key={item.sk} value={item.sk} selected={item.sk === this.state.defaultValue}>{item.name}</option>
      )
      this.setState({
        funds: items,
        options: options
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  render() {

    const {formik} = this.props

    return (
      <>
      <div className="tablet:grid-col-4">
        <div className="usa-label">Fund</div>
        <Form.Control className="usa-select"
                      style={{backgroundColor: '#eeeeee'}}
                      as="select"
                      name="donation_fund"
                      id="donation_fund"
                      onChange={(event) => this.handleChange(event, formik.setFieldValue)}
                      onBlur={formik.handleBlur}
                      value={formik.values.fund} >
          {this.state.options}
          </Form.Control>
      </div>
      {formik.values.donation_fund === 'other' &&
        <div className="tablet:grid-col-4">
          <div className="usa-label">Your Fund Preference</div>
          <Form.Control className="usa-input"
                        id="donation_fund_other"
                        name="donation_fund_other"
                        plaintext maxlength="100"
                        style={{backgroundColor: '#eeeeee'}}
                        onChange={(event) => this.handleChange(event, formik.setFieldValue)}
                        onBlur={formik.handleBlur}
                        value={formik.values.donation_fund_other}
          />
          {formik.touched.donation_fund_other &&
           formik.errors.donation_fund_other ?
            <FieldError message={formik.errors.donation_fund_other} /> :
            null}
        </div>
      }
    </>
    )
  }
}

export default Fund
