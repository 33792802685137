import React, { Component } from 'react'
import { NavLink, Redirect, useLocation } from "react-router-dom"
import Loading from '../../Loading'
import ErrorContainer from '../../ErrorContainer'
import HallOfFameList from '../../controls/HallOfFameList'
import ChapterList from '../../controls/ChapterList'
import {navigation} from '../../controls/menus'
import {Section, ButtonStatic} from '../../sections/Section';
import Sidebar from './Sidebar'
import Modal from 'react-bootstrap/Modal'
import ExternalLink from '../../controls/ExternalLink'
import {PageLoad} from '../../controls/Events'
import {SponsorView, sponsorClick} from '../../controls/Events'

import {ContentfulContext} from '../../../classes/context'

const ReactMarkdown = require('react-markdown/with-html')

class Sponsor extends Component {

    constructor(props) {
      super(props);

      this.showContent = this.showContent.bind(this)
      this.modalVisibility = this.modalVisibility.bind(this)

      this.state = {
        error: null,
        isLoaded: false,
        page: null,
      };
    }

    componentDidMount() {
      if (!this.state.isLoaded) {
        const contentfulClient = this.context.contentfulClient

        contentfulClient.getEntries({'content_type': 'itemExhibitor', 'limit': 500, 'order': 'fields.headline'})
        .then(entries => {
          const sponsors = entries.items.filter(item => item.fields.participation.includes('sponsor'))

          let items = []
          items.push(sponsors.filter(item => item.fields.sponsorshipLevel.includes('Diamond')))
          items.push(sponsors.filter(item => item.fields.sponsorshipLevel.includes('Platinum')))
          items.push(sponsors.filter(item => item.fields.sponsorshipLevel.includes('Gold')))
          items.push(sponsors.filter(item => item.fields.sponsorshipLevel.includes('Silver')))
          items.push(sponsors.filter(item => item.fields.sponsorshipLevel.includes('Bronze')))

          this.setState({
            isLoaded: true,
            items: items,
          });
        })
        .catch(error => {
          console.log(error)
          this.setState({
            error: error
          })
        })
      }
    }

    modalVisibility(isVisible) {
      this.setState({modalIsVisible: isVisible})
    }

    showContent(type, item) {
      this.setState({
        modalType: type,
        modalItem: item,
        modalIsVisible: true,
      })
    }

    render() {

      const { error, isLoaded, pages } = this.state;

      if (error) {
        return (
          <ErrorContainer title='Error' error={error}
          />
        )
      } else if (!isLoaded) {
        return <Loading title=''
                        subtitle=''/>;
      }

        const regex = / /g
        let page
        if (this.props.pageId) {
          const pageIndex = pages.findIndex((page) => (page.sys.id === this.props.pageId));
          if (pageIndex === -1) {
            // The page was not found, which is an error.
            return <ErrorContainer title='Error' error={{message: "The requested page was not found " + this.props.pageId}} />
          }
          page = pages[pageIndex]
        }

        // Page name is actually the title, all lowercase with hyphens rather
        // than spaces
        if (this.props.pageName) {
          const pageIndex = pages.findIndex((page) => (this.props.pageName === (page.fields.headline.toLowerCase().replace(regex, '-'))))
          page = pages[pageIndex]
        }

        return (
          <div>
          <SearchRequest />
          <PageLoad />
          <Section>
            <div className="grid-container margin-y-0">
                <div className="grid-row grid-gap">
                <div className="grid-col-9">
                <h2 className="margin-y-0">Convention Sponsors</h2>
                <p className='usa-prose'>Thank you to all the 2020 sponsors</p>

                <SponsorList level="Diamond Sponsors" items={this.state.items[0]} size={300}/>
                <SponsorList level="Platinum Sponsors" items={this.state.items[1]} size={300}/>
                <SponsorList level="Gold Sponsors" items={this.state.items[2]} size={250}/>
                <SponsorList level="Silver Sponsors" items={this.state.items[3]} size={200}/>
                <SponsorList level="Bronze Sponsors" items={this.state.items[4]} size={150}/>

                <ModalDetail
                  handleClose={this.modalVisibility}
                  modalIsVisible={this.state.modalIsVisible}
                  item={this.state.modalItem}
                  modalType={this.state.modalType}
                  />

                </div>
                <div class="tablet:grid-col-3">
                  <div>
                    <Sidebar
                      showContent={this.showContent}
                    />
                  </div>
                </div>
              </div>
              </div>

          </Section>
          </div>
        )

    }
}

function SearchRequest() {
  const {search, pathname} = useLocation();

  if (!search) return null
  const keywords = search.split('=');

  // If there are other parameters on the URL, this means somebody (facebook)
  // added the parameter. Remove.
  if (keywords[0] !== '?search') return <Redirect to={pathname} />

  return <Redirect to={"/?search=" + keywords[1]} />
}

function SponsorList(props) {

  if (props.items.length === 0) {
    return null
  }
  return (
    <>
    <h4>{props.level}</h4>
    {props.items.map(item => {
      return (
        <>
        <SponsorView sponsorName={item.fields.headline} />
        <ExternalLink
          link={{to: item.fields.externalLinks[0].fields.url, label: item.fields.headline} }
          confirmationForm={item.fields.externalLinks.map(link => {return {to: link.fields.url, label: link.fields.label} })}
        >
        <span onClick={() => sponsorClick({sponsorName: item.fields.headline})}>
          { item.fields.image && item.fields.image.fields.file &&
            <img alt={item.fields.headline} style={{marginBottom: '20px', marginRight: '40px', verticalAlign: 'Top'}} src={item.fields.image.fields.file.url} width={props.size} />
          }
        </span>
        </ExternalLink>
        </>
      )
    })}
    <hr />
    </>
  )
}

function ModalDetail(props) {
  if (!props.modalIsVisible) return null

  let modalStyle = {
      background: '#fff',
      border: '#b40808',
      borderRadius: '5px',
      marginLeft: '-200px',
      textAlign:'center',
      position: 'fixed',
      left: '25%',
      top: '20%',
      zIndex: 11,
      width: '80%',
      minHeight: '250px',
      boxShadow:'0 5px 10px rgba(0,0,0,0.3)',
      transform: 'translate(0, 0)',
      transition: 'transform 0.3s ease-out',
      outline: 'none',
      pointEvents: 'auto',
      overflowY: 'initial' // !important'

    };

    const {item, modalType, handleClose} = props
  return (

    <Modal show={true} style={modalStyle} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className='modal-header-additional'>
        <h2 style={{float:'left', maxWidth: '80%', marginLeft: 10, textAlign: 'left'}}>{props.item.fields.headline}</h2>
      </Modal.Header>
      <Modal.Body style={{maxHeight: 500, overflowY: 'auto'}}>
        {modalType === 'news' &&
          <div class="usa-prose" style={{textAlign: 'left', margin: 20}}>
            { item.fields.image && item.fields.image.fields.file &&
              <img alt={item.fields.headline} style={{float: 'left', marginBottom: '20px', marginRight: '20px'}} src={item.fields.image.fields.file.url} width='200' />
            }
            <ReactMarkdown source={item.fields.body} escapeHtml={false}/>
              <hr />
          </div>

        }

        <ButtonStatic
          onClick={() => handleClose(false)}
          style={{marginRight: 20, marginBottom:20, float: 'right'}}
          >
          Close
        </ButtonStatic>

      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  )

}


function IndustryPartnerProduct(props) {
  const {section} = props

  return (
    <>
      <section id="test-section-id" className="usa-section margin-y-0" style={{paddingTop: 0, paddingBottom: 20}}>
      <div className="grid-container">
        <h3 className="font-heading-xl margin-y-0">{section.fields.title}</h3>
        <p className="usa-prose" >
        { section.fields.productImage && section.fields.productImage.fields.file &&
          <img alt={section.fields.title} style={{float: 'left', marginBottom: '20px', marginRight: '20px'}} src={section.fields.productImage.fields.file.url} width='200' />
        }
          <ReactMarkdown source={section.fields.body} escapeHtml={false} style={{minHeight: 350}}/>
        </p>
        <hr />
      </div>
    </section>
    </>
  )
}

Sponsor.contextType = ContentfulContext
export default Sponsor;
