import React, { Component } from 'react';
import { Link } from "react-router-dom"

import {ContentfulContext} from '../../classes/context'
import ExternalLink from '../controls/ExternalLink'
import {SponsorView, sponsorClick} from '../controls/Events'

const multiple = {
  'Diamond': 15,
  'Platinum': 10,
  'Gold': 7,
  'Silver': 5,
  'Bronze': 1
}

const css = {
  backgroundColor: 'green'
}

class ConventionSponsors extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
    };
  }

  componentDidMount() {
    const contentfulClient = this.context.contentfulClient

    contentfulClient.getEntries({'content_type': 'itemExhibitor', 'fields.participation[in]': 'sponsor', 'limit': 500})
    .then(entries => {

      // build a list based on the level of sponsorship
      let list=[]
      for (const item of entries.items) {
        for (let j = 0; j < multiple[item.fields.sponsorshipLevel]; j++) {
          list.push(item)
        }
      }

      this.setState({
        itemsShuffled: entries.items,
        items: shuffleArray(list),
        isLoaded: true,
        base: getRandomInt(list.length - 3)
      });
    })
    .catch(error => {
      console.log(error)
      this.setState({error: error, refreshing: false})
    })

  }

  render() {

    if (!this.state.isLoaded) {return null}
    const base = this.props.lastRefresh % (this.state.items.length - 3)
    let basePlus1 = base + 1
    while (this.state.items[base].sys.id === this.state.items[basePlus1].sys.id) {
      basePlus1 = (basePlus1 + 1) % this.state.items.length
    }
    let basePlus2 = (basePlus1 + 1) % this.state.items.length
    while (this.state.items[basePlus2].sys.id === this.state.items[basePlus1].sys.id ||
           this.state.items[basePlus2].sys.id === this.state.items[base].sys.id) {
      basePlus2 = (basePlus2 + 1) % this.state.items.length
    }

    const format = this.props.dark ? "usa-section usa-section--dark" : "usa-section"
    return (

    <section id="test-section-id" className={format} style={{paddingTop: 0, paddingBottom: 0}}>
      <div className="grid-container">
      <div style={{fontSize: 'small', fontWeight: 'bold', textAlign: 'center'}}></div>

        <div className="usa-graphic-list__row grid-row grid-gap ">
          {[base, basePlus1,  basePlus2].map((index) =>
            <div className="grid-col-4" key={index}>
              <SponsorView sponsorName={this.state.items[index].fields.headline} />
              <ExternalLink
                link={{to: this.state.items[index].fields.externalLinks[0].fields.url, label: this.state.items[index].fields.headline, displayLabel: false, toolTip: this.state.items[index].fields.externalLinks[0].fields.label} }
                confirmationForm={this.state.items[index].fields.externalLinks.map(link => {return {to: link.fields.url, label: link.fields.label} })}
              >
              <span onClick={() => sponsorClick({sponsorName: this.state.items[index].fields.headline})}>
              <div >
              {this.state.items[index].fields.image &&
                <div style={{fontSize: 'x-small', paddingBottom: 4, color:'grey'}}>
                  {this.state.items[index].fields.sponsorshipLevel} Sponsor
                  {this.state.items[index].fields.participation.includes('exhibitor') && <span> & Exhibitor</span>}
                <img
                  src={this.state.items[index].fields.image.fields.file.url}
                  style={{maxHeight:80, maxWidth:200, display: 'block', marginLeft: "auto", marginRight: "auto", marginRight: 10, paddingTop: 6}}
                  alt={this.state.items[index].fields.headline} />
              </div>
              }
              {!this.state.items[index].fields.image &&
                <img
                  src={'/images/' + this.props.type + '.jpg'}
                  style={{maxHeight:80, maxWidth: 200, display: 'block', marginLeft: "auto", marginRight: "auto"}, css}
                  alt={this.state.items[index].fields.headline} />
              }
              </div>
              </span>
              </ExternalLink>
            </div>
          )}


        </div>

      </div>
    </section>
    )
  }
}

ConventionSponsors.contextType = ContentfulContext
export default ConventionSponsors;

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }

  return array
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

// function shuffleArray(array, seed) {
//   console.log(seed)
//   for (let i = array.length - 1; i > 0; i--) {
//       const j = Math.floor(mulberry32(seed) / 1000000000 * (i + 1));
//       [array[i], array[j]] = [array[j], array[i]];
//   }
//
//   return array
// }
//
// function mulberry32(a) {
//     return function() {
//       var t = a += 0x6D2B79F5;
//       t = Math.imul(t ^ t >>> 15, t | 1);
//       t ^= t + Math.imul(t ^ t >>> 7, t | 61);
//       return ((t ^ t >>> 14) >>> 0) / 4294967296;
//     }
// }
