import React, { Component } from 'react';
import { CardElement } from '@stripe/react-stripe-js';

import ErrorMessage from '../../ErrorMessage';
import FieldError from '../../controls/FieldError'

class PaymentCard extends Component {

  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)

    this.state = {
      gettingToken: false,
      errorMessage: null,
    }
  }

  // componentWillReceiveProps(props) {
  //
  //   if (props.getToken && !this.state.gettingToken) {
  //     this.setState({
  //       errorMessage: null,
  //       gettingToken: true,
  //     }, () => {
  //       this.getToken()
  //     })
  //   }
  // }
  //
  // async getToken() {
  //   this.props.stripe.createToken({name: this.props.cardType})
  //   .then(token => {
  //     if (token.error) {
  //       this.setState({
  //         errorMessage: token.error.message,
  //         gettingToken: false
  //       })
  //
  //       this.props.clearTokenRequest(this.props.cardType)
  //     } else {
  //       this.setState({
  //         errorMessage: null,
  //         gettingToken: false
  //       }, () => {
  //         this.props.setToken(this.props.cardType, token)
  //       })
  //     }
  //   })
  //   .catch(error => {
  //     this.setState({
  //       errorMessage: 'Could not process your request',
  //       gettingToken: false
  //     })
  //
  //     this.props.clearTokenRequest(this.props.cardType)
  //   })
  // }

  handleChange(event) {
    if (event.complete) {
      this.props.cardAvailable(this.props.cardType, this.props.stripe, this.props.elements)
    }
  }

  render() {
    return (
      <>
        <Payment
          handleChange={this.handleChange}
          stripeError={this.state.stripeError}
          connectionError={this.state.connectionError}/>
        <ErrorMessage message={this.state.errorMessage} />
      </>
    )
  }
}
export default PaymentCard;

function Payment(props) {
  const {stripeError, connectionError} = props

  return (
  <div class="grid-row grid-gap">
    <div class="grid-col-12">
      <div className='usa-label'>Card Details</div>
        <CardElement
          style={{base: {fontSize: '18px'}}}
          className='usa-input'
          onChange={props.handleChange}/>
        {stripeError &&
          <FieldError message={stripeError.message} />
        }
        {connectionError &&
          <FieldError message="There was a connection error, your card was NOT charged. Please try again." /> }
      </div>
  </div>
)
}
