import React, { Component } from 'react';
import BadgeClass from '../../../classes/Badge'
import PrintBadge from '../../controls/PrintBadge'

class PreviewBatch extends Component {

  constructor(props) {
    super(props)

    this.state = {
      batch: new BadgeClass(),
      isLoaded: false
    }
  }

  componentDidMount() {
    this.state.batch.get(this.props.batch)
    .then(() => {
      this.setState({isLoaded: true})
    })
  }

  render() {

    let {isLoaded, batch} = this.state
    if (!isLoaded) {return null}

    let itemCount = batch.item.length

    const headerClass = itemCount === 1 ? "badge-page-header-single" : "badge-page-header"
    const dom = []
    let i = 0
    let badgeCount = 0
    do {
      let item = batch.item[i]
      if (badgeCount % 6 === 0) {
        dom.push(<div className={headerClass}></div>)
      }
      if (item.cart.includesGuest === true) {
        dom.push(<PrintBadge person={{item: item}} isGuest={true}/>)
        badgeCount += 1
      }
      dom.push(<PrintBadge person={{item: item}} />)

      if (badgeCount % 6 === 5) {
        dom.push(<div className="badge-page-break"></div>)
      }

      badgeCount += 1
      i += 1
    } while (i < itemCount)


    return (
      <div className="badge-preview">
        {dom}
      </div>
    )
  }
}

export default PreviewBatch
