import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Form from 'react-bootstrap/Form'

class Status extends Component {

  constructor(props) {
    super(props)

    // By default we provide the current value as the only option. We need to
    // wait for the picklist to appear form the componentDidMount below.
    this.state = {
      options: <option value={props.defaultValue} selected="true">{props.defaultDisplayValue}</option>,
      defaultValue: props.defaultValue
    }

    this.onChange = this.onChange.bind(this)

  }

  componentDidMount() {
    API.get('general', '/reference/' + this.props.service)
    .then(response => {

      const items = response.items.sort(function(a, b) {
        if (a.sk === 'none') {return -1}
        if (b.sk === 'none') {return 1}
        if (a.sk < b.sk) {return -1}
        if (a.sk > b.sk) {return 1}
        return 0
      })
      const options = items.map((item) =>
        <option key={item.sk} value={item.sk} selected={item.sk === this.state.defaultValue}>{item.name}</option>
      )
      this.setState({
        status: items,
        options: options
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  componentDidUpdate(prevProps) {
    // If the default value has changed (which usually means that the service
    // has changed) update the state. Make sure that the state update has been
    // completed before redoing the picklist.

    if (prevProps.defaultValue !== this.props.defaultValue) {
      const options = this.state.status.map((item) =>
        <option key={item.sk} value={item.sk} selected={item.sk === this.props.defaultValue}>{item.name}</option>
      )
      this.setState({options: options})
    }
  }

  onChange(event) {
    this.props.onChange(event)

    const statusInfo = this.state.status.filter(item => item.sk === event.target.value)

    if (this.props.name === 'military.status') {
      // After changing the service_name, set the rank and secondary fields to blank
      this.props.onChange({target: {name: 'military.status_name',  value: statusInfo[0].name, type: 'select-one'}})
    }

    if (this.props.name === 'civilian.status') {
      // After changing the service_name, set the rank and secondary fields to blank
      this.props.onChange({target: {name: 'civilian.status_name',  value: statusInfo[0].name, type: 'select-one'}})
    }

    this.props.stateChange()
  }

  render() {
    return (
      <Form.Control className="usa-select" style={{backgroundColor: '#eeeeee'}} as="select" name={this.props.name}  onChange={this.onChange} >
        {this.state.options}
      </Form.Control>
    )
  }
}

export default Status
