import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Form from 'react-bootstrap/Form'

class Rank extends Component {

  constructor(props) {
    super(props)

    this.state = {
      options: <option value={props.defaultValue} selected='true'>{props.defaultDisplayValue}</option>,
      defaultValue: props.defaultValue
    }

    this.onChange = this.onChange.bind(this)
  }

  ranksForService(service, ranks) {
    const serviceItems = ranks.filter(item => item.service === this.props.service)
    const items = serviceItems.sort((a,b) => {
      if (a.sort_index < b.sort_index) return -1
      if (a.sort_index > b.sort_index) return 1
      return 0
    })
    return items.map((item) =>
          <option value={item.sk} selected={item.sk === this.state.defaultValue}>{item.rank}</option>
        )
  }

  componentDidUpdate(prevProps) {
    // If the default value has changed (which usually means that the service
    // has changed) update the state. Make sure that the state update has been
    // completed before redoing the picklist.

    if (this.state.defaultValue !== this.props.defaultValue) {
      this.setState({
        defaultValue: this.props.defaultValue
      })
    }
    if (prevProps.service !== this.props.service) {
      this.setState({
        options: this.ranksForService(this.props.service, this.state.ranks)
      })
    }

  }

  componentDidMount() {
    API.get('general', '/reference/' + this.props.list)
    .then(response => {
      this.setState({
        ranks: response.items,
        options: this.ranksForService(this.props.service, response.items)
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  onChange(event) {
    // This is the rank field
    this.props.onChange(event)

    // We also store the rank itself (denormalized from the picklist). This needs
    // to be updated as well to match the new abbreviation.
    const rankInfo = this.state.ranks.filter(item => item.sk === event.target.value)

    if (this.props.name === 'military.rank') {
      // After changing the service_name, set the rank and secondary fields to blank
      this.props.onChange({target: {name: 'military.rank_name',  value: rankInfo[0].rank, type: 'select-one'}})
      this.props.onChange({target: {name: 'military.rank_abbreviation',  value: rankInfo[0].rank_abbreviation, type: 'select-one'}})
    }

    if (this.props.name === 'civilian.rank') {
      // After changing the service_name, set the rank and secondary fields to blank
      this.props.onChange({target: {name: 'civilian.rank_name',  value: rankInfo[0].rank, type: 'select-one'}})
      this.props.onChange({target: {name: 'civilian.rank_abbreviation',  value: rankInfo[0].rank_abbreviation, type: 'select-one'}})
    }

    this.props.stateChange()
  }

  render() {
    return (
      <>
      <Form.Control className='usa-select' style={{backgroundColor: '#eeeeee'}} as="select" name={this.props.name}  onChange={this.onChange} >
        {this.state.options}
      </Form.Control>
      {this.props.name === 'military.rank' &&
        <div className="usa-prose" style={{fontSize: 'smaller'}}>If you don't see your rank, contact us directly and we will ensure your rank is available for selection.</div>
      }
      </>
    )
  }
}

export default Rank
