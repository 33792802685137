import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { useLocation, Redirect } from "react-router-dom";
import Button from 'react-bootstrap/Button'

import {CardElement} from '@stripe/react-stripe-js';

import FieldError from '../../controls/FieldError'

import {PersonContext} from '../../../classes/context.js';
import ButtonActive from '../../ButtonActive'


class AddIid extends Component {

  constructor(props) {
    super(props)

    this.state = {
      iidRegistrationType: false,
      allowSubmit: false,
      requestingPurchaseIntent: false,
      activeSpinner: false,
      redirect: false,

      initialized: true,
      stripeError: null,
      connectionError: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.completePurchase = this.completePurchase.bind(this)
  }

  componentDidMount() {
    API.get('general', '/reference/registration-type')
    .then(response => {

      const iidRegistrationType = response.items.filter(item => item.includesIndustryDay && item.type === 'secondary')[0]
      const secondaryTypesAll= response.items.filter(item => item.type === 'secondary')

      this.setState({iidRegistrationType: iidRegistrationType, secondaryTypesAll: secondaryTypesAll});
    })
    .catch(error => {
      console.log(error)
      this.setState({error: error})
    });
  }

  addIid() {
    const {person} = this.context
    person
      .addSecondary({secondaryItemToAdd: this.state.iidRegistrationType.sk, secondaryTypesAll: this.state.secondaryTypesAll})
      .store()
      .then(() => {
        person.get(person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1))
        .then(() => {
          this.setState({redirect: true})
        })
        .catch(error => {
          console.log(error)
        })
      })
      .catch(this.handleRejected)
  }

  completePurchase() {

    const {stripe, elements} = this.props
    const {person} = this.context

    this.setState({requestingPurchaseIntent: true, activeSpinner: true})
    API.post('stripeProcessing', '/intent', {body: {pk: person.item.root.pk,
              receiptEmail: person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1),
              'iid': this.state.iidRegistrationType}})
    .then(response => {

      const {clientSecret} = response

      stripe.confirmCardPayment(clientSecret, {
        receipt_email: person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1),
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            email: person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1)
            }
        }
      })
      .then(result => {
        if (result.error) {
          this.setState({stripeError: result.error, allowSubmit: true, confirmation: true})
          return false
        }
        this.addIid()
      })
      .catch(error => {
        // may not be a stripe error
        this.setState({allowSubmit: true, stripeError: error.raw})
      });
    })
    .catch(error => {
      if (error.type === 'StripeConnectionError') {
        this.setState({allowSubmit: true, stripeError: error, connectionError: true})
      } else {
        this.setState({allowSubmit: true, stripeError: error})
      }
    })
  }

  handleChange(event) {
    this.setState({allowSubmit: event.complete})
  }

  render() {

    const {person} = this.context

    if (this.state.redirect) {
      if (this.props.isAdmin) {
        return (
          <Redirect to={"/admin/account/" + person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1)}/>
        )
      }
      return (
        <Redirect to="/account"/>
      )
    }

    const {activeSpinner, allowSubmit, iidRegistrationType} = this.state
    return (
      <>
        <SearchRequest />
        <main id="main-content" className="main-content">
          <section id="introduction-section-id" className="usa-section">
            <div className="grid-container">
              <h2  name="donation-entry" className="font-heading-xl margin-y-0">Attend the Industry Interface Day</h2>
              <p className="usa-prose">
                Attend the one day event prior to the main convention for Corporate attendees and invited Senior Military Leaders!
              </p>
              <p className="usa-prose">
                You may add the Industry Interface Day to your main registration.
              </p>

                  <div style={{minHeight: 202}}>
                  <div id="body-block" className="usa-prose margin-y-0">
                    <div style={{fontSize:'40px'}}><strong>${iidRegistrationType.fullPrice}</strong></div>
                  </div>
                  <div className="govCard">
                    <Payment
                      changeHandler={this.handleChange}
                    />
                  </div>
              <ButtonActive
                onClick={() => this.completePurchase()}
                label="Complete Purchase"
                aria-label="empty-your-cart"
                className="usa-button"
                style={{textDecoration: 'none', marginTop: 20}}
                active={activeSpinner}
                disabled={!allowSubmit || activeSpinner}
              >
                Complete Purchase
              </ButtonActive>

              <Button
                onClick={() => {this.setState({redirect: true})}}
                style={{textDecoration: 'none', marginTop: 20}}
                className="usa-button--unstyled"
              >
                Cancel
                </Button>
            </div>


                  </div>
          </section>

        </main>
      </>
    )
  }
}

AddIid.contextType = PersonContext
export default AddIid

function SearchRequest() {
  const {search, pathname} = useLocation();

  if (!search) {return null}
  const keywords = search.split('=');

  // If there are other parameters on the URL, this means somebody (facebook)
  // added the parameter. Remove.
  if (keywords[0] !== '?search') {return <Redirect to={pathname} />}

  return <Redirect to={"/?search=" + keywords[1]} />
}



function Payment(props) {
  const {stripeError, connectionError, onBlur, changeHandler} = props

  return (

  <div className="grid-row grid-gap">
    <div className="tablet:grid-col-6">
      <div className="usa-label">Card Details</div>
        <CardElement style={{base: {fontSize: '18px'}}}
            className="usa-input"
            id="card_info"
            name="card_info"
            onBlur={onBlur}
            onChange={changeHandler}/>
        <div id="card-errors" role="alert"></div>
        {stripeError &&
        <FieldError name="stripe_error" message={stripeError.message} />
        }
        {connectionError &&
        <FieldError  name="stripe_connection_error" message="There was a connection error, your card was NOT charged. Please try again." /> }
      </div>
  </div>
)
}
