
import React, { Component } from 'react';
import {ChapterContext} from '../../../classes/context.js'

import Form from 'react-bootstrap/Form'

var moment = require('moment');

const editingStyle = {
  backgroundColor: '#eeeeee',
};

const roles = [
  'Chairperson',
  'Chapter 2nd Vice President',
  'Chapter Active Duty Liaison',
  'Chapter Additional Officer 1',
  'Chapter Civilian Liaison',
  'Chapter Heritage',
  'Chapter Historian',
  'Chapter Industry Relations',
  'Chapter Industry Rep',
  'Chapter Membership',
  'Chapter President',
  'Chapter Secretary',
  'Chapter Treasurer',
  'Chapter Vice President',
  'Logistics Coordinator',
  'Past Chapter Officer',
  'Senior Advisor',
]

class EditorPoc extends Component {

  keyValue(item, key, format) {
    const {chapter} = this.context

    if (!chapter.editItem) {return null}

    const i = chapter.editItem.roles.findIndex(item => item.sk === this.props.editMode.sk)
    if (i === -1) {return null}
    if (format) {
      return (typeof(chapter.editItem.roles[i][key]) === 'undefined' ||
              !chapter.editItem.roles[i][key] ?
              null :
              moment(chapter.editItem.roles[i][key]).format(format))
    }

    return (typeof(chapter.editItem.roles[i][key]) === 'undefined' ? null : chapter.editItem.roles[i][key])

  }

  render() {

    const {chapter} = this.context
    if (this.props.editMode.mode !== 'editPoc') {return null}
    if (!chapter.editItem) {return null}

    const handleChange = chapter.handleChange.bind(chapter)

    const sk = this.props.editMode.sk

    return (
      <Form>
        <h2 className="margin-y-5" style={{marginBottom: 0}}>Point of Contact</h2>
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-12">
            <div className="usa-label">Full Name</div>
            <Form.Control className="usa-input" name={sk + ".full_name"} plaintext defaultValue={this.keyValue(sk, 'full_name')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-5">
            <div className="usa-label">POC Primary Email</div>
            <Form.Control className="usa-input" name={sk + ".primary_email"} plaintext defaultValue={this.keyValue(sk, 'primary_email')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-5">
            <div className="usa-label">POC Alternate Email</div>
            <Form.Control className="usa-input" name={sk + ".alternate_email"} plaintext defaultValue={this.keyValue(sk, 'alternate_email')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>

        <div className="tablet:grid-col-5">
          <div className="usa-label">POC Phone</div>
          <Form.Control className="usa-input" name={sk + ".phone_number"} plaintext defaultValue={this.keyValue(sk, 'phone_number')}  style={editingStyle} onChange={handleChange}/>
        </div>

        <div className="tablet:grid-col-5">
          <div className="usa-label">Role</div>
          <Form.Control className="usa-select" defaultValue={this.keyValue(sk, 'role_name')}  style={{backgroundColor: '#eeeeee'}} as="select" name={sk + ".role_name"}  onChange={handleChange} >
            {roles.map(role => {
              return <option key={role} value={role}>{role}</option>
            })}
          </Form.Control>
        </div>
      </Form>
      )
    }
}

EditorPoc.contextType = ChapterContext
export default EditorPoc;
