import React, { Component } from 'react'

import Modal from 'react-bootstrap/Modal'
import  {ButtonStatic} from '../sections/Section';
import {externalLinkClick} from '../controls/Events'


class ExternalLink extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      item: null,
      modalIsVisible: this.props.isVisible ? this.props.isVisible : false,
      modalItem: this.props.confirmationForm,
    };

    this.modalVisibility = this.modalVisibility.bind(this)
    this.showConfirmation = this.showConfirmation.bind(this)

  }


  modalVisibility(isVisible) {
    this.setState({modalIsVisible: isVisible})
  }

  showConfirmation() {
    this.setState({
      modalIsVisible: true,
    })
  }

  render() {

    let {indicator, label, displayLabel, to, link, toolTip} = this.props

    if (link) {
      to = link.to
      label = link.label
      toolTip = link.toolTip
    }

    return (
      <>
      {to &&
        <button
          className="usa-button usa-button--unstyled usa-tooltip"
          onClick={() => this.showConfirmation()}
          style={{textDecoration: 'none', cursor: 'pointer'}}
          outline="none"
          data-position="top"
          data-classes="width-full tablet:width-auto" title={toolTip}
        >
          {displayLabel && <span>{label}</span>}{indicator && <><span> </span><i className="fas fa-external-link"></i></>}
          {this.props.children}
        </button>
      }
      {!to &&
        <div className="usa-prose" style={{display: 'inline-block'}}>{label}</div>
      }
      <ModalLink
        handleClose={() => this.modalVisibility(false)}
        modalIsVisible={this.state.modalIsVisible}
        item={this.state.modalItem}
        label={label}
        />

      </>
    )
  }
}
export default ExternalLink;


function ModalLink(props) {
  if (!props.modalIsVisible) {return null}

  let modalStyle = {
      background: '#fff',
      border: '#b40808',
      borderRadius: '5px',
      marginLeft: '-200px',
      textAlign:'center',
      position: 'fixed',
      left: '45%',
      top: '20%',
      zIndex: 11,
      width: '40%',
      minHeight: '250px',
      boxShadow:'0 5px 10px rgba(0,0,0,0.3)',
      transform: 'translate(0, 0)',
      transition: 'transform 0.3s ease-out',
      outline: 'none',
      pointEvents: 'auto',
      overflowY: 'initial' // !important'

    };

  const {item, handleClose} = props

  return (
    <Modal show={true} style={modalStyle} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header-additional">
        <h2 style={{float:'left', maxWidth: '80%', marginLeft: 10, textAlign: 'left'}}>External Link</h2>
      </Modal.Header>
      <Modal.Body style={{maxHeight: 500, overflowY: 'auto'}}>

        <div className="usa-prose" style={{textAlign: 'left', margin: 20}}>
          You are leaving our site for <strong>{props.label.trim()}</strong>. Choose your destination
          {item && item.map((entry, index) =>
            <li key={index} style={{marginLeft: 20}}>
            <span onClick={() => externalLinkClick({url: entry.to})} cursor="pointer">
              <a
                href={entry.to}
                target="_default"
                onClick={props.handleClose}
              >
              {entry.label}
              </a>
              </span>
            </li>
          )}
        </div>


        <ButtonStatic
          onClick={() => handleClose(false)}
          style={{marginRight: 20, marginBottom:20, float: 'right'}}
          >
          Close
        </ButtonStatic>

      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  )

}
