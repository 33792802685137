
import React, { Component } from 'react'

import Form from 'react-bootstrap/Form'

import ButtonActive from '../../ButtonActive';

class MemberPrice extends Component {

  render() {

    if (this.props.onePrice) return null;

    var price
    // if (this.props.registrationType.type === 'secondary') {
    //   price = this.props.price
    // } else {
      price = this.props.price + this.props.additionalAnnualMembership
      if (this.props.isLifeMember ||
          (this.props.isValidConventionMembership && !this.props.autoRenewMembership)) {
        price = this.props.price // don't need to include a membership today.
      }
    // }

    // flag whether we need to include a membership to allow the person
    // to get the membership rate.
    let includeMembership = (!this.props.isValidConventionMembership ||
                              this.props.autoRenewMembership ) &&
                              !this.props.isLifeMember


    return (
      <div class="grid-col-6">
        <div style={{minHeight: '90px', paddingTop: '10px'}}  className='usa-prose'>
          <span className='price' style={{fontWeight: 'bold'}}> ${ price } </span>
          <smaller>A/TA Members</smaller>
          <MembershipSubscription registrationType={this.props.registrationType}
                                  isLifeMember={this.props.isLifeMember}
                                  isCorporateMembership={this.props.isCorporateMembership}
                                  isValidConventionMembership={this.props.isValidConventionMembership}
                                  autoRenewMembership={this.props.autoRenewMembership}
                                  includeMembership = {this.props.includeMembership}
                                  handleInputChange={this.props.handleInputChange}
                                  includeNewMembership = {true}
                                  />
        </div>
        <ButtonActive label="Add to Cart"
                      className="usa-button btn btn-primary"
                      name='memberAdd'
                      onClick={() => this.props.addItem(includeMembership)}
                      active={this.props.waiting}
                      disabled={!this.props.enableButtons || this.props.waiting} />

        <MembershipChargeNotes
          registrationType={this.props.registrationType}
          isLifeMember={this.props.isLifeMember}
          isValidConventionMembership={this.props.isValidConventionMembership}
          includeNewMembership = {true}
          autoRenewMembership={this.props.autoRenewMembership}
          additionalAnnualMembership={this.props.additionalAnnualMembership}
        />
      </div>
    )

  }

}

class OnePrice extends Component {

  render() {

    if (!this.props.onePrice) return null;

    return (

      <div class="grid-col-6 grid-offset-6">
        <div style={{minHeight: '90px', paddingTop: '10px'}} className='usa-prose'>
          <span className='price' style={{fontWeight: 'bold'}}> ${ this.props.price } </span>
          <smaller>one price for all attendees</smaller>
        </div>

        <ButtonActive label="Add to Cart"
                      className="usa-button btn btn-primary"
                      name='onePriceAdd'
                      onClick={() => this.props.addItem(false)}
                      active={this.props.waiting}
                      disabled={!this.props.enableButtons || this.props.waiting} />
      </div>
    )
  }

}

class NonMemberPrice extends Component {

  render() {

    if (this.props.onePrice) return null;

    if (this.props.isValidConventionMembership || this.props.includeNewMembership) {
      return (
        <div class="grid-col-6">
        </div>
      );
    }

    return (
      <div class="grid-col-6 ">
        <div
          style={{minHeight: '90px', paddingTop: '10px' }}
          className='usa-prose' >
          <span
            className='price'
            style={{fontWeight: 'bold'}}> ${ this.props.price }
          </span>
          <smaller> Non-Members</smaller>
        </div>
        <ButtonActive
          label="Add to Cart"
          className="usa-button btn btn-primary"
          name='nonMemberAdd'
          onClick={() => this.props.addItem(false)}
          active={this.props.waiting}
          disabled={!this.props.enableButtons || this.props.waiting}
        />
      </div>
    )
  }

}

class MembershipChargeNotes extends Component {

  render() {

    if (this.props.isLifeMember) return (
      <div style={{marginTop: 6, marginLeft: 10, marginRight: 10}}>
        <small>The price includes a discount because you are a Life Member</small>
      </div>
    )

    // If there is already a membership that is valid for the convention, then
    // we are doing a renewal
    if (this.props.isValidConventionMembership && this.props.autoRenewMembership) return (
      <div style={{marginTop: 10, marginLeft: 10}}>
        <small>Your price includes
        a ${this.props.additionalAnnualMembership} annual A/TA membership which will extend
        your current term. This is a personal expense and you must pay for it
        with a non-government charge card.</small>
      </div>
    )

    if (this.props.isValidConventionMembership && !this.props.autoRenewMembership) return (
      <div style={{marginTop: 10, marginLeft: 10}}>
        <small>Your membership is valid for the convention.</small>
      </div>
    )

    // There is already a request to renew, so just tell them about it.
    if (this.props.autoRenewMembership) return (
      <div style={{marginTop: 10, marginLeft: 10}}>
        <small>The price includes your convention registration fee and a
        ${this.props.additionalAnnualMembership} annual membership fee. The membership
        is a personal expense that cannot be paid for with a government charge card.
        </small>
      </div>
    )

    // This will be a new membership rather than a renewal (even if the person)
    // is in the grace period for their current membership

    return (
      <div style={{marginTop: 10, marginLeft: 10}}>
        <small>Includes a one-time ${this.props.additionalAnnualMembership} A/TA membership fee to allow you
        to attend this year's convention as a member. The membership
        is a personal expense that cannot be paid for with a government card.</small>
      </div>
    )

  }
}

class MembershipSubscription extends Component {

  render() {

    if (this.props.isLifeMember) return null
    if (!this.props.isValidConventionMembership) return null
    if (this.props.isCorporateMembership) return null
    if (this.props.includeMembership) return null

    const message=' Renew today to extend your membership another year'
    //
    // if (this.props.isValidConventionMembership) {
    //   message='Renew when your current membership ends'
    // }

    return (
      <Form.Group controlId="formBasicCheckbox">
        <small>
          <Form.Check name='autoRenewMembership' type="checkbox" label={message} checked={this.props.autoRenewMembership} onChange={this.props.handleInputChange}/>
        </small>
      </Form.Group>
    )
  }
}

export { MembershipSubscription,
         OnePrice, NonMemberPrice, MemberPrice,
         MembershipChargeNotes}
