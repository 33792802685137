import React, { Component } from 'react';
import { Link } from "react-router-dom";

var moment = require('moment');

const footerStyle = {
  borderTopWith: 1,
  borderTopStyle: 'solid',
  borderTopcolor: 'grey',
  paddingTop: 8,
}

class Card extends Component {


  render() {
    const {style, title, text, published, image} = this.props

    const footer = moment(published).from()
    const regex = / |\//g

    return (

      <div className="usa-media-block tablet:grid-col">
        <div style={style}>
          <div style={{ verticalAlign: "middle" }}>
            <img src={image} alt={title} style={{ verticalAlign: "middle" }} />
          </div>
          <h3>
            <Link
              to={"/news/" + title.toLowerCase().replace(regex, "-")}
              title={title}
              aria-label="news-item"
              className="usa-button--big usa-button--unstyled"
              style={{
                textDecoration: "none",
                fontWeight: "bold",
                fontSize: "larger"
              }}
            >
              {title}
            </Link>
          </h3>
          <div className="usa-prose" style={{ minHeight: 100 }}>
            {text}
          </div>
          <div style={footerStyle}>{footer}</div>
        </div>
      </div>
    )
  }
}

export default Card
