import React, { Component } from 'react';
import { API } from 'aws-amplify';

import Loading from '../Loading';
import ErrorContainer from '../ErrorContainer';

class ChapterList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      page: null,
    };
  }

  componentDidMount() {
    API.get('general', '/chapter-list')
    .then(response => {
      this.setState({
        isLoaded: true,
        chapters: response
      });
    })
    .catch(error => {
      console.log(error)
      this.setState({
        error: error
      })
    })
  }

  render() {
    const { error, isLoaded, chapters } = this.state;

    if (error) {
      return <ErrorContainer title="Error" error={error} />
    } else if (!isLoaded) {
      return <Loading title=""
                      subtitle=""/>;
    }

    const chaptersSorted = chapters.items.sort((a, b) => {
      if (a.name > b.name) {return 1}
      if (a.name < b.name) {return -1}
      return 0
    })

    return (
      <section className="usa-section" style={{paddingTop: 10}}>
        <div className="grid-container margin-y-0">
        <div className="usa-prose">The complete list of A/TA Chapters is below along with the current president.</div>
          <ul>
          {chaptersSorted.map(chapter =>
            <li key={chapter.pk}>
            <h3 style={{display: 'inline-block'}}className="margin-y-1">{chapter.name}</h3>
            <div className="usa-prose">

            {chapter.base} {chapter.url &&
                <a href={chapter.url}><i className="fas fa-external-link"></i> </a>
              } {chapter.contact_email &&
                <a href={"mailto:" + chapter.contact_email}><i className="fal fa-envelope"></i> </a> }
            </div>
            </li>
          )}
          </ul>
        </div>
      </section>
      )
  }
}

export default ChapterList;
