import { Link, Redirect } from "react-router-dom";
import React, { Component } from 'react';

import { API } from 'aws-amplify';
import Button from 'react-bootstrap/Button'
import ButtonActive from '../ButtonActive'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import EmptyCart from '../controls/EmptyCart';
import ErrorContainer from '../ErrorContainer';
import Form from 'react-bootstrap/Form'
import Loading from '../Loading'
import Modal from 'react-bootstrap/Modal'
import {PersonContext} from '../../classes/context.js';

import {TXT_REGISTRATION_PREAMBLE} from '../../constants'

const editingStyle = {
  backgroundColor: '#eeeeee',
};

const modalStyle = {
    background: '#fff',
    border: '#b40808',
    borderRadius: '5px',
    marginLeft: '-200px',
    textAlign:'center',
    position: 'fixed',
    left: '45%',
    top: '20%',
    zIndex: 11,
    width: '500px',
    minHeight: '250px',
    boxShadow:'0 5px 10px rgba(0,0,0,0.3)',
    transform: 'translate(0, 0)',
    transition: 'transform 0.3s ease-out',
    outline: 'none',
    pointEvents: 'auto',

  };

class Cart extends Component {

  constructor(props) {
    super(props);

    this.emptyCart = this.emptyCart.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShowForm = this.handleShowForm.bind(this);
    this.handleApplyCoupon = this.handleApplyCoupon.bind(this);
    this.handleChangeCoupon = this.handleChangeCoupon.bind(this);

    this.state = {
      coupon: '',
      showForm: false,
      tryingCoupon: false,
      buttonState: '',
      errorMessage: '',
    }
  }

  handleClose() {
    this.setState({
      showForm: false,
      tryingCoupon: false,
      buttonState: ''
    })
  }

  handleShowForm() {
    this.setState({
      showForm: true,
    })
  }

  emptyCart() {
    this.context.person
      .beginEdit()
      .emptyCart()
      .endEdit(true)
      .store()
      .then(() => {
        this.setState({isLoaded: true})
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 409') {
          alert('Someone has updated this item since you loaded it. Reload to see the latest changes.')
        } else {
          alert(error.message)
        }
      })
  }

  removeItem(sk) {
    this.context.person
      .beginEdit()
      .removeItem(sk)
      .endEdit(true)
      .store()
      .then(() => {
        this.setState({isLoaded: true})
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 409') {
          alert('Someone has updated this item since you loaded it. Reload to see the latest changes.')
        } else {
          alert(error.message)
        }
      })
  }

  lineItem() {
    this.line += 1
    return (<span>{this.line}</span>)
  }

  priceIsZero(price, primaryRegistrationPrice, priceMatchesRegistration) {
    if ( !isNaN(price)) {
      if (priceMatchesRegistration) {
        return (primaryRegistrationPrice === 0)
      } else {
        return (price === 0)
      }
    }

    return false
  }

  price(price, primaryRegistrationPrice, priceMatchesRegistration) {
    if ( !isNaN(price)) {
      if (priceMatchesRegistration) {
        return (<span>${primaryRegistrationPrice}</span>)
      } else {
        return (<span>${price}</span>)
      }
    }

    return (<span>{price}</span>)
  }

  handleApplyCoupon() {

    this.setState({
      tryingCoupon: true,
      buttonState: 'disabled',
      errorMessage: ''
    })

    let request = {
        headers: {},
        body: {coupon: this.state.coupon}
    }

    API.put('coupon', '/coupon', request).then(response => {
      if (response[0].available) {
        this.context.person
          .beginEdit()
          .applyCoupon(response[0])
          .endEdit(true)
          .store()
          .then(() => {
            this.setState({editMode: 'none'})
            alert('The coupon was applied')
          })
          .catch(error => {
            if (error.message === 'Request failed with status code 409') {
              alert('Someone has updated this item since you loaded it. Reload to see the latest changes.')
            } else {
              alert(error.message)
            }
          })
//        this.props.cartAddItem(couponRegistrationTypeId, this.state.coupon, null) // magic numbers that means coupon

        this.setState({
          tryingCoupon: false,
          buttonState: '',
          errorMessage: ''
        })

        this.handleClose()
      } else {
        this.setState({
          tryingCoupon: false,
          buttonState: '',
          errorMessage: 'That coupon is not available'
        })
      }
    }).catch(error => {
        console.log(error)
        this.setState({
          tryingCoupon: false,
          buttonState: '',
          errorMessage: 'We cannot confirm your coupon.'
        })
    });
  }

  handleChangeCoupon(event) {
    this.setState({
      coupon: event.target.value
    })
  }

  render() {
    this.line = 0

    const {loadFailed, error} = this.state;

    let preamble, title
    if (this.props.receipt) {
      title = 'Convention Registration - Itemized Receipt'
      preamble = 'This is an itemization of your purchase.'
    } else {
      title = 'Convention Registration - Shopping Cart'
      preamble = TXT_REGISTRATION_PREAMBLE
    }

    let person = this.context.person

    if (error) {
      return <ErrorContainer title={title} error={error} />
    } else if (loadFailed) {
      return <ErrorContainer title={title} error={{message:'There is a problem.'}} />
    } else if (person.isLoading || !person.isInitialized) {
      return <Loading title={title}
                      subtitle={preamble}/>;
    }

    if (['complete', 'payment-submitted', 'cancellation-requested'].includes(person.item.cart.status) &&
        !this.props.receipt) {
      return <Redirect to="/account" />
    }

    if (!person.item.cart || !person.item.cart.primaryRegistrationType) {
      return (
        <EmptyCart
          title={title}
          subtitle={preamble}
          to="/catalog"
          adminTo={"/admin/catalog/" + person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1)}
          isAdmin={this.props.isAdmin}
        />
      )
    }

    // =======================================================================
    // This is the core of cart class

    let primaryRegistrationPrice = person.item.cart.primaryRegistrationType.fullPrice
    if (person.item.cart.isValidConventionMembership ||
        person.item.cart.includeMembership ) {
      primaryRegistrationPrice = person.item.cart.primaryRegistrationType.memberPrice
    }


    const registrationIncludesSecondaryIid =
          person.item.cart.secondaryItems ?
          person.item.cart.secondaryItems.filter(item => item.includesIndustryDay).length > 0 :
          false

      return (
        <>
        <section
          className="usa-section"
          style={{ paddingTop: 10, paddingBottom: 10, borderTopWidth: 0 }}
         >
          <div className="grid-container margin-y-0">
            <h2 className="margin-y-0">{title}</h2>
            <p className="usa-prose">{preamble}</p>
            {!this.props.receipt &&
              <div>
                <CustomLink
                  className="usa-button"
                  to="/checkout"
                  adminTo={"/admin/checkout/" +person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1)}
                  isAdmin={this.props.isAdmin}
                  label="Continue Checkout"
                />
                <CustomLink
                  className="usa-button"
                  to="/catalog"
                  adminTo={"/admin/catalog/" + person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1)}
                  isAdmin={this.props.isAdmin}
                  label="Add More Items"
                />

                <Button
                  onClick={() => this.emptyCart()}
                  title="Empty Your Cart"
                  aria-label="empty-your-cart"
                  className="usa-button usa-button--unstyled"
                  style={{textDecoration: 'none'}}
                >
                  Empty Your Cart
                </Button>
              </div>
            }

            <table className="usa-table usa-table--borderless" style={{width:'100%', marginBottom: 32}}>
              <thead>
                <tr>
                  <th style={{textAlign: 'center'}}>#</th>
                  <th>Item</th>
                  <th lg={2}><div style={{textAlign: 'right', paddingRight: '20px'}}>Price</div></th>
                </tr>


                <tr key={person.item.cart.primaryRegistrationType.sk}>
                  <td lg={1} style={{verticalAlign: 'top'}}>{this.lineItem()}</td>
                  <td>
                    <div>
                      <p className="usa-prose margin-y-0">{person.item.cart.primaryRegistrationType.name}

                      {(person.item.cart.isValidConventionMembership ||
                          person.item.cart.includeMembership) ?
                        <> (Member)</> :
                        <> (Non Member)</>
                      }
                    </p>
                      {person.item.cart.primaryRegistrationType.isExhibitor &&
                        <div style={{fontSize: 'smaller'}}>{person.item.cart.exhibitor_company}</div>
                      }
                      {person.item.cart.primaryRegistrationType.isSpeaker &&
                        <div style={{fontSize: 'smaller'}}>Arrival: {person.item.cart.arrival_day}, {person.item.cart.arrival_time}</div>
                      }
                      {person.item.cart.isSpeaker &&
                       !person.item.cart.isSpeakerSingleDay &&
                        <div style={{fontSize: 'smaller'}}>Departure: {person.item.cart.departure_day}, {person.item.cart.departure_time}</div>
                      }
                      { !this.priceIsZero(primaryRegistrationPrice, primaryRegistrationPrice, false) &&
                        <span className="usa-tag">May be paid for with a US government payment card</span>
                      }
                    </div>
                  </td>
                  <td lg={2}><div style={{textAlign: 'right', paddingRight: '20px'}}>{this.price(primaryRegistrationPrice, primaryRegistrationPrice, false)}</div></td>
                </tr>

                {person.item.cart.includeMembership &&
                  <tr key={person.item.cart.primaryRegistrationType + 'b'}>
                    <td lg={1} style={{verticalAlign: 'top'}}>{this.lineItem()}</td>
                    <td>
                      <p className="usa-prose margin-y-0">Individual Annual Membership</p>
                    </td>
                    <td lg={2}><div style={{textAlign: 'right', paddingRight: '20px'}}>{this.price(person.item.cart.primaryRegistrationType.additionalAnnualMembership, primaryRegistrationPrice, false)}</div></td>
                  </tr>
                }

                {person.item.cart.secondaryItems && person.item.cart.secondaryItems.map((item) =>
                  <tr key={item.sk}>
                    <td style={{verticalAlign: 'top'}}>{this.lineItem()}</td>
                    <td>
                      <p className="usa-prose margin-y-0">{item.name}</p>
                      {item.sk === person.item.cart.relationships.isBanquetSeatingRequest  &&
                        <div style={{fontSize: 'smaller'}}>{person.item.cart.secondaryAttendeeBanquetSeatingRequest}</div>
                      }
                      {item.sk === person.item.cart.relationships.isBanquetSpecialMealRequest &&
                        <div style={{fontSize: 'smaller'}}>{person.item.cart.secondaryAttendeeBanquetDietaryPreference}</div>
                      }
                      { !item.isGuestActivity && item.fullPrice > 0 &&
                        <span className="usa-tag">May be paid for with a US government payment card</span>
                      }
                      { item.added &&
                        <span className="usa-tag" style={{display: 'inline-block', backgroundColor: '#b50908'}}>Added after original registration - billed and paid for separately</span>
                      }
                      {!this.props.receipt &&
                        <div style={{textAlign: 'right'}}>
                          <Button className="usa-button usa-button--unstyled" style={{textDecoration: 'none'}} onClick={() => this.removeItem(item.sk)}>
                            Remove
                          </Button>
                        </div>
                      }
                    </td>
                    { item.fullPrice === 0 &&

                      <td>
                        <div style={{textAlign: 'right', paddingRight: '20px'}}>Included</div>
                      </td>
                    }
                    { item.fullPrice !== 0 &&

                      (person.item.cart.isValidConventionMembership || person.item.cart.includeMembership) &&
                      <td><div style={{textAlign: 'right', paddingRight: '20px'}}>{this.price(item.memberPrice, primaryRegistrationPrice, item.priceMatchesRegistration)}</div></td>
                    }
                    { item.fullPrice !== 0 &&

                      !(person.item.cart.isValidConventionMembership || person.item.cart.includeMembership) &&
                      <td><div style={{textAlign: 'right', paddingRight: '20px'}}>{this.price(item.fullPrice, primaryRegistrationPrice, item.priceMatchesRegistration)}</div></td>
                    }
                    {item.added && item.unpaid && false &&
                      <td>
                        <div style={{textAlign: 'right', paddingRight: '20px'}}>Billed Separately - unpaid</div>
                      </td>
                    }
                    {item.added && !item.unpaid && false &&
                      <td>
                        <div style={{textAlign: 'right', paddingRight: '20px'}}>Billed Separately - paid</div>
                      </td>
                    }
                  </tr>
                )}

                { person.item.cart.couponName  &&
                  <tr key={person.item.cart.primaryRegistrationType + 'a'}>
                  <td lg={1}>{this.lineItem()}</td>
                  <td>
                    <p className="usa-prose margin-y-0">Coupon Applied to Convention Attendance</p>
                    <div style={{fontSize: 'smaller'}}>{person.item.cart.couponName}</div>
                  </td>
                  <td style={{textAlign: 'right', paddingRight: '25px'}}>({this.price(primaryRegistrationPrice, primaryRegistrationPrice, false)})</td>
                  </tr>
                }

                { person.item.cart.couponName &&
                  person.item.cart.couponIncludesGuest &&
                  person.item.cart.includesGuest &&
                  <tr key={person.item.cart.primaryRegistrationType + 'b'}>
                  <td lg={1}>{this.lineItem()}</td>
                  <td>
                    <p className="usa-prose margin-y-0">Coupon Applied to Guest</p>
                    <div style={{fontSize: 'smaller'}}>{person.item.cart.couponName}</div>
                  </td>
                  <td style={{textAlign: 'right', paddingRight: '25px'}}>({this.price(person.item.cart.unadjustedGuestPrice, person.item.cart.unadjustedGuestPrice, false)})</td>
                  </tr>
                }
                { person.item.cart.couponName &&
                  person.item.cart.couponIncludesIid &&
                  registrationIncludesSecondaryIid &&
                  <tr key={person.item.cart.primaryRegistrationType + 'b'}>
                  <td lg={1}>{this.lineItem()}</td>
                  <td>
                    <p className="usa-prose margin-y-0">Coupon Applied to Industry Interface Day</p>
                    <div style={{fontSize: 'smaller'}}>{person.item.cart.couponName}</div>
                  </td>
                  <td style={{textAlign: 'right', paddingRight: '25px'}}>($100)</td>
                  </tr>
                }
                <tr>
                  <td></td>
                  <td>
                    <div style={{textAlign: 'right'}}>
                      <strong className="mr">Total</strong><br />
                      { !this.props.receipt && typeof(person.item.cart.couponName) === 'undefined' &&
                        <Button className="usa-button usa-button--unstyled" style={{textDecoration: 'none'}} onClick={() => this.handleShowForm()}>
                          Apply Coupon
                        </Button>
                      }
                    </div>
                  </td>
                  <td style={{verticalAlign: 'top'}}><div style={{textAlign: 'right', paddingRight: '20px', float: 'right'}}><strong>${person.item.cart.totalPrice}</strong></div></td>
                </tr>

              </thead>

            </table>
          </div>
          <Modal style={modalStyle} show={this.state.showForm} onHide={this.handleClose}>
            <Modal.Header closeButton className="modal-header-additional">
              <h2 style={{float:'left', maxWidth: '80%', marginLeft: 10, textAlign: 'left'}}>Apply Coupon</h2>
            </Modal.Header>
            <Modal.Body>
            <Form>
              <div className="grid-col-6 grid-offset-3" style={{ marginBottom: 32 }}>
                <div className="usa-label">Coupon Number</div>
                <Form.Control
                  plaintext
                  className="usa-input"
                  name="coupon"
                  defaultValue={this.state.coupon}
                  style={editingStyle}
                  onChange={this.handleChangeCoupon}>
                </Form.Control>
              </div>
              <ButtonToolbar>
                <ButtonActive label="Apply Coupon"
                              className="usa-button"
                              variant="secondary"
                              style={{marginLeft: '5px'}}
                              onClick={() => this.handleApplyCoupon()}
                              active={this.state.tryingCoupon}
                              disabled={this.state.tryingCoupon} />

                <Button className="usa-button"
                        style={{marginLeft: '10px'}}
                        onClick={this.handleClose}>
                  Close
                </Button>
              </ButtonToolbar>

              {this.state.errorMessage &&
                <div className="grid-col-10 grid-offset-1" style={{ marginTop: 16 }}>
                <div className="usa-alert usa-alert--info usa-alert--slim" >
                  <div className="usa-alert__body">
                    <p className="usa-alert__text">{this.state.errorMessage}</p>
                  </div>
                </div>
              </div>
               }
            </Form>
            </Modal.Body>
            <Modal.Footer>
              <p className="usa-prose">Coupons are issued by A/TA and may only be used once.</p>
            </Modal.Footer>
          </Modal>
        </section>



      </>
        )
    }

}

Cart.contextType = PersonContext
export default Cart;

function CustomLink(props) {
  let to = props.isAdmin ? props.adminTo : props.to
  return (
    <Link
      className="usa-button"
      to={to}
      >
    {props.label}
    </Link>
  )
}
