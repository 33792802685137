
import React, { Component } from 'react';
import {ChapterContext} from '../../../classes/context.js'

import Form from 'react-bootstrap/Form'

var moment = require('moment');

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class Editor extends Component {

  constructor(props) {
    super(props)

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.stateChange = this.stateChange.bind(this)

    this.state = {
      changed: moment()
    }
  }

  keyValue(item, key, format) {
      const {chapter} = this.context

      switch (item) {
        case 'root':
          if (!chapter.editItem) {return null}
          if (!chapter.editItem[item]) {return null}

          if (format) {
            return (typeof(chapter.editItem[item][key]) === 'undefined' ||
                    !chapter.editItem[item][key] ?
                    null :
                    moment(chapter.editItem[item][key]).format(format))
          }

          return (typeof(chapter.editItem[item][key]) === 'undefined' ? null : chapter.editItem[item][key])

        default:
         return null
      }
    }

  handleCheckboxChange(event) {
    const handleChange = this.context.chapter.handleChange.bind(this.context.chapter)
    handleChange(event)

    // This is really a hack to force a state change and a rerender. The context
    // change does not appear to do that automatically. Although it seems like
    // it should.
    this.stateChange()
  }

  stateChange() {
    this.setState({
      changed: moment()
    })
  }

  render() {

    const {chapter} = this.context
    if (this.props.editMode.mode !== 'edit') {return null}
    if (!chapter.editItem) {return null}

    const handleChange = chapter.handleChange.bind(chapter)

    return (

      <Form>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-12">
            <div className="usa-label">Chapter</div>
            <Form.Control className="usa-input" name="root.name" plaintext defaultValue={this.keyValue('root', 'name')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>

        <h2 className="margin-y-5" style={{marginBottom: 0}}>General Information</h2>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-6">
            <div className="usa-label">Base</div>
            <Form.Control className="usa-input" name="root.base" plaintext defaultValue={this.keyValue('root', 'base')}  style={editingStyle} onChange={handleChange}/>
          </div>
          <div className="tablet:grid-col-2">
            <div className="usa-label">ICAO</div>
            <Form.Control className="usa-input" name="root.icao" plaintext defaultValue={this.keyValue('root', 'icao')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-12">
            <div className="usa-label">Chapter Website</div>
            <Form.Control className="usa-input" name="root.url" plaintext defaultValue={this.keyValue('root', 'url')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-12">
            <div className="usa-label">Chapter Contact Email</div>
            <Form.Control className="usa-input" name="root.contact_email" plaintext defaultValue={this.keyValue('root', 'contact_email')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>

        <h2 className="margin-y-5" style={{marginBottom: 0}}>Location</h2>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-12">
            <div className="usa-label">Full Address</div>
            <Form.Control className="usa-input" name="root.full_address" plaintext defaultValue={this.keyValue('root', 'full_address')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-2">
            <div className="usa-label">Latitude</div>
            <Form.Control className="usa-input" name="root.latitude" plaintext defaultValue={this.keyValue('root', 'latitude')}  style={editingStyle} onChange={handleChange}/>
          </div>
          <div className="tablet:grid-col-2">
            <div className="usa-label">Longitude</div>
            <Form.Control className="usa-input" name="root.longitude" plaintext defaultValue={this.keyValue('root', 'longitude')}  style={editingStyle} onChange={handleChange}/>
          </div>
          <div className="tablet:grid-col-3">
            <div className="usa-label">Membership Radius</div>
            <Form.Control className="usa-input" name="root.standard_member_distance" plaintext defaultValue={this.keyValue('root', 'standard_member_distance')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>
      </Form>
    )
  }
}

Editor.contextType = ChapterContext
export default Editor;
