import React, { Component } from 'react';
import { NavLink, Link, useLocation } from "react-router-dom";
import Button from 'react-bootstrap/Button'
import {navigation} from '../controls/menus'

// {this.props.env !== 'prod' && this.props.authState === 'signedin' &&
//   <li class="usa-nav__primary-item">
//     <NavLink
//       to="/virtual2020"
//       title="Attend"
//       aria-label="virtual2020"
//       activeClassName="usa-current"
//     >
//       Convention 2020
//     </NavLink>
//   </li>
// }
// {this.props.env !== 'prod' && this.props.authState !== 'signedin' &&
//   <li class="usa-nav__primary-item">
//     <NavLink
//       to="/virtual2020"
//       title="Attend"
//       aria-label="about-the-convention"
//       activeClassName="usa-current"
//     >
//       Convention 2020
//     </NavLink>
//   </li>
// }


class TopMenu extends Component {

  render() {

    return (
      <nav
        aria-label="Primary navigation"
        className="usa-nav">
        <div className="usa-nav__inner">
          <button className="usa-nav__close">
            <img src="/images/close.svg" alt="close" />
          </button>
          <ul className="usa-nav__primary usa-accordion">
            <Menus />
            <li className="usa-nav__primary-item">
           <NavLink
             to="/chairmans-message"
            >
             Welcome from General McNabb

           </NavLink>
           </li>
          </ul>

          <SecondaryMenu
            signOut={this.props.signOut}
            authState={this.props.authState}
            env={this.props.env}
          />
        </div>
      </nav>
    )
  }
}

function Menus() {

  const location = useLocation()

  return (
  navigation.map(function(menu) {
    if (!menu.topNavigation) return null

    return (
    <li className="usa-nav__primary-item" key={menu.sequence}>
      <button
        className={"usa-accordion__button usa-nav__link "+active(menu, location)}
        aria-expanded="false"
        aria-controls={"extended-nav-section-"+menu.sequence}
      >
        <span>{menu.parentLabel} {menu.auth && <i class="fad fa-lock-alt"></i>}</span>
      </button>
      <ul id={"extended-nav-section-"+menu.sequence}className="usa-nav__submenu">
        {menu.items.map((item) => {
          return (
            <li className="usa-nav__submenu-item" key={item.order}>
            {item.link.substring(0,4) === 'http'
            ?
              <a href={item.link} className="usa-nav__submenu-item">{item.label} {item.auth && <i class="fad fa-lock-alt"></i>}</a>
            :
              <NavLink
                to={item.link}
                title={item.label}
                aria-label={item.label}
                className="usa-nav__submenu-item"
              >
                {item.label} {item.auth && <i class="fad fa-lock-alt"></i>}
              </NavLink>
            }
          </li>
        )
        })}

      </ul>
    </li>
     )
  })
)
}


function active(topMenu, location) {

  let sidebar = topMenu.items.filter(menuItem => (menuItem.link === location.pathname))[0]

  // Check to see if any of the second tier items match
  if (!sidebar) {
    topMenu.items.forEach(menuItem => {
      if (menuItem.children && !sidebar) {
        if (menuItem.children.filter(child => (child.link === location.pathname)).length > 0) {
          sidebar = menuItem
        }
      }
    })
  }
  if (sidebar) return ("usa-current")
  return null
}

function SecondaryMenu(props) {
  return (
  <div className="usa-nav__secondary">
    <ul className="usa-nav__secondary-links">

      <div className='usa-nav__secondary-item'>
        <Link
          to="/contact"
          title="Contact"
          aria-label="Contact"
          style={{textDecoration: 'none'}}
        >
        Contact
        </Link>
      </div>
      <div className='usa-nav__secondary-item'>
        <Link
          to="/donate"
          title="Donate"
          aria-label="Donate"
          style={{textDecoration: 'none'}}
        >
          Donate
        </Link>
      </div>


      <div className='usa-nav__secondary-item'>
        <Link
          to="/account"
          title="Account"
          aria-label="Account"
          style={{textDecoration: 'none'}}
        >
          Account
        </Link>
      </div>

      {props.authState === 'signedin' &&
        <div className='usa-nav__secondary-item'>
          <Button className='usa-button usa-button--unstyled' onClick={() => props.signOut()}><i class="fal fa-sign-out-alt"></i></Button>
        </div>
      }
    </ul>

    <form className="usa-search usa-search--small ">
      <div role="search">
        <label className="usa-sr-only" for="extended-search-field-small">
          Search small
        </label>
        <input
          className="usa-input"
          id="extended-search-field-small"
          type="search"
          name="search"
        />
        <button className="usa-button" type="submit">
          <span className="usa-sr-only">Search</span>
        </button>
      </div>
    </form>
  </div>
)
}


export default TopMenu;
