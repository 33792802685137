import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { Redirect, Link } from "react-router-dom";

import { API } from 'aws-amplify';

import ErrorMessage from '../ErrorMessage';

import {PersonContext} from '../../classes/context.js';

var moment = require('moment')

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class Confirmation extends Component {

  constructor(props) {
    super(props)

    this.roomNotNeeded = this.roomNotNeeded.bind(this)
    this.roomUsingDouble = this.roomUsingDouble.bind(this)
    this.roomNeeded = this.roomNeeded.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleGotoHotel = this.handleGotoHotel.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleConfirmationCode = this.handleConfirmationCode.bind(this)
    this.keyValue = this.keyValue.bind(this)

    this.state = {
      errorMessage: null,
      redirect: false,
      roomNotNeededActive: false,
      roomNeededActive: false,
      roomUsingDoubleActive: false,
      setupHotel: false,
      confirmationCode: false,
      hotelConfirmationCode: '',
      hotelUrl: null,
    }

  }

  handleChange(event) {
    if (!this.context.person.editItem) {
      this.context.person.beginEdit()
    }

    this.context.person.editItem.cart.hotelConfirmationCode = event.target.value
    this.setState({hotelConfirmationCode: event.target.value})
  }

  keyValue(item, key, format) {
    const {person} = this.context

    switch (item) {
      case 'root':
        if (!person.editItem) {return null}
        if (!person.editItem[item]) {return null}

        if (format) {
          return (typeof(person.editItem[item][key]) === 'undefined' ||
                  !person.editItem[item][key] ?
                  null :
                  moment(person.editItem[item][key]).format(format))
        }

        return (typeof(person.editItem[item][key]) === 'undefined' ? null : person.editItem[item][key])

      default:
       return null
    }
  }

  async roomUsingDouble() {

    this.setState({
      roomUsingDoubleActive: true,
      waitingForPasskey: true,
    })
    this.context.person
      .beginEdit()
      .editItem.cart.hotelStatus = 'using-double-from-another-attendee'

    this.context.person
      .endEdit(true)
      .store()
      .then(this.setState({redirect: true}))
      .catch(error => {
        console.log(error)
        this.setState({
          errorMessage: "We were not able to complete your hotel request",
          roomUsingDoubleActive: false,
        })
      })
  }


  async roomNotNeeded() {

    this.setState({
      roomNotNeededActive: true,
      waitingForPasskey: true,
    })
    this.context.person
      .beginEdit()
      .editItem.cart.hotelStatus = 'not-required'

    this.context.person
      .endEdit(true)
      .store()
      .then(this.setState({redirect: true}))
      .catch(error => {
        console.log(error)
        this.setState({
          errorMessage: "We were not able to complete your hotel request",
          roomNotNeededActive: false,
        })
      })
  }

  async roomNeeded(doubleRoom) {

    let {person} = this.context

    this.setState({
      roomNeededActive: true,
      setupHotel: true,
      waitingForPasskey: true,
      errorMessage: '',
    })

    let request = {
      headers: {},
      body: {
        cart: person.item.cart,
        root: person.item.root,
        address: person.item.address,
        phoneNumber: person.item['phone-primary'].phone_number,
        doubleRoom: doubleRoom
      }
    }

    API.post('hotel', '/hotel', request)
    .then(response => {
      this.setState({
        errorMessage: '',
        roomNeededActive: false,
        hotelBridgeId: response.hotelBridgeId,
        hotelUrl: response.url,
        waitingForPasskey: false
      })

      person.beginEdit()
      if (doubleRoom) {
        person.editItem.cart.hotelStatus = 'requested-double-not-confirmed'
      } else {
        person.editItem.cart.hotelStatus = 'requested-not-confirmed'
      }
      person.editItem.cart.hotelBridgeId = response.hotelBridgeId

      this.context.person
        .endEdit(true)
        .store()
        .then(response => {
//          this.setState({redirect: true})
        })
        .catch(error => {
          console.log(error)
          this.setState({
            errorMessage: "We were not able to complete your hotel request",
            roomNotNeededActive: false,
          })
        })
    })
    .catch(error => {
      console.log(error)
      this.setState({
        errorMessage: error.message,
        roomNeededActive: false,
        setupHotel: false,
        waitingForPasskey: false,
      })
    })
  }

  handleClose() {

    if (this.state.confirmationCode) {
      this.setState({
        confirmationCode: false,
        redirect: true,
      })
    } else {
      this.setState({
        setupHotel: false,
        confirmationCode: false
      })
    }
  }

  handleGotoHotel() {
    this.setState({
      setupHotel: false,
      confirmationCode: true,
      redirect: false,
    })
  }

  handleConfirmationCode() {
    if (this.context.person.editItem) {
      this.context.person.editItem.cart.hotelStatus =
          this.context.person.editItem.cart.hotelStatus.replace('not-confirmed', 'confirmed')
      this.context.person
        .endEdit(true)
        .store()
        .then(this.setState(
          {setupHotel: false,
          confirmationCode: false,
          redirect: true})
        )
        .catch(error => {
          console.log(error)
          this.setState({
            errorMessage: "We were not able to update your confirmation code",
          })
        })
    } else {
      this.setState(
        {setupHotel: false,
        confirmationCode: false,
        redirect: true})
    }
  }

  render() {

    const modalStyle = {
      background: '#fff',
      border: '#b40808',
      borderRadius: '5px',
      marginLeft: '-200px',
      textAlign:'center',
      position: 'fixed',
      left: '45%',
      top: '20%',
      zIndex: 11,
      width: '500px',
      minHeight: '250px',
      boxShadow:'0 5px 10px rgba(0,0,0,0.3)',
      transform: 'translate(0, 0)',
      transition: 'transform 0.3s ease-out',
      outline: 'none',
      pointEvents: 'auto',

    };
    let person = this.context.person


    const toHotel ='https://api.passkey.com/httpapi/RegLink?' +
              'PartnerID=10567978&' +
              'OP=GetForRedirectResWeb&' +
              'Version=4.00.02&' +
              'Mode=S&' +
              'Destination=02&' +
              'LastName=' + person.item.root.last_name + '&' +
              'BridgeID=' + this.state.hotelBridgeId + '&' +
              'Locale=EN_US'


    if (this.state.redirect ) {
      return (
        <CustomRedirect
          to="/account"
          adminTo={"/admin/account/" + person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1)}
          isAdmin={this.props.isAdmin}
        />
      )
    }

    let offerDouble = false
    if (person.item.cart.primaryRegistrationType.roomDoubleCode &&
        person.item.cart.primaryRegistrationType.roomDoubleCode.length > 0) {
      offerDouble = true
    }

    return (
      <>
      <section className="usa-section" style={{paddingTop: 10, borderTopWidth: 0}}>
        <div className="grid-container margin-y-0">
          <h1 className="margin-y-0">Convention Registration - Confirmation</h1>
          <p className="usa-prose">Congratulations, you are attending the 2021 A/TA Convention and Symposium. We are looking forward to seeing you.</p>

          {person.item.cart.execAssistantCode &&
            <>
            <h2>Exec Code</h2>
            <p className="usa-prose">
              Are you a DV attending with a support team? Each member of your support team must register for the convention separately
              as <strong>Exec Support</strong> and use the code <strong>{person.item.cart.execAssistantCode}</strong> when registering.
              Using the code will ensure we know they are part of your team. It will also ensure they are charged the same rate as you.
            </p>
            <p className="usa-prose">
              If you lose this code you can find it again on your <a href="/account">Account</a> page.
            </p>
            </>
          }
          <div className="grid-row grid-gap" style={{marginTop: 20}}>
            <div className="grid-col-12">
              <h2>Accomodation at the Convention Hotel</h2>
            </div>
            <div className="grid-col-3">
              <img alt="alert" src="/images/warning750.jpg" />
            </div>
            <div className="grid-col-9 ">
              <p className="usa-prose" style={{marginTop: 0}}>You have a convention registration but <strong>not</strong> a hotel booking. A/TA has negotiated great rates for convention attendees
              that are lower than available any other way. Starting your hotel booking here is the best way to find a room that is within the per diem rates.</p>
            <p>Find out more about the <a target="_hotel" href="https://www.marriott.com/en-us/hotels/mcowc-orlando-world-center-marriott/overview/">Orlando World Center Marriott</a></p>

              <Button
                onClick={() => this.roomNeeded(false)}
                label="Start Hotel Reservation"
                aria-label="start-hotel-reservation"
                className="usa-button usa-button--unstyled"
                style={{textDecoration: 'none', cursor: 'pointer'}}
              >
                Reserve a Single Room
              </Button>
              {offerDouble &&
                <>
                <span> or </span>
                <Button
                  onClick={() => this.roomNeeded(true)}
                  label="Start Hotel Reservation"
                  aria-label="start-hotel-reservation"
                  className="usa-button--unstyled"
                  style={{textDecoration: 'none', cursor: 'pointer'}}
                >
                  Reserve a Double Room
                </Button>
                <div className="usa-alert usa-alert--info usa-alert--slim" >
                  <div className="usa-alert__body">
                    <p className="usa-alert__text">Double room rates are $239.00 but with double occupants are below the $129.00 single room per diem rate.</p>
                  </div>
                </div>
                </>
              }

              <p className="usa-prose">If you do not need to reserve a hotel room now, please let us know why.</p>

              {offerDouble &&
                <div style={{marginBottom: 12}}>
                  <Button
                    className="usa-button usa-button--unstyled"
                    style={{textDecoration: 'none'}}
                    onClick={this.roomUsingDouble}
                  >
                    I will use a double room booked by another attendee
                  </Button>
                </div>
              }

              <div style={{marginBottom: 12}}>
                <Button
                  className="usa-button usa-button--unstyled"
                  style={{textDecoration: 'none'}}
                  onClick={this.roomNotNeeded}
                >
                  I do not need a hotel room
                </Button>
              </div>

              <CustomLink
                className="usa-button usa-button--unstyled"
                to="/account"
                adminTo={"/admin/account/" + person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1)}
                isAdmin={this.props.isAdmin}
                label="I'll complete my hotel reservation later"
                style={{textDecoration: 'none'}}
              />

              <ErrorMessage message={this.state.errorMessage} />

            </div>
          </div>
        </div>
      </section>

      <Modal
        style={modalStyle}
        size="lg"
        show={this.state.confirmationCode}
        onHide={this.handleClose}>
        <Modal.Header
          closeButton
          className="modal-header-additional">
          <h2 style={{float:'left', maxWidth: '80%', marginLeft: 10, textAlign: 'left'}}>
            Hotel Confirmation Code
          </h2>
        </Modal.Header>
        <Modal.Body className="modal-body" style={{minHeight:200}}>

          <div className="grid-row grid-gap" style={{marginTop:20}}>
            <div className="grid-col-6 ">
              <div style={{marginBottom:16}}><i className="far fa-hotel fa-7x"></i></div>
              <div>
                <i className="fal fa-keynote fa" style={{marginRight: 12}}></i>
                <i className="fal fa-dumbbell fa" style={{marginRight: 5}}></i>
                <i className="fal fa-swimming-pool fa" style={{marginRight: 5}}></i>
                <i className="fal fa-spa fa" style={{marginRight: 5}}></i>
                <i className="fal fa-coffee fa" style={{marginRight: 5}}></i>
                <i className="fal fa-utensils fa" style={{marginRight: 5}}></i>
                <i className="fal fa-glass-cheers fa" style={{marginRight: 5}}></i>
              </div>

            </div>
            <div className="grid-col-5 ">
              <div className="usa-label">Hotel Confirmation Code</div>
              <Form.Control  maxLength={8} className="usa-input" name="cart.confirmationCode" plaintext defaultValue={this.keyValue('cart', 'confirmationCode')}  style={editingStyle} onChange={this.handleChange}/>
              <br />
              <Button
                className="usa-button btn btn-primary"
                name="confirmationCode"
                disabled={this.state.hotelConfirmationCode.length !== 8}
                onClick={this.handleConfirmationCode}
              >
            Confirm
            </Button>

            </div>

          </div>
          <ErrorMessage message={this.state.errorMessage} />
        </Modal.Body>
        <Modal.Footer style={{minHeight: 36}}>
          <span>
          </span>
        </Modal.Footer>
      </Modal>

      <Modal
        style={modalStyle}
        size="lg"
        show={this.state.setupHotel}
        onHide={() => this.handleClose()}>
        <Modal.Header
          closeButton
          className="modal-header-additional">
          <h2 style={{float:'left', maxWidth: '80%', marginLeft: 10, textAlign: 'left'}}>
            Hotel Reservation
          </h2>
        </Modal.Header>
        <Modal.Body className="modal-body" style={{minHeight:200}}>

          <div className="grid-row grid-gap" style={{marginTop:20}}>
            <div className="grid-col-6 ">
              <div style={{marginBottom:16}}><i className="far fa-hotel fa-7x"></i></div>
              <div>
                <i className="fal fa-keynote fa" style={{marginRight: 12}}></i>
                <i className="fal fa-dumbbell fa" style={{marginRight: 5}}></i>
                <i className="fal fa-swimming-pool fa" style={{marginRight: 5}}></i>
                <i className="fal fa-spa fa" style={{marginRight: 5}}></i>
                <i className="fal fa-coffee fa" style={{marginRight: 5}}></i>
                <i className="fal fa-utensils fa" style={{marginRight: 5}}></i>
                <i className="fal fa-glass-cheers fa" style={{marginRight: 5}}></i>
              </div>

            </div>
            <div className="grid-col-5 ">
              <div style={{marginBottom: 16}}>
                {this.state.waitingForPasskey &&
                  <span>Transfering your contact information to the Convention hotel</span>
                }
                {!this.state.waitingForPasskey &&
                  <span>Your contact information has been transfered to the hotel booking website</span>
                }
              </div>
              <Button
                label="Open Hotel Site"
                className="usa-button btn btn-primary"
                name="openHotelWebsite"
                target="_default"
                href={toHotel}
                onClick={this.handleGotoHotel}
                disabled={this.state.waitingForPasskey}
              >
                Open Hotel Site
              </Button>
            </div>


          </div>
          <ErrorMessage message={this.state.errorMessage} />
        </Modal.Body>
        <Modal.Footer style={{minHeight: 36}}>
          <span>
          </span>
        </Modal.Footer>
      </Modal>
      </>
    )
  }
}

Confirmation.contextType = PersonContext
export default Confirmation;

function CustomLink(props) {
  let to = props.isAdmin ? props.adminTo : props.to
  return (
    <Link
      className={props.className}
      to={to}
      style={props.style}
      >
    {props.label}
    </Link>
  )
}


function CustomRedirect(props) {
  let to = props.isAdmin ? props.adminTo : props.to
  return (
    <Redirect
      to={to}
      >
    {props.label}
  </Redirect>
  )
}
