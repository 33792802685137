
import React, { Component } from 'react'
import Moment from 'react-moment'
import { Link } from "react-router-dom"

import {PersonContext} from '../../../classes/context'
import RegistrationSummary from './RegistrationSummary'

var moment = require('moment');

class AccountSummary extends Component {

  // New structure start
  constructor(props) {
    super(props)
    this.editMembership = this.editMembership.bind(this)
  }
  // New structure end

  editMembership(startEdit, sk) {
    this.props.startEdit({mode: 'editMembership', sk: sk, adminMode: 'view'}, null)
  }


  render() {

    if (this.props.editMode.mode !== 'view') {return null}


    const item = this.context.person.item
    const account = this.context.person.account

    // New structure start
    const { startEdit } = this.props
    // New structure end

    if (this.props.isNew && this.props.isAdmin) {
      return (
        <div className="margin-y-5">
          <h2 className="usa-heading-alt margin-y-0">
            {item.root.pk.slice(item.root.pk.indexOf('-') + 1)}
          </h2>
          <p className="usa-prose">This user does not exist</p>
        </div>
      )
    }

    return (
      <div className="margin-y-5">
        <h2 className="usa-heading-alt margin-y-0">
          <Name item={item} />
        </h2>

        <div className="usa-intro">
          <div style={{ fontSize: "smaller" }}>{item.root.pk.slice(item.root.pk.indexOf('-') + 1)}</div>
        </div>
        {this.props.isAdmin &&
          <>
          <div className="usa-prose">
            <hr />
            <div className="usa-line-length-example usa-prose">
              <h3>Account Information</h3>
              <div className="usa-prose" style={{ marginLeft: 20 }}>
                {!account.Username &&
                  <div>No sign in information exist for this person.</div>
                }
                {account.Username &&
                  <>
                  <div><strong>Account</strong>: {account.Username}</div>
                  <div><strong>Account Created</strong>: {moment(account.UserCreateDate).format('YYYY-MM-DD HH:mm (Z)')}</div>
                  <div><strong>Account Last Modified</strong>: {moment(account.UserLastModifiedDate).format('YYYY-MM-DD HH:mm (Z)')}</div>
                  <div><strong>Enabled</strong>: {account.Enabled ? <span>Yes</span> : <span>No</span>}</div>
                  <div><strong>Status</strong>: {account.UserStatus}</div>
                  </>
                }
              </div>
            </div>
          </div>
          </>
        }

        <div className="usa-prose">
          <hr />
          <div className="usa-line-length-example usa-prose">
            <h3>Personal Information</h3>
            <div className="usa-prose" style={{ marginLeft: 20 }}>
              <Address root={item.root} address={item.address} />
              <Phone phone={item['phone-primary']} subtype="Primary" />
              <Phone phone={item['phone-secondary']} subtype="Secondary" />
              <AlternateEmail root={item.root} />
              <Nationality nationality={item.nationality} />
              <Spouse root={item.root} />
            </div>
          </div>
        </div>

        <div className="usa-prose">
          <hr />
          <div className="usa-line-length-example usa-prose">
            <h3>Service and Professional</h3>
            <div className="usa-prose" style={{ marginLeft: 20 }}>
              <Military military={item.military} />
              <Civilian civilian={item.civilian} />
              <Private root={item.root} />
              <None
                type="service"
                root={item.root}
                civilian={item.civilian}
                military={item.military}
              />
            </div>
          </div>
        </div>

        <RegistrationSummary
          hasCart={this.props.hasCart}
          hasCancellationRequest={this.props.hasCancellationRequest}
          hasCancellationConfirmed={this.props.hasCancellationConfirmed}
          showModal={this.props.showModal}
          showConfirmationModal={this.props.showConfirmationModal}
          isAdmin={this.props.isAdmin}
          updateModalVisibility={this.props.updateModalVisibility}
          updateConfirmationModalVisibility={this.props.updateConfirmationModalVisibility}
          handleChange={this.props.handleChange}
          requestCancellation={this.props.requestCancellation}
          confirmCancellation={this.props.confirmCancellation}
        />

        <div className="usa-prose">
          <hr />
          <div className="usa-line-length-example usa-prose">
            <h3><i className="far fa-address-card"></i> Membership and Benefits</h3>
            <div className="usa-prose" style={{ marginLeft: 20 }}>
              <Convention registration={item.registration} />
              <Membership root={item.root} chapter={item.address} membershipSummary={item['membership-summary']} isAdmin={this.props.isAdmin}/>
              <MembershipHistory memberships={item.membership} isAdmin={this.props.isAdmin} startEdit={startEdit} editMembership={this.editMembership} deleteMembership={this.props.deleteMembership}/>
              <Contact root={item.root} />
              <Donation donation={item.donation} />
            </div>
          </div>
        </div>
        {this.props.isAdmin &&
          <div className="usa-prose">
            <hr />
            <div className="usa-line-length-example usa-prose">
              <h3>Admin Only</h3>
              <div className="usa-prose" style={{ marginLeft: 20 }}>
                <CommentList comments={item.comments} isAdmin={this.props.isAdmin}/>
              </div>
              </div>

          </div>
        }

      </div>
    )
  }
}

function Name(props) {
  const {item} = props

  let name = ''
  if (item.military.rank && item.military.rank !== 'none') {
    name += ' ' + item.military.rank_abbreviation
  }

  name += ' ' + item.root.first_name
  if (item.root.middle_initial) {
    name += ' ' + item.root.middle_initial
  }
  name += ' ' + item.root.last_name

  if (item.military.status === 'retired') {
    name += ' Ret'
  }

  return (<span>{name}</span>)
}

function None(props) {

  const {military, civilian, root, type} = props

  switch (type) {
    case 'service':
      if (!root.has_private &&
          (!civilian || !civilian.service || civilian.service === 'none') &&
          (!military || !military.service || military.service === 'none')) {
           return <span>No information provided.</span>
         }
      break;
    default:
      return null
  }

  return null
}

function Membership(props) {
  const {chapter, membershipSummary, isAdmin} = props

  const membershipName = membershipSummary.membershipTypeName ? membershipSummary.membershipTypeName : 'An A/TA Membership'

  if (membershipSummary.isLifeMember) {
    return (
      <>
      <div><strong>Membership</strong>: {membershipSummary.membershipTypeName}</div>
      <Chapter chapter={chapter} isAdmin={isAdmin} isActiveMember={membershipSummary.isActiveMember}/>
      </>
    )
  }

  if (membershipSummary.isExpiredMember) {
    return (
      <>
      <strong>Membership</strong>: Expired {membershipName} {membershipSummary.associatedCompany && <span> - {membershipSummary.associatedCompany}</span>} {membershipSummary.lastDayOfMembership && <span>- expired <Moment format="YYYY-MM-DD">{membershipSummary.lastDayOfMembership}</Moment></span>}
      </>
    )
  }

  if (membershipSummary.isInGracePeriodMember) {
    return (
      <>
      <strong>Membership</strong>: {membershipName} {membershipSummary.associatedCompany && <span> - {membershipSummary.associatedCompany}</span>} {membershipSummary.lastDayOfMembership && <span>- in grace period after expiring on <Moment format="YYYY-MM-DD">{membershipSummary.lastDayOfMembership}</Moment></span>}
      <div style={{fontSize: 'smaller'}}>Your membership has expired but we provide a grace period during which your membership benefits continue. The grace period ends 6 months after the end of your membership.</div>
      <Chapter chapter={chapter} isAdmin={isAdmin} isActiveMember={membershipSummary.isActiveMember}/>
      </>
    )
  }

  if (membershipSummary.lastDayOfMembership) {
    return (
      <>
      <div><strong>Membership</strong>: {membershipName} {membershipSummary.associatedCompany && <span> - {membershipSummary.associatedCompany}</span>} (expires <Moment format="YYYY-MM-DD">{membershipSummary.lastDayOfMembership}</Moment>)</div>
      <Chapter chapter={chapter} isAdmin={isAdmin} isActiveMember={membershipSummary.isActiveMember}/>
      </>
    )
  }

  return (
    <><strong>Membership</strong>: No membership</>
  )
}

function CommentList(props) {

  const {comments} = props
  if (!comments) {return null}

  if (comments.length === 0) {
    return (
      <div className="usa-prose" style={{ marginLeft: 20, marginBottom: 10}}>
        No comments yet.
      </div>
    )
  }

  const commentSorted = comments.sort((a, b) => {
    if (a.created_at < b.created_at) {return 1}
    if (a.created_at > b.created_at) {return -1}
    return 0
  })

  return (
    <div className="usa-accordion">

      <h3 className="usa-accordion__heading">
        <button className="usa-accordion__button"
          aria-expanded="true"
          aria-controls="ipmh">
          Comments
        </button>
      </h3>
      <div id="ipmh" className="usa-accordion__content" >
        <table  className="usa-table usa-table--borderless " style={{width:'100%', marginTop: 0, fontSize: 'small'}}>
          <thead>
            <tr>
              <th>Added</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>
            {commentSorted.map(item => {
              return (
                <tr key={item.sk}>
                  <td style={{width: 190}}>{moment(item.created_at).format('YYYY-MM-DD h:mm a')}</td>
                  <td>{item.comment}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}



function MembershipHistory(props) {
  const {isAdmin, memberships, deleteMembership, editMembership, startEdit} = props

  if (!isAdmin) {return null}

  const membershipsSorted = memberships.filter(item => item.type !== 'membership-summary' && item.membershipTypeName).sort((a, b) => {
    if (a.membershipStartDate < b.membershipStartDate) {return 1}
    if (a.membershipStartDate > b.membershipStartDate) {return -1}
    return 0
  })
  if (membershipsSorted.length === 0) {return null}
  let includeNoChargeComment = false
  let includeZeroCostComment = false
  return (
    <div className="usa-accordion" style={{marginTop: 20}}>

      <h3 className="usa-accordion__heading">
        <button className="usa-accordion__button"
          aria-expanded="true"
          aria-controls="mh">
          Membership History
        </button>
      </h3>
      <div id="mh" className="usa-accordion__content" >
        <table  className="usa-table usa-table--borderless " style={{width:'100%', marginTop: 0, fontSize: 'small'}}>
          <thead>
            <tr>
              <th>Type</th>
              <th>Company</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Period</th>
              <th>Status</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {membershipsSorted.map(membership => {
              let price = membership.price ? '$' + membership.price : null
              if (membership.amountReceived === 0 || membership.noCharge) {
                price = 'No Charge'
                includeNoChargeComment = true
              }
              if (membership.price === 0) {
                price = 'Zero Cost'
                includeZeroCostComment = true
              }
              const display = moment().diff(membership.created_at, 'minutes') < 15 ? 'inline' : 'none'
              return (
                <tr key={membership.membershipStartDate}>
                  <td>
                    {isAdmin &&
                      <span
                        className="usa-button-unstyled"
                        style={{textDecoration: "none", paddingRight: 8, color: "#b40808", cursor: "pointer"}}
                        onClick={() => deleteMembership(membership.sk)}
                      >
                        <i className="fas fa-trash-alt"> </i>
                      </span>
                    }
                    {isAdmin &&
                      <span
                        className="usa-button-unstyled"
                        style={{textDecoration: "none", paddingRight: 8, color: "#b40808", cursor: "pointer"}}
                        onClick={() => editMembership(startEdit, membership.sk)}
                      >
                        <i className="fas fa-pencil-alt"> </i>
                      </span>
                    }
                    {membership.membershipTypeName}
                    <span style={{display: display}}> <i className="far fa-asterisk fa-spin" data-fa-transform="shrink-8 up-6"></i></span>
                  </td>
                  <td>
                    {isAdmin && membership.companyId &&
                      <Link to={"/admin/company/" + membership.companyId}>{membership.companyName}</Link>
                    }
                    {!isAdmin &&
                      <>{membership.companyName}</>
                    }
                  </td>
                  <td>{moment(membership.membershipStartDate).format('YYYY-MM-DD')}</td>
                  <td>{membership.membershipEndDate && moment(membership.membershipEndDate).format('YYYY-MM-DD')}</td>
                  <td>{membership.termMonths}</td>
                  <td>{membership.statusText}</td>
                  <td>{price}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {(includeZeroCostComment || includeNoChargeComment) &&
          <p style={{fontSize: 'small'}}>
            {includeZeroCostComment && <span><strong>Zero Cost</strong> means that the membership type list price was $0. </ span>}
            {includeNoChargeComment && <span><strong>No Charge</strong> means that the membership was free as part of a Convention Coupon or added without charge by an Admin.</span>}
          </p>
        }

      </div>
    </div>
  )
}

function Chapter(props) {
  const {chapter, isAdmin, isActiveMember} = props
  if (!chapter.chapterName || !chapter.chapter) {return null}

  const chapterLabel = isActiveMember && chapter.withinRadius ? 'Chapter' : 'Closest Chapter'
  const suffix = isActiveMember ? '' : ' (Not an active member)'
  const withinRadius = chapter.withinRadius ? '' : '(outside membership radius)'
  if (isAdmin) {
    return (
      <span><strong>{chapterLabel}</strong>: <Link to={'/admin/chapter/' + chapter.chapter.substr(8)}>{chapter.chapterName}</Link> {suffix} {withinRadius}</span>
    )
  }
  return (
    <span><strong>{chapterLabel}</strong>: {chapter.chapterName} {suffix}</span>
  )
}

function yesNo(value) {
  if (value) {
    return (<>Yes</>)
  }
  return (<>No</>)
}

function Address(props) {
  const {address} = props

  if (!address || !address.line_1) {return null}

  const fullAddress =
    (address.line_1 ? address.line_1 : '') +
    (address.line_2 ? address.line_2 + ', ' : ', ') +
    (address.city ? address.city + ' ' : '') +
    (address.state_or_province ? address.state_or_province + ' ' : '') +
    (address.postal_code ? address.postal_code + ' ' : '') +
    (address.country ? address.country + ' ' : '')

  return <address><strong>Address:</strong> {fullAddress}</address>
  // return (
  //   <address>
  //     <strong>Address:</strong> {address.line_1}, {address.line_2 && <span>{address.line_2}, </span>}
  //     {address.city}, {address.state_or_province} {address.postal_code} {address.country}
  //   </address>
  // )
}

function AlternateEmail(props) {
  const {root} = props

  if (!root || !root.alternate_email_address) {return null}

  return (
    <div>
      <strong>Alternate Email Address</strong>: {root.alternate_email_address}
    </div>
  )
}

// || nationality.name === 'United States'
function Nationality(props) {
  const {nationality} = props

  if (!nationality || nationality.code === 'none') {return null}
  return (
    <div>
      <strong>Nationality</strong>: {nationality.name}
    </div>
  )
}

function Phone(props) {
  const {phone, subtype} = props

  if (!phone || phone.type === 'none') {return null}
  return (
    <div>
      <strong>{subtype} Phone</strong>: {phone.phone_number} ({phone.type_name})
    </div>
  )
}

function Spouse(props) {
  const {root} = props

  if (!root.spouse_first_name || !root.spouse_last_name) {return null}
  return (
    <div>
      <strong>Spouse</strong>: {root.spouse_first_name} {root.spouse_last_name}
    </div>
  )
}

function Contact(props) {
  const {root} = props

  return (
    <>
    <div>
      <strong>Prefer A/TQ Magazine by US Mail</strong>: {yesNo(root.receive_atq)}
    </div>
    {root.receive_atq &&
      <div style={{fontSize: 'smaller'}}>A/TQ is published quarterly. A deliverable address, active membership and other factors affect whether we mail you the magazine.</div>
    }

    {root.new_membership_package_sent_date &&
      <div>
        <strong>New Membership Package Sent</strong>: {root.new_membership_package_sent_date}
      </div>
    }
    </>
  )
}

function Donation(props) {
  const {donation} = props

  if (!donation || donation.length === 0) {return null}
  return (
    <div>
      <strong>Receipts:</strong>
      <ul>
      {donation.map(item => {
        return <li key={item.pk}>A donation of ${item.amountReceived} on {moment(item.created_at).format("MMMM Do YYYY")} <a href={item.receiptUrl}>[receipt]</a></li>
      })}
      </ul>
      <div>Thank you for your donations to support the A/TA</div>
    </div>
  )
}

function Convention(props) {
  const {registration} = props
  if (!registration || !registration[0]) {return null}
  return (
    <div>
      <strong>Convention Attendance</strong>: {registration.map(reg => reg.year).sort().join(', ')}
    </div>
  )
}

function Military(props) {
  const {military} = props
  // Change the logic for None at the same time
  if (!military || !military.service || military.service === 'none') {return null}
  return (
    <div>
      <span><strong>Military</strong>: </span>
      <span>
        {military.service_name}{military.rank !== 'none' && <span>, {military.rank_name}</span>}{military.status === 'retired' && <span> ({military.status_name})</span>} {military.base !== 'none' && military.base !== 'other' && military.base_name && <span> - {military.base_name}</span>} {military.base === 'other' && military.base_other && <span> - {military.base_other}</span>}
      </span>
    </div>
  )
}

function Civilian(props) {
  const {civilian} = props
  // Change the logic for None at the same time
  if (!civilian || !civilian.service || civilian.service === 'none') {return null}
  return (
    <div>
      <strong>Civilian</strong>: {civilian.service_name}{civilian.rank_abbreviation !== 'none' && <span>, {civilian.rank_abbreviation}</span>} {civilian.base !== 'none' && civilian.base !== 'other' && civilian.base_name && <span> - {civilian.base_name}</span>} {civilian.base === 'other' && civilian.base_other && <span> - {civilian.base_other}</span>}
    </div>
  )
}

function Private(props) {
  const {root} = props
  // Change the logic for None at the same time
  if (!root.has_private) {return null}
  return (
    <div>
      <strong>Non-Government</strong>: {root.private_organization}{root.title && <span>, {root.title}</span>}
    </div>
  )
}

AccountSummary.contextType = PersonContext
export default AccountSummary
