import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Redirect, Link } from "react-router-dom";

import Loading from '../../Loading'
import ErrorMessage from '../../ErrorMessage'
import ErrorContainer from '../../ErrorContainer';
import EmptyCart from '../../controls/EmptyCart';
//
// import PersonalInformation from './PersonalInformation'
// import ServiceInformation from './ServiceInformation'
import BanquetInformation from './BanquetInformation'
import BanquetConfirmation from './BanquetConfirmation'
import Personal from './Personal'
import GuestAttendeeInformation from './GuestAttendeeInformation'
import FirstTimeAttendee from './FirstTimeAttendee'
import {PersonContext} from '../../../classes/context.js';

import {TXT_REGISTRATION_PREAMBLE} from '../../../constants'

class Checkout extends Component {

  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      form: React.createRef(),
      isValid: true,
      submit: false,
      emptyCardRedirect: false,
    }
  }

  emptyCart() {
    this.context.person
      .beginEdit()
      .emptyCart()
      .endEdit(true)
      .store()
      .then(() => {
        this.setState({emptyCardRedirect: true})
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 409') {
          alert('Someone has updated this item since you loaded it. Reload to see the latest changes.')
        } else {
          alert(error.message)
        }
      })
  }

  handleChange(event) {
    const person = this.context.person

    if (!person.editItem) {person.beginEdit()}
    person.handleChange(event)
  }

  handleSubmit() {

    const person = this.context.person
    if (!person.editItem) {
      person.beginEdit()
    }
    person
      .endEdit(true)
      .store()
      .then(() => {
        this.setState({submit: true})
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 409') {
          alert('Someone has updated this item since you loaded it. Reload to see the latest changes.')
        } else {
          alert(error.message)
        }
      })
  }

  render() {

    const {person} = this.context
    if (this.state.emptyCardRedirect) {
      return (<Redirect to="/catalog" />)
    }

    const {loadFailed, error} = this.state;

    if (error) {
      return <ErrorContainer title="Convention Registration - Checkout" error={error} />
    } else if (loadFailed) {
      return <ErrorContainer title="Convention Registration - Checkout" error={{message:'The shopping cart failed to load.'}} />
    } else if (person.isLoading || !person.isInitialized) {
      return <Loading title="Convention Checkout"
                      subtitle={TXT_REGISTRATION_PREAMBLE}/>;
    }


    if (!person.item.cart || !person.item.cart.primaryRegistrationType) {
      return (
        <EmptyCart
          title="Convention Registration - Checkout"
          subtitle={TXT_REGISTRATION_PREAMBLE}
          to="/catalog"
          adminTo={"/admin/catalog/" + person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1)}
          isAdmin={this.props.isAdmin}
        />
      )
    }

    if (this.state.submit) {
      if (this.props.isAdmin) {
        return (
          <Redirect to={"/admin/payment/" + person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1)}/>
        )
      }
      return (
        <Redirect to="/payment"/>
      )

    }

    // Determine guest settings
    let hasGuest = false
    let hasGuestBanquet = false

    if (person.item.cart.secondaryItems) {
      person.item.cart.secondaryItems.forEach((item) => {
        hasGuest = hasGuest || item.isGuestActivity
        hasGuestBanquet = hasGuestBanquet || (item.isGuestActivity && item.includesGuestBanquet)
      })
    }

    return (
      <section className="usa-section" style={{paddingTop: 10, borderTopWidth: 0}}>
        <div className="grid-container margin-y-0">
          <h2 className="margin-y-0">Convention Registration - Checkout</h2>
          <p className="usa-prose">{TXT_REGISTRATION_PREAMBLE}</p>
          <div className="grid-row">
            <div className="grid-col-12">
              <Button className="usa-button"
                      onClick={() => this.handleSubmit()}>
                Continue to Payment Information
              </Button>
              {!this.state.isValid &&
                <ErrorMessage message="Check for required fields" />
              }

              <CustomLink
                className="usa-button"
                to="/catalog"
                adminTo={"/admin/catalog/" + person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1)}
                isAdmin={this.props.isAdmin}
                label="Add More Items"
              />
              <Button
                onClick={() => this.emptyCart()}
                title="Empty Your Cart"
                aria-label="empty-your-cart"
                className="usa-button usa-button--unstyled"
                style={{textDecoration: 'none'}}
              >
                Empty Your Cart
              </Button>

              <Personal />
              <BanquetInformation />
              <BanquetConfirmation hasGuestBanquet={hasGuestBanquet}/>
              <GuestAttendeeInformation />
              <FirstTimeAttendee />
            </div>
          </div>

        <Form noValidate>


        </Form>
        </div>
      </section>
    )
  }
}

Checkout.contextType = PersonContext
export default Checkout;

function CustomLink(props) {
  let to = props.isAdmin ? props.adminTo : props.to
  return (
    <Link
      className="usa-button"
      to={to}
      >
      {props.label}
    </Link>
  )
}
