import React, { Component } from 'react';

import { CompanyContext } from '../../../classes/context'

import { ADD_POC, VIEW } from './CompanyGlobals'
import CompanySummary from './CompanySummary'
import CompanyControl from './CompanyControl'

import EditorPoc from './EditorPoc'
import EditorIpMembership from './EditorIpMembership'
import Editor from './Editor'
import ErrorContainer, {ERROR_409, ERROR_COMPANY_LOAD_FAILED} from '../../ErrorContainer';
import Loading from '../../Loading';
import {Section} from '../../sections/Section'

var moment = require('moment');

// const ERROR_409 = 'Someone has updated this item since you loaded it. Reload to see the latest changes.'
// const ERROR_PERSON_LOAD_FAILED = 'We failed to load the account information.'


class Company extends Component {

  constructor(props) {
    super(props);

    this.startEdit = this.startEdit.bind(this)
    this.endEdit = this.endEdit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.removeItem = this.removeItem.bind(this)

    this.state = {
      editMode: VIEW,
      error: null,
    }

  }

  startEdit(editMode) {
    this.context.company.beginEdit()
    this.setState({editMode: editMode})
  }

  endEdit(saveChanges, value) {
    let company = this.context.company

    // eslint-disable-next-line
    if (!saveChanges) {
      company.endEdit(false)
      this.setState({editMode: VIEW, error: null})
    }  else {

      if (this.state.editMode.mode === 'editPoc') {
        company.editItem.updated = [this.state.editMode.sk]
      }
      if (this.state.editMode.mode === 'editIpMembership') {
        company.editItem.updated = [this.state.editMode.sk]
      }
      if (this.state.editMode.adminMode === ADD_POC) {
        const newSk = moment().unix()
        company.editItem.new = [{type: 'roles', pk: company.editItem.root.pk, sk: 'role-' + newSk}]
        company.editItem.roles.push({
          pk: company.editItem.root.pk,
          sk: 'role-' + newSk,
          primary_email: value.primary_email
        })
      }
      if (this.state.editMode.adminMode === 'add-ip-membership') {
        const newSk = moment().unix()
        company.editItem.new = [{type: 'memberships', pk: company.editItem.root.pk, sk: 'membership-' + newSk}]
        company.editItem.memberships.push({
          pk: company.editItem.root.pk,
          sk: 'membership-' + newSk,
          membership_start_date: moment(value.membership_start_date).format()
        })
      }
      if (this.state.editMode.adminMode === 'align-end-dates') {
        company.editItem.align = [{type: 'align', endDate: moment(value.endDate).format()}]
      }
      company.endEdit(true)

      company
        .store()
        .then(() => {
          if (this.state.editMode.adminMode === 'align-end-dates') {
            company.get(company.id)
            .then(() => {
              this.setState({editMode: VIEW, error: null})
            })
          } else {
            this.setState({editMode: VIEW, error: null})
          }
        })
        .catch(error => {
          if (error.message === 'Request failed with status code 409') {
            const error = {message: ERROR_409}
            this.setState({editMode: VIEW, error: error})
          } else {
            this.setState({editMode: VIEW, error: error})
          }
        })
    }
  }

  removeItem(pk, sk, list) {
    const {company} = this.context
    company.beginEdit()
    company.editItem.deleted = [{pk: pk, sk: sk}]
    let index = company.editItem[list].findIndex(item => item.sk === sk)
    if (index === -1) {return}

    company.editItem[list].splice(index, 1)
    company
      .endEdit(true)
      .store()
      .then(() => {
        this.setState({editMode: VIEW, error: null})
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 409') {
          const error = {message: ERROR_409}
          this.setState({editMode: VIEW, error: error})
        } else {
          this.setState({editMode: VIEW, error: error})
        }
      })
  }

  handleChange(event) {
    const target = event.target
    const name = target.name.split('.')
    let value = target.type === 'checkbox' ? target.checked : target.value

    if (name === 'primary_email') {value = value.toLowerCase()}
    this.setState({
      [name]: value
    })
  }

  render() {
    let {company} = this.context
    let {editMode, error} = this.state

    if (company.isNew) {
      editMode.mode = 'edit'
      company.beginEdit()
    }

    if (error) {
      return (
        <ErrorContainer
          title="Error" error={error}
        />
      )
    } else if (company.loadFailed) {
      return (
        <ErrorContainer
          title="Error"
          error={{message:ERROR_COMPANY_LOAD_FAILED}}
        />
      )
    } else if ( company.isLoading || !company.isInitialized) {
      return (
        <Loading
          title="Industry Partner Profile"
          size="h1"
        />
      )
    }

    return (
      <Section>
        <div className="grid-container margin-y-0">
          <CompanyControl
            editMode={editMode}
            isNew={company.isNew}
            startEdit={this.startEdit}
            endEdit={this.endEdit}
            isAdmin={this.props.isAdmin}
          />
          <NoCompany company={company}/>
          <CompanySummary
            editMode={editMode}
            handleChange={this.handleChange}
            startEdit={this.startEdit}
            endEdit={this.endEdit}
            removeItem={this.removeItem}
          />
          <Editor
            editMode={editMode}
            startEdit={this.startEdit}
            endEdit={this.endEdit}
          />
          <EditorPoc
            editMode={editMode}
            startEdit={this.startEdit}
            endEdit={this.endEdit}
          />
          <EditorIpMembership
            editMode={editMode}
            startEdit={this.startEdit}
            endEdit={this.endEdit}
          />
        </div>
      </Section>
    )
  }
}

function NoCompany(props) {

  const {company} = props
  if (!company.isNew) {return null}

  return (

    <div className="margin-y-2">
      <h2 className="usa-heading-alt margin-y-0">
        {company.item.root.id}
      </h2>

      <div className="usa-prose">
        <div className="usa-alert usa-alert--info">
          <div className="usa-alert__body">
            You are creating a new Industry Partner
          </div>
        </div>
      </div>
    </div>

  )
}

Company.contextType = CompanyContext;
export default Company;
