import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { API } from 'aws-amplify';
import ErrorMessage from '../../ErrorMessage';
import {PersonContext} from '../../../classes/context.js'

import { OnePrice, NonMemberPrice, MemberPrice
        } from './CatalogItemComponents'

var moment = require('moment');


class CatalogItem extends Component {

  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.addItem = this.addItem.bind(this);

    this.state = {
        show: false,
        isSignedIn: false,
        showSignIn: false,
        autoRenewMembership: true,
        waiting: false,
        errorMessage: null,
      };
  }

  handleClose(updateParent) {
    this.setState({ show: false });
    if (updateParent) {this.props.selectionUpdated();}
  }
  handleShow() {
    this.setState({ show: true });
  }



  handleInputChange(event) {
      const target = event.target;
      const name = target.name;
      let value = target.type === 'checkbox' ? target.checked : target.value;

      if (value === '') {value = null}

      let person = this.context.person
      if (!person.editItem) {
        person.beginEdit()
      }
      person.editItem.cart[name] = value
      person
        .endEdit(true)
        .store()
        .then(response => {
          this.setState({changed: moment()})
        })
        .catch(error => {
          if (error.message === 'Request failed with status code 409') {
            alert('Someone has updated this item since you loaded it. Reload to see the latest changes.')
          } else {
            alert(error.message)
          }
        });
      if (name === 'exhibitorOrganization') {
        const organizationProvided = (value !== '0')
        const enableButtons = (this.state.organizationRequired && organizationProvided) ||
                               !this.state.organizationRequired

        this.setState({enableButtons: enableButtons,
                       organizationProvided: organizationProvided})
      }

  }

  addItem(includeMembership) {

    if (this.props.registrationType.requiresExecCodeValidation) {
      this.setState({
        waiting: true,
        errorMessage: null,
      }, () => {
        let params = { // OPTIONAL
            headers: {}, // OPTIONAL
            body: {execLastName: this.context.person.item.cart.execAssistantLastName, execAssistantCode: this.context.person.item.cart.execAssistantCode}
        }

        API.post('execsupport', '/execsupport', params).then(response => {
            if (response.result === 'success') {

              this.setState({
                waiting: false,
                errorMessage: null,
              })

//              this.props.updateRegistrant({execRegistrationId: response.execRegistrationId, execFullName: response.execFullName})
              this.doAddCart(includeMembership)
            } else {
              console.log(response)
              this.setState({
                errorMessage: 'The code and name do not match.',
                waiting: false,
              })

            }
        }).catch(error => {
          this.setState({
            errorMessage: 'The code and name do not match',
            waiting: false,
          })
          console.log(error)
        })
      })

    } else {
      // If we added a full guest registration, remove any previous individual
      // guest items.
      if (this.props.registrationType.sk === this.props.relationships.isFullGuest) {
        this.context.person
          .beginEdit()
          .removePartialGuestItems()
          .endEdit(true)
          // Not calling store because we do it in doAddCart.
      }

      this.doAddCart(includeMembership)
    }
  }

  doAddCart(includeMembership) {
    let person = this.context.person
    const handleChange = person.handleChange.bind(person)

    person.beginEdit()

    let cart = []
    const {registrationType} = this.props
    if (registrationType.type === 'primary') {
      cart = [
        {name: 'cart.primaryRegistrationType', value: registrationType, type: 'input'},
        {name: 'cart.includeMembership', value: includeMembership, type: 'input'},
        {name: 'cart.relationships', value: this.props.relationships, type: 'input'},
        {name: 'cart.isValidConventionMembership', value: this.isValidConventionMembership(), type: 'input'},
      ]
    } else {
      // secondary
      cart = [
        {name: 'cart.secondaryItems', value: registrationType, type: 'map'},
      ]
      // Handles the case where the membership is added when the secondary
      // registration type is selected.
      if (includeMembership) {
        cart.push(
          {name: 'cart.includeMembership', value: includeMembership, type: 'input'},
        )
      }
    }

    for (const i in cart) {
      handleChange({target: {name: cart[i].name,  value: cart[i].value, type: cart[i].type}})
    }

    person
      .endEdit(true)
      .store()
      .then(response => {
        this.setState({changed: moment()})
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 409') {
          alert('Someone has updated this item since you loaded it. Reload to see the latest changes.')
        } else {
          alert(error.message)
        }
      })

    this.handleClose(true)
  }

  isValidConventionMembership() {
    const {person} = this.context
    if (person.item.cart.memberPricing) return true
    if (person.item['membership-summary'].isLifeMember) return true
    if (!person.item['membership-summary'].lastDayOfMembership) return false

    if (person.item['membership-summary'].lastDayOfMembership) {
      const lastDay = moment(person.item['membership-summary'].lastDayOfMembership)
      return (lastDay.isAfter(moment('2021-10-27')))
    }

    return false
  }

  render() {

    const {person} = this.context

    if (person.isLoading) return null;
    const isValidConventionMembership = this.isValidConventionMembership()

    let modalStyle = {
        background: '#fff',
        border: '#b40808',
        borderRadius: '5px',
        marginLeft: '-200px',
        textAlign:'center',
        position: 'fixed',
        left: '45%',
        top: '20%',
        zIndex: 11,
        width: '500px',
        minHeight: '250px',
        boxShadow:'0 5px 10px rgba(0,0,0,0.3)',
        transform: 'translate(0, 0)',
        transition: 'transform 0.3s ease-out',
        outline: 'none',
        pointEvents: 'auto',

      };

    // Specific to guest banquet
    if (this.props.registrationType.requiresGuestBanquet === true && this.props.hasGuestBanquet === false) return  null

    let memberPrice, fullPrice, additionalAnnualMembership
    if (this.props.registrationType.type === 'secondary') {
      memberPrice =
        (this.props.registrationType.priceMatchesRegistration &&
         this.props.registrationType.memberPrice !== 0) ?
        person.item.cart.primaryRegistrationType.memberPrice :
        this.props.registrationType.memberPrice

      fullPrice =
        (this.props.registrationType.priceMatchesRegistration &&
         this.props.registrationType.fullPrice !== 0) ?
        person.item.cart.primaryRegistrationType.fullPrice :
        this.props.registrationType.fullPrice

      additionalAnnualMembership = person.item.cart.primaryRegistrationType.additionalAnnualMembership
    } else {
      memberPrice = this.props.registrationType.memberPrice
      fullPrice = this.props.registrationType.fullPrice
      additionalAnnualMembership = this.props.registrationType.additionalAnnualMembership
    }
    var onePrice = (memberPrice === fullPrice);


    // Look for the full guest item in the cart
    var isFullGuestInCart = false
    if (person.item.cart.secondaryItems) {
      for (const item of person.item.cart.secondaryItems) {
        if (item.sk === this.props.relationships.isFullGuest) {
          isFullGuestInCart = true
        }
      }
    }

    // Determine if the item is already in the cart.
    var isInCart = false
    if (person.item.cart.secondaryItems &&
        person.item.cart.secondaryItems.findIndex(item => item.sk === this.props.registrationType.sk) !== -1) {
      isInCart = true
    }
    // If FullGuest is in the cart, disable the buttons for all the remaining
    // individual guest items.
    if (person.item.cart.secondaryItems) {
      for (const item of person.item.cart.secondaryItems) {
        if (item.sk === this.props.registrationType.sk ||
            (isFullGuestInCart && this.props.registrationType.isGuestActivity)) {
          isInCart = true
        }
      }
    }

    let enableButtons = true

    // These checks ensure that the modal data has been selected.
    if (this.props.registrationType.isExhibitor) {
      enableButtons = false
      if (person.item.cart.exhibitorOrganization && person.item.cart.exhibitorOrganization !== '0') {
        enableButtons = true
      }
    }
    // } else if (this.props.registrationType.isSpeaker && !this.props.registrationType.isSpeakerSingleDay) {
    //   if ('arrival_day' in this.props.registrant.person && this.props.registrant.person.arrival_day !== '0'  &&
    //       'arrival_time' in this.props.registrant.person && this.props.registrant.person.arrival_time !== '0' &&
    //       'departure_day' in this.props.registrant.person && this.props.registrant.person.departure_day !== '0'  &&
    //       'departure_time' in this.props.registrant.person && this.props.registrant.person.departure_time !== '0') {
    //
    //     enableButtons = true
    //   }
    // } else if (this.props.registrationType.isSpeaker && this.props.registrationType.isSpeakerSingleDay) {
    //   if ('arrival_day' in this.props.registrant.person && this.props.registrant.person.arrival_day !== '0'  &&
    //       'arrival_time' in this.props.registrant.person && this.props.registrant.person.arrival_time !== '0' ) {
    //
    //     enableButtons = true
    //   }
    // } else if (this.props.registrationType.isExecSupport) {
    //   if ('exec_assistant_code' in this.props.registrant.person &&
    //       this.props.registrant.person.exec_assistant_code !== '' && this.props.registrant.person.exec_assistant_code !== null &&
    //       'exec_last_name' in this.props.registrant.person &&
    //       this.props.registrant.person.exec_last_name !== '' && this.props.registrant.person.exec_last_name !== null ) {
    //
    //     enableButtons = true
    //   }
    // } else {
    //   enableButtons = true
    // }

    const footerStyle = {
      borderTopWith: '1px',
      borderTopStyle: 'solid',
      borderTopcolor: '#b40808',
      paddingTop: 8,
      textAlign: 'right',
      marginBottom: 12,
    }

    const priceStyle = {
      paddingTop: 8,
      textAlign: 'right',
      paddingRight: 10
    }

    return (
<>
      <div id="card" style={{display: 'inline-block', verticalAlign: 'top', margin: 10, marginRight: 10, marginBottom: 10}}>
        <div id="image-block" style={{display: 'inline-block', verticalAlign: 'top', width:60}}>
          <i className={this.props.registrationType.icon} ></i>
        </div>
        <div id="content-block" style={{width: 320, display: 'inline-block', verticalAlign: 'top'}}>
          <div id="detail-block" style={{display: 'inline-block', verticalAlign: 'top', marginLeft: 10, minHeight: 180}}>
            <div id="title-block" style={{verticalAlign: 'top'}}>
                <h2 style={{marginTop: 0, marginBottom: 5}}>{this.props.registrationType.name}</h2>
            </div>
            <div id="body-block" className="usa-prose">
                {this.props.registrationType.description}
            </div>
          </div>
          {this.props.registrationType.type === 'primary' &&
          <>
          {isValidConventionMembership &&
            <div id="price-block" style={priceStyle} >
              <p className="usa-prose"><strong>with membership: ${ memberPrice }</strong></p>
            </div>
          }
          {!isValidConventionMembership &&
            <div id="price-block" style={priceStyle} >
              <p className="usa-prose"><strong>price: ${ fullPrice }<br /> with membership: ${ memberPrice }</strong></p>
            </div>
          }
            </>
          }
          {this.props.registrationType.type === 'secondary' &&
            <>
            {person.item.cart.memberPricing &&
              <div id="price-block" style={priceStyle} >
                <p className="usa-prose"><strong>with membership: ${ memberPrice }</strong></p>
              </div>
            }
            {!person.item.cart.memberPricing &&
              <div id="price-block" style={priceStyle} >
                <p className="usa-prose"><strong>price: ${ fullPrice }</strong><br />
                {memberPrice !== fullPrice &&
                  <strong>members pay less</strong>
                }
              </p>
              </div>
            }
            </>
          }
          <div id="footer-block">
            <div style={footerStyle}>
              <Button
                title="Add Item"
                aria-label="Add Item"
                className="usa-button"
                disabled={isInCart}
                name={this.props.registrationType.sk}
                onClick={() => this.handleShow(true)}
              >
                Select
              </Button>
            </div>
          </div>
        </div>

        <Modal style={modalStyle} size="lg" show={this.state.show} onHide={() => this.handleClose(true)}>
          <Modal.Header closeButton className='modal-header-additional'>
            <h2 style={{float:'left', maxWidth: '80%', marginLeft: 10, textAlign: 'left'}}>{this.props.registrationType.name}</h2>
          </Modal.Header>
          <Modal.Body className='modal-body'>

            <div class="grid-row grid-gap">
              <TravelInformation include={this.props.registrationType.isSpeaker}
                                 isSpeakerSingleDay = {this.props.registrationType.isSpeakerSingleDay}
                                 conventionDays={this.props.conventionDays}
                                 handleInputChange = { this.handleInputChange }
                                 registrant={this.props.registrant}
                                 defaultValueArrivalDay={person.item.cart.arrivalDay}
                                 defaultValueArrivalTime={person.item.cart.arrivalTime}
                                 defaultValueDepartureDay={person.item.cart.departureDay}
                                 defaultValueDepartureTime={person.item.cart.departureTime}
              />

              <IndustryInformationDay
                include={this.props.registrationType.isIndustryDayOnly}
                description={this.props.registrationType.description}
              />

              <ExhibitorOrganization include={this.props.registrationType.isExhibitor}
                                     exhibitors={this.props.exhibitors}
                                     defaultValue={person.item.cart.exhibitorOrganization}
                                     handleInputChange = { this.handleInputChange }
                                     registrant={this.props.registrant}
              />

              <ExecCode include={this.props.registrationType.isExecSupport}
                        handleInputChange = { this.handleInputChange }
                        registrant={this.props.registrant}
                        defaultValueName={person.item.cart.execAssistantLastName}
                        defaultValueCode={person.item.cart.execAssistantCode}
              />

              <OnePrice price={ fullPrice }
                        onePrice = { onePrice }
                        addItem = { this.addItem }
                        handleInputChange = { this.handleInputChange }
                        id = { this.props.registrationType.id }
                        registrationType = {this.props.registrationType}
                        registrant={this.props.registrant}
                        includeNewMembership = {false}
                        includeMembership = {person.item.cart.includeMembership}
                        additionalAnnualMembership= { additionalAnnualMembership }
                        autoRenewMembership = {person.item.cart.autoRenewMembership}
                        isActiveMember = {person.item['membership-summary'].isActiveMember}
                        isLifeMember = {person.item['membership-summary'].isLifeMember}
                        isValidConventionMembership={isValidConventionMembership}
                        enableButtons={enableButtons}
                        waiting={this.state.waiting}
              />

              <MemberPrice price= { memberPrice }
                           additionalAnnualMembership= { additionalAnnualMembership }
                           onePrice = { onePrice }
                           addItem = { this.addItem }
                           handleInputChange = { this.handleInputChange }
                           id = { this.props.registrationType.id}
                           registrationType = {this.props.registrationType}
                           isCorporateMembership={person.item['membership-summary'].isIndividualCorporateMember}
                           includeNewMembership = {true}
                           includeMembership = {person.item.cart.includeMembership}
                           autoRenewMembership = {person.item.cart.autoRenewMembership}
                           isActiveMember = {person.item['membership-summary'].isActiveMember}
                           isLifeMember = {person.item['membership-summary'].isLifeMember}
                           isValidConventionMembership={isValidConventionMembership}
                           enableButtons={enableButtons}
                           waiting={this.state.waiting}
               />

              <NonMemberPrice price= { fullPrice }
                               additionalAnnualMembership= { additionalAnnualMembership }
                               onePrice = { onePrice }
                               addItem = { this.addItem }
                               handleInputChange = { this.handleInputChange }
                               id = { this.props.registrationType.id}
                               registrationType = {this.props.registrationType}
                               registrant={this.props.registrant}
                               includeNewMembership = {false}
                               includeMembership = {person.item.cart.includeMembership}
                               autoRenewMembership = {person.item.cart.autoRenewMembership}
                               isActiveMember = {person.item['membership-summary'].isActiveMember}
                               isLifeMember = {person.item['membership-summary'].isLifeMember}
                               isValidConventionMembership={isValidConventionMembership}
                               enableButtons={enableButtons}
                               waiting={this.state.waiting}
                />
              </div>
            <ErrorMessage message={this.state.errorMessage} />
          </Modal.Body>
          <Modal.Footer style={{minHeight: 36}}>
            <span> </span>
          </Modal.Footer>
        </Modal>


  </div>

</>
    )
  }
}


function IndustryInformationDay(props) {
  if (!props.include) return null

  return (
    <div class="grid-col-10 grid-offset-1" style={{ marginBottom: 32 }}>
      <p className='usa-prose'>{props.description}</p>
    </div>

  )
}

function TravelInformation(props) {

  if (!props.include) return null;

  const timePicklist = [
    {key: '0', value: 'Select a Time'},
    {key: 'any', value: 'Not Decided Yet'},
    {key: 'morning', value: 'Morning'},
    {key: 'afternoon', value: 'Afternoon'},
    {key: '10', value: 'Around 10:00h'},
    {key: '12', value: 'Around 12:00h'},
    {key: '14', value: 'Around 14:00h'},
    {key: '16', value: 'Around 16:00h'},
    {key: 'later', value: 'After 10:00h'},
  ]

  return (
    <>

    <div class="grid-col-5 grid-offset-1" style={{ marginBottom: 32 }}>
      <div className="usa-label">Arrival Information</div>
      <Form.Control
        className="usa-select"
        style={{ backgroundColor: "#eeeeee" }}
        as="select"
        name="arrivalDay"
        onChange={props.handleInputChange}
      >
        <option key='0' value='0'>Select a Day</option>
        {props.conventionDays.map((e) => {
          return <option key={e.sk} selected={e.sk === props.defaultValueArrivalDay} value={e.sk}>{e.short}</option>;
        })}
      </Form.Control>

      <Form.Control
        className="usa-select"
        style={{ backgroundColor: "#eeeeee" }}
        as="select"
        name="arrivalTime"
        onChange={props.handleInputChange}
      >
      {timePicklist.map((e) => {
        return <option key={e.key} selected={e.key === props.defaultValueArrivalTime} value={e.key}>{e.value}</option>;
      })}
      </Form.Control>

    </div>

    { !props.isSpeakerSingleDay &&
      <div class="grid-col-5" style={{ marginBottom: 32 }}>
        <div className="usa-label">Depature Information</div>
        <Form.Control
          className="usa-select"
          style={{ backgroundColor: "#eeeeee" }}
          as="select"
          name="departureDay"
          onChange={props.handleInputChange}
        >
          <option key='0' value='0'>Select a Day</option>
            {props.conventionDays.map((e) => {
              return <option key={e.sk} selected={e.sk === props.defaultValueDepartureDay} value={e.sk}>{e.short}</option>;
            })}
        </Form.Control>

        <Form.Control
          className="usa-select"
          style={{ backgroundColor: "#eeeeee" }}
          as="select"
          name="departureTime"
          onChange={props.handleInputChange}
        >
        {timePicklist.map((e) => {
          return <option key={e.key} selected={e.key === props.defaultValueDepartureTime} value={e.key}>{e.value}</option>;
        })}
        </Form.Control>

      </div>
    }



    </>

  )
}

function ExhibitorOrganization(props) {

  if (!props.include) {return null}

  const defaultSelected = props.defaultValue ?? '0'
  return (
    <div className="grid-col-10 grid-offset-1" style={{ marginBottom: 32 }}>
      <div className="usa-label">Exhibiting Company or Organization</div>
      <Form.Control
        className="usa-select"
        style={{ backgroundColor: "#eeeeee" }}
        as="select"
        name="exhibitorOrganization"
        onChange={props.handleInputChange}
        value={defaultSelected}
      >
        <option key="0" value="0">
          Select One
        </option>
        {props.exhibitors.map((e) => {
          return <option key={e.pk} value={e.name}>{e.name}</option>;
        })}      </Form.Control>
      <small>
        If you do not see your company, please contact ata@atalink.org for
        assistance
      </small>
    </div>
  )
}




function ExecCode(props) {

  if (!props.include) return null;
  const editingStyle = {
    backgroundColor: '#eeeeee',
  };


  return (
    <>
    <div class="grid-col-6 grid-offset-1" style={{ marginBottom: 32 }}>
      <div className="usa-label">Executive Last Name</div>
      <Form.Control
        plaintext
        className='usa-input'
        name='execAssistantLastName'
        defaultValue={props.defaultValueName}
        style={editingStyle}
        onChange={props.handleInputChange}>
      </Form.Control>
    </div>

    <div class="grid-col-4" style={{ marginBottom: 32 }}>
      <div className="usa-label">Assistant Code</div>
      <Form.Control
        plaintext
        className='usa-input'
        name='execAssistantCode'
        defaultValue={props.defaultValueCode}
        style={editingStyle}
        onChange={props.handleInputChange}>
      </Form.Control>
    </div>

    </>
  )
}


CatalogItem.contextType = PersonContext
export default CatalogItem;


// <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
//   <Modal.Header closeButton>
//     <Modal.Title>{this.props.registrationType.name}</Modal.Title>
//   </Modal.Header>
//   <Modal.Body>
//     <TravelInformation include={this.props.registrationType.isSpeaker}
//                        isSpeakerSingleDay = {this.props.registrationType.isSpeakerSingleDay}
//                        conventionDays={this.props.conventionDays}
//                        handleInputChange = { this.handleInputChange }
//                        registrant={this.props.registrant} />
//
//     <ExhibitorOrganization include={this.props.registrationType.isExhibitor}
//                            exhibitors={this.props.exhibitors}
//                            handleInputChange = { this.handleInputChange }
//                            registrant={this.props.registrant} />
//
//     <ExecCode include={this.props.registrationType.isExecSupport}
//               handleInputChange = { this.handleInputChange }
//               registrant={this.props.registrant} />
//
//     { (this.props.registrationType.isSpeaker ||
//        this.props.registrationType.isExhibitor) && <hr /> }
//
//     <Row>
//       <OnePrice price={ this.props.registrationType.fullPrice }
//                 onePrice = { onePrice }
//                 handleClose = { this.handleClose }
//                 addItem = { this.addItem }
//                 handleInputChange = { this.handleInputChange }
//                 id = { this.props.registrationType.id }
//                 registrationType = {this.props.registrationType}
//                 registrant={this.props.registrant}
//                 includeNewMembership = {'this.props.registrant.cart.includeNewMembership'}
//                 autoRenewMembership = {this.state.autoRenewMembership}
//                 isActiveMember = true
//                 isLifeMember = {this.props.registrant.person.individual_membership_is_life}
//                 isValidConventionMembership={this.props.registrant.is_valid_convention_membership}
//                 enableButtons={enableButtons}
//                 waiting={this.state.waiting}
//                 />
//
//       <NonMemberPrice price= { this.props.registrationType.fullPrice }
//                       additionalAnnualMembership= { this.props.registrationType.additionalAnnualMembership }
//                       onePrice = { onePrice }
//                       handleClose = { this.handleClose }
//                       addItem = { this.addItem }
//                       handleInputChange = { this.handleInputChange }
//                       id = { this.props.registrationType.id}
//                       registrationType = {this.props.registrationType}
//                       registrant={this.props.registrant}
//                       includeNewMembership = {this.props.registrant.cart.includeNewMembership}
//                       autoRenewMembership = {this.state.autoRenewMembership}
//                       isActiveMember = {this.props.registrant.person.individual_membership_is_active}
//                       isLifeMember = {this.props.registrant.person.individual_membership_is_life}
//                       isValidConventionMembership={this.props.registrant.is_valid_convention_membership}
//                       enableButtons={enableButtons}
//                       waiting={this.state.waiting}
//                     />
//
//       <MemberPrice price= { this.props.registrationType.memberPrice }
//                    additionalAnnualMembership= { this.props.registrationType.additionalAnnualMembership }
//                    onePrice = { onePrice }
//                    handleClose = { this.handleClose }
//                    addItem = { this.addItem }
//                    handleInputChange = { this.handleInputChange }
//                    id = { this.props.registrationType.id}
//                    registrationType = {this.props.registrationType}
//                    registrant={this.props.registrant}
//                    includeNewMembership = {this.props.registrant.cart.includeNewMembership}
//                    autoRenewMembership = {this.state.autoRenewMembership}
//                    isActiveMember = {this.props.registrant.person.individual_membership_is_active}
//                    isLifeMember = {this.props.registrant.person.individual_membership_is_life}
//                    isValidConventionMembership={this.props.registrant.is_valid_convention_membership}
//                    enableButtons={enableButtons}
//                    waiting={this.state.waiting}
//                    />
//     </Row>
//     <ErrorMessage message={this.state.errorMessage} />
//   </Modal.Body>
//   <Modal.Footer>
//     { this.props.authState !== 'signedIn' &&
//       <SignInFooter handleShowSignIn={this.handleShowSignIn} />
//     }
//   </Modal.Footer>
// </Modal>


// <div className="usa-media-block tablet:grid-col" style={{display: 'inline-block', verticalAlign: 'top', margin: 10}}>
//   <div style={{width: '18rem', borderWidth: 1, borderColor: 'green', borderStyle: 'solid', padding:10,  minHeight: 200}}>
//
//     <h2 style={{marginTop: 0}}>
//         {this.props.registrationType.name}
//     </h2>
//     <div><i class="fal fa-clipboard-user"></i></div>
//     <div className="usa-prose" style={{ minHeight: 100 }}>
//       <div>
//         {this.props.registrationType.description}
//       </div>
//       <div style={{textAlign: 'right'}}>${ this.props.registrationType.fullPrice }</div>
//     </div>
//     <div style={footerStyle}>
//       <Button
//         to="/virtual-seminars"
//         title="Virtual Seminats"
//         aria-label="virtual-seminars"
//         className="usa-button"
//       >
//       Select
//     </Button>
//     </div>
//   </div>
// </div>
