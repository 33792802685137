import React, { Component } from 'react'
import { NavLink, Link, Redirect, useLocation } from "react-router-dom"
import Loading from '../Loading'
import ErrorContainer from '../ErrorContainer'
import Bio from '../controls/Bio'
import IndustryPartnerList from '../controls/IndustryPartnerList'
import HallOfFameList from '../controls/HallOfFameList'
import ChapterList from '../controls/ChapterList'
import {navigation} from '../controls/menus'

import {ContentfulContext} from '../../classes/context'
import {PageLoad} from '../controls/Events'

const ReactMarkdown = require('react-markdown/with-html')

class BasicPage extends Component {

    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        page: null,
      };
    }

    componentDidMount() {

      if (!this.state.isLoaded) {
        const contentfulClient = this.context.contentfulClient

        contentfulClient.getEntries({'content_type': 'pageBasic', 'limit': 500})
        .then(entries => {
          const hallOfFamePages = entries.items.filter(page => page.fields.hallOfFameYear)
                                               .sort (function(a,b) {
                                                  if (a.fields.hallOfFameYear > b.fields.hallOfFameYear) return -1
                                                  if (a.fields.hallOfFameYear < b.fields.hallOfFameYear) return 1
                                                  return 0
                                                })
          const industryPartnerPages = entries.items.filter(page => page.fields.industryPartnerLegacyId)
                                               .sort (function(a,b) {
                                                  if (a.fields.headline < b.fields.headline) return -1
                                                  if (a.fields.headline > b.fields.headline) return 1
                                                  return 0
                                                })

          this.setState({
            isLoaded: true,
            pages: entries.items,
            hallOfFamePages: hallOfFamePages,
            industryPartnerPages: industryPartnerPages
          });
        })
        .catch(error => {
          console.log(error)
          this.setState({
            error: error
          })
        })
      }
    }

    render() {

      const { error, isLoaded, pages } = this.state;
      const { noSidebar } = this.props
      const basicSidebar = !(this.props.hallOfFameSidebar || this.props.industryPartnerSidebar) && !noSidebar

      if (error) {
        return <ErrorContainer title="Error" error={error} />
      } else if (!isLoaded) {
        return <Loading title=""
                        subtitle=""/>;
      }

      const regex = / /g
      let page
      if (this.props.pageId) {
        const pageIndex = pages.findIndex((page) => (page.sys.id === this.props.pageId));
        if (pageIndex === -1) {
          // The page was not found, which is an error.
          return <ErrorContainer title="Error" error={{message: "The requested page was not found " + this.props.pageId}} />
        }
        page = pages[pageIndex]
      }

      // Page name is actually the title, all lowercase with hyphens rather
      // than spaces
      if (this.props.pageName) {
        const pageIndex = pages.findIndex((page) => (this.props.pageName === (page.fields.headline.toLowerCase().replace(regex, '-'))))
        page = pages[pageIndex]
      }

      return (
        <>
        <SearchRequest />
        <PageLoad />
        <div className="grid-container" style={{paddingLeft: 0}}>

          <div className="grid-row">
            <div className="tablet:grid-col-9">
              <main id="main-content">
                <PageContent page={page} />
              </main>
            </div>
            <div className="tablet:grid-col-3">
              <div style={{paddingLeft: 8}}>
              {basicSidebar &&
                <Sidebar />
               }
              {this.props.hallOfFameSidebar &&
                <SidebarHallOfFame hallOfFamePages={this.state.hallOfFamePages}/>
              }
              </div>
            </div>
          </div>
        </div>
        </>
      )

    }
}

function SearchRequest() {
  const {search, pathname} = useLocation();

  if (!search) {return null}
  const keywords = search.split('=');

  // If there are other parameters on the URL, this means somebody (facebook)
  // added the parameter. Remove.
  if (keywords[0] !== '?search') {return <Redirect to={pathname} />}

  return <Redirect to={"/?search=" + keywords[1]} />
}

function PageContent(props) {
  const {page} = props

  return (
    <>
    <section className="usa-section" style={{paddingTop: 10, paddingBottom: 10}}>
      <div className="grid-container margin-y-0">
        <h2 className="margin-y-0" style={{paddingBottom: 10}}>{page.fields.headline}</h2>
        <div className="usa-prose">
          { page.fields.image && page.fields.image.fields.file &&
            <img alt={page.fields.headline} style={{float: 'left', marginBottom: '20px', marginRight: '20px'}} src={page.fields.image.fields.file.url} width="200" />
          }
          <ReactMarkdown source={page.fields.body} escapeHtml={false}/>
        </div>
      </div>
    </section>
    {(page.sys.id === 'pHkydPGNPE2NmwbGcoGGH' ||
      page.sys.id === '2GAeIqVGHZ1HF0esIBVv15' ||
      page.sys.id === '11U074De9SR8lBHnfAPzKQ' || // support staff
      page.sys.id === '1FeiaOwRoWxyBRWFnCXFsM' || // convention contacts
      page.sys.id === '3CPJcGVXnfogocRxsm7rir' || // general contact info
      page.sys.id === '2Tg1bDPoQILoAhm38kxs3j') &&
      page.fields.sectionReferences.map(subpage =>
        <Bio key={page.sys.id} bio={subpage} />
      )
    }

    {page.fields.subtype === 'Industry Partner' &&
     page.fields.sectionReferences &&
    <>
      <section className="usa-section" style={{paddingTop: 10, paddingBottom: 10}}>
        <div className="grid-container margin-y-0">
          <hr />
          <h2>Featured Product and Service </h2>
        </div>
      </section>
      {page.fields.sectionReferences.map(product =>
        <IndustryPartnerProduct key={page.sys.id} section={product} />
      )}
    </>
    }

    {page.fields.subtype === 'Industry Partner List' &&
      <IndustryPartnerList />
    }

    {page.fields.subtype === 'Chapter List' &&
      <ChapterList />
    }

    {page.fields.subtype === 'Hall of Fame' &&
      <HallOfFameList />
    }

    {page.fields.subtype === 'Registration Introduction' &&
      <section className="usa-section--dark" style={{marginLeft: 20, paddingTop: 20, paddingBottom: 10, }}>
        <div className="grid-container margin-y-0">

          <div className="grid-row grid-gap">
            <div className="tablet:grid-col-3">
              <main id="main-content">
                <img src="/images/convention-2021-logo.png" />
              </main>
            </div>
            <div className="tablet:grid-col-9">
            <p className="usa-prose">You will be asked to <strong>Sign In</strong> to your A/TA account before registering for the convention.
                    Doing this ensures that you get the best rate for the convention and for your hotel room. If you can't remember which
                    email address you used previously, contact us directly.</p>
              <Link
                to="/catalog"
                title="Begin Registration"
                aria-label="news"
                className="usa-button"
                style={{textDecoration: 'none', marginBottom: 20}}
              >
              Begin Registration
              </Link>

            </div>
          </div>


    </div>
  </section>
    }

    {page.fields.bodyContinue &&
      <section className="usa-section" style={{paddingTop: 10, paddingBottom: 10}}>
        <div className="grid-container margin-y-0">
          <div className="usa-prose">
          <ReactMarkdown source={page.fields.bodyContinue} escapeHtml={false}/>
          </div>
        </div>
      </section>
    }
    </>
  )
}

function SidebarHallOfFame(props) {

  const {hallOfFamePages} = props

  return (
  <>
    <h5 className="usa-heading-alt">Hall of Fame Awardees</h5>
    <nav aria-label="Secondary navigation">
      <ul className="usa-sidenav">
        {hallOfFamePages.map(item => {
            return (
            <li key={item.sys.id }className="usa-sidenav__item">
              <NavLink to={"/hall-of-fame/" +item.sys.id} activeClassName="usa-current">
                {item.fields.headline}
              </NavLink>
            </li>
          )}
        )}
      </ul>
    </nav>
  </>
)

}


function Sidebar() {

  const {pathname} = useLocation()
  const pathComponents = pathname.split('/')
  let location = '/' + pathComponents[1]

  // Hard coded for partner pages. If part 2 is 'partners', then don't add
  if (pathComponents[2] && pathComponents[2] !== 'partners') {
    location += '/' + pathComponents[2]
  }

  let sidebar = navigation.filter(topMenu => (
    topMenu.items.filter(menuItem => (menuItem.link === location)).length > 0  )
  )[0]

  // Check to see if any of the second tier items match
  if (!sidebar) {
    navigation.forEach(topMenu => {
      topMenu.items.forEach(item => {
        if (item.children && !sidebar) {
          if (item.children.filter(child => (child.link === location)).length > 0) {
            sidebar = topMenu
          }
        }
      })
    })
  }

  if (!sidebar) {
    return null
  }
  return (
  <>
    <h5 className="usa-heading-alt">{sidebar.parentLabel}</h5>
    <nav aria-label="Secondary navigation">
    <ul className="usa-sidenav">
    {sidebar.items.map((item, i) => {
      return (
      <>
      <li className="usa-sidenav__item">
        {item.link.substring(0, 4) === 'http' ?
          <a href={item.link} activeClassName="usa-current">{item.label}</a> :
          <NavLink to={item.link} activeClassName="usa-current">
            {item.label} {item.auth && <i className="fad fa-lock-alt"></i>}
          </NavLink>
        }
        {item.children && item.children.map(child => {
          return (
            <li key={child.order} className="usa-sidenav__sublist">
            <NavLink to={child.link} activeClassName="usa-current">
              {child.label}
            </NavLink>
            </li>
          )
        })
        }
      </li>
      </>)
    })}
    </ul>
    </nav>
  </>
)
}

function IndustryPartnerProduct(props) {
  const {section} = props

  return (
    <>
      <section id="test-section-id" className="usa-section margin-y-0" style={{paddingTop: 0, paddingBottom: 20}}>
      <div className="grid-container">
        <h3 className="font-heading-xl margin-y-0">{section.fields.title}</h3>
        <p className="usa-prose" >
        { section.fields.productImage && section.fields.productImage.fields.file &&
          <img alt={section.fields.title} style={{float: 'left', marginBottom: '20px', marginRight: '20px'}} src={section.fields.productImage.fields.file.url} width='200' />
        }
          <ReactMarkdown source={section.fields.body} escapeHtml={false} style={{minHeight: 350}}/>
        </p>
        <hr />
      </div>
    </section>
    </>
  )
}

BasicPage.contextType = ContentfulContext
export default BasicPage
