import React, { Component } from 'react'
import { API } from 'aws-amplify';

import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import ButtonActive from '../../ButtonActive'
import {ButtonStatic} from '../../sections/Section'
import ErrorMessage from '../../ErrorMessage';

import {PersonContext} from '../../../classes/context'

import RegistrationHistory from './RegistrationHistory'
import RegistrationDetail from './RegistrationDetail'

var moment = require('moment')

const modalStyle = {
  background: '#fff',
  border: '#b40808',
  borderRadius: '5px',
  marginLeft: '-200px',
  textAlign:'center',
  position: 'fixed',
  left: '45%',
  top: '20%',
  zIndex: 11,
  width: '500px',
  minHeight: '250px',
  boxShadow:'0 5px 10px rgba(0,0,0,0.3)',
  transform: 'translate(0, 0)',
  transition: 'transform 0.3s ease-out',
  outline: 'none',
  pointEvents: 'auto',
};

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class RegistrationSummary extends Component {

  constructor(props) {
    super(props)

    this.roomNeeded = this.roomNeeded.bind(this)
    this.handleGotoHotel = this.handleGotoHotel.bind(this)
    this.showHotelConfirmationModal = this.showHotelConfirmationModal.bind(this)
    this.handleConfirmationCode = this.handleConfirmationCode.bind(this)
    this.handleHotelCancellation = this.handleHotelCancellation.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.removeConfirmationCode = this.removeConfirmationCode.bind(this)

    this.state = {
      setupHotel: false,
      confirmationCode: false,
      hotelConfirmationCode: ''
    }

  }

  keyValue(item, key, format) {
    const {person} = this.context

    switch (item) {
      case 'root':
        if (!person.editItem) {return null}
        if (!person.editItem[item]) {return null}

        if (format) {
          return (typeof(person.editItem[item][key]) === 'undefined' ||
                  !person.editItem[item][key] ?
                  null :
                  moment(person.editItem[item][key]).format(format))
        }

        return (typeof(person.editItem[item][key]) === 'undefined' ? null : person.editItem[item][key])

      default:
       return null
    }
  }

  handleChange(event) {
    if (!this.context.person.editItem) {
      this.context.person.beginEdit()
    }

    this.setState({hotelConfirmationCode: event.target.value})
    this.context.person.handleChange(event)
  }

  handleGotoHotel() {
    this.setState({
      setupHotel: false
    })
  }

  showHotelConfirmationModal(visible) {
    this.setState({confirmationCode: visible})
  }

  handleConfirmationCode() {
    let {person} = this.context
//    person.beginEdit()
    person.editItem.cart.hotelStatus =
        person.editItem.cart.hotelStatus.replace('not-confirmed', 'confirmed')
    person
      .endEdit(true)
      .store()
      .then(this.setState(
        {setupHotel: false,
        confirmationCode: false,
        redirect: true})
      )
      .catch(error => {
        console.log(error)
        this.setState({
          errorMessage: "We were not able to update your confirmation code",
        })
      })
  }

  handleHotelCancellation() {
    let {person} = this.context
    person.beginEdit()
    this.context.person.editItem.cart.hotelStatus = 'cancelled'
    delete this.context.person.editItem.cart.hotelConfirmationCode
    this.context.person
      .endEdit(true)
      .store()
      .then(this.setState(
        {setupHotel: false,
        confirmationCode: false,
        redirect: false})
      )
      .catch(error => {
        console.log(error)
        this.setState({
          errorMessage: "We were not able to update your confirmation status",
        })
      })
  }

removeConfirmationCode() {
  let {person} = this.context
  person.beginEdit()
  this.context.person.editItem.cart.hotelStatus = 'requested-not-confirmed'
  delete this.context.person.editItem.cart.hotelConfirmationCode
  person
    .endEdit(true)
    .store()
    .then(this.setState(
      {setupHotel: false,
      confirmationCode: false,
      redirect: false})
    )
    .catch(error => {
      console.log(error)
      this.setState({
        errorMessage: "We were not able to update your confirmation information",
      })
    })
  }

  roomNeeded(doubleRoom) {

    let {person} = this.context

    this.setState({
      roomNeededActive: true,
      setupHotel: true,
      waitingForPasskey: true,
      errorMessage: '',
      toHotel: false
    })

    let request = {
      headers: {},
      body: {
        cart: person.item.cart,
        root: person.item.root,
        address: person.item.address,
        phoneNumber: person.item['phone-primary'].phone_number,
        doubleRoom: doubleRoom
      }
    }

    API.post('hotel', '/hotel', request)
    .then(response => {
      this.setState({
        errorMessage: '',
        roomNeededActive: false,
        hotelBridgeId: response.hotelBridgeId,
        hotelUrl: response.url
      })

      person.beginEdit()
      if (doubleRoom) {
        person.editItem.cart.hotelStatus = 'requested-double-not-confirmed'
      } else {
        person.editItem.cart.hotelStatus = 'requested-not-confirmed'
      }
      person.editItem.cart.hotelBridgeId = response.hotelBridgeId

      this.context.person
        .endEdit(true)
        .store()
        .then(response => {
          this.setState({ toHotel:
                'https://api.passkey.com/httpapi/RegLink?' +
                'PartnerID=10567978&' +
                'OP=GetForRedirectResWeb&' +
                'Version=4.00.02&' +
                'Mode=S&' +
                'Destination=02&' +
                'LastName=' + person.item.root.last_name + '&' +
                'BridgeID=' + person.item.cart.hotelBridgeId + '&' +
                  'Locale=EN_US',
                waitingForPasskey: false })
        })
        .catch(error => {
          console.log(error)
          this.setState({
            errorMessage: "We were not able to complete your hotel request",
            roomNotNeededActive: false,
          })
        })
    })
    .catch(error => {
      console.log(error)
      this.setState({
        errorMessage: error.message,
        roomNeededActive: false,
        setupHotel: false,
        waitingForPasskey: false,
      })
    })
  }

  render() {

    const {person} = this.context

    const waiting = false

    const hasCart = this.props.hasCart
    const includesIid = hasCart && (person.item.cart.primaryRegistrationType.isIndustryDayOnly ||
                        (person.item.cart.secondaryItems &&
                        person.item.cart.secondaryItems.filter(item => item.includesIndustryDay).length > 0))


    return (
      <>
      <div className="usa-prose">
        <hr />
        <div className="usa-line-length-example usa-prose">
          <h3><i className="fas fa-users-class"></i> Convention Information</h3>
          <RegistrationDetail
            isAdmin={this.props.isAdmin}
            hasCart={hasCart}
            person={person}
            hasCancellationRequest={this.props.hasCancellationRequest}
            showHotelModal={this.roomNeeded}
            showHotelConfirmationModal={this.showHotelConfirmationModal}
            hasCancellationConfirmed={this.props.hasCancellationConfirmed}
            handleHotelCancellation={this.handleHotelCancellation}
            removeConfirmationCode={this.removeConfirmationCode}
            includesIid={includesIid}
          />

          <RegistrationHistory
            isAdmin={this.props.isAdmin}
            convention={person.item.convention}
            firstTimeAttendee={person.item.cart.firstTimeAttendee}
          />


        </div>
      </div>

      {hasCart &&
        <>
        <Modal
          style={modalStyle}
          size="lg"
          show={this.props.showModal}
          onHide={() => this.props.updateModalVisibility(false)}>
          <Modal.Header
            closeButton
            className="modal-header-additional">
            <h2 style={{float:'left', maxWidth: '80%', marginLeft: 10, textAlign: 'left'}}>
              Request Cancellation
            </h2>
          </Modal.Header>
          <Modal.Body className="modal-body">

            <div
              className="grid-row grid-gap"
              style={{textAlign: 'left'}}>
              <div
                className="grid-col-10 grid-offset-1"
                style={{ marginBottom: 32 }}>
                <div className="usa-label">
                  Cancellation Reason
                </div>
                <Form.Control
                  plaintext
                  className="usa-input"
                  name="cancellationReason"
                  style={editingStyle}
                  onChange={this.props.handleChange}>
                </Form.Control>

                <div className="usa-prose margin-y-1">
                  Cancellations incur a fee.
                </div>

                <ButtonActive
                  label="Request Cancellation"
                  className="usa-button btn btn-primary"
                  onClick={() => this.props.requestCancellation()}
                  active={waiting}
                  disabled={waiting}
                  style={{marginTop: 20}}
                  />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer style={{minHeight: 18}}>
            <span>
            </span>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          show={this.props.showConfirmationModal}
          onHide={() => this.props.updateConfirmationModalVisibility(false)}>
          <Modal.Header
            closeButton
            className="modal-header-additional">
            <h2 style={{float:'left', maxWidth: '80%', marginLeft: 10, textAlign: 'left'}}>
              Confirm Cancellation
            </h2>
          </Modal.Header>
          <Modal.Body className="modal-body">

            <div
              className="grid-row grid-gap"
              style={{textAlign: 'left'}}>
              <div
                className="grid-col-10 grid-offset-1"
                style={{ marginBottom: 32 }}
              >
                <div className="usa-prose margin-y-2">
                  Confirming the cancellation will not change the receipts. You must do that directly in Stripe.
                </div>

                <ButtonStatic onClick={this.props.confirmCancellation} >
                  Confirm Cancellation
                </ButtonStatic>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer style={{minHeight: 18}}>
            <span>
            </span>
          </Modal.Footer>
        </Modal>

        <Modal
          style={modalStyle}
          size="lg"
          show={this.state.setupHotel}
          onHide={() => this.handleClose()}>
          <Modal.Header
            closeButton
            className="modal-header-additional">
            <h2 style={{float:'left', maxWidth: '80%', marginLeft: 10, textAlign: 'left'}}>
              Hotel Reservation
            </h2>
          </Modal.Header>
          <Modal.Body className="modal-body" style={{minHeight:200}}>

            <div className="grid-row grid-gap" style={{marginTop:20}}>
              <div className="grid-col-6 ">
                <div style={{marginBottom:16}}><i className="far fa-hotel fa-7x"></i></div>
                <div>
                  <i className="fal fa-keynote fa" style={{marginRight: 5}}></i>
                  <i className="fal fa-dumbbell fa" style={{marginRight: 5}}></i>
                  <i className="fal fa-swimming-pool fa" style={{marginRight: 5}}></i>
                  <i className="fal fa-spa fa" style={{marginRight: 5}}></i>
                  <i className="fal fa-coffee fa" style={{marginRight: 5}}></i>
                  <i className="fal fa-utensils fa" style={{marginRight: 5}}></i>
                  <i className="fal fa-glass-cheers fa" style={{marginRight: 5}}></i>
                </div>

              </div>
              <div className="grid-col-5 ">
                <div style={{marginBottom: 16}}>
                  {this.state.waitingForPasskey &&
                    <span>Transfering your contact information to the Convention hotel</span>
                  }
                  {!this.state.waitingForPasskey &&
                    <span>Your contact information has been transfered to the hotel booking website</span>
                  }
                </div>
                <Button
                  label="Open Hotel Site"
                  className="usa-button btn btn-primary"
                  name="openHotelWebsite"
                  target="_default"
                  href={this.state.toHotel}
                  onClick={() => this.handleGotoHotel()}
                  disabled={this.state.waitingForPasskey}
                >
                  Open Hotel Site
                </Button>
              </div>

            </div>
            <ErrorMessage message={this.state.errorMessage} />
          </Modal.Body>
          <Modal.Footer style={{minHeight: 36}}>
            <span>
            </span>
          </Modal.Footer>
        </Modal>

        <Modal
          style={modalStyle}
          size="lg"
          show={this.state.confirmationCode}
          onHide={() => this.showHotelConfirmationModal(false)}>
          <Modal.Header
            closeButton
            className="modal-header-additional">
            <h2 style={{float:'left', maxWidth: '80%', marginLeft: 10, textAlign: 'left'}}>
              Hotel Confirmation Code
            </h2>
          </Modal.Header>
          <Modal.Body className="modal-body" style={{minHeight:200}}>

            <div className="grid-row grid-gap" style={{marginTop:20}}>
              <div className="grid-col-6 ">
                <div style={{marginBottom:16}}><i className="far fa-hotel fa-7x"></i></div>
                <div>
                  <i className="fal fa-keynote fa" style={{marginRight: 12}}></i>
                  <i className="fal fa-dumbbell fa" style={{marginRight: 5}}></i>
                  <i className="fal fa-swimming-pool fa" style={{marginRight: 5}}></i>
                  <i className="fal fa-spa fa" style={{marginRight: 5}}></i>
                  <i className="fal fa-coffee fa" style={{marginRight: 5}}></i>
                  <i className="fal fa-utensils fa" style={{marginRight: 5}}></i>
                  <i className="fal fa-glass-cheers fa" style={{marginRight: 5}}></i>
                </div>

              </div>
              <div className="grid-col-5 ">
                <div className="usa-label">Hotel Confirmation Code</div>
                <Form.Control maxLength={8} className="usa-input" name="cart.hotelConfirmationCode" plaintext defaultValue={this.keyValue('cart', 'hotelConfirmationCode')}  style={editingStyle} onChange={this.handleChange}/>
                <br />
                <Button
                  className="usa-button btn btn-primary"
                  name="confirmationCode"
                  disabled={this.state.hotelConfirmationCode.length !== 8}
                  onClick={this.handleConfirmationCode}
                >
              Confirm
              </Button>

              </div>

            </div>
            <ErrorMessage message={this.state.errorMessage} />
          </Modal.Body>
          <Modal.Footer style={{minHeight: 36}}>
            <span>
            </span>
          </Modal.Footer>
        </Modal>
        </>
      }

      </>
    )
  }
}

RegistrationSummary.contextType = PersonContext
export default RegistrationSummary
