import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'

import {PersonContext} from '../../../classes/context.js';

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class BanquetConfirmation extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  nn(field) {
    let person = this.context.person
    let item = person.editItem ? person.editItem : person.item
    return item.cart[field] ? item.cart[field] : 'Yes'
  }

  handleChange(event) {
    let person = this.context.person
    if (!person.editItem) {person.beginEdit()}
    const handleChange = person.handleChange.bind(person)
    handleChange(event)
  }

  render() {

    let person = this.context.person
    let attendeeSeating = false
    let attendeeDiet = false

    if (person.item.cart.secondaryItems) {
      attendeeSeating = person.item.cart.secondaryItems.filter(item => item.sk === 'secondaryAttendeeSeating')
      attendeeDiet = person.item.cart.secondaryItems.filter(item => item.sk === 'secondaryAttendeeDiet')
    }

    if (attendeeDiet.length > 0 || attendeeSeating.length > 0) {return null}
    if (!person.item.cart.primaryRegistrationType.banquetIncluded) {return null}

    return (
      <>
      <h2 className="margin-y-5" style={{marginBottom: 0}}>Banquet Information</h2>

      <div className="grid-row grid-gap margin-y-0">
        <div className="grid-col-6">
          <div className="usa-label">Will You Attend the Hall of Fame Banquet?</div>

            <Form.Control
              className="usa-select"
              style={editingStyle}
              as="select"
              name={'cart.willAttendBanquet'}
              defaultValue={this.nn('willAttendBanquet')}
              onChange={this.handleChange}
            >
              <option key="-1" value="">Select One</option>
              <option key="Yes" value="Yes">Yes, I will attend the Banquet</option>
              <option key="No" value="No">No, I will not be able to attend the Banquet</option>
            </Form.Control>
      </div>

        <div className="usa-prose margin-y-1">
          The <strong>Hall of Fame Banquet</strong> is the formal closing of the convention.
          This year the banquet will be held on Saturday October, 30th. We look
          forward to your attendance, but we understand that travel or other activities may prevent you from participating.
          Please help us plan for the best banquet yet by letting us know whether you will attend this year’s banquet.</div>
          {this.props.hasGuestBanquet &&
            <div>You are answering for both you are your guest. Let us know during convention check in if your guest will not be attending the banquet.</div>
          }
      </div>
</>
    )
  }
}

BanquetConfirmation.contextType = PersonContext
export default BanquetConfirmation
