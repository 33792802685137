import React from 'react'
import { Link } from "react-router-dom"

export function isValidEmail(email) {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase()) && !email.includes('%');
}

export function CustomLink(props) {
  let to = props.isAdmin ? props.adminTo : props.to
  return (
    <Link
      className={props.className}
      to={to}
      style={props.style}
      >
    {props.label}
    </Link>
  )
}
