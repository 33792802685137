
import React, { Component } from 'react';
import {CompanyContext} from '../../../classes/context.js'

import Form from 'react-bootstrap/Form'
import State from '../../controls/State'


var moment = require('moment');

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class Editor extends Component {

  constructor(props) {
    super(props)

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.stateChange = this.stateChange.bind(this)

    this.state = {
      changed: moment()
    }
  }

  keyValue(item, key, format) {
      const {company} = this.context

      switch (item) {
        case 'root':
          if (!company.editItem) {return null}
          if (!company.editItem[item]) {return null}

          if (format) {
            return (typeof(company.editItem[item][key]) === 'undefined' ||
                    !company.editItem[item][key] ?
                    null :
                    moment(company.editItem[item][key]).format(format))
          }

          return (typeof(company.editItem[item][key]) === 'undefined' ? null : company.editItem[item][key])

        default:
         return null
      }
    }

  handleCheckboxChange(event) {
    const handleChange = this.context.company.handleChange.bind(this.context.company)
    handleChange(event)

    // This is really a hack to force a state change and a rerender. The context
    // change does not appear to do that automatically. Although it seems like
    // it should.
    this.stateChange()
  }

  stateChange() {
    this.setState({
      changed: moment()
    })
  }

  render() {

    const {company} = this.context
    if (this.props.editMode.mode !== 'edit') {return null}
    if (!company.editItem) {return null}

    const handleChange = company.handleChange.bind(company)

    return (

      <Form>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-12">
            <div className="usa-label">Company Name</div>
            <Form.Control className="usa-input" name="root.name" plaintext defaultValue={this.keyValue('root', 'name')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>

        <h2 className="margin-y-5" style={{marginBottom: 0}}>Primary Contact</h2>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-12">
            <div className="usa-label">Primary POC Full Name</div>
            <Form.Control className="usa-input" name="root.primary_poc_full_name" plaintext defaultValue={this.keyValue('root', 'primary_poc_full_name')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-5">
            <div className="usa-label">POC Email Address</div>
            <Form.Control className="usa-input" name="root.primary_poc_primary_email" plaintext defaultValue={this.keyValue('root', 'primary_poc_primary_email')}  style={editingStyle} onChange={handleChange}/>
          </div>
          <div className="tablet:grid-col-5">
            <div className="usa-label">Alternate Email Address</div>
            <Form.Control className="usa-input" name="root.primary_alternate_email" plaintext defaultValue={this.keyValue('root', 'primary_alternate_email')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>

        <div className="tablet:grid-col-3">
          <div className="usa-label">POC Phone</div>
          <Form.Control className="usa-input" name="root.primary_poc_phone_number" plaintext defaultValue={this.keyValue('root', 'primary_poc_phone_number')}  style={editingStyle} onChange={handleChange}/>
        </div>

        <h2 style={{marginTop: 15, marginBottom: -10}}>Address</h2>
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-4">
            <div className="usa-label">Line 1</div>
            <Form.Control className="usa-input" name="root.line_1" plaintext defaultValue={this.keyValue('root', 'line_1')}  style={editingStyle} onChange={handleChange}/>
          </div>
          <div className="tablet:grid-col-4">
            <div className="usa-label">Line 2</div>
            <Form.Control className="usa-input" name="root.line_2" plaintext defaultValue={this.keyValue('root', 'line_2')}  style={editingStyle} onChange={handleChange}/>
          </div>

        </div>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-3">
            <div className="usa-label">City</div>
            <Form.Control className="usa-input" name="root.city" plaintext defaultValue={this.keyValue('root', 'city')}  style={editingStyle} onChange={handleChange}/>
          </div>

          <div className="tablet:grid-col-3">
            <div className="usa-label">State</div>
            <State defaultValue={this.keyValue('root', 'state_or_province')} onChange={handleChange} name="root.state_or_province"/>
          </div>

          <div className="tablet:grid-col-2">
            <div className="usa-label">Zip</div>
            <Form.Control className="usa-input" name="root.postal_code" plaintext defaultValue={this.keyValue('root', 'postal_code')}  style={editingStyle} onChange={handleChange}/>
          </div>

          <div className="tablet:grid-col-3">
            <div className="usa-label">Country</div>
            <Form.Control className="usa-input" name="root.country" plaintext defaultValue={this.keyValue('root', 'country')}  style={editingStyle} onChange={handleChange}/>
          </div>

        </div>

        <h2 className="margin-y-5" style={{marginBottom: 0}}>Exhibitor</h2>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-4" style={{paddingTop: 25}}>
            <div className="usa-checkbox">
              <input className="usa-checkbox__input" id="root.isExhibitor" name="root.isExhibitor" checked={this.keyValue('root', 'isExhibitor')} type="checkbox" onChange={this.handleCheckboxChange}/>
              <label className="usa-checkbox__label" htmlFor="root.isExhibitor">Is Convention Exhibitor</label>
            </div>
          </div>
          <div className="tablet:grid-col-3">
            <div className="usa-label" style={{marginTop: 0}}>Convention Year</div>
            <Form.Control className="usa-input" name="root.conventionYear" plaintext defaultValue={this.keyValue('root', 'conventionYear')}  style={editingStyle} onChange={handleChange}/>
          </div>
          <div className="tablet:grid-col-3">
            <div className="usa-label" style={{marginTop: 0}}>Convention Booth</div>
            <Form.Control className="usa-input" name="root.conventionBooth" plaintext defaultValue={this.keyValue('root', 'conventionBooth')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>


        <h2 className="margin-y-5" style={{marginBottom: 0}}>Industry Partner</h2>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-4" style={{paddingTop: 25}}>
            <div className="usa-checkbox">
              <input className="usa-checkbox__input" id="root.isIndustryPartner" name="root.isIndustryPartner" checked={this.keyValue('root', 'isIndustryPartner')} type="checkbox" onChange={this.handleCheckboxChange}/>
              <label className="usa-checkbox__label" htmlFor="root.isIndustryPartner">Is Industry Partner</label>
            </div>
          </div>
          <div className="tablet:grid-col-3">
            <div className="usa-label" style={{marginTop: 0}}>IP End Date</div>
            <Form.Control className="usa-input" name="root.industryPartnershipEndDate" plaintext placeholder="YYYY-MM-DD" defaultValue={this.keyValue('root', 'industryPartnershipEndDate', 'YYYY-MM-DD')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>
        </Form>
      )
    }
}

Editor.contextType = CompanyContext
export default Editor;
