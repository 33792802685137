import React, { Component } from 'react';

import { ButtonStatic, PageHeader } from '../../sections/Section'
import ToolbarAdminHome from '../../controls/ToolbarAdminHome'
import {isValidEmail} from '../../common'

import { ADD_IP_MEMBERSHIP, ADD_POC, ALIGN_END_DATES, EDIT_IP } from './ChapterGlobals'

// Structure
// There are multiple modes for displaying the control bar
// view: The user is viewing the person
//   If the user is an admin, during view the following also are available
//   - actions: the actions available to the admin are displayed
//   - delete-person: the user is viewing the delete person confirmation screen
//   - merge-person: the user is viewing the merge person screen
//   - edit-primary-email: the user is viewing the change primary email screen
// edit: The user is editing the person
// membership: The user is adding a membership
// membership-edit: The user is editing a membership

// The editMode is as follows {view: XXX, adminView: YYY}

class ChapterControl extends Component {

  constructor(props) {
    super(props)
    this.selectAction = this.selectAction.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      primary_email: '',
      membership_start_date: '',
      endDate: '',
    }
  }

  selectAction(event) {

    const { startEdit } = this.props
    switch (event.target.value) {
      case ADD_POC:
      case ADD_IP_MEMBERSHIP:
      case ALIGN_END_DATES:
        startEdit({mode: 'view', adminMode: event.target.value})
        break
      default:
        // nothing
    }
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  render() {
    return (
      <>
        <PageHeader title="Chapter Profile" />
        <div className="grid-row grid-gap">
          <div className="mobile-lg:grid-col-6">
            <ToolbarAdminHome {...this.props}/>
            <Default {...this.props} />
            <Edit {...this.props} />
          </div>
          <div className="mobile-lg:grid-col-6" style={{textAlign: 'right'}}>
            <SelectAction selectAction={this.selectAction} {...this.props}/>
            <AddPoc {...this.props} handleChange={this.handleChange} primary_email={this.state.primary_email}/>
          </div>
        </div>
      </>
    )
  }
}

export default ChapterControl;

function Edit(props) {
  const {editMode, endEdit, isNew} = props

  if (editMode.mode !== 'edit' && editMode.mode !== 'editPoc' && editMode.mode !== 'editIpMembership') {return null}

  return (
    <span>
      <ButtonStatic onClick={() => endEdit(true)}>
        <i className="fas fa-check"></i> OK
      </ButtonStatic>
      {!isNew &&
        <ButtonStatic onClick={() => endEdit(false)} disabled={isNew}>
          <i className="fas fa-times"></i> Cancel
        </ButtonStatic>
      }
    </span>
  )
}

function Default(props) {
  const {editMode, startEdit} = props

  if (editMode.mode !== 'view') {return null}

  return (
    <ButtonStatic onClick={() => startEdit(EDIT_IP, null)}>
      <i className="fas fa-pencil"></i>
    </ButtonStatic>
  )
}

function SelectAction(props) {

  const { editMode } = props
  if (editMode.adminMode !== 'view') {return null}

  return (
      <select className="usa-select" onChange={props.selectAction} style={{marginTop: 0, backgroundColor: '#eeeeee'}}>
        <option key="action" value="action">Select an action...</option>
        <option disabled>──────────────────</option>
        <option key="delete-chapter" disabled value="delete-chapter">Delete Chapter</option>
        <option key="add-poc" value="add-poc">Add POC</option>
      </select>
    )
  }

function AddPoc(props) {
  const {editMode, endEdit, handleChange, primary_email} = props

  if (editMode.adminMode !== ADD_POC) {return null}

  const validEmail = isValidEmail(primary_email)

  return (
    <div>
    <input className="usa-input" id="primary_email" name="primary_email" type="text"
      style={{width:'58%', display: 'inline', marginRight: 10, marginTop: 0, backgroundColor: '#eeeeee'}}
      placeholder="POC primary email"
      onChange={handleChange}
    />
    <ButtonStatic disabled = {!validEmail} onClick={() => endEdit(true, {primary_email: primary_email})}>
      <i className="fas fa-check"></i> OK
    </ButtonStatic>
    <ButtonStatic className="usa-button usa-button--unstyled" style={{textDecoration: 'none'}} onClick={() => endEdit(false, {primary_email: primary_email})}>
      Cancel
    </ButtonStatic>
    </div>
  )
}
