
import React, { Component } from 'react'
import { Link } from "react-router-dom"

import {CompanyContext} from '../../../classes/context'


var moment = require('moment');

class CompanySummary extends Component {

  constructor(props) {
    super(props)
  }

  render() {

    if (this.props.editMode.mode !== 'view') {return null}

    const {company} = this.context
    const {startEdit, removeItem} = this.props
    return (

      <div className="margin-y-5">
        <h2 className="usa-heading-alt margin-y-0">
          {company.item.root.name}
        </h2>

        <div className="usa-prose">
          <hr />
          <div className="usa-line-length-example usa-prose">
            <h3>Primary Contact Information</h3>
            <div className="usa-prose" style={{ marginLeft: 20 }}>
              <div><strong>Primary Contact</strong>: {company.item.root.primary_poc_full_name}</div>
              <Address root={company.item.root} address={company.item.root} />
              <div><strong>Email</strong>: {company.item.root.primary_poc_primary_email}</div>
              <div><strong>Alternate Email</strong>: {company.item.root.primary_alternate_email}</div>
              <div><strong>Phone</strong>: {company.item.root.primary_poc_phone_number}</div>
            </div>
        </div>
        <div className="usa-prose">
          <hr />
          <div className="usa-line-length-example usa-prose">
            <h3>Exhibitor Information</h3>
            <div className="usa-prose" style={{ marginLeft: 20, marginBottom: 20 }}>
              <div><strong>Is Convention Exhibitor</strong>: {yesNo(company.item.root.isExhibitor)}</div>
              <div><strong>Convention Year</strong>: {company.item.root.conventionYear ?? <span>None</span>}</div>
              <div><strong>Convention Booth</strong>: {company.item.root.conventionBooth ?? <span>None</span>}</div>
            </div>
            </div>
        </div>

        <div className="usa-prose">
          <hr />
          <div className="usa-line-length-example usa-prose">
            <h3>Industry Partner Information</h3>
            <div className="usa-prose" style={{ marginLeft: 20, marginBottom: 20 }}>
              <div><strong>Is Industry Partner</strong>: {yesNo(company.item.root.isIndustryPartner)}</div>
              <div><strong>Industry Partner Until</strong>: {moment(company.item.root.industryPartnershipEndDate).format('YYYY-MM-DD')}</div>
            </div>
            <PocList
              company={company}
              startEdit={startEdit}
              removeItem={removeItem}
            />
            <IndividualList
              company={company}
              removeItem={removeItem}
            />
            <MembershipList
              company={company}
              startEdit={startEdit}
              removeItem={removeItem}
            />
        </div>

      </div>
    </div>
  </div>

    )
  }
}

function editPoc(startEdit, sk) {
  startEdit({mode: 'editPoc', sk: sk, adminMode: 'view'}, null)
}

function editIpMembership(startEdit, sk) {
  startEdit({mode: 'editIpMembership', sk: sk, adminMode: 'view'}, null)
}


function PocList(props) {
  const {company, startEdit, removeItem} = props

  if (!company || !company.item || !company.item.roles) {return null}

  return (
    <div className="usa-accordion">

      <h3 className="usa-accordion__heading">
        <button className="usa-accordion__button"
          aria-expanded="true"
          aria-controls="mh">
          Points of Contact
        </button>
      </h3>
      <div id="mh" className="usa-accordion__content" >
        <table  className="usa-table usa-table--borderless " style={{width:'100%', marginTop: 0, fontSize: 'small'}}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {company.item.roles.map(role => {
              return (
                <tr key={role.sk}>
                  <td>
                    <span
                      className="usa-button-unstyled"
                      style={{textDecoration: "none", paddingRight: 8, color: "#b40808", cursor: "pointer"}}
                      onClick={() => removeItem(role.pk, role.sk, 'roles')}
                    >
                      <i className="fas fa-trash-alt"> </i>
                    </span>
                    <span
                      className="usa-button-unstyled"
                      style={{textDecoration: "none", paddingRight: 8, color: "#b40808", cursor: "pointer"}}
                      onClick={() => editPoc(startEdit, role.sk)}
                    >
                      <i className="fas fa-pencil-alt"> </i>
                    </span>
                    {role.full_name}
                  </td>
                  <td><Link to={'/admin/account/' + role.primary_email} >
                    {role.primary_email}
                  </Link></td>
                  <td>{role.phone_number}</td>
                  <td>{role.role_name}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

function IndividualList(props) {
  const {company, removeItem} = props
  let myRef = React.createRef()

  if (!company || !company.item || !company.item.memberships) {return null}

  return (
    <div className="usa-accordion">

      <h3 className="usa-accordion__heading">
        <button className="usa-accordion__button"
          aria-expanded="true"
          aria-controls="ipmh">
          Corporate Individual Members
        </button>
      </h3>
      <div className="usa-button" onClick={() => {alert('click');myRef.current.select(); document.execCommand('copy');}}><i className="far fa-copy"></i> Copy</div>
      <div ref={myRef} id="ipmh" className="usa-accordion__content" >
        <div className="usa-alert usa-alert--info usa-alert--slim">
          <div className="usa-alert__body">
            To add a new member, navigate to that person&lsquo;s account page and add it there.
          </div>
        </div>
        <table id="ipil" className="usa-table usa-table--borderless " style={{width:'100%', marginTop: 0, fontSize: 'small'}}>
          <thead>
            <tr>
              <th>Member</th>
              <th>Name</th>
              <th>Type</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            {company.item.individuals.map(individual => {
              return (
                <tr key={individual.sk}>
                  <td>
                    <span
                      className="usa-button-unstyled"
                      style={{textDecoration: "none", paddingRight: 8, color: "#b40808", cursor: "pointer"}}
                      onClick={() => removeItem(individual.pk, individual.sk, 'individuals')}
                    >
                      <i className="fas fa-trash-alt"> </i>
                    </span>
                    <Link to={"/admin/account/" + individual.pk.substring(individual.pk.indexOf('-') + 1).toLowerCase()}>
                      {individual.pk.substring(individual.pk.indexOf('-') + 1)}
                    </Link>
                  </td>
                  <td>{individual.fullName}</td>
                  <td>Corporate Individual Membership</td>
                  <td>{moment(individual.membershipStartDate).format('YYYY-MM-DD')}</td>
                  <td>{moment(individual.membershipEndDate).format('YYYY-MM-DD')}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

function MembershipList(props) {
  const {company, removeItem, startEdit} = props

  if (!company || !company.item || !company.item.memberships) {return null}

  const membershipsSorted = company.item.memberships.sort((a, b) => {
    if (a.membership_start_date < b.membership_start_date) {return 1}
    if (a.membership_start_date > b.membership_start_date) {return -1}
    return 0
  })

  return (
    <div className="usa-accordion">

      <h3 className="usa-accordion__heading">
        <button className="usa-accordion__button"
          aria-expanded="true"
          aria-controls="ipmh">
          Industry Partner Membership History
        </button>
      </h3>
      <div id="ipmh" className="usa-accordion__content" >
        <table  className="usa-table usa-table--borderless " style={{width:'100%', marginTop: 0, fontSize: 'small'}}>
          <thead>
            <tr>
              <th>Type</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            {membershipsSorted.map(membership => {
              return (
                <tr key={membership.sk}>
                  <td>
                    <span
                      className="usa-button-unstyled"
                      style={{textDecoration: "none", paddingRight: 8, color: "#b40808", cursor: "pointer"}}
                      onClick={() => removeItem(membership.pk, membership.sk, 'memberships')}
                    >
                      <i className="fas fa-trash-alt"> </i>
                    </span>
                    <span
                      className="usa-button-unstyled"
                      style={{textDecoration: "none", paddingRight: 8, color: "#b40808", cursor: "pointer"}}
                      onClick={() => editIpMembership(startEdit, membership.sk)}
                    >
                      <i className="fas fa-pencil-alt"> </i>
                    </span>
                    Industry Partner Membership
                  </td>
                  <td>{moment(membership.membership_start_date).format('YYYY-MM-DD')}</td>
                  <td>{membership.membership_end_date ? <span>{moment(membership.membership_end_date).format("YYYY-MM-DD")}</span> : <span></span>}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

function yesNo(value) {
  if (value) {
    return (<>Yes</>)
  }
  return (<>No</>)
}

function Address(props) {
  const {address} = props

  if (!address || !address.line_1) {return null}

  const fullAddress =
    (address.line_1 ? address.line_1 : '') +
    (address.line_2 ? ' ' + address.line_2 + ', ' : ', ') +
    (address.city ? address.city + ' ' : '') +
    (address.state_or_province ? address.state_or_province + ' ' : '') +
    (address.postal_code ? address.postal_code + ' ' : '') +
    (address.country ? address.country + ' ' : '')

  return <address><strong>Address:</strong> {fullAddress}</address>

}

function guessName(individual, roles) {

  const name = roles.filter(role => role.primary_email === individual.pk.substring(individual.pk.indexOf('-') + 1))
  return name[0] ? name[0].full_name : ''
}

CompanySummary.contextType = CompanyContext
export default CompanySummary
