import React from 'react';

function PrintBadge(props) {

  const person = props.person.item
  const {isGuest} = props

  const baseAbbreviations = [
    {short: 'JBMDL', long: 'Joint Base McGuire-Dix-Lakehurst'},
    {short: 'JBLM', long: 'Joint Base Lewis McChord'},
    {short: 'JBER', long: 'Joint Base Elmendorf-Richardson'},
    {short: 'JBPH-H', long: 'Joint Base Pearl Harbor-Hickam'},
    {short: 'JBPH-H', long: 'Joint Base PH Hickam'},
    {short: 'JBCHS', long: 'Joint Base Charleston'},
    {short: 'JBSA', long: 'Joint Base San Antonio'},
    {short: 'JBA', long: 'Joint Base Andrews'},
    {short: 'JBAB', long: 'Joint Base Anacostia-Bolling'},
    {short: 'JBLE', long: 'Joint Base Langley-Eustis'},
  ]


  if (isGuest && !person.cart.includesGuest) {return null}

  let exhibitor = <span> </span>
  if (!isGuest &&
      ((person.cart.primaryRegistrationType && person.cart.primaryRegistrationType.isExhibitor && person.cart.badgeShowAsExhibitorOnBadge !== 'No') ||
        person.cart.badgeShowAsExhibitorOnBadge === 'Yes')) {
    exhibitor = 'EXHIBITOR'
  }

  let goByName = person.root.first_name
  let name = ''
  if (isGuest) {
      goByName =  person.cart.secondaryGuestFirstName ? person.cart.secondaryGuestFirstName.trim() : <span>&nbsp;</span>
      name = person.cart.secondaryGuestLastName ? person.cart.secondaryGuestLastName.trim() : <span>&nbsp;</span>
  } else {
    if (person.root.nickname && person.root.nickname !== goByName) {
      goByName = person.root.nickname
    }
    name = <Name person={person} isGuest={false} noFirstName={true} forceRank={false}/>
  }

  const showSecurity = (person.cart.badgeHasSecurityClearance === 'Yes' && !isGuest) ? 'badge-security' : 'badge-no-security'

  let organization = <span>&nbsp;</span>
  if (isGuest) {
    organization =  <span>&nbsp;</span>
  } else {
    if (person.cart.exhibitorOrganization && person.cart.exhibitorOrganization.trim()) {
      organization = person.cart.exhibitorOrganization.trim()
    } else if (person.military.organization && person.military.organization.trim()) {
      organization = person.military.organization.trim()
    } else if (person.civilian.organization && person.civilian.organization.trim()) {
      organization = person.civilian.organization.trim()
    } else if (person.root.private_organization && person.root.private_organization.trim()) {
      organization = person.root.private_organization.trim()
    }
  }

  let adminText =  <span>&nbsp;</span>
  if (isGuest) {
    adminText = person.cart.badgeGuestAdminContent ?? adminText
  } else {
    adminText = person.cart.badgeAdminContent ?? adminText
  }

  let chapter = person.cart.badgeOverrideBaseChapter ?? false
  if (isGuest) {
      chapter = <span>&nbsp;</span>
  } else {
    if (!chapter) {
      let base_name = false
      const match = baseAbbreviations.find(base => person.military.base_name === base.long)
      if (match) {
        base_name = match.short
      }
      if (!base_name) {
        base_name = person.military.base_name ? person.military.base_name.replace('Joint Base', 'JB') : false
      }
      if ( person.military.base === 'other' || person.military.base_name === 'Other' || person.military.base_name === 'None') {
        base_name =  person.military.base_other ?? <span>&nbsp;</span>
      }
      chapter = base_name ?? false

      if (!chapter ) {
        if (person.address.chapterName && person.address.withinRadius) {
          chapter = person.address.chapterName ?? <span>&nbsp;</span>
        } else {
          chapter = <span>&nbsp;</span>
        }
      }
    }
  }

  const sponsor =  isGuest ? <span>Guest of <LongName person={person} /></span> : <span>&nbsp;</span>
  let awards = <span>&nbsp;</span>
  if (!isGuest) {
    awards = person.cart.badgeAwardContent ?? awards
  }

  return (
    <div className="badge-frame">
      <div className="badge-header">
        <div className="badge-exhibitor">{exhibitor}</div>
        <div className={showSecurity}> </div>
      </div>
      <div className="badge-body">
        <div className="badge-go-by-name">{goByName}</div>
        <div className="badge-fullname">{name}</div>
        <div className="badge-organization-name">{organization}</div>
      </div>
      <div className="badge-admin-text">{adminText}</div>
      <div className="badge-footer">
        <div className="badge-footer-left">
          <div className="badge-footer-left-row-1">{awards}</div>
          <div className="badge-footer-left-row-2">{sponsor}</div>
        </div>
        <div className="badge-footer-right">
          <div className="badge-footer-right-row-1">{chapter}</div>
          <div className="badge-footer-right-row-2"><LongName person={person} isGuest={isGuest}/></div>
        </div>
      </div>
    </div>
 )

}

export default PrintBadge

function Name(props) {

  const {person, isGuest, forceRank, noFirstName} = props

  let noRank = true
  if (person.cart.badgeShowRankOnBadge === 'Yes') {
    noRank = false
  } else if (!person.cart.badgeShowRankOnBadge || person.cart.badgeShowRankOnBadge === 'useProfile') {
    if (!person.cart.exhibitorOrganization && !person.root.private_organization) {
      noRank = false
    }
  }
  if (!isGuest && forceRank) {
    noRank = false
  }

  if (isGuest) {return (<span>&nbsp;</span>)}

  let name = ''
  if (person.military.rank && person.military.rank_abbreviation !== 'none' && !noRank) {
    name += ' ' + person.military.rank_abbreviation
  }

  if (!noFirstName) {
    if (!isGuest && (!person.root.nickname ||
        (person.root.nickname && person.root.nickname.toUpperCase() !== person.root.first_name.toUpperCase()))) {
      name += ' ' + person.root.first_name
    }
  }

  name += ' ' + person.root.last_name

  if (person.military.status === 'retired' && !noRank) {
    name += ' (Ret)'
  }

  return name
}

function LongName(props) {

  const {person, isGuest} = props

  if (isGuest) {return null}
// rank_abbreviation
  let name = ''
  if (person.military && person.military.rank_abbreviation && person.military.rank_abbreviation !== 'none') {
    name += ' ' + person.military.rank_abbreviation
  }

  name += ' ' + person.root.first_name
  if (person.root.middle_initial) {
    name += ' ' + person.root.middle_initial
  }
  name += ' ' + person.root.last_name

  if (person.military && person.military.status === 'retired') {
    name += ' Ret'
  }

  return (<span>{name}</span>)
}
