// Base class for data retrieve
import { API } from 'aws-amplify'

export const ITEM_PK_COMPANY = 'company'
export const ITEM_PK_PERSON = 'person'

export const ITEM_SK_ADDRESS = 'address'
export const ITEM_SK_CART = 'cart'
export const ITEM_SK_CART_CANCELLATION = 'cart-cancellation'
export const ITEM_SK_CART_PERSONAL = 'cart-personal'
export const ITEM_SK_CART_GOV = 'cart-gov'
export const ITEM_SK_CART_IID = 'cart-iid'
export const ITEM_SK_CHAPTER = 'chapter'
export const ITEM_SK_CIVILIAN = 'civilian'
export const ITEM_SK_COMMENT = 'comment'
export const ITEM_SK_CONVENTION = 'convention'
export const ITEM_SK_DONATION = 'donation'
export const ITEM_SK_MILITARY = 'military'
export const ITEM_SK_NATIONALITY = 'nationality'
export const ITEM_SK_PHONE_PRIMARY = 'phone-primary'
export const ITEM_SK_PHONE_SECONDARY = 'phone-secondary'
export const ITEM_SK_REGISTRATION = 'registration'
export const ITEM_SK_ROOT = 'root'
export const ITEM_SK_ROLE = 'role'
export const ITEM_SK_MEMBERSHIP = 'membership'

class Data {

  constructor() {
    this.item = {}
    this.account = false
    this.isInitialized = false
    this.isLoading = false
    this.isSaving = false
    this.loadFailed = false
  }

  async initialize() {
    this.item.id = this.uuidv4();
    this.isInitialized = true
  }

  beginEdit() {
    this.editItem = JSON.parse(JSON.stringify(this.item))
    return this
  }


  endEdit(keep) {
    if (keep) {
      this.item = JSON.parse(JSON.stringify(this.editItem))
    }
    delete this.editItem
    return this
  }

  async storeItem(group, path) {
    this.isSaving = true
    let that = this

    return new Promise((resolve, reject) => {
      API.put(group, path, {body: this.item})
      .then(response => {
        that.isSaving = false
        that.isNew = false
        resolve(response)
      })
      .catch(error => {
        that.isSaving = false
        console.log(error)
        reject(error)
      })
    })
  }

  async deleteItem(group, path) {
    this.isSaving = true
    let init = {
      headers: {},
      response: true,
      body: {
        pk: this.item.root.pk,
      },
    }

    if (path === '/person') {
      init.body.person = true
      init.body.registration = true
    }

    return new Promise((resolve, reject) => {
      API.del(group, path, init)
      .then(response => {
        this.item = {}
        this.isLoading = false
        this.isSaving = false
        this.loadFailed = false
        this.isNew = true
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
    })
  }

  // eslint-disable-next-line
  uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => // eslint-disable-next-line
      (c ^ crypto.getRandomValues(     // eslint-disable-next-line
        new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }

  filterItem(sk, pk ) {
    const lengthSk = sk.length
    if (pk) {
      const lengthPk = pk.length
      return this.rawItems.filter(
        element => element.sk.substring(0, lengthSk) === sk &&
                   element.pk.substring(0, lengthPk) === pk
      )
    }
    return this.rawItems.filter(
      element => element.sk.substring(0, lengthSk) === sk
    )
  }

  filterItems(items, sk, pk ) {
    const lengthSk = sk.length
    if (pk) {
      const lengthPk = pk.length
      return items.filter(
        element => element.sk.substring(0, lengthSk) === sk &&
                   element.pk.substring(0, lengthPk) === pk
      )
    }
    return items.filter(
      element => element.sk.substring(0, lengthSk) === sk
    )
  }
}

export default Data
