import React, { Component } from 'react';
import { BrowserRouter as Router,
         Route,
         Switch,
         Redirect,
         useParams } from "react-router-dom";

//import { Authenticator, Greetings, SignIn } from 'aws-amplify-react';
//import Amplify from 'aws-amplify';
import {  AmplifyAuthenticator,
          AmplifySignIn,
          AmplifyConfirmSignIn,
          AmplifySignUp,
          AmplifyConfirmSignUp,
          AmplifyForgotPassword,
          AmplifyRequireNewPassword,
          AmplifyVerifyContact } from '@aws-amplify/ui-react';

import AtaGreetings from '../auth/AtaGreetings';
import {Section} from '../sections/Section'

import Body from './Body'
import Footer from './Footer'
import Header from './Header'
import Donate from "./Donate";
import BasicPage from "./BasicPage";
import NewsPage from "./NewsPage";
import IndustryPartnerNews from "./IndustryPartnerNews";
import Atq from "./Atq";
import Agenda from "./virtual2020/Agenda";
import Account from "./account/Account";
import Catalog from "./catalog/Catalog";
import Checkout from "./checkout/Checkout";
import Payment from "./payment/Payment";
import Cart from "./Cart";
import PreviewBatch from "./preview/PreviewBatch"
import AddIid from "./checkout/AddIid";
import Sponsor from "./virtual2020/Sponsor";
import AdminRoutes from "./admin/AdminRoutes";
import ScrollToTop from './ScrollToTop';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';

import {PersonContext} from '../../classes/context'

const hostname = window && window.location && window.location.hostname

const stripePromise = (hostname === 'new.atalink.org' ||
                       hostname === 'www.atalink.org' ||
                       hostname === 'atalink.org') ?
                       loadStripe('pk_live_0NIWTgcAcd9TuJKNmoCqxsX8') :
                       loadStripe('pk_test_TdYkfN3C0hFR8JvDmp5sDCbk')

const env = (hostname === 'www.atalink.org' ||
             hostname === 'atalink.org') ?
             'prod' :
             'non-prod';

const basicPages = [
  {path: '/contact', pageId: '3CPJcGVXnfogocRxsm7rir'},
  {path: '/chairmans-message', pageId: '6BxUWflVHzd6gEMo90cTCy'},
  {path: '/about', pageId: '21GUTWii0vyf4sEzXI9dKG'},
  {path: '/about/board-of-officers', pageId: 'pHkydPGNPE2NmwbGcoGGH'},
  {path: '/about/board-of-advisors', pageId: '2GAeIqVGHZ1HF0esIBVv15'},
  {path: '/about/liaisons', pageId: '2Tg1bDPoQILoAhm38kxs3j'},
  {path: '/about/support-staff', pageId: '11U074De9SR8lBHnfAPzKQ'},
  {path: '/about/convention-contacts', pageId: '1FeiaOwRoWxyBRWFnCXFsM'},
  {path: '/industry-partners', pageId: '5L7OSM3fBjaQh96Nrze3Wa'},
  {path: '/industry-partner-benefits', pageId: '2yvrD1ZI0n3PlqPHFRMdS6'},
  {path: '/industry-partner-benefits/industry-interface-day', pageId: '3flTfrzzF3M0ik5bhMOtuJ'},
  {path: '/industry-partner-benefits/convention-sponsorships', pageId: '1pLNKjeyhMoDqfzzbVNEPa'},
  {path: '/w9-request', pageId: '1CVOdVPEA7lm8WOGtUk2f6'},
  {path: '/awards-program', pageId: 'j8IMqldwBkUO21CcSTHuv'},
  {path: '/awards-program/fogleman-award', pageId: '4zBp5xfmryAILqtMUNULGL'},
  {path: '/awards-program/awardee-benefits', pageId: '38Mxcoqv2FKoer4LLvc1H8'},
  {path: '/enlisted-education-grant', pageId: '78MoZSv6Clo16xmnVzABFg'},
  {path: '/enlisted-education-grant/guidelines', pageId: 'L1vz4si7D2b8Mj2cT2xP7'},
  {path: '/enlisted-education-grant/forms', pageId: '1761md7uzNDthIaoXNQf2R'},
  {path: '/become-a-member', pageId: '7kpunbBNAO1cQxVXtKOQY4'},
  {path: '/chapters', pageId: '1LdzIS5LuhToG9xstYePVh'},

  {path: '/virtual-seminars', pageId: '4Ty5W0nHji34A3ad7sXqSO'},
  {path: '/hall-of-fame', pageId: 'QlVZNOUpE9UV444wowa5V'},
  {path: '/hall-of-fame/nomination-guidelines', pageId: '3k2o8zuWdfN4R836nhFlYL'},
  {path: '/tech-expo', pageId: '2cuugxU4dQ1MljayN3THtA'},
  {path: '/about-the-convention', pageId: '3rZF4fBBhmI4sSZ2Sov2hD'},
  {path: '/convention-exhibitors/exhibiting', pageId: '3EBJvZTpuct8IJAJgK8323'},

  {path: '/convention-exhibitors/exhibitor-package', pageId: '4XGc94F5mH5nZMWDntFjUR'},
  {path: '/convention-exhibitors/exhibit-space', pageId: '6SHVENVHT5uGtJbhyQaxOp'},
  {path: '/about-the-convention/sponsorship', pageId: '4UN7iblTwkstBDv2KJ0j9P'},
  {path: '/about-the-convention/spouses-welcome', pageId: '3XVtRMYiMLLzpCod9slQK3'},
  {path: '/about-the-convention/calendar', pageId: '3vdfVMlthCY3fyu44nMI7p'},
  {path: '/about-the-convention/media', pageId: '8PBGdK8dRJgqljnprNuB1'},
  {path: '/about-the-convention/symposium', pageId: '1gQb9ay2FvkKv0FQ6ABgDN'},
  {path: '/about-the-convention/conference2020-mcnabb-letter', pageId: '2mPO9pyPVEzezvqe4B8104'},

  {path: '/about-the-convention/industry-interface-day', pageId: '1VE830DxDfPfNNhSnopXrZ'},
  {path: '/convention-attendee', pageId: '2OrAEF9EJ4ibwRVNaNLoYH'},
  {path: '/convention-attendee/banquet', pageId: '6IP7TOoSgQxZLDZFRH5Ru6'},
  {path: '/convention-attendee/fees', pageId: '6yKqTXSJEz8vQHVgBrlR7E'},
  {path: '/convention-attendee/dress-code', pageId: '3MIOU7meUs4yzpRH4gBJdQ'},
  {path: '/convention-attendee/cornhole', pageId: '9XRxY6qRdbtbvNboTdHAO'},
  {path: '/convention-attendee/heritage-room', pageId: '5y8kLnYDnAPczK6KLY7Wj3'},
  {path: '/ata-business-meeting', pageId: '1TgQEFBGEic5Jf6YPOX6dQ'},
  {path: '/virtual2020/ondemand', pageId: '1D7sbXM9IBKwEshVSiN1Er'},
  {path: '/virtual2020/welcome', pageId: '4pf9OCplG2RJIFEeLcRb8B'},
  {path: '/virtual2020-awards', pageId: '69D3MiZpBk1ND7xk51sAYi'},
  {path: '/virtual2020-awards/amc-awards', pageId: 'N16FKTePt1vbZFCX9zJxn'},
  {path: '/virtual2020-awards/hof-award', pageId: '1uYmXEt9EDCEbw3Q24rWZP'},
  {path: '/virtual2020-awards/tampa-bay-award', pageId: '35x6oSkFuItESDyArZ6SZu'},
  {path: '/virtual2020-awards/abilene-award', pageId: '1X6JrHx06ZJxu2DHELpt4G'},


  {path: '/virtual2020-browse-to-win', pageId: '53UBLW1bplG88BJfDwPRwI'},
  {path: '/virtual2020-browse-to-win/wingo', pageId: '5JeeEbxLoLnHiFAMGabtrA'},
  {path: '/virtual2020-browse-to-win/crossword-puzzler', pageId: '28fznXE6Tu3rm1cHNLBh5H'},
  {path: '/virtual2020/spark-tank', pageId: '6oXWkelBkUTsuh6goI2R1I'},
  {path: '/virtual2020/iid', pageId: '1VE830DxDfPfNNhSnopXrZ'},
  {path: '/virtual2020/berlin-candy-bomber-petition', pageId: '2AuOs10EgtD0erhuZ5Rttk'},

  {path: '/virtual2020/amc-priorities', pageId: '5clarKEi4Xbzi0NjzX42KQ'},

  {path: '/register', pageId: '1ik3fe2F2hpvTXwi3b02sT'},
  {path: '/privacy-statement', pageId: '7JQ95RjEgDl4WHerV8135n'},
]

class Routes extends Component {

  render() {
    if (this.props.authState === 'signOutFailed') {return null}

    const {person} = this.context
    let registrationStatus = 'loading'
    if (!person.isLoading  && person.isInitialized) {
      registrationStatus = person.item.cart ? person.item.cart.status : 'none'
    }

    const signInMessages = ['signin', 'forgotpassword', 'signup', 'confirmSignUp'].includes(this.props.authState)

    return (
      <Router>
        {this.props.redirect &&
          <Redirect to="/account" />
        }
        <ScrollToTop />
        <Header
          authState={this.props.authState}
          signOut={this.props.signOut}
          env={env}
        />
        <Switch>
          <Route exact path="/">
            <Body env={env}/>
          </Route>

          {basicPages.map(page =>
            <Route key={page.pageId} exact path={page.path}>
              <BasicPage pageId={page.pageId}/>
            </Route>
          )}

          <Route exact path="/industry-partners-news">
            <IndustryPartnerNews />
          </Route>
          <Route exact path="/industry-partners/partners/:name" component={IndustryPartner} />
          <Route exact path="/atq" component={Atq} />
          <Route exact path="/hall-of-fame/:id" component={HallOfFame}/>
          <Route exact path="/news" component={NewsPage}/>
          <Route exact path="/news/year/:year" component={NewsYear}/>
          <Route exact path="/news/:headline" component={NewsItem}/>
          <Route exact path="/virtual2020-sponsors" component={Sponsor}/>

          <Route exact path="/virtual2020" authState={this.props.authState}>
            <Agenda registrationStatus={registrationStatus}/>
          </Route>

          <Route exact path="/donate">
            <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({elements, stripe}) => (
                <Donate elements={elements} stripe={stripe} />
              )}
            </ElementsConsumer>
            </Elements>
          </Route>


        <PrivateRoute exact path="/iid" authState={this.props.authState}>
            <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({elements, stripe}) => (
                <AddIid elements={elements} stripe={stripe} />
              )}
            </ElementsConsumer>
            </Elements>
          </PrivateRoute>

          <PrivateRoute exact path="/account" authState={this.props.authState}>
              <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({elements, stripe}) => (
                  <Account elements={elements} stripe={stripe} />
                )}
              </ElementsConsumer>
              </Elements>
            </PrivateRoute>

          <PrivateRoute exact path="/preview/:batch" authState={this.props.authState}>
            <AdminPreviewBatch />
          </PrivateRoute>

          <PrivateRoute exact path="/catalog" authState={this.props.authState}>
            <Catalog />
          </PrivateRoute>

          <PrivateRoute exact path="/checkout" authState={this.props.authState}>
            <Checkout />
          </PrivateRoute>
          <PrivateRoute exact path="/cart" authState={this.props.authState}>
            <Cart receipt={false}/>
          </PrivateRoute>
          <PrivateRoute exact path="/receipt" authState={this.props.authState}>
            <Cart receipt={true}/>
          </PrivateRoute>
          <PrivateRoute exact path="/payment" authState={this.props.authState}>
            <Payment />
          </PrivateRoute>


          <PrivateRoute exact path={
              ['/admin',
               '/admin/coupon',
               '/admin/peoplelist',
              "/admin/account/:username",
              "/admin/catalog/:username",
              "/admin/cart/:username",
              "/admin/receipt/:username",
              "/admin/iid/:username",
              "/admin/checkout/:username",
              "/admin/payment/:username"]} authState={this.props.authState} >
            <Admin person={person}/>
          </PrivateRoute>
          <PrivateRoute exact path={
              ['/admin',
               '/admin/company/:uuid',
              ]} authState={this.props.authState} >
            <AdminCompany />
          </PrivateRoute>
          <PrivateRoute exact path={
              ['/admin',
               '/admin/chapter/:uuid',
              ]} authState={this.props.authState} >
            <AdminChapter />
          </PrivateRoute>
         <Route exact path="/auth">
            <Section>
                  <div className="grid-container margin-y-0">
              <div className="grid-row">
              <div className="grid-col-5">
                {signInMessages &&
                  <>
                  <p className="usa-prose" style={{marginTop: 40}}>
                    A valid email address is required to register and to access our virtual seminars.
                  </p>
                  <p className="usa-prose">
                    <strong>For new accounts</strong> - passwords require at least one lowercase character, one uppercase character, one number and one special character.
                  </p>
                  <p className="usa-prose">
                    <strong>For .mil and .gov accounts</strong> - if you don&lsquo;t receive your sign in/sign up email and you think it is your base&lsquo;s mail filtering, please contact us directly to resolve.
                  </p>
                  </>
                }
              </div>
              <div className="grid-col-1"></div>
              <div className="grid-col-6" style={{textAlign: 'right'}}>
              <AmplifyAuthenticator
                usernameAlias="email"
                handleAuthStateChange={this.props.onStateChange}
                >
                  <AmplifySignUp
                    slot="sign-up"
                    headerText="Create a new A/TA Account"
                    usernameAlias="email"
                    formFields={[
                      { type: "email" },
                      { type: "password" }
                    ]}
                  />
                  <AmplifyConfirmSignUp
                    slot="confirm-sign-up"
                    usernameAlias="email"
                    headerText="Confirm your A/TA Account"

                  />
                  <AmplifySignIn
                    slot="sign-in"
                    usernameAlias="email"
                    headerText="Sign in with your A/TA Account"
                    formFields={[
                      { type: "email" },
                      { type: "password" }
                    ]}
                  />
                  <AmplifyConfirmSignIn
                    slot="confirm-sign-in"
                    usernameAlias="email"
                    headerText="Confirm your A/TA Account (2)"
                  />
                  <AmplifyForgotPassword
                    slot="forgot-password"
                    usernameAlias="email"
                    headerText="Reset password on your A/TA Account"
                  />
                  <AmplifyRequireNewPassword
                    slot="require-new-password"
                    usernameAlias="email"
                    headerText="A new A/TA password is required"
                  />
                  <AmplifyVerifyContact
                    headerText="Verify your A/TA Contact Information"
                    slot="verify-contact">
                  </AmplifyVerifyContact>
                  <AtaGreetings
                    authState={this.props.authState}
                  />
            </AmplifyAuthenticator>
            </div>
            </div>
            </div>
          </Section>
          </Route>

        <Route path="*">
          <BasicPage pageId="33o86lF4RstPywTcu8xYN9" noSidebar={true}/>
        </Route>

        </Switch>
        <Footer />
      </Router>

    )
  }
}

// <PrivateRoute exact path="/exhibit-hall" authState={this.props.authState}>
//   <ExhibitHall registrationStatus={registrationStatus}/>
// </PrivateRoute>


Routes.contextType = PersonContext
export default Routes

function Admin(props) {
  let {username} = useParams();
  const {person} = props
  const adminMenusPending = !person.isInitialized
  const adminMenus = !adminMenusPending && person.item.root ? (person.item.root.adminMenus === true) : false

  return (
    <AdminRoutes username={username} adminMenus={adminMenus} adminMenusPending={adminMenusPending}/>
  )
}

function AdminCompany() {
  let {uuid} = useParams();
  const adminMenusPending = false
  const adminMenus = true

  return (
    <AdminRoutes uuid={uuid} entity="company" adminMenus={adminMenus} adminMenusPending={adminMenusPending}/>
  )
}

function AdminChapter() {
  let {uuid} = useParams();
  const adminMenusPending = false
  const adminMenus = true

  return (
    <AdminRoutes uuid={uuid} entity="chapter" adminMenus={adminMenus} adminMenusPending={adminMenusPending}/>
  )
}


function HallOfFame() {
  let {id} = useParams();
  return (
    <BasicPage pageId={id} hallOfFameSidebar="true" />
  )
}

function IndustryPartner() {
  let {name} = useParams();
//  <BasicPage pageName={name} industryPartnerSidebar='true' />
  return (
    <BasicPage pageName={name} />
  )
}

function NewsItem() {
  let {headline} = useParams();
  return (
    <NewsPage pageName={headline} />
  )
}

function NewsYear() {
  let {year} = useParams();
  return (
    <NewsPage year={year} />
  )
}

function AdminPreviewBatch() {
  let {batch} = useParams();
  return (
    <PreviewBatch batch={batch} />
  )
}

function PrivateRoute({ children, authState, ...rest }) {

  return (
    <Route
         {...rest}
         render={({ location }) =>
           (authState === 'signedin' ? (
             children
           ) : (
             <Redirect
               to={{
                 pathname: "/auth",
                 state: { from: location }
               }}
             />
           ))
         }
       />
  );
}
