import React, { Component } from 'react';
import { Link } from "react-router-dom";

import * as contentful from 'contentful';

class AtqAnnouncement extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      page: null,
    };
  }

  componentDidMount() {
    var contentfulClient = contentful.createClient({
      space: 'bg11zawujrgr',
      accessToken: 'c86395435846a2c448defbf0c59f24677fa2a58d522e6b2fe3e6c4b57c083caf' })

    contentfulClient.getEntries({'content_type': 'atq', 'limit': 1, 'order': '-sys.updatedAt'})
    .then(entries => {

      this.setState({
        isLoaded: true,
        edition: entries.items[0],
      });
    })
    .catch(error => {
      console.log(error)
      this.setState({
        error: error
      })
    })
  }

  render() {

    if (!this.state.isLoaded) return null

    const {edition} = this.state
    const format = this.props.dark ? "usa-section usa-section--dark usa-graphic-list" : "usa-section usa-graphic-list"
    return (

      <section id="atq-section-id" className={format}>
        <div className="grid-container">
          <div className="usa-graphic-list__row grid-row grid-gap">
            <div className="usa-media-block tablet:grid-col-3">
              <a href={edition.fields.documentUrl} className="usa-button--big usa-button--unstyled" style={{textDecoration: 'none'}}>
                <img className="usa-media-block__img" src={edition.fields.coverImage} width='200' alt="AT/Q Cover"/>
              </a>
            </div>
            <div className="usa-media-block tablet:grid-col-9">
              <div className="usa-media-block__body">
                <h3 className="font-heading-xl margin-y-0">A/TQ Magazine {edition.fields.edition} is available now</h3>
                <p className="usa-intro">
                  A/TQ is our quarterly in-house magazine. Print copies are available by subscription and are mailed free to all members.
                </p>
                <p className="usa-intro">
                  Browse our <Link to="/atq" className="usa-button--unstyled" style={{textDecoration: 'none'}}>back catalog</Link>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default AtqAnnouncement;
