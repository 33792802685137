import React, { Component } from 'react';
import { API } from 'aws-amplify';
import {CardElement, useElements} from '@stripe/react-stripe-js';

import Form from 'react-bootstrap/Form'
import CardDeck from 'react-bootstrap/CardDeck'
import Confirmation from '../Confirmation'
import ButtonActive from '../../ButtonActive';
import FieldError from '../../controls/FieldError'

import PaymentPersonalCard from './PaymentPersonalCard'
import PaymentGovCard from './PaymentGovCard'

import {PersonContext} from '../../../classes/context.js'
import Person from '../../../classes/Person.js'

var moment = require('moment')

class Payment extends Component {

  constructor(props) {
    super(props);


    this.completePurchase = this.completePurchase.bind(this)
    this.cardChoice = this.cardChoice.bind(this)
    this.noCharge = this.noCharge.bind(this)
    this.cardAvailable = this.cardAvailable.bind(this)
    this.updateSubmitButton = this.updateSubmitButton.bind(this)
    this.getPaymentIntent = this.getPaymentIntent.bind(this)

    this.state = {
        personalCardChoice: true,
        personalCardAmount: 0,
        govCardChoice: false,
        govCardAmount: 0,
        submitButtonActive: false,
        confirmed: false,
        isReady: false,

        personalCardStripe: false,
        personalCardElements: false,
        govCardStripe: false,
        govCardElements: false,
      }
  }

  componentDidMount() {
    const isReady = (
      !this.context.person.isLoading &&
      this.context.person.isInitialized
    )
    if (isReady) {
      this.setState(this.setAmounts(false, true))
      // Handles the case where there is a previous failed payment attempt
      // Ensures that that payment attempt is wiped out.
      this.context.person.item['payment-personal'] = {}
      this.context.person.item['payment-gov'] = {}
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const isReady = (
      !this.context.person.isLoading &&
      this.context.person.isInitialized
    )
    if (isReady !== prevState.isReady) {
      this.setState(this.setAmounts(false, true))
    }
  }

  cardAvailable(cardType, stripe, elements) {

    if (cardType === 'personal') {
      this.setState({
        personalCardStripe: stripe,
        personalCardElements: elements,
      }, this.updateSubmitButton)
    }
    if (cardType === 'government') {
      this.setState({
        govCardStripe: stripe,
        govCardElements: elements,
      }, this.updateSubmitButton)
    }
  }

  cardChoice(event) {
    let {cart} = this.context.person.item
    let {govCardChoice, personalCardChoice,
         personalCardStripe, personalCardElements,
         govCardStripe, govCardElements } = this.state

    const target = event.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    if (name === 'personalCardChoice') {
      personalCardChoice = value
      // If user unchecks personal, they must be putting it all on to
      // the gov card, so check that one for them.
      // This logic is sufficient because the checkbox is disabled if there is
      // additional fees.
      if (!value) {
        govCardChoice = true
      }

      // Case: All the registration fee can be placed on either card. Selecting
      // personal should uncheck gov
      if (personalCardChoice && cart.totalPrice === this.props.govCardAmount) {
        govCardChoice = false
      }
    }
    if (name === 'govCardChoice') {
      govCardChoice = value
      // If user unchecks gov, then they must be putting it all on to
      // the personal card, so check that one for them.
      if (!value) {
        personalCardChoice = true
      }
    }

    // This handles the case where the personal amount is zero.
    const amounts = this.setAmounts(govCardChoice, personalCardChoice)
    if (!amounts.personalCardChoice) {
      personalCardStripe = false
      personalCardElements = false
    }
    if (!govCardChoice) {
      govCardStripe = false
      govCardElements = false
    }

    let newState = {
      personalCardChoice: amounts.personalCardChoice,
      govCardChoice: govCardChoice,
      govCardStripe: govCardStripe,
      govCardElements: govCardElements,
      personalCardStripe: personalCardStripe,
      personalCardElements: personalCardElements,
    }
    newState.personalCardAmount = amounts.personalCardAmount
    newState.govCardAmount = amounts.govCardAmount
    this.setState(newState, this.updateSubmitButton)
  }


  setAmounts(govCardChoice, personalCardChoice) {
    let amounts = {
      personalCard: 0,
      govCard: 0,
    }
    let {cart} = this.context.person.item

    if (govCardChoice && personalCardChoice) {

      amounts.personalCardAmount = cart.totalPrice - cart.govCardPrice
      amounts.govCardAmount = cart.govCardPrice

    } else if (govCardChoice && !personalCardChoice) {

      amounts.personalCardAmount = cart.totalPrice - cart.govCardPrice
      amounts.govCardAmount = cart.govCardPrice

    } else if (!govCardChoice && personalCardChoice) {

      amounts.personalCardAmount = cart.totalPrice
      amounts.govCardAmount = 0
    }

    amounts.personalCardChoice = (amounts.personalCardAmount !== 0)
    this.setState({
      isReady: true
    })

    return amounts
  }

  updateSubmitButton() {
    let submitButtonActive = true
    if (this.state.personalCardAmount > 0) {
      submitButtonActive = submitButtonActive && this.state.personalCardStripe
    }
    if (this.state.govCardAmount > 0) {
      submitButtonActive = submitButtonActive && this.state.govCardStripe
    }

    this.setState({
      submitButtonActive: submitButtonActive
    })
  }

  async getPaymentIntent(cardType) {
    let {person} = this.context

    person.editItem.cart.status = 'payment-intents-pending'
    person.editItem.cart[cardType + 'CardAmount'] = this.state[cardType + 'CardAmount']

    let body = Object.assign({}, person.editItem.cart)
    body.receiptEmail = person.editItem.root.pk.slice(person.editItem.root.pk.indexOf('-') + 1)
    body.amount = person.editItem.cart[cardType + 'CardAmount']
    body.primaryRegistrationType = person.editItem.cart.primaryRegistrationType
    body.secondaryItems = person.editItem.cart.secondaryItems
    body.cardType = cardType
    body.pk = person.editItem.root.pk
    body.sk = 'cart'
    body.includeMembership = person.editItem.cart.includeMembership && cardType === 'personal'
    body.relatedMembershipSk = person.editItem.cart.relatedMembershipSk

    return new Promise((resolve, reject) => {
      API.post(
          'stripeProcessing',
          '/intent',
          {body: {registration: body}}
        )
      .then(response => {
        const {clientSecret, paymentIntentId} = response

        this.setState({[cardType + 'paymentIntentId']: paymentIntentId})
        this.context.person.editItem.cart[cardType + 'PaymentIntentId'] = paymentIntentId
        resolve(clientSecret)
      })
      .catch(error => {
        if (error.type === 'StripeConnectionError') {
          this.setState({stripeError: error, connectionError: true, processingPayment: false})
        } else {
          this.setState({stripeError: error, processingPayment: false})
        }
        reject()
      })

    })
  }

  async confirmCardPayment(stripe, elements, clientSecret, cardType) {

    let {person} = this.context
    return new Promise((resolve, reject) => {
      stripe.confirmCardPayment(
        clientSecret,
        { receipt_email: person.editItem.root.primary_email_address,
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              email: person.editItem.root.primary_email_address,
              name: person.editItem.root.first_name + ' ' + person.editItem.root.last_name,
            }
          }}
      )
      .then(result => {
        if (result.error) {
          this.setState({[cardType + 'StripeError']: result.error, processingPayment: false})
          reject()
        } else {
          resolve()
        }
      })
      .catch(error => {
        // may not be a stripe error
        this.setState({[cardType + 'StripeError']: error.raw, processingPayment: false})
        reject()
      });
     })


  }

  completePurchase() {

    this.setState(
      {
        getPaymentIntentPersonal: (this.state.personalCardAmount > 0),
        getPaymentIntentGov: (this.state.govCardAmount > 0),
        gettingTokens: true
      },
      this.submit
    )
  }

  noCharge() {
    this.setState({
      personalCardChoice: false,
      govCardChoice: false,
    }, () => {
      this.submit()
    })
  }

  submit() {
    let {person} = this.context
    if (!person.editItem) {
      person.beginEdit()
    }


    let promises = []
    if (this.state.getPaymentIntentPersonal) {
      promises.push(this.getPaymentIntent('personal'))
    }
    if (this.state.getPaymentIntentGov) {
      promises.push(this.getPaymentIntent('gov')) // shorter for a reason
    }

    if (!this.state.getPaymentIntentPersonal && !this.state.getPaymentIntentGov) {
      person.editItem.cart.status='complete'
      person.endEdit(true)
      person.store()
      this.setState({
        confirmed: true
      })
    } else {
      Promise.all(promises)
      .then(clientSecrets => {

        person.editItem.cart.status='payment-intent-pending'

        if (this.state.getPaymentIntentPersonal) {
          promises.push(this.confirmCardPayment(this.state.personalCardStripe, this.state.personalCardElements, clientSecrets[0], 'personal'))
        }
        if (this.state.getPaymentIntentGov) {
          promises.push(this.confirmCardPayment(this.state.govCardStripe, this.state.govCardElements, clientSecrets.slice(-1)[0], 'gov'))
        }

        Promise.all(promises)
        .then(() => {
          // If we need to add a membership, do it here
          // To Do: clean up receipt (its included in the main registration receipt)
          if (person.editItem.cart.includeMembership) {
            // If we have added a convention related membership in the last 15 minutes
            // don't do it again. Likely this is a retry of a CC
            const registrationMemberships = person.editItem.membership.filter(item =>
              item.includedWithRegistration &&
              moment().isBefore(moment(item.created_at).add(15, 'minutes')))
            if (registrationMemberships.length === 0) {
              const membershipTimestamp = moment().unix()
              const membershipWithTimestamp = 'membership-' + membershipTimestamp
              person.editItem.membership.push({
                pk: person.editItem.root.pk,
                sk: membershipWithTimestamp,
                membershipType: 'y1',
                membershipTypeName: 'Convention Annual Membership',
                status: 'complete',
                statusText: 'Complete',
                termMonths: 12,
                includedWithRegistration: true,
                price: 40,
              })
              person.editItem.cart.relatedMembershipSk = membershipWithTimestamp
              person.setMembershipStartDate(membershipTimestamp)
            }

          }
          person.editItem.cart.status='complete'
          // We need to save and then read back to ensure the status

          person.endEdit(true)
          person.item['membership-summary'] = Person.membershipSummary(person.item.membership)
          person.store()
          this.setState({
            confirmed: true
          })
        })
        .catch(error => {
          console.log(error)
          this.setState({gettingTokens: false})
        })
      })
      .catch(error => {
        console.log(error)
        this.setState({gettingTokens: false})
      })
    }
  }


  render() {

    let {person} = this.context
    let {cart} = this.context.person.item

    if (person.isLoading || !person.isInitialized) {return null}

    if (this.state.confirmed) {
      return (
      <Confirmation isAdmin={this.props.isAdmin}
                    to="/account"
                    adminTo={'/admin/account/' + person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1)}
                     />
    )}

    const includeSecondCard = person.item.cart.govCardPrice > 0

    let subtitle
    if (includeSecondCard) {
      subtitle =
        'You can use a government payment card to pay for convention ' +
        'attendance. Other optional items, such as A/TA membership ' +
        'and guest fees, must be paid for with a personal or corporate card. ' +
        'Your total today is $' + person.item.cart.totalPrice +
        ' and of that amount you may pay $'+ person.item.cart.govCardPrice +
        ' using a government payment card.'
    } else {
      subtitle =
        'There are no items in your registration that are eligible to be paid for ' +
        'using a government charge card. ' +
        'You should use a personal or corporate credit card.  ' +
        'Your total today is $' + person.item.cart.totalPrice + '.'
    }

    const disableButton = !this.state.submitButtonActive
    const activeSpinner = this.state.gettingTokens

    if (cart.totalPrice === 0) {
      return (
        <NoCharge noCharge={this.noCharge}/>
      )
    }
//    let style, footer
    const footerStyle = {
      borderTopWith: '1px',
      borderTopStyle: 'solid',
      borderTopcolor: '#b40808',
      paddingTop: 8,
      textAlign: 'right',
      marginBottom: 12,
    }

   //  const priceStyle = {
   //    paddingTop: 8,
   //    textAlign: 'right',
   //    paddingRight: 10
   //  }
   //
   //  const overlayStyle = this.state.show ?
   //                       {display: 'block'} :
   //                       {display: 'none'};
   // const overlayClass = this.state.show ?
   //                      'visible' :
   //                      'hidden';
    return (
      <section className="usa-section" style={{paddingTop: 10, borderTopWidth: 0}}>
        <div className="grid-container margin-y-0">
          <h2 className="margin-y-0">Convention Registration - Credit Card Information</h2>
          <p className="usa-prose">{subtitle}</p>

          <div className="grid-row grid-gap">

            <CardDeck>
              <div style={{minWidth: 400, minHeight: 300, display: 'inline-block', marginRight: 20, marginBottom: 20, borderWidth: 1, borderStyle: 'solid', borderColor: '#cccccc', padding: 10, verticalAlign: 'top'}}>
                <div id="image-block" style={{display: 'inline-block', verticalAlign: 'top', width:60}}>
                  <i className="fad fa-credit-card-front fa-3x"></i>
                </div>

                <div id="content-block" style={{width: 320, display: 'inline-block', verticalAlign: 'top'}}>
                  <div id="detail-block" style={{display: 'inline-block', verticalAlign: 'top', marginLeft: 10, minHeight: 80}}>
                    <div id="title-block" style={{verticalAlign: 'top'}}>
                      <h2 style={{marginTop: 0, marginBottom: 5, width:300}}>Primary Card</h2>
                    </div>
                    {this.state.personalCardChoice &&
                      <div style={{minHeight: 202}}>
                        <div id="body-block" className="usa-prose">
                            <div style={{fontSize:'40px'}}><strong>${this.state.personalCardAmount}</strong></div>
                        </div>
                        <div className="personalCard">
                          <PaymentPersonalCard cardAvailable={this.cardAvailable}/>
                        </div>
                        {this.state.personalStripeError &&
                          <div style={{marginBottom: 6, marginTop: 6}}>
                          <FieldError
                            name="stripe_error"
                            message={this.state.personalStripeError.message}
                          />
                        </div>
                        }
                      </div>
                    }

                  </div>
              <div id="footer-block">
                <div style={footerStyle}>
                  <Form.Check
                    type="checkbox"
                    label=" Use this card"
                    name="personalCardChoice"
                    checked={this.state.personalCardChoice}
                    id="formPersonalCardChoice"
                    onChange={this.cardChoice}
                    disabled={this.state.personalCardAmount !== 0}
                    />
                </div>
              </div>
            </div>
            {!this.state.personalCardChoice &&
              <div style={{minHeight: 202}}>
                <p className="usa-prose margin-y-0">You can pay for your registration of ${person.item.cart.totalPrice} using this card</p>
              </div>
            }
          </div>

          {includeSecondCard && person.item.cart.govCardPrice !== person.item.cart.totalPrice &&
            <div style={{minWidth: 400, minHeight: 300, display: 'inline-block', marginRight: 20, marginBottom: 20, borderWidth: 1, borderStyle: 'solid', borderColor: '#cccccc', padding: 10, verticalAlign: 'top'}}>
              <div id="image-block" style={{display: 'inline-block', verticalAlign: 'top', width:60}}>
                <i className="far fa-credit-card-front fa-3x"></i>
              </div>
              <div id="content-block" style={{width: 320, display: 'inline-block', verticalAlign: 'top'}}>
                <div id="detail-block" style={{display: 'inline-block', verticalAlign: 'top', marginLeft: 10, minHeight: 80}}>
                  <div id="title-block" style={{verticalAlign: 'top'}}>
                      <h2 style={{marginTop: 0, marginBottom: 5}}>Government Payment Card</h2>
                  </div>

                  {this.state.govCardChoice &&
                    <div style={{minHeight: 202}}>
                    <div id="body-block" className="usa-prose margin-y-0">
                      <div style={{fontSize:'40px'}}><strong>${this.state.govCardAmount}</strong></div>
                    </div>
                    <div className="govCard">
                        <PaymentGovCard cardAvailable={this.cardAvailable}/>
                    </div>
                   </div>
                  }
                  {!this.state.govCardChoice &&
                    <div style={{minHeight: 202}} >
                      <p className="usa-prose margin-y-0">You can pay for your registration of ${person.item.cart.govCardPrice} using this card</p>
                    </div>
                  }
                  </div>

                <div id="footer-block">
                  <div style={footerStyle}>
                    <Form.Check
                      type="checkbox"
                      label=" Use this card"
                      name="govCardChoice"
                      id="formgovCardChoice"
                      checked={this.state.govCardChoice}
                      onChange={this.cardChoice}
                      />
                  </div>
                </div>
              </div>
              {this.state.govStripeError &&
                <div style={{marginBottom: 6, marginTop: 6}}>
                  <FieldError
                    name="stripe_error"
                    message={this.state.govStripeError.message}
                  />
              </div>
              }
            </div>
           }

            </CardDeck>
          </div>

            <p className="usa-prose">
              Your cards will be charged immediately. Registrations are non-transferable and cancellations incur a fee.
            </p>

          <div className="grid-row">
            <div className="grid-col-12">
              <ButtonActive
                onClick={() => this.completePurchase()}
                label="Complete Purchase"
                aria-label="empty-your-cart"
                className="usa-button"
                style={{textDecoration: 'none'}}
                active={activeSpinner}
                disabled={disableButton || activeSpinner}
              >
                Complete Purchase
              </ButtonActive>

            </div>
          </div>
        </div>
      </section>


  )
  }
}

function NoCharge(props) {
  return (
    <section className="usa-section" style={{paddingTop: 10, borderTopWidth: 0}}>
      <div className="grid-container margin-y-0">
        <h2 className="margin-y-0">
          Convention Registration - Credit Card Information
        </h2>
        <p className="usa-prose" style={{paddingBottom: 60}}>
          Normally this is where we capture credit card information. But
          your registration is free, so nothing to do here.
        </p>

        <div className="grid-row">
          <div className="grid-col-12">
            <ButtonActive
              onClick={() => props.noCharge()}
              label="Complete Purchase"
              aria-label="empty-your-cart"
              className="usa-button"
              style={{textDecoration: 'none'}}
            >
              Complete Purchase
            </ButtonActive>
          </div>
        </div>
      </div>
    </section>
  )
}


Payment.contextType = PersonContext
export default Payment
