import React, { Component } from 'react';

class FieldError extends Component {

  render() {
    return (
      <div class="usa-alert usa-alert--info usa-alert--slim" >
        <div class="usa-alert__body">
          <p class="usa-alert__text" name={this.props.name}>{this.props.message}</p>
        </div>
      </div>
    )
  }
}

export default FieldError
