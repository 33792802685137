import './App.scss'
import './badge.css'
import Amplify, { Auth } from 'aws-amplify'
import React, { Component } from 'react'
import GeneralError from './components/structure/GeneralError'
import Person from './classes/Person'
import {PersonContext} from './classes/context'
import Routes from './components/structure/Routes'
import aws_exports from './aws-exports'

// Allows data migration flows
aws_exports.authenticationFlowType = 'USER_PASSWORD_AUTH'
Amplify.configure(aws_exports)
//Auth.configure(aws_exports)

class App extends Component {

  constructor(props) {
    super(props);
    this.onStateChange = this.onStateChange.bind(this)

    this.state = {
      authState: 'noSignIn',
      person: new Person(),
      redirect: false
    };
  }

  onStateChange(authState, authData) {
    switch (authState) {
      case 'signOut':
        Auth.signOut()
        .then(() => {
          this.setState({authState: 'signin', person: new Person()})
        })
        .catch(error => {
          console.log(error)
          this.setState({authState: 'signOutFailed', person: new Person()})
        })
      break
      case 'signedin':
        this.setState(
          {personLoading: true, loadFailed: false, authState: authState},
            () => {
              Auth.currentUserInfo()
              .then(user => {
                if (user.username && !this.state.person.isLoading) {

                  this.state.person.get(user.attributes.email.toLowerCase())
                  .then(() => {
                    this.setState({personLoading: false, loadFailed: false})
                  })
                  .catch(error => {
                    this.setState({personLoading: false, loadFailed: true})
                    console.error('loading person', error)
                    alert('failed to load person: ' + user.attributes.email)
                  })
                }
              })
              .catch(error => console.log(error))
            }
          )
        break
      case 'signin':
        if (this.state.authState === 'confirmSignUp' || this.state.authState === 'forgotpassword') {
          this.setState({authState: authState, redirect: true})
        }
        break
      default:
        this.setState({authState: authState})
    }
  }

  render() {

    return (
      <PersonContext.Provider value={{person: this.state.person}}>
        <GeneralError authState={this.state.authState} />
        <Routes
          redirect={this.state.redirect}
          authState={this.state.authState}
          signOut={() => this.onStateChange("signOut")}
          onStateChange={authState => this.onStateChange(authState)}
        />
      </PersonContext.Provider>
    )
  }
}

export default App
