
import React, { Component } from 'react';
import {CompanyContext} from '../../../classes/context.js'

import Form from 'react-bootstrap/Form'

var moment = require('moment');

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class EditorIpMembership extends Component {

  keyValue(item, key, format) {
    const {company} = this.context

    if (!company.editItem) {return null}

    const i = company.editItem.memberships.findIndex(item => item.sk === this.props.editMode.sk)
    if (i === -1) {return null}
    if (format) {
      return (typeof(company.editItem.memberships[i][key]) === 'undefined' ||
              !company.editItem.memberships[i][key] ?
              null :
              moment(company.editItem.memberships[i][key]).format(format))
    }

    return (typeof(company.editItem.memberships[i][key]) === 'undefined' ? null : company.editItem.memberships[i][key])

  }

  render() {

    const {company} = this.context
    if (this.props.editMode.mode !== 'editIpMembership') {return null}
    if (!company.editItem) {return null}

    const handleChange = company.handleChange.bind(company)

    const sk = this.props.editMode.sk

    return (
      <Form>
        <h2 className="margin-y-5" style={{marginBottom: 0}}>Industry Partner Membership</h2>
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-4">
            <div className="usa-label">Start Date</div>
            <Form.Control className="usa-input" name={sk + ".membership_start_date"} plaintext defaultValue={this.keyValue(sk, 'membership_start_date', 'YYYY-MM-DD')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-4">
            <div className="usa-label">End Date</div>
            <Form.Control className="usa-input" name={sk + ".membership_end_date"} plaintext defaultValue={this.keyValue(sk, 'membership_end_date', 'YYYY-MM-DD')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>
      </Form>
      )
    }
}

EditorIpMembership.contextType = CompanyContext
export default EditorIpMembership;
