import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'

import {PersonContext} from '../../../classes/context.js';

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class GuestAttendeeInformation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      form: React.createRef()
    }

    this.handleChange = this.handleChange.bind(this);
  }


  nn(field) {
    let person = this.context.person
    let item = person.editItem ? person.editItem : person.item
    return item.cart[field] ? item.cart[field] : ''
  }

  handleChange(event) {
    let person = this.context.person
    if (!person.editItem) {person.beginEdit()}
    const handleChange = person.handleChange.bind(person)
    handleChange(event)
  }

  render() {

    // Added because virtual convention does not have any guests.
    // return null

    let person = this.context.person
    if (this.props.includesGuest ) {
      // do nothing
    } else {
      if (!person.item.cart.includesGuest || !person.item.cart.secondaryItems) {return null}
    }

    let hasGuestBanquet = person.item.cart.secondaryItems
      .filter(item => item.sk === 'secondaryGuestAll' || item.sk === 'secondaryGuestFridayBanquet')
    let hasGuestDietaryRequest = person.item.cart.secondaryItems
      .filter(item => item.sk === 'secondaryGuestDiet')

    return (

      <>
      <h2 className="margin-y-5" style={{marginBottom: 0}}>Guest Information</h2>

      <div className="grid-row grid-gap">
        <div className="grid-col-4">
          <div className="usa-label">Guest First Name</div>
          <Form.Control
            className="usa-input"
            name="cart.secondaryGuestFirstName"
            plaintext
            defaultValue={this.nn('secondaryGuestFirstName')}
            style={editingStyle}
            onChange={this.handleChange}/>
        </div>

        <div className="grid-col-4">
          <div className="usa-label">Guest Last Name</div>
          <Form.Control
            className="usa-input"
            name="cart.secondaryGuestLastName"
            plaintext
            defaultValue={this.nn('secondaryGuestLastName')}
            style={editingStyle}
            onChange={this.handleChange}/>
        </div>
        <div className="grid-col-3">
          <div className="usa-label">Guest Nationality</div>
          <Form.Control
            className="usa-input"
            name="cart.secondaryGuestNationality"
            plaintext
            defaultValue={this.nn('secondaryGuestNationality')}
            style={editingStyle}
            onChange={this.handleChange}/>
        </div>
      </div>

      {hasGuestBanquet.length > 0 &&
          <div className="grid-row grid-gap">
            <div className="grid-col-6">
              <div className="usa-label">Guest Banquet Seating Request</div>
              <Form.Control
                className="usa-input"
                name="cart.secondaryGuestBanquetSeatingRequest"
                plaintext
                defaultValue={this.nn('secondaryGuestBanquetSeatingRequest')}
                style={editingStyle}
                onChange={this.handleChange}/>
            </div>
            { hasGuestDietaryRequest.length > 0 &&
              <div className="grid-col-6">
                <div className="usa-label">Guest Dietary Restrictions</div>
                <Form.Control
                  className="usa-input"
                  name="cart.secondaryGuestBanquetDietaryPreference"
                  plaintext
                  defaultValue={this.nn('secondaryGuestBanquetDietaryPreference')}
                  style={editingStyle}
                  onChange={this.handleChange}/>
              </div>
            }
          </div>
        }
      </>
    )
  }

}

GuestAttendeeInformation.contextType = PersonContext
export default GuestAttendeeInformation
