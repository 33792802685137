import React, { Component } from 'react'
import { CSVLink } from "react-csv"
import { API } from 'aws-amplify'

import Loading from '../../Loading'
import ButtonActive from '../../ButtonActive'

const greenPill = {
  padding: 6, borderRadius: 6, lineHeight: 2, backgroundColor: 'green', marginRight: 5, color: 'white'
}

const bluePill = {
  padding: 6, borderRadius: 6, lineHeight: 2, backgroundColor: '#0A3161', marginRight: 5, color: 'white'
}

class AdminBanquet extends Component {

  constructor(props) {
    super(props)

    this.state = {
      tableSize: 12,
      assignTablesActive: false,
      putActive: false,
      activeItem: false,
      activeRequestedTableItem: false,
      activeItemAssignedGroup: null,
      requestedTable: null,
      tableOccupancy: [],
      vipTables: [],
      sortBy: null,
      orderBy: 1, // 1 or -1
      changed: true, // will be false by default when we are saving assignments to the server
      warnings: [],
      csv: []
    }

    this.assignTables = this.assignTables.bind(this)
    this.updateItem = this.updateItem.bind(this)
    this.makeActive = this.makeActive.bind(this)
    this.putItem = this.putItem.bind(this)
    this.updateAssignedGroup = this.updateAssignedGroup.bind(this)
    this.completeAssignedGroup = this.completeAssignedGroup.bind(this)
    this.makeRequestedTableActive = this.makeRequestedTableActive.bind(this)
    this.completeRequestedTable = this.completeRequestedTable.bind(this)
    this.updateRequestedTable = this.updateRequestedTable.bind(this)
    this.handleTableSizeChange = this.handleTableSizeChange.bind(this)
    this.sortBy = this.sortBy.bind(this)
    this.header = this.header.bind(this)
    this.getBanquetCsv = this.getBanquetCsv.bind(this)

    this.active = React.createRef()
  }

  componentDidUpdate() {
    if (this.active.current) {
      this.active.current.focus();
    }
  }

  putItem(item) {
    let that = this
    this.setState({putActive: true})
    API.put('banquet', '/banquet', {body: {pk: item.pk, sk: item.sk, isVip: item.isVip, assignedGroup: item.assignedGroup, requestedTable: item.requestedTable}})
    .then(() => {
      that.setState({putActive: false, csv: this.getBanquetCsv()})
    })
    .catch(error => {
      that.setState({putActive: false, csv: this.getBanquetCsv()})
      console.log(error)
    })
  }

  updateItem(item) {
    item.isVip = !item.isVip
    this.putItem(item)
  }

  handleTableSizeChange(change) {
    this.setState(prevState => {
       return {tableSize: Math.max(prevState.tableSize + change, 5), changed: true}
    })
  }

  sortBy(field) {
    let orderBy = (this.state.sortBy === field) ? -1 * this.state.orderBy : 1
    this.setState({sortBy: field, orderBy: orderBy})
  }

  // Groups

  makeActive(item) {
    this.setState({activeItem: item})
  }

  completeAssignedGroup(event) {
    if (event.key === "Enter" || event.type === 'blur') {
      let item = this.state.activeItem
      item.assignedGroup = this.state.activeItemAssignedGroup
      this.putItem(this.state.activeItem)
      // If there is a guest, we need to update the VIP table assignment locally
      // It will be correctly assigned on the next refresh from the server without
      // us doing anything then. But we do need to do it now.

      const {banquet, banquetPagesCurrent} = this.props.banquet

      let hasGuest = banquet[banquetPagesCurrent].findIndex(item => item.guestOfEmail === this.state.activeItem.pk)
      if (hasGuest !== -1) {
        banquet[banquetPagesCurrent][hasGuest].assignedGroup = this.state.activeItemAssignedGroup
      }

      this.setState({activeItem: false, activeItemAssignedGroup: null, changed: true})
    }
    if (event.key === "Escape") {
      this.setState({activeItem: false, activeItemAssignedGroup: null})
    }
  }

  updateAssignedGroup(event) {
    this.setState({activeItemAssignedGroup: event.target.value})
  }
  // End Groups
  // Requested Table

  makeRequestedTableActive(item) {
    this.setState({activeRequestedTableItem: item})
  }

  completeRequestedTable(event) {
    if (event.key === "Enter" || event.type === 'blur') {
      let item = this.state.activeRequestedTableItem
      item.requestedTable = this.state.requestedTable
      this.putItem(this.state.activeRequestedTableItem)

      // If there is a guest, we need to update the VIP table assignment locally
      // It will be correctly assigned on the next refresh from the server without
      // us doing anything then. But we do need to do it now.
      const {banquet, banquetPagesCurrent} = this.props.banquet

      let hasGuest = banquet[banquetPagesCurrent].findIndex(item => item.guestOfEmail === this.state.activeRequestedTableItem.pk)
      if (hasGuest !== -1) {
        banquet[banquetPagesCurrent][hasGuest].requestedTable = this.state.requestedTable
      }
      this.setState({activeRequestedTableItem: false, requestedTable: null, changed: true})
    }
    if (event.key === "Escape") {
      this.setState({activeRequestedTableItem: false, requestedTable: null})
    }
  }

  updateRequestedTable(event) {
    this.setState({requestedTable: event.target.value})
  }
  // End Requested Table

  assignTables() {
    const {banquet, banquetPagesCurrent} = this.props.banquet

    this.setState({assignTablesActive: true})

    let {assignments, tableOccupancy, vipTables, warnings} = tableAssignments(banquet[banquetPagesCurrent], this.state.tableSize)

    this.setState({tableOccupancy: tableOccupancy, vipTables: vipTables, warnings: warnings})

    let that = this
    API.post('banquet', '/banquet', {body: {tableSize: this.state.tableSize, assignments: assignments}})
    .then(() => {
      that.setState({assignTablesActive: false, changed: false, csv: this.getBanquetCsv()})
    })
    .catch(error => {
      that.setState({assignTablesActive: false, changed: false, csv: this.getBanquetCsv()})
      console.log(error)
    })
  }

  render() {
  if (this.props.filter !== 'banquet') {return null}

  const {banquet, banquetPages, banquetPagesCurrent,
         banquetLoading,
         banquetMoreDataAvailable} = this.props.banquet

  let minTablesRequired = banquet[banquetPagesCurrent] ? Math.trunc((banquet[banquetPagesCurrent].length / this.state.tableSize) + 1) : ''

  const unsortedPages = banquet[banquetPagesCurrent]
  let sortedPages = []
  if (banquet.length > 0) {
    sortedPages = unsortedPages.sort((a, b) => {

      let aName, bName

      if (this.state.sortBy) {
        aName = a[this.state.sortBy] ? String(a[this.state.sortBy]).trim().toLowerCase() : 'zz'
        bName = b[this.state.sortBy] ? String(b[this.state.sortBy]).trim().toLowerCase() : 'zz'
      } else {
        if (a.guestOfLastName) {
          aName = a.guestOfLastName.trim().concat(a.guestOfFirstName.trim())
        } else {
          aName = a.lastName ? a.lastName.trim().concat(a.firstName.trim()) : 'zz'
        }

        if (b.guestOfLastName) {
          bName = b.guestOfLastName.trim().concat(b.guestOfFirstName.trim())
        } else {
          bName = b.lastName ? b.lastName.trim().concat(b.firstName.trim()) : 'zz'
        }

      }

      if (aName < bName) {return -1 * this.state.orderBy}
      if (aName > bName) {return this.state.orderBy}
      return 0
    })

  }

  const banquetCsv = (this.state.csv.length === 0) ? this.getBanquetCsv() : this.state.csv

  return (
    <>
    <h2>Detail
    <span style={{marginLeft: 10, fontSize: 'smaller'}}>
       <CSVLink
         style={{verticalAlign: 'bottom'}}
         data={banquetCsv}
       >
        <i className="fas fa-file-spreadsheet fa-2x" style={{fontSize: 'smaller'}}> </i>
       </CSVLink>
     </span>
     <span>
       {banquetPagesCurrent === 0 || banquetLoading ?
         <span style={{marginLeft: 5, fontSize: 'small', color: 'grey', cursor: 'pointer'}} >
           <i className="fas fa-fast-backward"></i>
         </span> :
         <span style={{marginLeft: 5, fontSize: 'small', color: '#b50909', cursor: 'pointer'}} onClick={() => this.props.pagination('banquet', 'first')}>
           <i className="fas fa-fast-backward"></i>
         </span>
       }
       {(banquetMoreDataAvailable || banquetPagesCurrent + 1 !== banquetPages) && !banquetLoading ?
         <span>
           <span style={{fontSize: 'small', fontWeight: 'normal', paddingLeft: 5}}>Page {banquetPagesCurrent + 1} of {banquetMoreDataAvailable ? <span>unknown</span> : <span>{banquetPages}</span>} </span>
           <span style={{marginLeft: 5, fontSize: 'small', color: '#b50909', cursor: 'pointer'}} disabled={!banquetMoreDataAvailable} onClick={() => this.props.pagination('banquet', 'next')}>
             <i className="fas fa-forward"></i>
           </span>
        </span> :
        <span>
          <span style={{fontSize: 'small', fontWeight: 'normal', paddingLeft: 5}}>Page {banquetPagesCurrent + 1} of {banquetMoreDataAvailable ? <span>unknown</span> : <span>{banquetPages}</span>} </span>
          <span style={{marginLeft: 5, fontSize: 'small', color: 'grey', cursor: 'pointer'}}>
            <i className="fas fa-forward"></i>
          </span>
        </span>
       }
     </span>
     {banquetLoading &&
       <i className="fas fa-sync-alt fa-spin" style={{marginLeft: 5, fontSize: 'small', color: '#b50909'}} ></i>
     }

    </h2>
    <div className="grid-row grid-gap margin-y-2">
      <div className="grid-col-3">
        <div className="usa-prose">Table Size: {this.state.tableSize} <i className="fas fa-plus" onClick={() => this.handleTableSizeChange(1)}></i>/<i className="fas fa-minus" onClick={() => this.handleTableSizeChange(-1)}></i></div>
        <div className="usa-prose">Attendees & Guests: {sortedPages.length}</div>
        <div className="usa-prose">Min Tables Required: {minTablesRequired}</div>
        <div className="usa-prose">Actual Tables Used: {this.state.tableOccupancy.filter(size => size > 0).length}</div>
        <div className="usa-prose">Actual VIP Tables Used: {this.state.vipTables.length}</div>
      </div>
      <div className="grid-col-9">
        {this.state.tableOccupancy &&
          <div style={{marginTop: 20, marginBottom: 20}}>
            {this.state.tableOccupancy.map((table, index) => {
              if (this.state.vipTables.includes(index)) {
                return (
                  <span key={index} style={greenPill}>VIP&nbsp;{index}:&nbsp;{table} </span>
                )
              }
              if (table === 0) {return null}
              return (
                <span key={index} style={bluePill}>Regular&nbsp;{index}:&nbsp;{table} </span>
              )}
            )}
          </div>
        }
      </div>
    </div>
    {this.state.warnings.length > 0 &&
      <div>
        {this.state.warnings.map((warning, index) => {
          return (
            <div key={index}><i className="fas fa-exclamation-triangle"></i> {warning}</div>
          )
        })}

      </div>
    }
    <hr style={{marginBottom: 10}}/>
    <ButtonActive
      className="usa-button"
      label="Assign Tables"
      active={this.state.assignTablesActive}
      disabled={this.state.assignTablesActive || !this.state.changed || banquet.length === 0}
      onClick={this.assignTables}
    />
    { banquetLoading && banquet.length === 0 ?
      <Loading title="" subtitle=""/> :
      <table className="usa-table usa-table--borderless" style={{width:'100%', marginBottom: 32, marginTop: 0, fontSize: 'smaller'}}>
        <thead>
          <tr>
            <th style={{cursor: 'pointer', color: '#b50908'}} onClick={() => this.sortBy(false)}>{this.header(false)} Name</th>
            <th style={{cursor: 'pointer', color: '#b50908'}} onClick={() => this.sortBy('isVip')}>{this.header('isVip')} VIP</th>
            <th>Guest Of</th>
            <th style={{cursor: 'pointer', color: '#b50908'}} onClick={() => this.sortBy('table')}>{this.header('table')} Assigned Table</th>
            <th style={{cursor: 'pointer', color: '#b50908'}} onClick={() => this.sortBy('requestedTable')}>{this.header('requestedTable')} VIP Table</th>
            <th style={{cursor: 'pointer', color: '#b50908'}} onClick={() => this.sortBy('assignedGroup')}>{this.header('assignedGroup')} Requested Group</th>
            <th style={{cursor: 'pointer', color: '#b50908'}} onClick={() => this.sortBy('seatingRequest')}>{this.header('seatingRequest')} Seating Request</th>
            <th style={{cursor: 'pointer', color: '#b50908'}} onClick={() => this.sortBy('dietaryPreference')}>{this.header('dietaryPreference')} Dietary Preference</th>
            <th style={{cursor: 'pointer', color: '#b50908'}} onClick={() => this.sortBy('chapterName')}>{this.header('chapterName')} Closest Chapter</th>
            <th style={{cursor: 'pointer', color: '#b50908'}} onClick={() => this.sortBy('base_name')}>{this.header('base')} Base</th>
            <th style={{cursor: 'pointer', color: '#b50908'}} onClick={() => this.sortBy('organization')}>{this.header('organization')} Org</th>
          </tr>
        </thead>
        <tbody>
          {banquet.length > 0 && sortedPages.map(item =>
            <BanquetItem
              key={item.pk}
              item={item}
              activeRef={this.active}
              updateItem={this.updateItem}
              makeActive={this.makeActive}
              makeRequestedTableActive={this.makeRequestedTableActive}
              activeItem={this.state.activeItem}
              activeRequestedTableItem={this.state.activeRequestedTableItem}
              updateAssignedGroup={this.updateAssignedGroup}
              completeAssignedGroup={this.completeAssignedGroup}
              updateRequestedTable={this.updateRequestedTable}
              completeRequestedTable={this.completeRequestedTable}
            />
          )}
        </tbody>
      </table>
    }
    </>
  )
}

header(attribute) {
  if (this.state.sortBy === attribute) {
    if (this.state.orderBy > 0) {
      return (<i className="fas fa-arrow-circle-down"></i>)
    }
    return (<i className="fas fa-arrow-circle-up"></i>)
  }
  return null
}

getBanquetCsv() {

  const {banquet, banquetPagesCurrent} = this.props.banquet

  if (banquet.length === 0) {return []}
  let items = banquet[banquetPagesCurrent]
  let csv = items.map(item => {

     return [item.pk.slice(item.pk.indexOf('-') + 1),
            item.requestedTable,
            item.assignedGroup,
            item.isVip,
            item.table,
            item.firstTimeAttendee,

            item.service_name,
            item.rank_abbreviation,
            item.status_name,
            item.fullname,
            item.firstName,
            item.lastName,

            item.guestOfFullname,
            item.guestOfEmail,
            item.guestFullname,
            item.spouse_first_name,
            item.spouse_last_name,

            item.chapterName,
            item.base_name,
            item.organization,
            item.role,
            item.exhibitorOrganization,

            item.seatingRequest,
            item.dietaryPreference,
          ]
  })

    csv.unshift(['Email',
                 'Requested Table',
                 'Assigned Group',
                 'VIPs',
                 'Table',
                 'First Time Attendee',

                 'Service Name',
                 'Rank Abbreviation',
                 'Service Status',
                 'Fullname',
                 'First Name',
                 'Last Name',

                 'Guest of Fullname',
                 'Guest of Email',
                 'Guest Fullname',
                 'Spouse First Name',
                 'Spouse Last Name',

                 'Chapter Name',
                 'Base Name',
                 'Organization',
                 'Role',
                 'Exhibitor Org',

                 'Seating Request',
                 'Dietary Peference',

               ]
                 )

  return csv
}

}
 function BanquetItem(props) {
  const {item, updateItem,
         activeItem, makeActive,
         updateAssignedGroup, completeAssignedGroup,
         activeRef,
         activeRequestedTableItem, makeRequestedTableActive,
         updateRequestedTable, completeRequestedTable} = props

  let account = item.pk.slice(item.pk.indexOf('-') + 1).replace(':guest', '')
  let email, guestOfEmail
  if (item.guestOfEmail) {
    email = <span>{item.fullname}</span>
    guestOfEmail = <a href={"/admin/account/" + account}>{item.guestOfFullname}&nbsp;<i className="fas fa-link"></i></a>

  } else {
    email = <a href={"/admin/account/" + account}>{item.fullname}&nbsp;<i className="fas fa-link"></i></a>
    guestOfEmail = <span>{item.guestOfFullname}</span>

  }

  const isVip = item.isVip ? 'Yes' : 'No'

  let requestedTable
  if (item.pk === activeRequestedTableItem.pk) {
    requestedTable=  <input style={{  backgroundColor: '#eeeeee',
      borderStyle: 'none',
      padding: 4,
      marginLeft: -6, width: '100%'}}
      ref={activeRef} plaintext defaultValue={item.requestedTable} onBlur={completeRequestedTable} onKeyUp={completeRequestedTable} onChange={updateRequestedTable}/>

  } else {
    if (item.guestOfEmail) {
      requestedTable = <span>{(!item.requestedTable || item.requestedTable === '') ? <span>(none)</span> : <span>{item.requestedTable}</span>}</span>
    } else {
      requestedTable = <span style={{cursor: 'pointer', color: '#b50908'}} onClick={() => makeRequestedTableActive(item)}>{(!item.requestedTable || item.requestedTable === '') ? <span>(none)</span> : <span>{item.requestedTable}</span>}</span>
    }
  }

  let assignedGroup
  if (item.pk === activeItem.pk) {
    assignedGroup=  <input style={{  backgroundColor: '#eeeeee',
      borderStyle: 'none',
      padding: 4,
      marginLeft: -6, width: '100%'}}
      ref={activeRef} plaintext defaultValue={item.assignedGroup} onBlur={completeAssignedGroup} onKeyUp={completeAssignedGroup} onChange={updateAssignedGroup}/>

  } else {
    if (item.requestedTable && item.requestedTable !== '') {
        assignedGroup = null
    } else {
      if (item.guestOfEmail) {
        assignedGroup = <span>{(!item.assignedGroup || item.assignedGroup === '') ? <span>(none)</span> : <span>{item.assignedGroup}</span>}</span>
      } else {
        assignedGroup = <span style={{cursor: 'pointer', color: '#b50908'}} onClick={() => makeActive(item)}>{(!item.assignedGroup || item.assignedGroup === '') ? <span>(none)</span> : <span>{item.assignedGroup}</span>}</span>
      }
    }
  }

  return (
    <tr style={{verticalAlign: 'top', fontSize: 'small'}}>
      <td>{email}</td>
      <td><span onClick={() => updateItem(item)} style={{cursor: 'pointer', color: '#b50908'}}>{isVip}</span></td>
      <td>{guestOfEmail}</td>
      <td>{item.table}</td>
      <td>{requestedTable}</td>
      <td>{assignedGroup}</td>
      <td>{item.seatingRequest}</td>
      <td>{item.dietaryPreference}</td>
      <td>{item.chapterName}</td>
      <td>{item.base_name}</td>
      <td>{item.organization}</td>
    </tr>
  )
}

function tableAssignments(attendees, tableSize) {

  let warnings = []
  // Create an array for 200 tables (well above any reasonable maximum)
  let tableOccupancy = new Array(200)
  let groups = []
  for (let i = 0; i < tableOccupancy.length; i += 1) {
    tableOccupancy[i] = 0
  }

  // Remove all the current assignments
  for (let i = 0; i < attendees.length; i += 1) {
    attendees[i].table = null
  }

  // Create a list of VIP tables
  let vipTables = []

  // Assign the fixed VIP tables
  for (let i = 0; i < attendees.length; i += 1) {
    if (attendees[i].requestedTable) {
      attendees[i].table = attendees[i].requestedTable
      tableOccupancy[attendees[i].requestedTable] += 1

      if (tableOccupancy[attendees[i].requestedTable] === 1) {
        vipTables.push(Number(attendees[i].requestedTable))
      }
    }
  }

  // Identify groups
  for (let i = 0; i < attendees.length; i += 1) {
    if (!attendees[i].requestedTable && attendees[i].assignedGroup) {
      let groupIndex = groups.findIndex(item => item.group === attendees[i].assignedGroup)
      if (groupIndex === -1) {
        groups.push({group: attendees[i].assignedGroup, size: 1})
      } else {
        groups[groupIndex].size += 1
      }
    }
  }

  // sort the group names to improve adjacency of the groups.
  groups.sort((a, b) => {
    if (a.group > b.group) {return 1}
    if (a.group < b.group) {return -1}
    return 0
  })

  // Assign groups to tables
  for (let i = 0; i < groups.length; i += 1) {
    // Find the first table with enough space
    // Make sure we don't choose table zero

    let firstTableWithSpace = tableOccupancy.findIndex((currentOccupancy, table) => (table > 0 && tableSize - currentOccupancy >= groups[i].size && !vipTables.includes(table)))

    if (firstTableWithSpace === -1) {
      warnings.push('No table with sufficient space: ' + groups[i].group + ' has ' + groups[i].size + ' members')
    } else {
      // Go through all the attendees, find those with that group and assign them to the table
        for (let j = 0; j < attendees.length; j += 1) {
          if (attendees[j].assignedGroup === groups[i].group) {
            attendees[j].table = firstTableWithSpace
            tableOccupancy[firstTableWithSpace] += 1
          }
        }
      }
    }

  // Finally assign everyone else

  for (let j = 0; j < attendees.length; j += 1) {
    if (!attendees[j].table) {
      let firstTableWithSpace = tableOccupancy.findIndex((currentOccupancy, table) => (table > 0 && tableSize - currentOccupancy >= 1 && !vipTables.includes(table)))
      attendees[j].table = firstTableWithSpace
      tableOccupancy[firstTableWithSpace] += 1
    }
  }

  return {assignments: null, tableOccupancy: tableOccupancy, vipTables: vipTables, warnings: warnings}

}

export default AdminBanquet;
