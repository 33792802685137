import React, { Component } from 'react';
import { Link } from "react-router-dom";

class AssociationHighlights extends Component {

  render() {

    const format = this.props.dark ? "usa-section usa-section--dark usa-graphic-list" : "usa-section usa-graphic-list"

    return (
      <section className={format}>
        <div className="grid-container">

        <div className="usa-graphic-list__row grid-row grid-gap">
          <div className="usa-media-block tablet:grid-col">
            <div className="grid-row">
              <div className="grid-col-5">
                <span className="usa-media-block__img"><i className="fas fa-user-hard-hat fa-fw fa-7x"></i></span>
              </div>
              <div className="grid-col-7">
                <div className="usa-media-block__body">
                  <h3 className="usa-graphic-list__heading">Corporate Partnerships</h3>
                  <p className="usa-prose">The A/TA provides its
                  <> <Link
                    to="/industry-partners"
                    title="Industry Partners"
                    aria-label="industry-partners"
                    className="usa-button--unstyled"
                  >
                  partners
                  </Link> </>
                  a forum specifically focused on air mobility. The exchange/interface between military, industry and government personnel afforded by association activities is a significant source of information, professional development and marketing exposure.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="usa-media-block grid-col">
            <div className="grid-row">
              <div className="grid-col-5">
                <span className="usa-media-block__img"><i className="fas fa-map-pin fa-fw fa-7x"></i></span>
              </div>
              <div className="grid-col-7">
                <div className="usa-media-block__body">
                  <h3 className="usa-graphic-list__heading">Chapters</h3>
                  <p className="usa-prose">Our local
                  <> <Link
                    to="/chapters"
                    title="A/TA Chapters"
                    aria-label="chapters"
                    className="usa-button--unstyled"
                  >
                  Chapters
                  </Link> </>
                  are the Association’s life-blood. Chapters provide a forum from which members can “lead from below,” often greatly affecting the course of mobility operations.</p>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="usa-graphic-list__row grid-row grid-gap">
          <div className="usa-media-block tablet:grid-col">
            <div className="grid-row">
              <div className="grid-col-5">
                <span className="usa-media-block__img"><i className="fas fa-books fa-fw fa-7x"></i></span>
              </div>
              <div className="grid-col-7">
                <div className="usa-media-block__body">
                  <h3 className="usa-graphic-list__heading">Programs</h3>
                  <p className="usa-prose">The A/TA administers multiple
                  <> <Link
                    to="/awards-program"
                    title="Awards Programs"
                    aria-label="awards-programs"
                    className="usa-button--unstyled"
                  >
                  annual awards
                  </Link> </>
                  that recognize the best within the air mobility community.
                  A/TA also invest in the future by providing
                  <> <Link
                    to="/enlisted-education-grant"
                    title="Enlisted Education Grants"
                    aria-label="enlisted-education-grant"
                    className="usa-button--unstyled"
                  >
                  education assistance
                  </Link> </>
                  to support the growth of the next generation of air mobility leaders.</p>

                </div>
              </div>
            </div>
          </div>

          <div className="usa-media-block grid-col">
            <div className="grid-row">
              <div className="grid-col-5">
                <span className="usa-media-block__img"><i className="fas fa-ticket-alt fa-fw fa-7x"></i></span>
              </div>
              <div className="grid-col-7">
                <div className="usa-media-block__body">
                  <h3 className="usa-graphic-list__heading">Membership</h3>
                  <p className="usa-prose">Our
                  <> <Link
                    to="/become-a-member"
                    title="Become a Member"
                    aria-label="become-a-member"
                    className="usa-button--unstyled"
                  >
                  membership
                  </Link> </>
                  is dedicated ensuring American military forces continue to have the air mobility capability required to implement U.S. national security strategy. </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        </div>
      </section>

    )
  }
}

export default AssociationHighlights;
