import React, { Component } from 'react';
import { Link, Redirect, useLocation } from "react-router-dom";
import Loading from '../Loading';
import ErrorContainer from '../ErrorContainer';
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import ErrorMessage from '../ErrorMessage';
import { CSVLink } from "react-csv"
import ToolbarAdminHome from '../controls/ToolbarAdminHome'

import { API } from 'aws-amplify';
import {Section, ButtonStatic, PageHeader} from '../sections/Section';

var moment = require('moment')

const modalStyle = {
    background: '#fff',
    border: '#b40808',
    borderRadius: '5px',
    marginLeft: '-200px',
    textAlign:'center',
    position: 'fixed',
    left: '45%',
    top: '20%',
    zIndex: 11,
    width: '500px',
    minHeight: '250px',
    boxShadow:'0 5px 10px rgba(0,0,0,0.3)',
    transform: 'translate(0, 0)',
    transition: 'transform 0.3s ease-out',
    outline: 'none',
    pointEvents: 'auto',

  };

class Coupon extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.beginAdd = this.beginAdd.bind(this)

    this.state = {
      error: null,
      coupon: [],
      isLoaded: false,
      showAddCoupon: false,
      couponPrefix: '',
      csv: []
    };
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    API.get('general', '/coupon')
    .then(response => {
      const used = response.items.filter(item => !item.available)
      const available = response.items.filter(item => item.available)
      let csv = response.items.map(item => {
        return [item.sk, item.type, item.available, item.includesIid, item.includesGuest, item.usedBy]
      })
      csv.unshift(['name', 'type', 'available', 'includes IID', 'includes Guest', 'used By'])
      this.setState(
        {items: response.items,
        used: used,
        available: available,
        csv: csv,
        isLoaded: true }
      )
    })
    .catch(error => {
      this.setState({ isLoaded: false, error: error })
    })
  }

  handleChange(event) {
    const target = event.target;
    const value = ['includesGuest', 'includesIid'].includes(target.name) ?
                  target.checked :
                  target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleDelete(coupon) {

    API.del('coupon', '/coupon', {body: {coupon: coupon}})
    .then(() => {
      let items = this.state.items.filter(item => item.sk !== coupon)
      const used = items.filter(item => !item.available)
      const available = items.filter(item => item.available)
      let csv = items.map(item => {
        return [item.sk, item.type, item.available, item.includesIid, item.includesGuest, item.usedBy]
      })
      csv.unshift(['name', 'type', 'available', 'includes IID', 'includes Guest', 'used By'])
      this.setState(
        {items: items,
        used: used,
        available: available,
        csv: csv,
        isLoaded: true }
      )
    })
    .catch(error => {
      this.setState({ isLoaded: false, error: error })
    })
  }

  handleClose(generateCoupons) {

    if (!generateCoupons) {
      this.setState({showAddCoupon: false})
      return
    }

    const body = {
      numberOfCoupons: this.state.numberOfCoupons,
      couponType: this.state.couponType,
      couponPrefix: this.state.couponPrefix,
      includesGuest: this.state.includesGuest,
      includesIid: this.state.includesIid,
    }
    this.setState({isLoaded: false})

    API.post('coupon', '/coupon', {body: body})
    .then(() => {
      this.loadData()
      this.setState({showAddCoupon: false});
    })
    .catch(error => {
      this.setState({ isLoaded: false, error: error })
    })
  }

  beginAdd() {
    this.setState({showAddCoupon: true})
  }

  render() {

    const { error } = this.state;

    let items
    switch (this.state.filter) {
      case 'available':
        items = this.state.available
        break
      case 'used':
        items = this.state.used
        break
      default:
        items = this.state.items
    }
    return (
      <Section>
      <div className="grid-container margin-y-0">
        <PageHeader title="Coupon Managament" />
        <div className="grid-row">
          <div className="mobile-lg:grid-col">
            <ToolbarAdminHome isAdmin/>
            <ButtonStatic onClick={this.beginAdd} style={{marginTop: 8}}>
              Add
            </ButtonStatic>
            <CSVLink
              className="usa-button"
              style={{verticalAlign: "bottom"}}
              data={this.state.csv}
            >
              <i className="fas fa-file-csv"></i> CSV</CSVLink>
          </div>

          <div className="mobile-lg:grid-col">
            <Form.Control
              className="usa-select"
              style={{backgroundColor: '#eeeeee'}}
              as="select"
              name="filter"
              onChange={this.handleChange}
            >
              <option key="all" value="all">All Coupons</option>
              <option key="available" value="available">Available</option>
              <option key="used" value="used">Used</option>
            </Form.Control>
          </div>
        </div>
      </div>

      <SearchRequest />
      {error &&
        <ErrorContainer title="Error" error={error} />
      }

      {!error &&
        <div className="grid-container margin-y-0">
          <Coupons
            filter={this.state.filter}
            items={items}
            isLoaded={this.state.isLoaded}
            handleDelete={this.handleDelete}
          />

          <ModalForm
            showAddCoupon={this.state.showAddCoupon}
            handleChange={this.handleChange}
            handleClose={this.handleClose}
            includesGuest={this.state.includesGuest}
            includesIid={this.state.includesIid}
            couponType={this.state.couponType}
            numberOfCoupons={this.state.numberOfCoupons}
            couponPrefix={this.state.couponPrefix}
          />
        </div>

      }



    </Section>

    )
  }
}


function ModalForm(props) {

  if (!props.showAddCoupon) {return null}

  return (
    <Modal style={modalStyle} size="lg" show={true} onHide={() => props.handleClose(false)}>
      <Modal.Header closeButton className="modal-header-additional">
        <h2 style={{float:'left', maxWidth: '80%', marginLeft: 10, textAlign: 'left'}}>Add Coupon</h2>
      </Modal.Header>
      <Modal.Body className="modal-body">

        <div className="grid-row grid-gap" style={{marginLeft: 20, marginRight: 20}}>
          <AddCoupon
            handleChange={props.handleChange}
            includesGuest={props.includesGuest}
            includesIid={props.includesIid}
            couponType={props.couponType}
            numberOfCoupons={props.numberOfCoupons}
          />
        </div>
        <ErrorMessage message={props.errorMessage} />
      </Modal.Body>
      <Modal.Footer style={{minHeight: 36, marginBottom: 10}}>
        <ButtonStatic
          title="Generate Coupons"
          aria-label="Generate Coupons"
          className="usa-button"
          disabled={props.couponType === '0' || !(props.numberOfCoupons > 0) || props.couponPrefix.length !== 4}
          name="generateCoupons"
          onClick={() => props.handleClose(true)}
        >
          Add Coupons
        </ButtonStatic>
      </Modal.Footer>
    </Modal>
  )

}

function AddCoupon(props) {

  return (
    <>
    <div className="grid-col-5" style={{ marginBottom: 32 }}>
      <div className="usa-label">Coupon Type</div>
      <Form.Control
        className="usa-select"
        style={{ backgroundColor: "#eeeeee" }}
        as="select"
        name="couponType"
        onChange={props.handleChange}
      >
        <option key="0" value="0">Select One</option>
        <option key="company" value="company">Company</option>
        <option key="general" value="general">General</option>
        <option key="military" value="military">Military</option>
        <option key="ata" value="ata">A/TA Staff</option>
      </Form.Control>

      <div className="usa-label">4 Character Prefix</div>
      <Form.Control className="usa-input"
                    id="count"
                    name="couponPrefix"
                    plaintext maxlength="4" minlength="4"
                    style={{backgroundColor: '#eeeeee'}}
                    onChange={props.handleChange}
                    value={props.couponPrefix}
      />


      <div className="usa-label"
           htmlFor="count" >
        # of Coupons
      </div>
      <Form.Control className="usa-input"
                    id="count"
                    name="numberOfCoupons"
                    plaintext maxlength="2"
                    type="number"
                    style={{backgroundColor: '#eeeeee'}}
                    onChange={props.handleChange}
                    value={props.numberOfCoupons}
      />
    </div>
    <div className="grid-col-7" style={{ marginBottom: 32 }}>
      <div className="usa-label">Options</div>
      <div style={{textAlign: 'left', marginTop: 10, marginBottom: 10}}>
        <Form.Check
          name="includesGuest"
          type="checkbox"
          label=" Includes Guest"
          checked={props.includesGuest}
          onChange={props.handleChange}
        />
        <Form.Check
          name="includesIid"
          type="checkbox"
          label=" Includes IID"
          checked={props.includesIid}
          onChange={props.handleChange}
        />
      <small style={{marginTop: 10}}>
         Coupons always apply to the main registration.
        </small>
      </div>
    </div>
    </>
  )
}

function Coupons(props) {

  if (!props.isLoaded) {
    return <Loading title="" subtitle=""/>;
   }
  return (
    <table className="usa-table usa-table--borderless" style={{width:'100%', marginBottom: 32, fontSize: 'smaller'}}>
      <thead>
        <tr>
          <th>Coupons</th>
          <th>Type</th>
          <th style={{textAlign: "center"}}>Includes Guest</th>
          <th style={{textAlign: "center"}}>Includes IID</th>
          <th>Assigned To</th>
          <th>Used At</th>
        </tr>
      </thead>
      <tbody>
        {props.items.map(coupon =>
          <tr key={coupon.sk}>
            <td>
              <span
                className="usa-button usa-button--unstyled"
                onClick={() => props.handleDelete(coupon.sk)}
                ariaLabel="Delete"
                style={{textDecoration: 'none', marginRight: 5}}
              >
                <i className="fas fa-trash-alt"></i>
              </span>
              {coupon.sk}
            </td>
            <td>{coupon.type}</td>
            <td style={{textAlign: "center"}}>{coupon.includesGuest ? <div>Yes</div> : <div>No</div>}</td>
            <td style={{textAlign: "center"}}>{coupon.includesIid ? <div>Yes</div> : <div>No</div>}</td>
            <td>
              {coupon.usedBy ?
                <Link
                  to={"/admin/account/" + coupon.usedBy}
                  className="usa-button usa-button--unstyled"
                  ariaLabel="Unlink"
                  style={{textDecoration: 'none', marginLeft: 5}}
                >{coupon.usedBy}
                </Link> :
              <span>N/A</span>
          }
            </td>
            <td>{coupon.usedAt && <span>{moment(coupon.usedAt).format("YYYY-MM-DD hh:mm")}</span>}</td>

          </tr>
        )}
      </tbody>
    </table>
  )
}

function SearchRequest() {
  const {search, pathname} = useLocation();

  if (!search) {return null}
  const keywords = search.split('=');

  // If there are other parameters on the URL, this means somebody (facebook)
  // added the parameter. Remove.
  if (keywords[0] !== '?search') {return <Redirect to={pathname} />}

  return <Redirect to={"/?search=" + keywords[1]} />
}


export default Coupon;
