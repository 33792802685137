import React, { Component } from 'react';
import { useLocation, Redirect } from "react-router-dom";

import Loading from '../Loading';
import Hero from '../sections/Hero'
import LatestNews from '../sections/LatestNews'
import Sponsors from '../sections/Sponsors'
import AtqAnnouncement from '../sections/AtqAnnouncement'
import IntroducingAta from '../sections/IntroducingAta'
import AssociationHighlights from '../sections/AssociationHighlights'
import WelcomeMessage from '../sections/WelcomeMessage'
import GeneralAnnouncement from '../sections/GeneralAnnouncement'
import Search from '../sections/Search'
import {ContentfulContext} from '../../classes/context'

class Body extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      page: null,
    };
  }

  componentDidMount() {
    if (!this.state.isLoaded) {
      const contentfulClient = this.context.contentfulClient

      contentfulClient.getEntries({'content_type': 'homePage'})
      .then(entries => {
        this.setState({
          isLoaded: true,
          page: entries.items[0],
        });
      })
      .catch(error => {
        console.log(error)
        this.setState({
          error: error
        })
      })
    }
  }


  render() {

    if (!this.state.isLoaded) {
      return <Loading title=''
                      subtitle=''/>;
    }

    const {page} = this.state

    // get the banner information
    const section0 = {
      text: page.fields.section0BannerText,
      headline: page.fields.section0ArticleReference.fields.headline,
      headlineContentType: page.fields.section0ArticleReference.sys.contentType.sys.id,
      unpublishDate: page.fields.section0UnpublishDate,
    }

    const section1 = {
      headline: page.fields.section1Headline,
      body: page.fields.section1Body,
      actionLabel: page.fields.section1ActionLabel,
      link: page.fields.section1ActionDestination,
    }

    return (
      <main id="main-content">
        <SearchRequest />
        <Standard
          env={this.props.env}
          section0={section0}
          section1={section1}
        />
      </main>
    )
  }
}

function SearchRequest() {
  const {search} = useLocation()
  if (!search) return null
  const keywords = search.split('=')
  if (keywords[0] !== '?search') return <Redirect to="/" />

  return <Search keyword={keywords[1]} />
}

function Standard(props) {
  const {search} = useLocation();
  if (search) return null

  const {section0, section1} = props
  return (
    <>
      <WelcomeMessage content={section0}/>
      <Hero env={props.env}/>
      <GeneralAnnouncement content={section1} dark={true}/>
      <IntroducingAta dark={false}/>
      <AssociationHighlights dark={true}/>
      <LatestNews dark={false} />
      <AtqAnnouncement dark={true}/>
      <Sponsors dark={false}/>
    </>
  )
}
// Other potential Sections
// <Convention dark={true} />

Body.contextType = ContentfulContext
export default Body;
