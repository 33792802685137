import React from 'react'

export default function RegistrationHistory(props) {
  const {isAdmin, convention, firstTimeAttendee } = props

  if (!isAdmin) {return null}

  const registrationsSorted = convention.filter(a => a.primaryRegistrationType).sort((a, b) => {
    if (a.sk < b.sk) {return 1}
    if (a.sk > b.sk) {return -1}
    return 0
  })

  if (registrationsSorted.length === 0) {return null}

  if (firstTimeAttendee === 'Yes') {
    return (
      <div className="usa-alert usa-alert--info usa-alert--slim" style={{ marginLeft: 20, marginBottom: 10 }} >
        <div className="usa-alert__body">
          <p className="usa-alert__text">First Time Attendee.</p>
        </div>
      </div>
    )
  }

  return (
    <div className="usa-prose" style={{ marginLeft: 20 }}>
      <div className="usa-accordion">

        <h3 className="usa-accordion__heading">
          <button className="usa-accordion__button"
            aria-expanded="true"
            aria-controls="mh">
            Prior Convention Attendance
          </button>
        </h3>
        <div id="mh" className="usa-accordion__content" >
          <table  className="usa-table usa-table--borderless " style={{width:'100%', marginTop: 0, fontSize: 'small'}}>
            <thead>
              <tr>
                <th style={{textAlign: 'left', paddingLeft: 8}}>Year</th>
                <th style={{textAlign: 'left'}}>Type</th>
                <th style={{textAlign: 'center', width: 200}}>Attended Industry Interface Day</th>
                <th style={{textAlign: 'center', width: 200}}>Attended as Member</th>
                <th style={{textAlign: 'center'}}>Status</th>
                <th style={{textAlign: 'center'}}>Price</th>
              </tr>
            </thead>
            <tbody>
              {registrationsSorted.map(registration => {
                const includesIid = registration.primaryRegistrationType.isIndustryDayOnly ||
                                    (registration.secondaryItems &&
                                    registration.secondaryItems.filter(item => item.includesIndustryDay).length > 0)
                const price = registration.totalPrice ? '$' +registration.totalPrice : '-'

                const status = registration.statusText ?? registration.status
                return (
                  <tr key={registration.pk}>
                    <td style={{textAlign: 'left', paddingLeft: 8, verticalAlign: 'top'}}>{registration.sk.slice(11)}</td>
                    <td style={{textAlign: 'left', verticalAlign: 'top'}}>
                      {registration.primaryRegistrationType.name}
                      {registration.company && <span style={{fontSize: 'small', fontStyle: 'italic'}}><br />{registration.company}</span> }
                    </td>
                    <td style={{textAlign: 'center', verticalAlign: 'top'}}>{yesNo(includesIid)}</td>
                    <td style={{textAlign: 'center', verticalAlign: 'top'}}>{yesNo(registration.isValidConventionMembership)}</td>
                    <td style={{textAlign: 'center', verticalAlign: 'top'}}>{status}</td>
                    <td style={{textAlign: 'center', verticalAlign: 'top'}}>{price}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

function yesNo(value) {
  if (value) {
    return (<>Yes</>)
  }
  return (<>No</>)
}
