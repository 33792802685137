import React, { Component } from 'react';
import { API } from 'aws-amplify'

import { ButtonStatic, PageHeader } from '../../sections/Section'
import ToolbarAdminHome from '../../controls/ToolbarAdminHome'
import ToolbarCoupon from '../../controls/ToolbarCoupon'
import { Link, Redirect, useLocation } from "react-router-dom"
import Badge from '../../controls/Badge'

import ButtonActive from '../../ButtonActive'

var moment = require('moment')

// Structure
// There are multiple modes for displaying the control bar
// view: The user is viewing the person
//   If the user is an admin, during view the following also are available
//   - actions: the actions available to the admin are displayed
//   - delete-person: the user is viewing the delete person confirmation screen
//   - merge-person: the user is viewing the merge person screen
//   - edit-primary-email: the user is viewing the change primary email screen
// edit: The user is editing the person
// membership: The user is adding a membership
// membership-edit: The user is editing a membership

// The editMode is as follows {view: XXX, adminView: YYY}

class AccountControl extends Component {

  constructor(props) {
    super(props)
    this.selectAction = this.selectAction.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      targetEmail: '',
      secondaryTypesAll: false
    }
  }

  componentDidMount() {
    API.get('general', '/reference/registration-type')
    .then(response => {

      const items = response.items.sort(function(a, b) {
        if (a.displayOrder < b.displayOrder) {return -1}
        if (a.displayOrder > b.displayOrder) {return 1}
        return 0;
      });

      const secondary = items.filter(item => {
          return (item.type === 'secondary' && item.isVisible);
        });

      this.setState({secondaryTypesAll: secondary});
    })
    .catch(error => {
      console.log(error)
      this.setState({error: error})
    });
  }

  selectAction(event) {

    const { startEdit } = this.props
    switch (event.target.value) {
      case 'edit-primary-email':
      case "delete-person":
      case "edit-primary-email":
      case "merge-with":
      case "add-comment":
      case "registration-delete":
      case "undo-cancellation-request":
      case "add-secondary":
      case "remove-secondary":
      case "confirm-secondary-payment":
      case "print":
        startEdit({mode: 'view', adminMode: event.target.value})
        break
      case "confirm-registration-cancellation":
        this.props.updateConfirmationModalVisibility(true)
        break


      default:
        // nothing
    }
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  render() {

    return (
      <>
        <AdminRedirect adminMenus={this.props.adminMenus}/>
        <PageHeader title="Individual Profile" />
        <div className="grid-row margin-y-1">
          <div className="mobile-lg:grid-col-12">
            <ToolbarAdminHome {...this.props}/>
            <ToolbarCoupon {...this.props}/>
            <Default {...this.props} />
            <Membership  {...this.props} />
            <Edit {...this.props} />
          </div>
        </div>
        {this.props.isAdmin &&
          <div className="grid-row margin-y-1">
            <div className="mobile-lg:grid-col-12">
              <SelectAction selectAction={this.selectAction} {...this.props}/>
              <AddComment {...this.props} handleChange={this.handleChange} comment={this.state.comment}/>
              <UndoCancellationRequestConfirmation {...this.props} />
              <DeletePersonConfirmation {...this.props} />
              <DeleteRegistrationConfirmation {...this.props} />
              <EditPrimaryEmail {...this.props} handleChange={this.handleChange} targetEmail={this.state.targetEmail}/>
              <MergeWith {...this.props} handleChange={this.handleChange} targetEmail={this.state.targetEmail}/>
              <RemoveSecondaryOption {...this.props} handleChange={this.handleChange} secondaryTypesAll={this.state.secondaryTypesAll} secondaryItemToRemove={this.state.secondaryItemToRemove}/>
              <AddSecondaryOption {...this.props} handleChange={this.handleChange} secondaryTypesAll={this.state.secondaryTypesAll} secondaryItemToAdd={this.state.secondaryItemToAdd}/>
              <ConfirmSecondaryPayment {...this.props} />
              <PrintBadge {...this.props} />
            </div>
          </div>
        }
      </>
    )
  }
}
//

export default AccountControl;

function PrintBadge(props) {
  const {editMode, endEdit} = props
  if (editMode.mode !== 'view' || editMode.adminMode !== 'print' ) {return null}

  return (

    <Badge person={props.person} endEdit={endEdit}/>
  )
}


function AdminRedirect(props) {
  const {pathname} = useLocation();
  if (props.adminMenus && pathname === '/account') {
    return <Redirect to="/admin" />
  }
  return null
}

function Edit(props) {
  const {editMode, endEdit, isNew} = props

  if (editMode.mode !== 'edit' && editMode.mode !== 'editMembership' &&
      editMode.mode !== 'edit-registration-info') {return null}

  return (
    <>
      <ButtonStatic onClick={() => endEdit(true)}>
        <i className="fas fa-check"></i> OK
      </ButtonStatic>
      <ButtonStatic onClick={() => endEdit(false)} disabled={isNew}>
        <i className="fas fa-times"></i> Cancel
      </ButtonStatic>
    </>
  )
}

function Membership(props) {
  const {editMode, endEdit, addMembership,
        processingPayment, membershipTimestamp,
        membershipReadyToSubmit, noCharge, stripeError} = props

  if (editMode.mode !== 'membership') {return null}
  const label = noCharge ? 'Add Membership' : <><i className="fas fa-credit-card"></i> Pay</>

  return (
    <>
      <ButtonActive
        onClick={() => addMembership(true, membershipTimestamp, noCharge)}
        label={label}
        aria-label="add-membership"
        className="usa-button"
        style={{textDecoration: 'none'}}
        active={processingPayment}
        disabled={!membershipReadyToSubmit || processingPayment || stripeError}
      />

      <ButtonStatic onClick={() => endEdit(false)}>
        <i className="fas fa-times"></i> Cancel
      </ButtonStatic>
    </>
  )
}

function Default(props) {
  const {editMode, startEdit,
        hasActiveRegistration, hasCancellationRequest, // hasCart,
        hasCancellationConfirmed,
        updateModalVisibility,
        pk, isAdmin, isNew, hasCart, hasIid} = props

  if (editMode.mode !== 'view') {return null}
  const membershipTimestamp = moment().unix()

  let editLabel = 'Edit'
  if (isAdmin) {
    editLabel = isNew ? <i className="fas fa-plus"></i> : <><i className="fas fa-user-alt" /> Edit</>
  }

  return (
      <>
        {editMode.mode === 'view' &&
          <>
            <ButtonStatic
              onClick={() => startEdit({mode: 'edit', adminMode: 'view'}, null)}
              className="usa-button usa-tooltip"
              dataPosition="top"
              title="Edit Profile"
            >
              {editLabel}
            </ButtonStatic>
            <ButtonStatic
              onClick={() => startEdit({mode: 'membership', adminMode: 'membership'}, membershipTimestamp)}
              className="usa-button usa-tooltip"
              dataPosition="top"
              title="Add a Membership"
            >
              {isAdmin ? <span><i className="fas fa-address-card"></i> Add</span> : <span>Add Membership</span>}

            </ButtonStatic>
            {!hasCart &&
              <CustomLink
                className="usa-button usa-tooltip"
                to="/catalog"
                adminTo={"/admin/catalog/" + pk}
                isAdmin={isAdmin}
                label={isAdmin ? <span><i className="fas fa-users-class"></i> Add</span> : "Register"}
                icon="fa-users-class"
                data-position="right"
                title="Edit Profile"
              />
            }
            {hasCart &&
             !hasActiveRegistration &&
             !hasCancellationRequest &&
             !hasCancellationConfirmed &&
              <CustomLink
                className="usa-button usa-tooltip"
                dataPosition="top"
                title="Complete Registration"
                to="/cart"
                adminTo={"/admin/cart/" + pk}
                isAdmin={isAdmin}
                label={isAdmin ? <span><i className="fas fa-users-class"></i> Complete</span> : "Complete Registration"}
              />
            }
            {hasCart && !hasCancellationRequest && !hasCancellationConfirmed &&
              <>

              <ButtonStatic
                onClick={() => startEdit({mode: 'edit-registration-info', adminMode: 'view'}, null)}
                className="usa-button usa-tooltip"
                dataPosition="top"
                title="Edit Registration"
              >
                <i className="fas fa-users-class" /> Edit
              </ButtonStatic>
              {!hasIid &&
                <CustomLink
                  className="usa-button usa-tooltip"
                  dataPosition="top"
                  title="Add Industry Interface Day"
                  to="/iid"
                  adminTo={"/admin/iid/" + pk}
                  isAdmin={isAdmin}
                  label={isAdmin ? <span><i className="fas fa-users-class"></i> Add IID</span> : "Add IID"}
                />
              }
              <ButtonStatic
                className="usa-button usa-tooltip"
                dataPosition="top"
                title="Request Registration Cancellation"
                onClick={() => updateModalVisibility(true)}
              >
                {isAdmin ? <span><i className="fas fa-users-class"></i> Cancel</span> : <span>Cancel Registration</span>}
              </ButtonStatic>
              </>
            }
          </>
        }
      </>
    )
  }
function SelectAction(props) {

  const {editMode, isAdmin, hasCancellationRequest} = props
  if (editMode.adminMode !== 'view' || !isAdmin) {return null}

  const hasSecondaryRegistrationItems = (props.secondaryItems && props.secondaryItems.length > 0)
  return (
    <span style={{display: "flex", paddingRight: 8, width: 250, marginRight: "auto"}}>
      <select className="usa-select" onChange={props.selectAction} style={{marginTop:0, backgroundColor: '#eeeeee'}}>
        <option key="action" value="action">Select an action...</option>
        <option disabled>──────────────────</option>
        <option key="add-comment" value="add-comment">Add Comment</option>
        <option disabled>──────────────────</option>
        <option key="delete-person" value="delete-person">Delete Person</option>
        <option key="merge-with" value="merge-with">Merge Into</option>
        <option key="edit-primary-email" value="edit-primary-email">Change Primary Email</option>
        <option disabled>──────────────────</option>
        <option key="undo-cancellation-request" disabled={!hasCancellationRequest} value="undo-cancellation-request">Undo Cancellation Request</option>
        <option key="registration-confirm-cancellation" disabled={!hasCancellationRequest} value="confirm-registration-cancellation">Confirm Cancellation</option>
        <option key="registration-delete" value="registration-delete" style={{color: '#b50908'}}>Delete Registration</option>
        <option key="add-secondary" value="add-secondary">Add Secondary Option</option>
        <option key="remove-secondary" disabled={!hasSecondaryRegistrationItems} value="remove-secondary">Remove Secondary Option</option>
        <option key="confirm-secondary-payment" value="confirm-secondary-payment">Confirm Secondary Payment</option>
        <option key="print" value="print">Print Badge</option>
      </select>
      </span>
    )
  }

// function EditRegistrationInfo(props) {
//   const {editMode, endEdit, isAdmin} = props
//
//   if (editMode.adminMode !== 'edit-registration-info' || !isAdmin) {return null}
//
// }

function DeletePersonConfirmation(props) {
  const {editMode, endEdit, isAdmin} = props

  if (editMode.adminMode !== 'delete-person' || !isAdmin) {return null}

  return (
    <span style={{display: "flex"}}>
      <span style={{ marginRight: 'auto', display: 'inline', marginRight: 10, marginTop: 0}}>
        <ButtonStatic onClick={() => endEdit(true)}>
          Confirm Delete
        </ButtonStatic>
        <ButtonStatic className="usa-button usa-button--unstyled" style={{textDecoration: 'none'}} onClick={() => endEdit(false)}>
          Cancel
        </ButtonStatic>
        </span>
    </span>
  )
}

function ConfirmSecondaryPayment(props) {
  const {editMode, endEdit, isAdmin} = props

  if (editMode.adminMode !== 'confirm-secondary-payment' || !isAdmin) {return null}

  return (
    <span style={{display: "flex"}}>
      <span style={{ marginRight: 'auto', display: 'inline', marginRight: 10, marginTop: 0}}>
        <ButtonStatic onClick={() => endEdit(true)}>
          Confirm Payment
        </ButtonStatic>
        <ButtonStatic className="usa-button usa-button--unstyled" style={{textDecoration: 'none'}} onClick={() => endEdit(false)}>
          Cancel
        </ButtonStatic>
        </span>
    </span>
  )
}

function UndoCancellationRequestConfirmation(props) {
  const {editMode, endEdit, isAdmin} = props

  if (editMode.adminMode !== 'undo-cancellation-request' || !isAdmin) {return null}

  return (
    <span style={{display: "flex"}}>
      <span style={{ marginRight: 'auto', display: 'inline', marginRight: 10, marginTop: 0}}>
        <ButtonStatic onClick={() => endEdit(true)}>
          Confirm Undo Cancellation Request
        </ButtonStatic>
        <ButtonStatic className="usa-button usa-button--unstyled" style={{textDecoration: 'none'}} onClick={() => endEdit(false)}>
          Cancel
        </ButtonStatic>
        </span>
    </span>
  )
}


function DeleteRegistrationConfirmation(props) {
  const {editMode, endEdit, isAdmin} = props

  if (editMode.adminMode !== 'registration-delete' || !isAdmin) {return null}

  return (
    <span style={{display: "flex"}}>
      <span style={{ marginRight: 'auto', display: 'inline', marginTop: 0}}>
        <ButtonStatic onClick={() => endEdit(true)}>
          Confirm Registration Delete
        </ButtonStatic>
        <ButtonStatic className="usa-button usa-button--unstyled" style={{textDecoration: 'none'}} onClick={() => endEdit(false)}>
          Cancel
        </ButtonStatic>
        </span>
    </span>
  )
}


function AddComment(props) {
  const {editMode, endEdit, isAdmin, handleChange, comment} = props

  if (editMode.adminMode !== 'add-comment' || !isAdmin) {return null}

  return (
    <span style={{display: "flex"}}>
      <input className="usa-input" id="comment" name="comment" type="text"
        style={{ flex: 2, display: 'inline', marginRight: 10, marginTop: 0, backgroundColor: '#eeeeee'}}
        placeholder="Comment"
        maxLength={50}
        onChange={handleChange}
      />

      <ButtonStatic onClick={() => endEdit(true, {comment: comment})}>
        OK
      </ButtonStatic>
      <ButtonStatic className="usa-button usa-button--unstyled" style={{textDecoration: 'none'}} onClick={() => endEdit(false)}>
        Cancel
      </ButtonStatic>
    </span>
  )
}

function AddSecondaryOption(props) {
  const {editMode, endEdit, isAdmin, handleChange, secondaryItemToAdd, secondaryItems, secondaryTypesAll} = props

  if (editMode.adminMode !== 'add-secondary' || !isAdmin) {return null}
  let disabled = (!secondaryItemToAdd || secondaryItemToAdd === "0")

  return (
    <span style={{display: "flex"}}>
      <span style={{marginRight: 8}}>
      <select className="usa-select" name="secondaryItemToAdd" onChange={handleChange} style={{marginTop:0, backgroundColor: '#eeeeee'}}>
        <option key="0" value="0">Select One</option>
        {secondaryTypesAll.map(item => <option key={item.id} value={item.sk}>{item.name}</option> )}
      </select>
      </span>
      <ButtonStatic disabled={disabled} onClick={() => endEdit(true, {secondaryItemToAdd: secondaryItemToAdd, secondaryTypesAll: secondaryTypesAll})}>
        Add
      </ButtonStatic>
      <ButtonStatic className="usa-button usa-button--unstyled" style={{textDecoration: 'none'}} onClick={() => endEdit(false)}>
        Cancel
      </ButtonStatic>
    </span>
  )
}


function RemoveSecondaryOption(props) {
  const {editMode, endEdit, isAdmin, handleChange, secondaryItemToRemove, secondaryItems, secondaryTypesAll} = props

  if (editMode.adminMode !== 'remove-secondary' || !isAdmin) {return null}
  let disabled = (!secondaryItemToRemove || secondaryItemToRemove === "0")

  return (
    <span style={{display: "flex"}}>
      <span style={{marginRight: 8}}>
      <select className="usa-select" name="secondaryItemToRemove" onChange={handleChange} style={{marginTop:0, backgroundColor: '#eeeeee'}}>
        <option key="0" value="0">Select One</option>
        {secondaryItems.map(item => <option key={item.id} value={item.sk}>{item.name}</option> )}
      </select>
      </span>

      <ButtonStatic disabled={disabled} onClick={() => endEdit(true, {secondaryItemToRemove: secondaryItemToRemove, secondaryTypesAll: secondaryTypesAll})}>
        Remove
      </ButtonStatic>
      <ButtonStatic className="usa-button usa-button--unstyled" style={{textDecoration: 'none'}} onClick={() => endEdit(false)}>
        Cancel
      </ButtonStatic>
    </span>
  )
}

function EditPrimaryEmail(props) {
  const {editMode, endEdit, isAdmin, handleChange, targetEmail} = props

  if (editMode.adminMode !== 'edit-primary-email' || !isAdmin) {return null}

  return (
    <span style={{display: "flex"}}>
      <input className="usa-input" id="targetEmail" name="targetEmail" type="text"
        style={{flex: 2, display: 'inline', marginRight: 10, marginTop: 0, backgroundColor: '#eeeeee'}}
        placeholder="New primary email address"
        onChange={handleChange}
      />
      <ButtonStatic onClick={() => endEdit(true, {targetEmail: targetEmail})}>
        Change
      </ButtonStatic>
      <ButtonStatic className="usa-button usa-button--unstyled" style={{textDecoration: 'none'}} onClick={() => endEdit(false)}>
        Cancel
      </ButtonStatic>
    </span>
  )
}

function MergeWith(props) {
  const {editMode, endEdit, isAdmin, handleChange, targetEmail} = props

  if (editMode.adminMode !== 'merge-with' || !isAdmin) {return null}

  return (
    <span style={{display: "flex"}}>
      <input className="usa-input" id="targetEmail" name="targetEmail" type="text"
        style={{flex: 2, display: 'inline', marginRight: 10, marginTop: 0, backgroundColor: '#eeeeee'}}
        placeholder="Email to merge into"
        onChange={handleChange}
      />
      <ButtonStatic onClick={() => endEdit(true, {targetEmail: targetEmail})}>
        Confirm
      </ButtonStatic>
      <ButtonStatic className="usa-button usa-button--unstyled" style={{textDecoration: 'none'}} onClick={() => endEdit(false)}>
        Cancel
      </ButtonStatic>
    </span>
  )
}


function CustomLink(props) {
  let to = props.isAdmin ? props.adminTo : props.to
  const label = props.isAdmin ? <span>{props.label} </span> : props.label
  return (
    <Link
      className={props.className}
      to={to}
      style={props.style}
      >
      {label}
    </Link>
  )
}
