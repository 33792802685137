import React, { Component } from 'react';

class PeopleSearch  extends Component {

  render() {

    const {handleChange, handleKeyDown, handleSearch, handleMatchClick,
           searching, emailSearchActive, matches, matchSelectedIndex, searchError, hideMatches} = this.props

    return (
      <>
      <span style={{display: 'flex', marginRight: -60}}>
        <input
          className="usa-input"
          id="admin-email"
          placeholder="email, name or city"
          style={{display: 'inline-block', verticalAlign: 'middle', width: '80%'}}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          name="emailSearch"
        />
        {searching &&
          <span style={{marginLeft:-38, width: 20, marginRight: 8, display: 'inline-block', verticalAlign: 'sub'}}>
            <i className="fas fa-sync-alt fa-spin" style={{marginLeft: 5, fontSize: 'small', color: '#b50909', marginTop: 20}} ></i><
          /span>
        }
        <button className="usa-button"
          onClick={handleSearch}
          style=
            {{backgroundImage: 'url(/static/media/search.c160cdf7.svg), linear-gradient(transparent, transparent)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: '1rem',
              width: '2.5rem',
              height: '2.5rem',
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              marginTop: '0.5rem',
              display: 'inline-block'}
            }
          disabled={!emailSearchActive}
        >

        </button>
        {matches.length > 0 && !hideMatches &&
          <ul className="search-dropdown" style={{width: '88%'}}>
            {matches.map((item, index) => {
              const matchSelected = matchSelectedIndex === index ?
                                    'matchSelected' :
                                    ''
              return <li key={index} className={matchSelected} onClick={() => handleMatchClick(item.email, index)} value={item.email}>{item.fullname} ({item.email})</li>
            })}
          </ul>
        }
      </span>
      {searchError &&
        <div className="usa-tag" style={{padding: 5, marginRight: 8, marginTop: 5, backgroundColor: "#c60000", fontSize: 'small', textAlign: 'left', display:'inline-block'}}>Search is temporarily unavailable. Try again in a few minutes. You can still use an exact email address. </div>
      }
      </>
    )
  }

  }
export default PeopleSearch
