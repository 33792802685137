import { Link } from "react-router-dom"
import React from 'react'

function PersonWithActions(props) {

  const {item, deletePerson, showPrint, name, email,
        togglePrint, printSelected,
        toggleReviewed, reviewedSelected,
        togglePrinted, printedSelected} = props

  let displayEmail = email ? email : false
  let displayName = name ? name : false

  let itemReviewed = reviewedSelected ?
    <span style={{color: '#b50908'}} className="usa-tooltip" data-position="top" title="Reviewed"> <i className="fas fa-thumbs-up" /></span> :
    <span className="usa-tooltip" data-position="top" title="Not Reviewed"><i className="fas fa-question" /></span>
  let itemPrinted = printedSelected ?
    <span style={{color: '#b50908'}} className="usa-tooltip" data-position="top" title="Printed"><i className="fas fa-handshake" /></span> :
    <span className="usa-tooltip" data-position="top" title="Not Printed"><i className="fas fa-handshake-alt-slash" /></span>

  if (!displayName) {

    displayName = item ? item.pk.slice(item.pk.indexOf('-') + 1) : 'unknown'
  }

  if (!displayEmail) {
    displayEmail = item ? item.pk.slice(item.pk.indexOf('-') + 1) : 'unknown'
  }

  let confirmBadge = true && showPrint

  return (
    <>
    <Link
      to={"/admin/account/" + displayEmail}
      className="usa-button usa-button--unstyled usa-tooltip" data-position="top" title="View Person"
      style={{textDecoration: 'none', fontSize: 'small'}}
    >
      {displayName}
    </Link> <br />
    {confirmBadge && item.registrationStatus === 'complete' &&
      <div className="usa-checkbox" style={{display: 'inline', fontSize: 'smaller'}}>
        <input className="usa-checkbox__input" id={displayEmail} checked={printSelected} name={displayEmail} type="checkbox" onChange={() => togglePrint(item.pk)}/>
        <label className="usa-checkbox__label" htmlFor={displayEmail} style={{paddingLeft: 0}}></label>
      </div>
    }
    { showPrint && item.registrationStatus === 'complete' && toggleReviewed &&
      <div style={{display: 'inline', marginRight: 10, cursor: 'pointer'}} onClick={() => toggleReviewed(item.pk)}>
        {itemReviewed}
      </div>
     }
     { showPrint && item.registrationStatus === 'complete' && togglePrinted &&
       <div style={{display: 'inline', marginRight: 10, cursor: 'pointer'}} onClick={() => togglePrinted(item.pk)}>
         {itemPrinted}
       </div>
      }
    {deletePerson &&
      <Link
        className="usa-tooltip" data-position="top" title="Delete Person"
        style={{marginRight: 10}}
        onClick={() => deletePerson(displayEmail)}
      >
        <i className="fas fa-user-minus"></i>
      </Link>
    }

    </>
  )
}

export default PersonWithActions;
