import React, { Component } from 'react'
import { CSVLink } from "react-csv"
import { Redirect } from "react-router-dom"

import Loading from '../../Loading'
import CompanyWithActions from './common/CompanyWithActions'
import { ButtonStatic } from '../../sections/Section'

var moment = require('moment')

class AdminCompanies extends Component {

  constructor(props) {
    super(props)

    this.handleCompaniesChange = this.handleCompaniesChange.bind(this)
    this.handleExhibitorsChange = this.handleExhibitorsChange.bind(this)
    this.handleIndustryPartnersChange = this.handleIndustryPartnersChange.bind(this)
    this.newIp = this.newIp.bind(this)
    this.state = {
      industryPartners: true,
      exhibitors: true,
      companies: true
    }

  }

  handleCompaniesChange() {
    this.setState({
      companies: !this.state.companies
    })
  }

  handleExhibitorsChange() {
    this.setState({
      exhibitors: !this.state.exhibitors
    })
  }

  handleIndustryPartnersChange() {
    this.setState({
      industryPartners: !this.state.industryPartners
    })
  }

  newIp() {
    // Creates a random uuid that will result in the new IP version of the
    // Industry Partner edit page being displayed.
    this.setState({redirectUuid: uuidv4()})
  }

render() {
  if (this.props.filter !== 'companies') {return null}

  const {companies, companiesPages, companiesPagesCurrent,
         companiesCsv, companiesLoading,
         companiesMoreDataAvailable} = this.props.companies

  if (this.state.redirectUuid) {return <Redirect to={"/admin/company/" + this.state.redirectUuid} />}

  return (
    <>
    <h2>Detail
    <span style={{marginLeft: 10, fontSize: 'smaller'}}>
       <CSVLink
         style={{verticalAlign: 'bottom'}}
         data={companiesCsv}
       >
        <i className="fas fa-file-spreadsheet fa-2x" style={{fontSize: 'smaller'}}> </i>
       </CSVLink>
     </span>
     <span>
       {companiesPagesCurrent === 0 || companiesLoading ?
         <span style={{marginLeft: 5, fontSize: 'small', color: 'grey', cursor: 'pointer'}} >
           <i className="fas fa-fast-backward"></i>
         </span> :
         <span style={{marginLeft: 5, fontSize: 'small', color: '#b50909', cursor: 'pointer'}} onClick={() => this.props.pagination('companies', 'first')}>
           <i className="fas fa-fast-backward"></i>
         </span>
       }
       {(companiesMoreDataAvailable || companiesPagesCurrent + 1 !== companiesPages) && !companiesLoading ?
         <span>
           <span style={{fontSize: 'small', fontWeight: 'normal', paddingLeft: 5}}>Page {companiesPagesCurrent + 1} of {companiesMoreDataAvailable ? <span>unknown</span> : <span>{companiesPages}</span>} </span>
           <span style={{marginLeft: 5, fontSize: 'small', color: '#b50909', cursor: 'pointer'}} disabled={!companiesMoreDataAvailable} onClick={() => this.props.pagination('companies', 'next')}>
             <i className="fas fa-forward"></i>
           </span>
        </span> :
        <span>
          <span style={{fontSize: 'small', fontWeight: 'normal', paddingLeft: 5}}>Page {companiesPagesCurrent + 1} of {companiesMoreDataAvailable ? <span>unknown</span> : <span>{companiesPages}</span>} </span>
          <span style={{marginLeft: 5, fontSize: 'small', color: 'grey', cursor: 'pointer'}}>
            <i className="fas fa-forward"></i>
          </span>
        </span>
       }
     </span>
     {companiesLoading &&
       <i className="fas fa-sync-alt fa-spin" style={{marginLeft: 5, fontSize: 'small', color: '#b50909'}} ></i>
     }

    </h2>

    <div className="grid-row grid-gap" style={{marginBottom: 6}}>
    <div className="tablet:grid-col-4">
      <ButtonStatic onClick={this.newIp}>
        Add Industry Partner
      </ButtonStatic>
    </div>
    <div className="tablet:grid-col-8" style={{marginTop: '0.5rem', textAlign: 'right'}}>
        <div className="usa-checkbox" style={{display: 'inline'}}>
          <input
            className="usa-checkbox__input"
            id="industry-partners-only"
            type="checkbox"
            name="industry-partners-only"
            value="industry-partners-only"
            checked={this.state.industryPartners}
            onClick ={this.handleIndustryPartnersChange}/>
          <label className="usa-checkbox__label" htmlFor="industry-partners-only">Show IPs</label>
        </div>
        <div className="usa-checkbox" style={{display: 'inline'}}>
          <input
            className="usa-checkbox__input"
            id="exhibitors-only"
            type="checkbox"
            name="exhibitors-only"
            value="exhibitors-only"
            checked={this.state.exhibitors}
            onClick ={this.handleExhibitorsChange}/>
          <label className="usa-checkbox__label" htmlFor="exhibitors-only">Show  Exhibitors</label>
        </div>
        <div className="usa-checkbox" style={{display: 'inline'}}>
          <input
            className="usa-checkbox__input"
            id="companies-only"
            type="checkbox"
            name="companies-only"
            value="companies-only"
            checked={this.state.companies}
            onClick ={this.handleCompaniesChange}/>
          <label className="usa-checkbox__label" htmlFor="companies-only">Show Companies</label>
        </div>
      </div>

    </div>

    { companiesLoading && companies.length === 0 ?
      <Loading title="" subtitle=""/> :
      <table className="usa-table usa-table--borderless" style={{width:'100%', marginBottom: 32, marginTop: 0, fontSize: 'smaller'}}>
        <thead>
          <tr>
            <th>Company</th>
            <th>Address</th>
            <th style={{textAlign: 'center'}}>Exhibitor</th>
            <th style={{textAlign: 'center'}}>Industry Partner</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
          {companies.length > 0 && companies[companiesPagesCurrent].map(item =>
            <CompanyItem key={item.pk} item={item} industryPartners={this.state.industryPartners} exhibitors={this.state.exhibitors} companies={this.state.companies}/>
          )}
        </tbody>
      </table>
    }

    </>
  )
}
}

function CompanyItem(props) {
  const { item } = props
  if (item.industryPartnershipEndDate === 'Invalid date') {
    console.log('this', item.industryPartnershipEndDate)
  } else {
    console.log(item.industryPartnershipEndDate)
  }
  const endDate = (item.industryPartnershipEndDate && item.industryPartnershipEndDate !== 'Invalid date') ?
                  moment(item.industryPartnershipEndDate).format('YYYY-MM-DD') :
                  ''

  let show = false
  show = show || (props.industryPartners && item.isIndustryPartner)
  show = show || (props.exhibitors && item.isExhibitor)
  show = show || (props.companies && !item.isIndustryPartner && !item.isExhibitor)

  if (!show) {return null}
//  if (props.industryPartnersOnly && (!item.isIndustryPartner && !item.isExhibitor)) {return null}
  return (
    <tr style={{verticalAlign: 'top'}}>
      <td>
        <CompanyWithActions
          pk={item.pk}
          name={item.name ?? "<no name>"}
          deleteCompany={false}
        />
      </td>
      <td>
        <span style={{fontSize: 'small'}}>
          <strong>{item.primary_poc_full_name}</strong><br />
          <em>{item.line_1} {item.line_2} <br />
              {item.city && item.state_or_province &&
                <>{item.city}, {item.state_or_province} {item.postal_code} {item.country}</>
              }</em>
          </span>
      </td>
      <td style={{textAlign: 'center'}}>
        <span style={{fontSize: 'small'}}>{item.isExhibitor ? 'Yes' : 'No'}</span>
      </td>
      <td style={{textAlign: 'center'}}>
        <span style={{fontSize: 'small'}}>{item.isIndustryPartner ? 'Yes' : 'No'}</span>
      </td>
      <td>
        <span style={{fontSize: 'small'}}>{endDate}</span>
      </td>
    </tr>
  )
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) { // eslint-disable-next-line
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export default AdminCompanies
