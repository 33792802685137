import React, { Component } from 'react';

export const ERROR_409 = 'Someone has updated this item since you loaded it. Reload to see the latest changes'
export const ERROR_PERSON_LOAD_FAILED = 'We failed to load the Individual Profile'
export const ERROR_COMPANY_LOAD_FAILED = 'We failed to load the Industry Partner Profile'
export const ERROR_CHAPTER_LOAD_FAILED = 'We failed to load the Chapter Profile'
export const GENERAL_MESSAGE = 'Try your request again. If this error persists, please contact us directly.'

class ErrorContainer extends Component {

  constructor(props) {
    super(props)

    const error = this.props.error
    let message = error && error.message ?
                  error.message :
                  'An error has occured'

    if (message === 'Network Error') {
      message = 'Did not receive a valid response from the server'
    }
    this.state = {
      message: message
    }
  }

  render() {
    return (
      <section className="usa-section" style={{paddingTop: 40, paddingBottom: 240, borderTopWidth: 0}}>
        <div className="grid-container margin-y-5">
          <div className="usa-prose">
            <div className="usa-alert usa-alert--error" >
              <div className="usa-alert__body">
                <h3 className="usa-alert__heading">{this.state.message}</h3>
                <p className="usa-alert__text">{GENERAL_MESSAGE}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ErrorContainer;
