import React, { Component } from 'react';

import ErrorContainer from '../ErrorContainer'

class GeneralError extends Component {

  render() {
    if (this.props.authState !== 'signOutFailed') return null

    return (
      <>
        <main id="main-content" class="main-content">
          <section id="introduction-section-id" class="usa-section">
            <div class="grid-container">
              <h2 name="donation-confirmation" class="font-heading-xl margin-y-0">Sign Out Failed</h2>
                <ErrorContainer
                    error={{message: 'Something unexpected happend during sign out. To ensure a successful sign out, please close your browser.'}}
                />
            </div>
          </section>
        </main>
      </>
    )
  }
}

export default GeneralError
