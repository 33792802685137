import React from 'react'
import { API } from 'aws-amplify'
import { Redirect } from "react-router-dom"

import Modal from 'react-bootstrap/Modal'
import PrintBadge from './PrintBadge'
import  {ButtonStatic} from '../sections/Section';

class Badge extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      redirect: false,
      batchId: uuidv4()
    }
  }

  batchRequest(person) {

    const item = {
      root: {
        first_name: person.root.first_name,
        last_name: person.root.last_name,
        nickname: person.root.nickname,
        private_organization: person.root.private_organization,
      },
      military: {
        organization: person.military.organization,
        base: person.military.base,
        base_name: person.military.base_name,
        base_other: person.military.base_other,
        rank: person.military.rank,
        rank_abbreviation: person.military.rank_abbreviation,
        status: person.military.status
      },
      civilian: {
        organization: person.civilian.organization
      },
      address: {
        chapterName: person.address.chapterName
      },
      cart: {
        includesGuest: person.cart.includesGuest,
        secondaryGuestFirstName: person.cart.secondaryGuestFirstName,
        secondaryGuestLastName: person.cart.secondaryGuestLastName,
        badgeShowAsExhibitorOnBadge: person.cart.badgeShowAsExhibitorOnBadge,
        badgeHasSecurityClearance: person.cart.badgeHasSecurityClearance,
        badgeShowRankOnBadge: person.cart.badgeShowRankOnBadge,
        primaryRegistrationType: {isExhibitor: person.cart.primaryRegistrationType.isExhibitor},

        exhibitorOrganization: person.cart.exhibitorOrganization,
        badgeGuestAdminContent: person.cart.badgeGuestAdminContent,
        badgeAdminContent: person.cart.badgeAdminContent,
        badgeAwardContent: person.cart.badgeAwardContent,
        badgeOverrideBaseChapter: person.cart.badgeOverrideBaseChapter
      },
      }

      API.put('general', '/batch/' + this.state.batchId, {body: {items : [item]}})
      .then(response => {
        this.setState({redirect: true})
      })
      .catch(error => {
        console.log(error)
      })
    }


render() {

  if (this.state.redirect) {
    return (
      <Redirect to={"/preview/" + this.state.batchId} push={true}/>
    )
  }
  const {person, endEdit} = this.props

  let modalStyle = {
      background: '#fff',
      border: '#b40808',
      borderRadius: '5px',
      marginLeft: '-200px',
      textAlign:'center',
      position: 'fixed',
      left: '45%',
      top: '20%',
      zIndex: 11,
      width: '580px',
      minHeight: '300px',
      boxShadow:'0 5px 10px rgba(0,0,0,0.3)',
      transform: 'translate(0, 0)',
      transition: 'transform 0.3s ease-out',
      outline: 'none',
      pointEvents: 'auto',
      overflowY: 'initial' // !important'

    };

 return (
   <Modal show={true} style={modalStyle} onHide={() => endEdit(false)}>
     <Modal.Header closeButton className="modal-header-additional">
       <h2 style={{float:'left', maxWidth: '80%', marginLeft: 10, textAlign: 'left'}}>Convention Badge</h2>
     </Modal.Header>
     <Modal.Body style={{maxHeight: 550, overflowY: 'auto'}}>

       <div className="usa-prose" style={{textAlign: 'left', margin: 20}}>
         <PrintBadge person={person} isGuest={false}/>
       </div>
       <div className="usa-prose" style={{textAlign: 'left', margin: 20}}>
         <PrintBadge person={person} isGuest={true}/>
       </div>

       <ButtonStatic
         onClick={() => endEdit(false)}
         style={{marginRight: 20, marginBottom:20, float: 'right'}}
         >
         Close
       </ButtonStatic>

       <ButtonStatic
         onClick={() => this.batchRequest(person.item)}
         style={{marginRight: 20, marginBottom:20, float: 'right'}}
         >
         Print Preview
       </ButtonStatic>

     </Modal.Body>
     <Modal.Footer>
     </Modal.Footer>
   </Modal>
 )
}
}


export default Badge

function uuidv4() {
  return 'xxxxxxxx-xxxx'.replace(/[xy]/g, function(c) { // eslint-disable-next-line
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
