
import Button from 'react-bootstrap/Button'
import React from 'react'

export function Section(props) {

  return (
    <div className="grid-container" style={{ paddingLeft: 0 }}>
      <div className="grid-row">
        <div className="tablet:grid-col-12">
          <section
            className="usa-section margin-y-0"
            style={{ paddingTop: 15, paddingBottom: 10 }}
          >
          {props.children}
        </section>
      </div>
    </div>
  </div>
  )
}

export function PageHeader(props) {
  return (
    <>
    <h1 className="font-heading-xl margin-y-0" style={{marginBottom: 5}}>
      {props.title}
    </h1>
    {props.description &&
      <p className="usa-prose" style={{fontSize: 'small', marginTop:-5, fontStyle: 'italic'}}>{props.description}</p>
    }
    </>
  )
}

export function ButtonStatic(props) {
  return (
    <Button className="usa-button" {...props}>
      {props.children}
    </Button>
  )
}
