import { API } from 'aws-amplify'
import React, { Component } from 'react'
import {PersonContext} from '../../../classes/context.js'

import Form from 'react-bootstrap/Form'

var moment = require('moment');

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class EditorMembership extends Component {

  componentDidMount() {

    API.get('general', '/individual-membership-type')
    .then(response => {

      const items = response.items.sort(function(a, b) {
        if (a.name < b.name) {return -1}
        if (a.name > b.name) {return 1}
        return 0
      })
      const options = items.map((item) =>
        <option key={item.sk} value={item.sk} >{item.name} (${item.price})</option>
      )
      // selected={item.sk === this.state.membershipType}
      this.setState({
        membershipTypes: items,
        options: options
      })
    })
    .catch(error => {
      console.log(error)
    })

    // To Do: make this admin only
    API.get('general', '/companies')
    .then(response => {

      const items = response.items.sort(function(a, b) {
        if (a.name < b.name) {return -1}
        if (a.name > b.name) {return 1}
        return 0
      })
      const options = items.filter(item => item.isIndustryPartner).map((item) =>
        <option value={item.pk.slice(item.pk.indexOf('-') + 1)} key={item.pk}>{item.name}</option>
      )
      this.setState({
        companyOptions: options,
        companies: items
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  keyValue(item, key, format) {
    const {person} = this.context

    if (!person.editItem) {return null}

    const i = person.editItem.membership.findIndex(item => item.sk === this.props.editMode.sk)
    if (i === -1) {return null}
    if (format) {
      return (typeof(person.editItem.membership[i][key]) === 'undefined' ||
              !person.editItem.membership[i][key] ?
              null :
              moment(person.editItem.membership[i][key]).format(format))
    }

    return (typeof(person.editItem.membership[i][key]) === 'undefined' ? null : person.editItem.membership[i][key])

  }

  handleChange(event) {
    let readyToSubmit = false

    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    let membershipType = event.target.name === 'membership-' + this.props.membershipTimestamp + '.membershipType' ?
                         value :
                         this.state.membershipType

    let noCharge = event.target.name === 'noCharge' ? value : this.state.noCharge

    if (membershipType === 'c1') {
      readyToSubmit = membershipType &&
                      this.state['membership-' + this.props.membershipTimestamp + '.requestedStartDate']
    } else if (membershipType === 'a1' ||
               (membershipType &&  event.target.value === 'a1')) { // zero cost Award Winner Annual
      readyToSubmit = true
    } else {
      readyToSubmit =
            (this.state.paymentCardStatus === 'complete' || noCharge === true) &&
            event.target.value !== '0' && membershipType !== '0'

    }

    this.setState({
      [event.target.name]: value,
      readyToSubmit: readyToSubmit
    })

    this.props.updateMembershipReadyToSubmit(readyToSubmit, noCharge)

    const handleChange = this.context.person.handleChange.bind(this.context.person)
    handleChange(event)

    if (event.target.name === 'membership-' + this.props.membershipTimestamp + '.membershipType') {
      let membershipType = [{name: null, price: null, termMonths: null}]
      if (event.target.value !== '0' && this.state.membershipType !== event.target.value) {
        membershipType = this.state.membershipTypes.filter(item => item.sk === event.target.value)
      }

      if (membershipType[0].name) {
        handleChange({
          target: {name: 'membership-' + this.props.membershipTimestamp + '.membershipTypeName',
                   value: membershipType[0].name,
                   type: 'input'}
        })
        handleChange({
          target: {name: 'membership-' + this.props.membershipTimestamp + '.price',
                   value: membershipType[0].price,
                   type: 'input'}
        })
        handleChange({
          target: {name: 'membership-' + this.props.membershipTimestamp + '.termMonths',
                   value: membershipType[0].termMonths,
                   type: 'input'}
        })
        // Zero out the company
        handleChange({
          target: {name: 'membership-' + this.props.membershipTimestamp + '.company',
                   value: '',
                   type: 'input'}
        })

        this.setState({
          price: membershipType[0].price,
          membershipType: membershipType[0].sk
        })
      }
    }
  }

  render() {

    const {person} = this.context
    if (this.props.editMode.mode !== 'editMembership') {return null}
    if (!person.editItem) {return null}

    const handleChange = person.handleChange.bind(person)

    const sk = this.props.editMode.sk

    return (
      <Form style={{minHeight: 500}}>
        <h2 className="margin-y-5" style={{marginBottom: 0}}>Membership</h2>
        <div className="grid-row grid-gap">
          <div className="grid-col-6">
            <div className="usa-label">Membership Type</div>
            <Form.Control
              className="usa-select"
              style={editingStyle}
              as="select"
              defaultValue={this.keyValue(sk, 'membershipType')}
              name={sk + '.membershipType'}
              disabled
              onChange={handleChange}
            >
              <option value="0">Select One</option>
              {this.state.options}
            </Form.Control>
          </div>
        </div>
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-3">
            <div className="usa-label">Start Date</div>
            <Form.Control className="usa-input" name={sk + ".membershipStartDate"} plaintext defaultValue={this.keyValue(sk, 'membershipStartDate', 'YYYY-MM-DD')}  style={editingStyle} onChange={handleChange}/>
          </div>

          <div className="tablet:grid-col-3">
            <div className="usa-label">End Date</div>
            <Form.Control className="usa-input" name={sk + ".membershipEndDate"} plaintext defaultValue={this.keyValue(sk, 'membershipEndDate', 'YYYY-MM-DD')}  style={editingStyle} onChange={handleChange}/>
          </div>
        </div>
        {this.keyValue(sk, 'membershipType') !== 'c1' &&
          <div className="grid-row grid-gap">
            <div className="grid-col-6">
              <div className="usa-label">Company</div>
              <Form.Control
                className="usa-select"
                style={editingStyle}
                as="select"
                disabled
                name={'membership-' + this.props.membershipTimestamp + '.companyId'}
                onChange={this.handleCompanyChange}
              >
                <option value="0">Select One</option>
                {this.state.companyOptions}
              </Form.Control>
            </div>
          </div>
        }
      </Form>
      )
    }
}

EditorMembership.contextType = PersonContext
export default EditorMembership;
