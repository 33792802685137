import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { CardElement } from '@stripe/react-stripe-js';
import { Redirect, useLocation  } from "react-router-dom";
import { VIEW } from './AccountGlobals'

import { PersonContext } from '../../../classes/context'

import AccountSummary from './AccountSummary';
import AccountControl from './AccountControl';
import AddMembership from './AddMembership';
import EditorMembership from './EditorMembership';
import Editor from './Editor'
import EditorRegistrationInfo from './EditorRegistrationInfo'
import ErrorContainer, {ERROR_409, ERROR_PERSON_LOAD_FAILED} from '../../ErrorContainer';
import Loading from '../../Loading';
import {Section} from '../../sections/Section'

var moment = require('moment');

// const ERROR_409 = 'Someone has updated this item since you loaded it. Reload to see the latest changes.'
// const ERROR_PERSON_LOAD_FAILED = 'We failed to load the account information.'

class Account extends Component {

  constructor(props) {
    super(props);

    this.startEdit = this.startEdit.bind(this)
    this.endEdit = this.endEdit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.addMembership = this.addMembership.bind(this)
    this.handleRejected = this.handleRejected.bind(this)

    this.updateModalVisibility = this.updateModalVisibility.bind(this)
    this.updateConfirmationModalVisibility =
      this.updateConfirmationModalVisibility.bind(this)
    this.updateMembershipReadyToSubmit = this.updateMembershipReadyToSubmit.bind(this)
    this.requestCancellation = this.requestCancellation.bind(this)
    this.confirmCancellation = this.confirmCancellation.bind(this)
    this.deleteMembership = this.deleteMembership.bind(this)

    this.state = {
      editMode: VIEW,
      processingPayment: false,
      error: null, // NOT USED; TODO figure out how errors are displayed.
      showModal: false,
      showConfirmationModal: false,
      adminConfirmedNewUser: false,
      membershipReadyToSubmit: false,
      noCharge: false,
      deleting: false,
      refresh: false,
    }

  }

  startEdit(type, membershipTimestamp) {
    this.context.person.beginEdit()
    if (membershipTimestamp) {
      this.context.person.editItem.membership.push({pk: this.context.person.editItem.root.pk, sk: 'membership-' + membershipTimestamp})
    }
    this.setState({editMode: type, membershipTimestamp: membershipTimestamp})
  }

  endEdit(saveChanges, additionalInformation) {
    let person = this.context.person

    // eslint-disable-next-line
    if (!saveChanges) {
      person.endEdit(false)
      this.setState({editMode: VIEW, error: null})
    } else {
      person.endEdit(saveChanges)

      if (this.state.editMode.adminMode === 'edit-primary-email') {
        person
          .editPrimaryEmail(additionalInformation.targetEmail.toLowerCase())
          .store()
          .then(() => {
            this.setState({editMode: VIEW, error: null, refresh: '/admin/account/' + additionalInformation.targetEmail.toLowerCase()})
          })
          .catch(this.handleRejected)
      } else if (this.state.editMode.adminMode === 'merge-with') {
        person
          .mergeWith(additionalInformation.targetEmail.toLowerCase())
          .store()
          .then(() => {
            this.setState({editMode: VIEW, error: null, refresh: '/admin/account/' + additionalInformation.targetEmail.toLowerCase()})
          })
          .catch(this.handleRejected)
      } else if (this.state.editMode.adminMode === 'add-comment') {
        person
          .addComment(additionalInformation.comment)
          .store()
          .then(() => {
            this.setState({editMode: VIEW, error: null})
          })
          .catch(this.handleRejected)
      } else if (this.state.editMode.adminMode === 'add-secondary') {
        person
          .addSecondary(additionalInformation)
          .store()
          .then(() => {
            person.get(person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1))
            .then(() => {
              this.setState({editMode: VIEW, processingPayment: false})
            })
            .catch(error => {
              console.log(error)
            })
          })
          .catch(this.handleRejected)
      } else if (this.state.editMode.adminMode === 'remove-secondary') {
        person
          .removeSecondary(additionalInformation)
          .store()
          .then(() => {
            this.setState({editMode: VIEW, error: null})
          })
          .catch(this.handleRejected)
      } else if (this.state.editMode.adminMode === 'confirm-secondary-payment') {
        person
          .confirmSecondaryPayment()
          .store()
          .then(() => {
            this.setState({editMode: VIEW, error: null})
          })
          .catch(this.handleRejected)
      } else if (this.state.editMode.adminMode === 'undo-cancellation-request') {
        person
          .undoCancellationRequest()
          .store()
          .then(() => {
            person.actionCleanUp()
            this.setState({editMode: VIEW, error: null})
          })
          .catch(this.handleRejected)

      } else if (this.state.editMode.adminMode === 'delete-person') {
        person
          .delete()
          .then(() => {
            this.setState({editMode: VIEW, error: null, refresh: '/admin'})
          })
          .catch(this.handleRejected)
      } else if (this.state.editMode.adminMode === 'registration-delete') {
        person
          .registrationDelete()
          .store()
          .then(() => {
            person.registrationDeleteCleanup()
            this.setState({editMode: VIEW, error: null})
          })
          .catch(this.handleRejected)
      } else {
        person
          .store()
          .then(() => {
            this.setState({editMode: VIEW, error: null})
          })
          .catch(this.handleRejected)
      }
    }
  }

  handleRejected(error) {
    if (error.message === 'Request failed with status code 409') {
      const error = {message: ERROR_409}
      this.setState({editMode: VIEW, error: error})
    } else {
      this.setState({editMode: VIEW, error: error})
    }
  }

  deleteMembership(membershipSk) {
    const {person} = this.context
    this.setState({deleting: true})
    person.deleteMembership(membershipSk)
    .then(() => {this.setState({deleting: false})})
    .catch(error => {
      console.log(error)
      this.setState({deleting: false})
    })
  }

  addMembership() {
    const {stripe, elements} = this.props
    const {membershipTimestamp, noCharge} = this.state
    const {person} = this.context

    const i = person.editItem.membership.findIndex(item => item.sk === 'membership-' + membershipTimestamp)
    if (person.editItem.membership[i].membershipType === 'c1' ||
        person.editItem.membership[i].membershipType === 'a1' ||
        noCharge) {
      person.editItem.membership[i].status = 'complete'
      person.editItem.membership[i].statusText = 'Complete'
      person.editItem.membership[i].noCharge = noCharge
      person.setMembershipStartDate(membershipTimestamp)

      person
        .endEdit(true)
        .store()
        .then(() => {
          this.setState({editMode: VIEW, processingPayment: false})
          person.get(person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1))
          .then(() => {
            this.setState({editMode: VIEW, processingPayment: false})
          })
          .catch(error => {
            console.log(error)
          })
        })
        .catch(error => {
          console.error(error)
        })
    } else {
      this.setState({processingPayment: true, stripeError: null, connectionError: false})
      // Start by getting a purchase intent from Stripe
      person.editItem.membership[i].status = 'payment-intent-pending'
      person.editItem.membership[i].statusText = 'Payment Intent Pending'
      person.setMembershipStartDate(membershipTimestamp)
      let body = person.editItem.membership[i]
      body.receiptEmail = person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1)
      body.name = person.editItem.membership[i].membershipTypeName
      body.pk = person.editItem.root.pk
      body.sk = 'membership-' + membershipTimestamp

      person
        .endEdit(true)
        .store()
        .then(() => {
          API.post(
            'stripeProcessing',
            '/intent',
            {body: {membership: body}}
          )
          .then(response => {
            const {clientSecret, paymentIntentId} = response
            this.setState({paymentIntentId: paymentIntentId})
            person.item.paymentIntentId = paymentIntentId

            stripe.confirmCardPayment(clientSecret, {
              receipt_email: person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1),
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  email: person.item.root.primary_email_address,
                  name: person.item.root.first_name + ' ' + person.item.root.last_name,
                  }
              }
            })
            .then(response => {
              if (response.error) {
                this.setState({stripeError: response.error, processingPayment: false})
              } else {
                person.get(person.item.root.pk.slice(person.item.root.pk.indexOf('-') + 1))
                .then(() => {
                  this.setState({editMode: VIEW, processingPayment: false})
                })
                .catch(error => {
                  console.log(error)
                })
              }
            })
            .catch(error => {
              // may not be a stripe error
              this.setState({stripeError: error.raw, processingPayment: false})
            });
          })
          .catch(error => {
            if (error.type === 'StripeConnectionError') {
              this.setState({stripeError: error, connectionError: true, processingPayment: false})
            } else {
              this.setState({stripeError: error, processingPayment: false})
            }
          })
        })
        .catch(error => {
          this.setState({processingPayment: false})
          if (error.message === 'Request failed with status code 409') {
                // eslint-disable-next-line
            alert('Someone has updated this item since you loaded it. Reload to see the latest changes.')
          } else {
            console.error(error.message)
          }
        })
    }


  }

  updateMembershipReadyToSubmit(membershipReadyToSubmit, noCharge) {
    this.setState({
      membershipReadyToSubmit: membershipReadyToSubmit,
      noCharge: noCharge
    })
  }

  handleChange(event) {
    const target = event.target
    const name = target.name.split('.')
    let value = target.type === 'checkbox' ? target.checked : target.value

    if (name === 'targetEmail') {value = value.toLowerCase()}
    this.setState({
      [name]: value
    })
  }

  requestCancellation() {
    this.context.person
      .beginEdit()
      .editItem['cart-cancellation'] =
        {
          pk: this.context.person.item.root.pk,
          sk: 'cart-cancellation',
          reason: this.state.cancellationReason,
          type: 'cart-cancellation',
          status: 'cancellation-requested'
        }
    this.context.person
      .editItem.cart.status='cancellation-requested'
    this.context.person
      .endEdit(true)
      .store()
      .then(() => {
        this.setState({showModal: false})
        this.forceUpdate()
      })
      .catch(error => {
        console.error(error)
        this.setState({showModal: false})
        this.forceUpdate()
      })
  }

  confirmCancellation() {

    let {person} = this.context
    person.beginEdit()
    person.editItem.cart.status='cancellation-confirmed'
    person.editItem['cart-cancellation'].status='cancellation-confirmed'
    person.editItem['cart-cancellation'].cancellationConfirmedDate = moment()

    this.context.person
      .endEdit(true)
      .store()
      .then(() => {
        this.setState({showConfirmationModal: false})
        this.forceUpdate()
      })
      .catch(error => {
        console.error(error)
        this.setState({showConfirmationModal: false})
        this.forceUpdate()
      })
  }

  updateConfirmationModalVisibility(show) {
    this.setState({
      showConfirmationModal: show
    })
  }

  updateModalVisibility(show) {
    this.setState({
      showModal: show
    })
  }

  render() {
    let person = this.context.person
    const {error, processingPayment, refresh} = this.state
    let {editMode} = this.state

    if (person.isNew) {
      editMode.mode = 'edit'
      this.context.person.beginEdit()
    }

    if (error) {
      return (
        <ErrorContainer
          title="Error" error={error}
        />
      )
    } else if (person.loadFailed) {
      return (
        <ErrorContainer
          title="Error"
          error={{message:ERROR_PERSON_LOAD_FAILED}}
        />
      )
    } else if (person.isLoading || !person.isInitialized) {
      return (
        <Loading
          title="Individual Profile"
          size="h1"
        />
      )
    }

    const hasCart = this.context.person.item.cart && !this.context.person.item.registrationDelete && this.context.person.item.cart.primaryRegistrationType ? true : false
    const hasActiveRegistration =
      (hasCart && ['complete', 'payment-submitted'].includes(this.context.person.item.cart.status))
    const hasCancellationRequest =
      (hasCart && ['cancellation-requested'].includes(this.context.person.item.cart.status))
    const hasCancellationConfirmed =
      (hasCart && ['cancellation-confirmed'].includes(this.context.person.item.cart.status))

    const hasIid = this.context.person.item.cart &&
                   this.context.person.item.cart.primaryRegistrationType &&
                   (this.context.person.item.cart.primaryRegistrationType.isIndustryDayOnly ||
                   (this.context.person.item.cart.secondaryItems &&
                   this.context.person.item.cart.secondaryItems.filter(item => item.includesIndustryDay).length > 0))

    let pk = (person.item && person.item.root) ? person.item.root.pk : '-'

    let secondaryItems = person.item.cart.secondaryItems

    return (

      <Section>
        <RedirectAfterUpdate refresh={refresh} />

        <div className="grid-container margin-y-0">
          <AccountControl
            editMode={editMode}
            addMembership={this.addMembership}
            startEdit={this.startEdit}
            endEdit={this.endEdit}
            secondaryItems={secondaryItems}
            person={person}
            membershipTimestamp={this.state.membershipTimestamp}
            hasCart={hasCart}
            hasActiveRegistration={hasActiveRegistration}
            hasCancellationRequest={hasCancellationRequest}
            hasCancellationConfirmed={hasCancellationConfirmed}
            hasIid={hasIid}
            processingPayment={processingPayment}
            pk={pk.slice(pk.indexOf('-') + 1)}
            isAdmin={this.props.isAdmin}
            stripeError={this.state.stripeError}
            adminMenus={this.context.person.item.root.adminMenus}
            isNew={this.context.person.isNew}
            membershipReadyToSubmit={this.state.membershipReadyToSubmit}
            noCharge={this.state.noCharge}
            updateModalVisibility={this.updateModalVisibility}
            updateConfirmationModalVisibility={this.updateConfirmationModalVisibility}
            showCancellationRequest={this.state.showCancellationRequest}
            showConfirmationModal={this.state.showConfirmationModal}
          />

          <AccountSummary
            editMode={editMode}
            hasCart={hasCart}
            startEdit={this.startEdit}
            hasActiveRegistration={hasActiveRegistration}
            hasCancellationRequest={hasCancellationRequest}
            hasCancellationConfirmed={hasCancellationConfirmed}
            showModal={this.state.showModal}
            showConfirmationModal={this.state.showConfirmationModal}
            isAdmin={this.props.isAdmin}
            isNew={this.context.person.isNew}

            updateConfirmationModalVisibility={this.updateConfirmationModalVisibility}
            updateModalVisibility={this.updateModalVisibility}
            handleChange={this.handleChange}
            requestCancellation={this.requestCancellation}
            confirmCancellation={this.confirmCancellation}
            deleteMembership={this.deleteMembership}
            editIpMembership={this.editMembership}
          />

          <Editor editMode={editMode} />
          <EditorRegistrationInfo editMode={editMode} />
          {editMode.mode === 'membership' &&
            <AddMembership
              editMode={editMode}
              isAdmin={this.props.isAdmin}
              stripeError={this.state.stripeError}
              connectionError={this.state.connectionError}
              membershipTimestamp={this.state.membershipTimestamp}
              updateMembershipReadyToSubmit={this.updateMembershipReadyToSubmit}
            />
          }
          <EditorMembership
            editMode={editMode}
            startEdit={this.startEdit}
            endEdit={this.endEdit}
          />
        </div>

      </Section>
    )
  }
}

function RedirectAfterUpdate(props) {

  const {refresh} = props
  const {pathname} = useLocation()
  if (refresh && refresh !== pathname) {
    return <Redirect push to={refresh} />
  }

  return null
}

Account.contextType = PersonContext;
export default Account;
