import React, { Component } from 'react';
import Card from '../structure/Card'
import { Link } from "react-router-dom";

import * as contentful from 'contentful';

String.prototype.trunc = String.prototype.trunc ||
  function(n){
      return (this.length > n) ? this.substr(0, n-1) + '...' : this;
  };

class LatestNews extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      page: null,
    };
  }

  componentDidMount() {
    var contentfulClient = contentful.createClient({
      space: 'bg11zawujrgr',
      accessToken: 'c86395435846a2c448defbf0c59f24677fa2a58d522e6b2fe3e6c4b57c083caf' })

    contentfulClient.getEntries(
      {'content_type': 'pageNews',
        'limit': 3,
        'order': '-fields.originalCreatedDate'
    })
    .then(entries => {
      this.setState({
        isLoaded: true,
        pages: entries.items,
      });
    })
    .catch(error => {
      console.log(error)
      this.setState({
        error: error
      })
    })
  }

  render() {

    if (!this.state.isLoaded) return null

    const format = this.props.dark ? "usa-section usa-section--dark" : "usa-section"
    return (

      <section
        id="test-section-id"
        class={format}
        style={{paddingTop: 40}}
      >
        <div class="grid-container">
          <h2 class="font-heading-xl margin-y-0">
            Latest News
          </h2>

          <div class="usa-graphic-list__row grid-row grid-gap">
            {this.state.pages.map((item) =>
              <Card
                style={{  paddingTop: 10, paddingBottom: 10}}
                title={item.fields.headline}
                text={item.fields.body.trunc(100)}
                published={item.fields.originalCreatedDate}
                image={item.fields.image.fields.file.url}
              />
            )}
          </div>
          <div class="usa-graphic-list__row grid-row grid-gap">
            <div className="usa-intro">
              <Link
                to="/news"
                title="News"
                aria-label="news"
                className="usa-button--unstyled"
                style={{textDecoration: 'none'}}
                >
                More news...
              </Link>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default LatestNews;
