import { API } from 'aws-amplify';
import {CardElement} from '@stripe/react-stripe-js';
import FieldError from '../../controls/FieldError'
import Form from 'react-bootstrap/Form'
import Moment from 'react-moment';

import {PersonContext} from '../../../classes/context'
import React from 'react';

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class AddMembership extends React.Component {

  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleCompanyChange = this.handleCompanyChange.bind(this)
    this.handlePaymentChange = this.handlePaymentChange.bind(this)

    this.state = {
     membershipType: '0',
     paymentCardStatus: 'incomplete',
     companyId: '0',
     readyToSubmit: false,
     error: false,
     companyOptions: []
    }
  }

  componentDidMount() {

    API.get('general', '/individual-membership-type')
    .then(response => {

      const items = response.items.sort(function(a, b) {
        if (a.name < b.name) {return -1}
        if (a.name > b.name) {return 1}
        return 0
      })
      const options = items.map((item) =>
        <option key={item.sk} value={item.sk} selected={item.sk === this.state.membershipType}>{item.name} (${item.price})</option>
      )
      this.setState({
        membershipTypes: items,
        options: options
      })
    })
    .catch(error => {
      console.log(error)
    })

    // To Do: make this admin only
    API.get('general', '/companies')
    .then(response => {

      const items = response.items.sort(function(a, b) {
        if (a.name < b.name) {return -1}
        if (a.name > b.name) {return 1}
        return 0
      })
      const options = items.filter(item => item.isIndustryPartner).map((item) =>
        <option value={item.pk.slice(item.pk.indexOf('-') + 1)} key={item.pk}>{item.name}</option>
      )
      this.setState({
        companyOptions: options,
        companies: items
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  componentWillUnmount() {
    this.setState({
     membershipType: '0',
     paymentCardStatus: 'incomplete',
     readyToSubmit: false,
     error: false
   })
   this.props.updateMembershipReadyToSubmit(false)
  }

  handleCompanyChange(event) {
    const handleChange = this.context.person.handleChange.bind(this.context.person)

    const companyInfo = this.state.companies.filter(item => item.pk === 'company-' + event.target.value)
    const companyName = companyInfo.length === 0 ? 'Select One' : companyInfo[0].name

    handleChange(event)
    handleChange({target: {name: 'membership-' + this.props.membershipTimestamp + '.companyName',  value: companyName, type: 'input'}})
    handleChange({target: {name: 'membership-' + this.props.membershipTimestamp + '.type',  value: 'industry-partner-individual-membership', type: 'input'}})

    let readyToSubmit = this.state['membership-' + this.props.membershipTimestamp + '.membershipStartDate'] &&
                        event.target.value !== '0'
    this.setState({
      [event.target.name]: event.target.value,
      readyToSubmit: readyToSubmit
    })
  }

  handlePaymentChange(event) {
    if (event.complete) {
      this.setState(
        {paymentCardStatus: 'complete',
         error: false,
         readyToSumbit: this.state.membershipType !== '0'},
        this.props.updateMembershipReadyToSubmit(this.state.membershipType !== '0')
      )
    } else {
      this.setState(
        {paymentCardStatus: 'incomplete',
         error: event.error,
         readyToSumbit: false},
        this.props.updateMembershipReadyToSubmit(false)
      )
    }

  }

  handleChange(event) {
    let readyToSubmit = false

    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    let membershipType = event.target.name === 'membership-' + this.props.membershipTimestamp + '.membershipType' ?
                         value :
                         this.state.membershipType

    let noCharge = event.target.name === 'noCharge' ? value : this.state.noCharge

    if (membershipType === 'c1') {
      readyToSubmit = membershipType &&
                      this.state['membership-' + this.props.membershipTimestamp + '.membershipStartDate'] &&
                      this.state['membership-' + this.props.membershipTimestamp + '.companyId']
    } else if (membershipType === 'a1' ||
               (membershipType &&  event.target.value === 'a1')) { // zero cost Award Winner Annual
      readyToSubmit = true
    } else {
      readyToSubmit =
            (this.state.paymentCardStatus === 'complete' || noCharge === true) &&
            event.target.value !== '0' && membershipType !== '0'

    }

    this.setState(
      {[event.target.name]: value,
       readyToSubmit: readyToSubmit},
      this.props.updateMembershipReadyToSubmit(readyToSubmit)
    )

    this.props.updateMembershipReadyToSubmit(readyToSubmit, noCharge)

    const handleChange = this.context.person.handleChange.bind(this.context.person)
    handleChange(event)

    if (event.target.name === 'membership-' + this.props.membershipTimestamp + '.membershipType') {
      let membershipType = [{name: null, price: null, termMonths: null}]
      if (event.target.value !== '0' && this.state.membershipType !== event.target.value) {
        membershipType = this.state.membershipTypes.filter(item => item.sk === event.target.value)
      }

      if (membershipType[0].name) {
        handleChange({
          target: {name: 'membership-' + this.props.membershipTimestamp + '.membershipTypeName',
                   value: membershipType[0].name,
                   type: 'input'}
        })
        handleChange({
          target: {name: 'membership-' + this.props.membershipTimestamp + '.price',
                   value: membershipType[0].price,
                   type: 'input'}
        })
        handleChange({
          target: {name: 'membership-' + this.props.membershipTimestamp + '.termMonths',
                   value: membershipType[0].termMonths,
                   type: 'input'}
        })
        // Zero out the company
        handleChange({
          target: {name: 'membership-' + this.props.membershipTimestamp + '.company',
                   value: '',
                   type: 'input'}
        })

        this.setState({
          price: membershipType[0].price,
          membershipType: membershipType[0].sk
        })
      }
    }
  }

  render() {
    if (this.props.editMode.mode !== 'membership') {return null}

    const person = this.context.person
    const {stripeError, connectionError, isAdmin} = this.props

    return (
      <>
        <Form>
          <h2 className="margin-y-5" style={{marginBottom: 0}}>Add a Membership</h2>
          <Membership membershipSummary={person.item['membership-summary']} />

          <div className="grid-row grid-gap">
            <div className="grid-col-6">
              <div className="usa-label">Membership Type</div>
              <Form.Control
                className="usa-select"
                style={editingStyle}
                as="select"
                name={'membership-' + this.props.membershipTimestamp + '.membershipType'}
                onChange={this.handleChange}
              >
                <option value="0">Select One</option>
                {this.state.options}
              </Form.Control>
            </div>
            {isAdmin &&
                <div className="grid-col-6">
                  <div className="usa-checkbox" style={{paddingTop: 60}}>
                    <input
                      className="usa-checkbox__input"
                      id="noCharge"
                      name="noCharge"
                      checked={this.state.noCharge}
                      type="checkbox"
                      onChange={this.handleChange}
                    />
                    <label
                      className="usa-checkbox__label"
                      htmlFor="noCharge"
                    >
                      No Charge
                    </label>
                  </div>
                </div>
            }
          </div>
          {isAdmin &&
            <>
            <div className="grid-row grid-gap">
              <div className="tablet:grid-col-3">
                <div className="usa-label">Start Date</div>
                <Form.Control
                  className="usa-input"
                  name={'membership-' + this.props.membershipTimestamp + ".membershipStartDate"}
                  plaintext
                  placeholder="YYYY-MM-DD"
                  style={editingStyle}
                  onChange={this.handleChange}
                />
              </div>

              <div className="tablet:grid-col-3">
                <div className="usa-label">End Date</div>
                <Form.Control
                  className="usa-input"
                  name={'membership-' + this.props.membershipTimestamp + ".membershipEndDate"}
                  plaintext
                  placeholder="YYYY-MM-DD"
                  style={editingStyle}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            </>
          }
          {this.state.membershipType === 'c1' &&
            <div className="grid-row grid-gap">
              <div className="grid-col-6">
                <div className="usa-label">Company</div>
                <Form.Control
                  className="usa-select"
                  style={editingStyle}
                  as="select"
                  name={'membership-' + this.props.membershipTimestamp + '.companyId'}
                  onChange={this.handleCompanyChange}
                >
                  <option value="0">Select One</option>
                  {this.state.companyOptions}
                </Form.Control>
              </div>
            </div>
          }

          {this.state.price !== 0 && !this.state.noCharge &&
            <Payment
              stripeError={stripeError}
              connectionError={connectionError}
              handlePaymentChange={this.handlePaymentChange}
            />
          }
        </Form>
      </>
      )
    }
}

function Payment(props) {
  const {stripeError, connectionError} = props

  return (
  <div className="grid-row grid-gap">
    <div className="tablet:grid-col-6">
      <div className="usa-label">Card Details</div>
        <CardElement
          style={{base: {fontSize: '18px'}}}
          className="usa-input"
          onChange={props.handlePaymentChange}
        />
        {stripeError &&
          <FieldError message={stripeError.message} />
        }
        {connectionError &&
          <FieldError message="There was a connection error, your card was NOT charged. Please try again." /> }
      </div>
  </div>
)
}

function Membership(props) {
  const {membershipSummary} = props

  if (membershipSummary.isLifeMember) {
    return (
      <>
      <div><strong>Membership</strong>: {membershipSummary.membershipTypeName}</div>
      </>
    )
  }

  if (membershipSummary.isExpiredMember) {
    return (
      <>
      <strong>Membership</strong>: Former Member ({membershipSummary.membershipTypeName} {membershipSummary.lastDayOfMembership && <span>expired <Moment format="YYYY-MM-DD">{membershipSummary.lastDayOfMembership}</Moment></span>})
      </>
    )
  }

  if (membershipSummary.isInGracePeriodMember) {
    return (
      <>
      <strong>Membership</strong>: An A/TA Member, currently in grace period ({membershipSummary.membershipTypeName} {membershipSummary.lastDayOfMembership && <span>expired <Moment format="YYYY-MM-DD">{membershipSummary.lastDayOfMembership}</Moment></span>})
      <div style={{fontSize: 'smaller'}}>Your membership has expired but we provide a grace period during which your membership benefits continue. The grace period ends 6 months after the end of your membership.</div>
      </>
    )
  }

  if (membershipSummary.lastDayOfMembership) {
    return (
      <>
      <div><strong>Membership</strong>: {membershipSummary.membershipTypeName} (expires <Moment format="YYYY-MM-DD">{membershipSummary.lastDayOfMembership}</Moment>)</div>
      </>
    )
  }

  return (
    <><strong>Membership</strong>: No membership</>
  )
}

AddMembership.contextType = PersonContext
export default AddMembership;
