import React, { useState, useEffect } from 'react'
import useList from './useList'
import GridControl from './GridControl'
import GridConfig from './GridConfig'
import GridData from './GridData'
import {Section, PageHeader} from '../../sections/Section'
import {CSVDownload} from 'react-csv'

var moment = require('moment')

const fixedColumns = [
  {key: 'favorite', value: 'favorite', label: 'Favorite', mayFilter: true},
  {key: 'recent', value: 'recent', label: 'Recent', mayFilter: true},
]

export default function Grid(props) {

  const {gridDef} = props
  const [columns, setColumns] = useState(gridDef.availableColumns.filter(col => col.isVisible).concat(fixedColumns))
  const [stopAfterPage, setStopAfterPage] = useState(gridDef.initialStopAfterPage)
  const [csvRequestState, setCsvRequestState] = useState('unavailable')
  const [csvColumns, setCsvColumns] = useState(gridDef.availableColumns.filter(col => col.includeInCsv).concat(fixedColumns))
  const [availableFilterColumns, setAvailableFilterColumns] = useState(gridDef.availableColumns.filter(col => col.mayFilter && col.isVisible))
  const [tableFilter, setTableFilter] = useState('')
  const [tableFilterColumn, setTableFilterColumn] = useState(gridDef.filterColumn)
  const [viewFavorites, setViewFavorites] = useState(false)
  const [viewRecents, setViewRecents] = useState(false)
  const [sortBy, setSortBy] = useState(gridDef.sortBy)
  const [sortByAscending, setSortByAscending] = useState(1)
  const [configMode, setConfigMode] = useState(false)
  const [lastRecentChange, setLastRecentChange] = useState()

  let listProps = {stopAfterPage: stopAfterPage, ...gridDef}
  const list = useList(listProps)

  useEffect(() => {
    if (csvRequestState === 'requested' && !list.moreData) {
      setCsvRequestState('readyToPrint')
    }
  }, [list.moreData, csvRequestState])

  useEffect(() => {
    if (csvRequestState === 'readyToPrint') {
      setCsvRequestState('printed')
    }
  }, [csvRequestState])

  function requestCsv() {
    if (list.moreData) {
      setCsvRequestState('requested')
      setStopAfterPage(999)
    } else {
      setCsvRequestState('readyToPrint')
    }
  }

  function handleHeaderClick(item) {
    if (item === sortBy[0] ||
        (item === props.sortByDefaultColumn && sortBy === props.sortBy)) { // clicking twice changed from asc to desc
      setSortByAscending(-1 * sortByAscending)
    } else {
      if (item === props.sortByDefaultColumn) {
        setSortByAscending(1)
        setSortBy(props.sortBy)
      } else {
        setSortByAscending(1)
        setSortBy([item].concat(sortBy.slice(1)))
      }
    }
  }

  function toggleFavorite(pk) {
    let index = gridDef.favorites.findIndex(favorite => favorite.pk === pk)
    if (index === -1) {
      gridDef.favorites.push({pk: pk, timestamp: moment().format()})
    } else {
      gridDef.favorites = gridDef.favorites.filter(favorite => favorite.pk !== pk)
    }
    setLastRecentChange(moment().format()) // really just a trigger to force a render

  }

  function trackRecent(pk) {
    // remove any current entry for this item, limit the list size and
    // then push it to the end of the list.
    gridDef.recents = [{pk: pk, timestamp: moment().format()}].concat(
      gridDef.recents.filter(item => item.pk !== pk).slice(0, 2)
    )
    setLastRecentChange(moment().format()) // really just a trigger to force a render
  }

  let filteredList = list
  filteredList.totalItems = list.items.length
  if (sortBy.length > 0) {
    const nullValue = sortByAscending === 1 ? 'zz' : 'aa'
    filteredList.items.sort((a, b) => {
      let itemA = sortBy.map(item => (a[item] ? String(a[item]).trim() : nullValue)).toString()
      let itemB = sortBy.map(item => (b[item] ? String(b[item]).trim() : nullValue)).toString()
      if (itemA.toLowerCase() < itemB.toLowerCase()) {return -1 * sortByAscending}
      if (itemA.toLowerCase() > itemB.toLowerCase()) {return sortByAscending}
      return 0
    })
  }

  filteredList.items.forEach(item => {
    let index = gridDef.recents.findIndex(recent => item.pk === recent.pk)
    item.recent = index === -1 ? false : gridDef.recents[index].timestamp
    index = gridDef.favorites.findIndex(favorite => item.pk === favorite.pk)
    item.favorite = (index !== -1)
  })

  if (viewFavorites && viewRecents) {
    filteredList.items = list.items.filter(item => item.favorite && item.recent)
  } else if (viewFavorites) {
    filteredList.items = list.items.filter(item => item.favorite)
  } else if (viewRecents) {
      filteredList.items = list.items.filter(item => item.recent)
  } else {
    if (tableFilterColumn && tableFilter.length > 1 && list.items.length > 0) {
      filteredList.items = list.items.filter(item => String(item[tableFilterColumn]).toLowerCase().includes(tableFilter.toLowerCase()))
    }
  }

  return (
    <Section>
      {csvRequestState === 'printed' &&
        <CSVDownload data={list.items} headers={csvColumns} target="_blank" />
      }
      <div className="grid-container margin-y-0">
        <PageHeader title={gridDef.pageHeader}
                    description={gridDef.description + ' (created using ' + gridDef.baseData + ')'}/>
        <GridControl
          list={filteredList}
          availableFilterColumns={availableFilterColumns}
          setAvailableFilterColumns={setAvailableFilterColumns}
          setTableFilterColumn={setTableFilterColumn}
          tableFilterColumn={tableFilterColumn}
          stopAfterPage={stopAfterPage}
          setStopAfterPage={setStopAfterPage}
          setTableFilter={setTableFilter}
          tableFilter={tableFilter}
          viewFavorites={viewFavorites}
          setViewFavorites={setViewFavorites}
          viewRecents={viewRecents}
          setViewRecents={setViewRecents}
          csvRequestState={csvRequestState} // not used yet.
          requestCsv={requestCsv}
          configMode={configMode}
          setConfigMode={setConfigMode}
        />

        {configMode ?
          <GridConfig
            gridDef={gridDef}
            fixedColumns={fixedColumns}
            setColumns={setColumns}
            setCsvColumns={setCsvColumns}
            setAvailableFilterColumns={setAvailableFilterColumns}
          /> :
          <GridData
            columns={columns}
            list={filteredList}
            sortBy={sortBy}
            sortByDefaultColumn={props.sortByDefaultColumn}
            sortByAscending={sortByAscending}
            handleHeaderClick={handleHeaderClick}
            toggleFavorite={toggleFavorite}
            trackRecent={trackRecent}
          />
        }

      </div>
    </Section>
  )
}

// function Header(props) {
//   const {column, sortBy, sortByDefaultColumn, sortByAscending, handleHeaderClick} = props
//
//   let label = column.label
//   let arrow
//   if (column.key === sortBy[0]) {// ||
// //      column.key === sortByDefaultColumn && sortBy == <the props value from function, not Header but the Component>) {
// //  if (sortBy[0] === column.key) {
//     if (sortByAscending === 1) {
//       arrow = <i className="fas fa-long-arrow-alt-down"></i>
//     } else {
//       arrow = <i className="fas fa-long-arrow-alt-up"></i>
//     }
//   }
//
//   return (
//     <th
//       onClick={() => handleHeaderClick(column.key)}
//       style={{cursor: 'pointer'}}
//     >
//       {label}{arrow}
//     </th>
//   )
//
//
// }
//
// function Attribute(props) {
//   const {item, column} = props
//   const style = {} //column.style ?? {}
//   if (column.key === 'pk') {
//     const email = item[column.key].slice(item[column.key].indexOf('-') + 1)
//     if (column.linkTo === 'person') {
//       return <td style={style}><a href={"/admin/account/" + email} onClick={() => }>{email}</a></td>
//     }
//     return <td style={style}>email</td>
//   }
//
//   if (column.isBoolean) {
//     return <td style={style}>{item[column.key] ? 'Yes' : 'No'}</td>
//   }
//   if (column.key === 'favorite') {
//     if (item.favorite) {
//       return <td>*</td>
//     }
//
//     return <td onClick={() => props.favorite(item.pk)}>IIIII</td>
//   }
//
//   return <td style={style}>{item[column.key]}</td>
// }
