import React, { Component } from 'react';
import { Link } from "react-router-dom";

import * as contentful from 'contentful';

class Search extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      page: null,
    };
  }

  componentDidMount() {
    var contentfulClient = contentful.createClient(
      { space: 'bg11zawujrgr',
        accessToken: 'c86395435846a2c448defbf0c59f24677fa2a58d522e6b2fe3e6c4b57c083caf' })

    contentfulClient.getEntries({'content_type': 'pageBasic', 'query': this.props.keyword, 'limit': 50})
    .then(entries => {

      const filterItems = entries.items.filter(item => !item.fields.excludeFromSearchResults || item.fields.excludeFromSearchResults !== true)

      this.setState({
        isLoaded: true,
        items: filterItems,
        total: filterItems.length
      });
    })
    .catch(error => {
      console.log(error)
      this.setState({
        error: error
      })
    })
  }

  render() {

    if (!this.state.isLoaded) {return null}

    return (
      <section className="usa-section" style={{paddingTop: 10, paddingBottom: 10}}>
        <div className="grid-container margin-y-0">
          <h2 className="margin-y-0" style={{paddingBottom: 10}}>Search Results</h2>
          <p className="usa-prose">{this.state.total} matches for <strong>&quot;{this.props.keyword}&quot;</strong> </p>
      </div>

      <div className="grid-container">

        {this.state.items.map(item =>
          <>
            <div>
              <Link
                to={"/" + item.fields.path}
                title={item.fields.headline}
                aria-label={item.fields.headline}
                style={{textDecoration: 'none', fontSize: 'larger'}}
              >
                {item.fields.headline}
              </Link>
            </div>
            <div style={{marginBottom: 10}}>/{item.fields.path}</div>
          </>
        )}


      </div>
    </section>
    )
  }
}
// <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: 40, marginBottom: 20}}>
//   <ReactMarkdown source={item.fields.body} escapeHtml={false}/>
// </div>
export default Search;
