import React, { Component } from 'react';

import Form from 'react-bootstrap/Form'
import State from '../../controls/State'
import Service from '../../controls/Service'
import Rank from '../../controls/Rank'
import Status from '../../controls/Status'
import Base from '../../controls/Base'
import BaseOther from '../../controls/BaseOther'
import Nationality from '../../controls/Nationality'
import PhoneType from '../../controls/PhoneType'
import {PersonContext} from '../../../classes/context.js';

var moment = require('moment');

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class Editor extends Component {

  constructor(props) {
    super(props)

    this.handlePrivateChange = this.handlePrivateChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.stateChange = this.stateChange.bind(this)

    this.state = {
      changed: moment()
    }
  }

  keyValue(item, key, format) {
      const {person} = this.context
      let currentItem = person.editItem ? person.editItem : person.item

      switch (item) {
        case 'root':
        case 'address':
        case 'military':
        case 'civilian':
        case 'nationality':
        case 'phone-primary':
        case 'phone-secondary':

          if (format) {
            return (typeof(currentItem[item][key]) === 'undefined' ||
                    !currentItem[item][key]
                    ? null
                    : moment(currentItem[item][key]).format(format))
          }

          return (typeof(currentItem[item][key]) === 'undefined' ? null : currentItem[item][key])

        default:
         return null
      }
    }

  handleChange(event) {
    let person = this.context.person
    if (!person.editItem) {person.beginEdit()}
    const handleChange = person.handleChange.bind(person)
    handleChange(event)
  }

  handlePrivateChange(event) {
    if (!this.context.person.editItem) {
      this.context.person.beginEdit()
    }

    const handleChange = this.context.person.handleChange.bind(this.context.person)
    handleChange(event)

    if (!event.target.checked) {
      // After changing non-government employment (private) set the current values to blank
      handleChange({target: {name: 'root.private_organization',  value: '', type: 'input'}})
      handleChange({target: {name: 'root.private_title',  value: '', type: 'input'}})
    }
    // This is really a hack to force a state change and a rerender. The context
    // change does not appear to do that automatically. Although it seems like
    // it should.
    this.setState({
      changed: moment()
    })
  }

  handleCheckboxChange(event) {
    const handleChange = this.context.person.handleChange.bind(this.context.person)
    handleChange(event)

    // This is really a hack to force a state change and a rerender. The context
    // change does not appear to do that automatically. Although it seems like
    // it should.
    this.stateChange()
  }

  stateChange() {
    this.setState({
      changed: moment()
    })
  }

  render() {

    const {person} = this.context
    let item = person.editItem ? person.editItem : person.item
    const {military, civilian, root, cart} = item

//    const handleChange = person.handleChange.bind(this.context.person)

    return (

      <Form>
        <h2 class="margin-y-1" style={{marginBottom: 0}}>Personal Information</h2>

        <div class="grid-row grid-gap">
          <div class="tablet:grid-col-1">
            <div className='usa-label'>Honorific</div>
            <Form.Control className='usa-input' name='root.honorific' plaintext defaultValue={this.keyValue('root','honorific')}  style={editingStyle} onChange={this.handleChange}/>
          </div>
          <div class="tablet:grid-col-3">
            <div className='usa-label'>First Name (requried)</div>
            <Form.Control className='usa-input' name='root.first_name' plaintext defaultValue={this.keyValue('root','first_name')}  style={editingStyle} onChange={this.handleChange}/>
          </div>

          <div class="tablet:grid-col-1">
            <div className='usa-label'>Initial</div>
            <Form.Control className='usa-input' name='root.middle_initial' plaintext defaultValue={this.keyValue('root','middle_initial')}  style={editingStyle} onChange={this.handleChange}/>
          </div>

          <div class="tablet:grid-col-3">
            <div className='usa-label'>Last Name (required)</div>
            <Form.Control className='usa-input' name='root.last_name' plaintext defaultValue={this.keyValue('root','last_name')}  style={editingStyle} onChange={this.handleChange}/>
          </div>

          <div class="tablet:grid-col-1">
            <div className='usa-label'>Suffix</div>
            <Form.Control className='usa-input' name='root.suffix' plaintext defaultValue={this.keyValue('root','suffix')}  style={editingStyle} onChange={this.handleChange}/>
          </div>

        </div>

        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-5">
            <div className="usa-label">Nickname</div>
            <Form.Control className="usa-input" name="root.nickname" plaintext defaultValue={this.keyValue('root', 'nickname')}  maxlength={20} style={editingStyle} onChange={this.handleChange}/>
            <p style={{fontSize: 'smaller'}}>This will appear on your convention badge</p>
          </div>

        </div>

        <div class="tablet:grid-col-3">
          <div className='usa-label'>Nationality</div>
          <Nationality
            defaultValue={this.keyValue('nationality','code')}
            defaultDisplayValue={this.keyValue('nationality','name')}
            onChange={this.handleChange}
            stateChange={this.stateChange}
          />
        </div>

        {cart.includeMembership &&
          <>
        <h2 style={{marginTop: 15, marginBottom: -10}}>Contact Info</h2>
        <div class="grid-row grid-gap">
          <div class="tablet:grid-col-4">
            <div className='usa-label'>Line 1</div>
            <Form.Control className='usa-input' name='address.line_1' plaintext defaultValue={this.keyValue('address','line_1')}  style={editingStyle} onChange={this.handleChange}/>
          </div>
          <div class="tablet:grid-col-4">
            <div className='usa-label'>Line 2</div>
            <Form.Control className='usa-input' name='address.line_2' plaintext defaultValue={this.keyValue('address','line_2')}  style={editingStyle} onChange={this.handleChange}/>
          </div>

        </div>

        <div class="grid-row grid-gap">
          <div class="tablet:grid-col-3">
            <div className='usa-label'>City</div>
            <Form.Control className='usa-input' name='address.city' plaintext defaultValue={this.keyValue('address','city')}  style={editingStyle} onChange={this.handleChange}/>
          </div>

          <div class="tablet:grid-col-3">
            <div className='usa-label'>State</div>
            <State defaultValue={this.keyValue('address','state_or_province')} onChange={this.handleChange}/>
          </div>

          <div class="tablet:grid-col-2">
            <div className='usa-label'>Zip</div>
            <Form.Control className='usa-input' name='address.postal_code' plaintext defaultValue={this.keyValue('address','postal_code')}  style={editingStyle} onChange={this.handleChange}/>
          </div>

          <div class="tablet:grid-col-3">
            <div className='usa-label'>Country</div>
            <Form.Control className='usa-input' name='address.country' plaintext defaultValue={this.keyValue('address','country')}  style={editingStyle} onChange={this.handleChange}/>
          </div>

        </div>

        <div class="grid-row grid-gap">
          <div class="tablet:grid-col-3">

            <PhoneType
              subtype='Primary'
              defaultValue={this.keyValue('phone-primary','type')}
              name="phone-primary.type"
              defaultDisplayValue={this.keyValue('phone-primary','type_name')}
              onChange={this.handleChange}
              stateChange={this.stateChange}
            />
            </div>
          {this.keyValue("phone-primary", "type") !== "none" && (
            <div class="tablet:grid-col-3">
              <div className='usa-label'>Number</div>
              <Form.Control className='usa-input' name='phone-primary.phone_number' plaintext defaultValue={this.keyValue('phone-primary','phone_number')}  style={editingStyle} onChange={this.handleChange}/>
            </div>
          )}
        </div>

        <div class="grid-row grid-gap">
          <div class="tablet:grid-col-3">

            <PhoneType
              subtype='Secondary'
              defaultValue={this.keyValue('phone-secondary','type')}
              name="phone-secondary.type"
              defaultDisplayValue={this.keyValue('phone-secondary','type_name')}
              onChange={this.handleChange}
              stateChange={this.stateChange}
            />
            </div>
          {this.keyValue("phone-secondary", "type") !== "none" && (
            <div class="tablet:grid-col-3">
              <div className='usa-label'>Number</div>
              <Form.Control className='usa-input' name='phone-secondary.phone_number' plaintext defaultValue={this.keyValue('phone-secondary','phone_number')}  style={editingStyle} onChange={this.handleChange}/>
            </div>
          )}
        </div>

        <div class="grid-row grid-gap">
          <div class="tablet:grid-col-5">
            <div className='usa-label'>Alternate Email Address</div>
            <Form.Control className='usa-input' name='root.alternate_email_address' plaintext defaultValue={this.keyValue('root','alternate_email_address')}  style={editingStyle} onChange={this.handleChange}/>
          </div>
        </div>
        </>
    }
        {false &&
          <>
          <h2 style={{marginTop: 15, marginBottom: -10}}>Spouse</h2>
          <div class="grid-row grid-gap">

            <div class="tablet:grid-col-3">
              <div className='usa-label'>Spouse's First Name</div>
              <Form.Control className='usa-input' name='root.spouse_first_name' plaintext defaultValue={this.keyValue('root','spouse_first_name')}  style={editingStyle} onChange={this.handleChange}/>
            </div>

            <div class="tablet:grid-col-3">
              <div className='usa-label'>Spouse's Last Name</div>
              <Form.Control className='usa-input' name='root.spouse_last_name' plaintext defaultValue={this.keyValue('root','spouse_last_name')}  style={editingStyle} onChange={this.handleChange}/>
            </div>

          </div>
          </>
        }

        <h2 class="margin-y-5" style={{marginBottom: 0}}>Service and Professional</h2>
          <div class="tablet:grid-col-3">
            <div className='usa-label'>Military Service</div>
            <Service
              defaultValue={this.keyValue('military','service')}
              name='military.service'
              defaultDisplayValue={this.keyValue('military','service_name')}
              onChange={this.handleChange}
              service="service-military"
              stateChange={this.stateChange}
            />
          </div>

          {military.service && military.service !== 'none' &&
            <div style={{ paddingLeft: 20, borderLeft: 'solid', borderLeftWidth: 1, borderLeftColor: 'grey' }}>

              <div class="grid-row grid-gap">
                <div class="tablet:grid-col-3">
                  <div className="usa-label">Status</div>
                  <Status
                    defaultValue={this.keyValue("military", "status")}
                    name="military.status"
                    defaultDisplayValue={this.keyValue("military", "status_name")}
                    onChange={this.handleChange}
                    service="status-military"
                    stateChange={this.stateChange}
                  />
                </div>
                <div class="tablet:grid-col-4">
                  <div className="usa-label">Rank</div>
                  <Rank
                    defaultValue={this.keyValue("military", "rank")}
                    name="military.rank"
                    defaultDisplayValue={this.keyValue("military", "rank_name")}
                    onChange={this.handleChange}
                    service={this.keyValue("military", "service")}
                    list="rank-military"
                    stateChange={this.stateChange}
                  />
                </div>
                <div class="tablet:grid-col-3">
                  <div className="usa-label">Date of Rank</div>
                  <Form.Control
                    className="usa-input"
                    name="military.date_of_rank"
                    placeholder="MM-DD-YYYY"
                    plaintext
                    defaultValue={this.keyValue("military", "date_of_rank", 'MM-DD-YYYY')}
                    style={editingStyle}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div class="grid-row grid-gap">
                <Base
                  type="military"
                  defaultValue={this.keyValue("military", "base")}
                  defaultDisplayValueBaseName={this.keyValue("military", "base_name")}
                  onChange={this.handleChange}
                  stateChange={this.stateChange}
                />
                {this.keyValue("military", "base") === "other" && (
                  <BaseOther
                    type="military"
                    defaultValue={this.keyValue("military", "base_other")}
                    onChange={this.handleChange}
                    stateChange={this.stateChange}
                  />
                )}
              </div>

              <div class="grid-row grid-gap">
                <div class="tablet:grid-col-4">
                  <div className="usa-label">Organization</div>
                  <Form.Control
                    className="usa-input"
                    name="military.organization"
                    plaintext
                    defaultValue={this.keyValue("military", "organization")}

                    style={editingStyle}
                    onChange={this.handleChange}
                  />
                </div>
                <div class="tablet:grid-col-4">
                  <div className="usa-label">Role</div>
                  <Form.Control
                    className="usa-input"
                    name="military.role"
                    plaintext
                    defaultValue={this.keyValue("military", "role")}

                    style={editingStyle}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
          }

          <div class="tablet:grid-col-3">
            <div className='usa-label'>Government Service</div>
            <Service
              defaultValue={this.keyValue('civilian','service')}
              defaultDisplayValue={this.keyValue('civilian','service_name')}
              name='civilian.service' onChange={this.handleChange}
              service="service-civilian"
              stateChange={this.stateChange}
              />
          </div>

          {civilian.service && civilian.service !== 'none' &&
            <>
              <div style={{ paddingLeft: 20, borderLeft: 'solid', borderLeftWidth: 1, borderLeftColor: 'grey' }}>
                <div class="grid-row grid-gap">
                  <div class="tablet:grid-col-3">
                    <div className="usa-label">Status</div>
                    <Status
                      defaultValue={this.keyValue("civilian", "status")}
                      name="civilian.status"
                      defaultDisplayValue={this.keyValue("civilian", "status_name")}
                      onChange={this.handleChange}
                      service="status-civilian"
                      stateChange={this.stateChange}
                    />
                  </div>
                  <div class="tablet:grid-col-4">
                    <div className="usa-label">Rank</div>
                    <Rank
                      defaultValue={this.keyValue("civilian", "rank")}
                      name="civilian.rank"
                      defaultDisplayValue={this.keyValue("civilian", "rank_name")}
                      onChange={this.handleChange}
                      service={this.keyValue("civilian", "service")}
                      list="rank-civilian"
                      stateChange={this.stateChange}
                    />
                  </div>
                  <div class="tablet:grid-col-3">
                    <div className="usa-label">Date of Rank</div>
                    <Form.Control
                      className="usa-input"
                      name="civilian.date_of_rank"
                      placeholder="MM-DD-YYYY"
                      plaintext
                      defaultValue={this.keyValue("civilian", "date_of_rank", 'MM-DD-YYYY')}
                      style={editingStyle}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div class="grid-row grid-gap">
                  <Base
                    type="civilian"
                    defaultValue={this.keyValue("civilian", "base")}
                    defaultDisplayValueBaseName={this.keyValue("civilian", "base_name")}
                    onChange={this.handleChange}
                    stateChange={this.stateChange}
                  />
                  {this.keyValue("civilian", "base") === "other" && (
                    <BaseOther
                      type="civilian"
                      defaultValue={this.keyValue("civilian", "base_other")}
                      onChange={this.handleChange}
                      stateChange={this.stateChange}
                    />
                  )}
                </div>

                <div class="grid-row grid-gap">
                  <div class="tablet:grid-col-4">
                    <div className="usa-label">Organization</div>
                    <Form.Control
                      className="usa-input"
                      name="civilian.organization"
                      plaintext
                      defaultValue={this.keyValue("civilian", "organization")}
                      style={editingStyle}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="tablet:grid-col-4">
                    <div className="usa-label">Role</div>
                    <Form.Control
                      className="usa-input"
                      name="civilian.role"
                      plaintext
                      defaultValue={this.keyValue("civilian", "role")}
                      style={editingStyle}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </>

          }

          <div class="tablet:grid-col-4" style={{paddingTop: 25}}>
            <div class="usa-checkbox">
              <input class="usa-checkbox__input" id="root.has_private" name='root.has_private' checked={root.has_private} type="checkbox" onChange={this.handlePrivateChange}/>
              <label class='usa-checkbox__label' for="root.has_private">Non-Government Employment</label>
            </div>
          </div>

          {root.has_private &&
            <>
            <div style={{ paddingLeft: 20, borderLeft: 'solid', borderLeftWidth: 1, borderLeftColor: 'grey' }}>
                <div class="grid-row grid-gap">
                  <div class="tablet:grid-col-4">
                    <div className="usa-label" style={{ marginTop: 16 }}>
                      Organization
                    </div>
                    <Form.Control
                      className="usa-input"
                      name="root.private_organization"
                      plaintext
                      defaultValue={this.keyValue("root", "private_organization")}

                      style={editingStyle}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="tablet:grid-col-4">
                    <div className="usa-label" style={{ marginTop: 16 }}>
                      Title
                    </div>
                    <Form.Control
                      className="usa-input"
                      name="root.private_title"
                      plaintext
                      defaultValue={this.keyValue("root", "private_title")}

                      style={editingStyle}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </>
          }


          {cart.includeMembership &&
            <>
            <h2 style={{marginTop: 15, marginBottom: -10}}>Other Information</h2>

            <div class="grid-row grid-gap">
              <div class="tablet:grid-col-4" style={{paddingTop: 25}}>
                <div class="usa-checkbox">
                  <input class="usa-checkbox__input" id="root.receive_atq" name='root.receive_atq' checked={root.receive_atq} type="checkbox" onChange={this.handleCheckboxChange}/>
                  <label class='usa-checkbox__label' for="root.receive_atq">Prefer A/TQ Magazine by US Mail</label>
                </div>
              </div>
            </div>
            </>
          }
        </Form>
      )
    }
}

Editor.contextType = PersonContext
export default Editor;
