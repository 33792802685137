import React, { Component } from 'react';
import { Link } from "react-router-dom";

class IntroducingAta extends Component {

  render() {

    const format = this.props.dark ? "usa-section usa-section--dark usa-graphic-list" : "usa-section  usa-graphic-list"

    return (
      <section id="test-section-id" className={format} style={{paddingTop: 40}}>
        <div className="grid-container">
          <h2 className="font-heading-xl margin-y-0">Introducing the Airlift/Tanker Association</h2>
          <p className="usa-prose" >
          The Airlift/Tanker Association is a Section 501 (c) (3) e tax-exempt organization dedicated to providing a forum for ensuring that American military forces continue to have the air mobility capability required to implement U.S. national security strategy. The Association is international in scope, with members and chapters throughout the world.  Membership includes active duty, guard, reserve and retired military personnel, both officers and enlisted, as well as civilian and industry supporters of the air mobility mission. Membership is open to anyone who wishes to join. </p><p className="usa-prose">
  Association benefits and programs include a quarterly magazine, annual national conventions, educational programs, seminars and symposiums, college grants and scholarships, awards, and heritage programs that recognize outstanding contributors to air mobility,
  both past and present.</p>
          <Link
            to="/become-a-member"
            title="Become a Member"
            aria-label="become-a-member"
            className="usa-button"
          >
          Become a Member
          </Link>

          <span style={{marginLeft: 20}}>Already a member?
            <Link
              to="/account"
              title="Renew Your Membership"
              aria-label="renew"
              className="usa-button usa-button--unstyled"
              style={{marginLeft: 10, textDecoration: 'none'}}
            >
            Renew
            </Link>
          </span>
        </div>
      </section>
    )
  }
}

export default IntroducingAta;
