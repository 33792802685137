import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Link, Redirect } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import CardDeck from 'react-bootstrap/CardDeck';

import CatalogItem from './CatalogItem';
import {PersonContext} from '../../../classes/context.js';

import Loading from '../../Loading';
import ErrorContainer from '../../ErrorContainer';
import ScrollToTop from '../ScrollToTop'
import {TXT_REGISTRATION_PREAMBLE, TXT_STANDARD_PRIMARY_REGISTRATION_TEXT, TXT_IID_PRIMARY_REGISTRATION_TEXT} from '../../../constants'

var moment = require('moment');

class Catalog extends Component {

  constructor(props) {
    super(props);

    this.selectionUpdated = this.selectionUpdated.bind(this)
    this.emptyCart = this.emptyCart.bind(this)

    this.state = {
      exhibitorsLoaded: false,
      registrationTypesLoaded: false,
      eventDaysLoaded: false,
      isLoaded: false,
      loadFailed: false,
      error: null,
      isGovTld: false,
    };
  }

  componentDidMount() {
    this.getData()
  }

  emptyCart() {
    this.context.person
      .beginEdit()
      .emptyCart()
      .endEdit(true)
      .store()
      .then(() => {
        this.setState({isLoaded: true})
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 409') {
          alert('Someone has updated this item since you loaded it. Reload to see the latest changes.')
        } else {
          this.setState({error: error})
        }
      })
  }

  selectionUpdated() {
    this.setState({changed: moment()})
  }

  render() {

    const {isLoaded, loadFailed, error} = this.state;

    const {person} = this.context

    const showPrimary = !(person.item.cart && typeof(person.item.cart.primaryRegistrationType) !== 'undefined')
    const part = showPrimary ? 'Primary Registration' : 'Optional Registration Items'
    const title = person.isLoading ? 'Convention Registration' : 'Convention Registration - ' + part
    const isAdmin = this.props.isAdmin

    if (error) {
      return <ErrorContainer title={title} error={error} />
    } else if (loadFailed) {
      return <ErrorContainer title={title} error={{message:'The catalog information failed to load.'}} />
    } else if (!isLoaded || person.isLoading || !person.isInitialized) {
      return <Loading title={title}
                      subtitle={TXT_REGISTRATION_PREAMBLE}/>;
    }

    if (['complete', 'payment-submitted', 'cancellation-requested'].includes(person.item.cart.status)) {
      return <Redirect to="/account" />
    }

    const emailAddressTld = person.item.root.pk ?
                      person.item.root.pk.split('.').pop() :
                      false
    const isGovTld = ['mil', 'gov'].includes(emailAddressTld)

    let includesGuestBanquet = (person.item.cart && person.item.cart.secondaryItems) ?
        person.item.cart.secondaryItems
        .filter(item => item.sk === 'secondaryGuestAll' || item.sk === 'secondaryGuestFridayBanquet')
        .length :
        false

    return (
      <section className="usa-section" style={{paddingTop: 10, borderTopWidth: 0}}>
        <div className="grid-container margin-y-0">
          <h2 className="margin-y-0">{title}</h2>
          <p className="usa-prose">{TXT_REGISTRATION_PREAMBLE}</p>

          <Primary
            showPrimary={showPrimary}
            registrationType={this.state.primary}
            relationships={this.state.relationships}
            exhibitors={this.state.exhibitors}
            conventionDays={this.state.conventionDays}
            selectionUpdated={this.selectionUpdated}
            isGovTld={isGovTld}
            isAdmin={isAdmin}
          />
          <Secondary
            showPrimary={showPrimary}
            primaryRegistrationType={person.item.cart.primaryRegistrationType}
            includesGuestBanquet={includesGuestBanquet}
            registrationType={this.state.secondary}
            relationships={this.state.relationships}
            exhibitors={this.state.exhibitors}
            conventionDays={this.state.conventionDays}
            selectionUpdated={this.selectionUpdated}
            emptyCart={this.emptyCart}
            pk={person.item.root.pk}
            isAdmin={this.props.isAdmin}
            isSaving={person.isSaving}
          />
        </div>
      </section>
    )
  }

  getData() {
    API.get('general', '/reference/registration-type')
    .then(response => {

      const items = response.items.sort(function(a, b) {
        if (a.displayOrder < b.displayOrder) {return -1}
        if (a.displayOrder > b.displayOrder) {return 1}
        return 0;
      });

      const primary = items.filter(item => {
          return (item.type === 'primary' && item.isVisible);
        });

      const secondary = items.filter(item => {
          return (item.type === 'secondary' && item.isVisible);
        });

      const relationships = items.filter(item => {
          return (item.sk === 'relationships');
        });

      const isLoaded = this.state.eventDaysLoaded && this.state.exhibitorsLoaded
      this.setState({
        registrationTypes: items,
        primary: primary,
        secondary: secondary,
        relationships: relationships[0],
        registrationTypesLoaded: true,
        isLoaded: isLoaded,
      });
    })
    .catch(error => {
      this.setState({
        isLoading: false,
        loadFailed: true,
        error: error
      });
      console.log(error);
    });

    API.get('general', '/exhibitor-list')
    .then(response => {

      const exhibitors = response.items.sort(function(a, b) {
        if (a.name < b.name) {return -1}
        if (a.name > b.name) {return 1}
        return 0;
      });

      // const exhibitors = items.filter(item => {
      //     return (item.convention === '2021');
      //   });

      const isLoaded = this.state.eventDaysLoaded && this.state.registrationTypesLoaded
      this.setState({
        exhibitors: exhibitors,
        exhibitorsLoaded: true,
        isLoaded: isLoaded,
      });
    })
    .catch(error => {
      this.setState({
        isLoaded: false,
        loadFailed: true,
        error: error
      });
      console.log(error);
    });

    API.get('general', '/reference/event-day')
    .then(response => {

      const items = response.items.sort(function(a, b) {
        if (a.sequence < b.sequence) {return -1}
        if (a.sequence > b.sequence) {return 1}
        return 0;
      });

      const conventionDays = items.filter(item => {
          return (item.isConvention);
        });

      const industryInterfaceDay = items.filter(item => {
          return (item.isIID);
        });

      const isLoaded = this.state.registrationTypesLoaded && this.state.exhibitorsLoaded
      this.setState({
        eventDays: items,
        industryInterfaceDay: industryInterfaceDay,
        conventionDays: conventionDays,
        eventDaysLoaded: true,
        isLoaded: isLoaded
      });
    })
    .catch(error => {
      this.setState({
        isLoaded: false,
        loadFailed: true,
        error: error
      });
      console.log(error);
    });
  }
}

Catalog.contextType = PersonContext
export default Catalog;

// ==========================================================================
// Local Components

function Primary(props) {
  if (!props.showPrimary) {return null}



   let primary = props.registrationType
        // .filter(item => (props.isGovTld &&  item.availableToGovEmailAddress) ||
        //                 (!props.isGovTld &&item.availableToNonGovEmailAddress ))
   if (props.isAdmin) {
     primary = primary.filter(type => !type.isAdminOnly || (type.isAdminOnly && props.isAdmin))
   }
  return (
    <>
      <ScrollToTop />

      <p className="usa-prose">
        Select your primary registration type below to begin.
      </p>
      <div className="grid-row grid-gap">
        <CardDeck>
          {primary.map(type => (
            <div key={type.name} style={{display: 'inline-block', marginRight: 20, marginBottom: 20, borderWidth: 1, borderStyle: 'solid', borderColor: '#cccccc', padding: 10}}>
            <CatalogItem
              registrationType={type}
              relationships={props.relationships}
              exhibitors={props.exhibitors}
              conventionDays={props.conventionDays}
              selectionUpdated={props.selectionUpdated}
            />
        </div>
          ))}
        </CardDeck>
      </div>
    </>
  )
}

function Secondary(props) {
  if (props.showPrimary) {return null}

  const visibleSecondaryItems = props.registrationType.filter(type =>
    type.validForRegistrationTypes.includes(props.primaryRegistrationType.sk) &&
    (!type.requiresGuestBanquet ||
     (type.requiresGuestBanquet && props.includesGuestBanquet))
  )

  // There is a special case where one secondary requires the selection of
  // another, specially for the guest banquet.


  // visible={registrationType.row === row && registrationType.type === 'secondary' &&
  //   registrationType.validForRegistrationTypes.includes(this.props.registrant.cart.primaryRegistrationType.id)}

  const additionalText = props.primaryRegistrationType.sk === 'virtualIID' ?
                        TXT_IID_PRIMARY_REGISTRATION_TEXT.replace('%1', props.primaryRegistrationType.name) :
                        TXT_STANDARD_PRIMARY_REGISTRATION_TEXT.replace('%1', props.primaryRegistrationType.name)

  return (
    <>
    <ScrollToTop />
    <p className="usa-prose">
      {additionalText}
    </p>
    <div>
      <div style={{marginBottom: 16}}>

        <CustomLink
          className="usa-button"
          to="/cart"
          adminTo={"/admin/cart/" + props.pk.slice(props.pk.indexOf('-') + 1)}
          isAdmin={props.isAdmin}
          label="Begin Checkout"
        />

        <Button
          className="usa-button usa-button--unstyled"
          style={{ textDecoration: "none" }}
          onClick={() => props.emptyCart()}
          name="emptyCart"
          disabled={props.isSavingDDD}
        >
          Choose a Different Registration Type
        </Button>
      </div>

      {visibleSecondaryItems.length === 0 &&
        <>
        <hr />
        <div style={{marginTop: 60}}>There are no optional registration items available to you.</div>
        </>
      }

      <div className="grid-row grid-gap">
        <CardDeck>
          {visibleSecondaryItems.map(type => (
            <div key={type.sk} style={{display: 'inline-block', marginRight: 20, marginBottom: 20, borderWidth: 1, borderStyle: 'solid', borderColor: '#cccccc', padding: 10}}>
              <CatalogItem
                registrationType={type}
                relationships={props.relationships}
                exhibitors={props.exhibitors}
                conventionDays={props.conventionDays}
                selectionUpdated={props.selectionUpdated}
                emptyCart={props.emptyCart}
              />
            </div>
          ))}
        </CardDeck>
      </div>
    </div>
    </>
  )
}

function CustomLink(props) {
  let to = props.isAdmin ? props.adminTo : props.to
  return (
    <Link
      className="usa-button"
      to={to}
      >
      {props.label}
    </Link>
  )
}
