/* jshint camelcase: false */
import { API } from 'aws-amplify'
import Data, {ITEM_SK_ROOT, ITEM_SK_MEMBERSHIP, ITEM_SK_ADDRESS,
              ITEM_SK_PHONE_PRIMARY, ITEM_SK_PHONE_SECONDARY,
              ITEM_SK_CHAPTER, ITEM_SK_MILITARY, ITEM_SK_CIVILIAN,
              ITEM_SK_NATIONALITY, ITEM_SK_DONATION, ITEM_SK_CONVENTION,
              ITEM_SK_COMMENT, ITEM_SK_REGISTRATION, ITEM_SK_CART,
              ITEM_SK_CART_PERSONAL, ITEM_SK_CART_GOV, ITEM_SK_CART_IID,
              ITEM_SK_CART_CANCELLATION } from './Data'

var moment = require('moment')

/* jshint asi: true */


class Person extends Data {

  static async delete(email, actions) {
    let init = {
      headers: {},
      response: true,
      body: {
        pk: 'person-' + email,
        person: actions.person,
        registration: actions.registration
      }
    }

    return new Promise((resolve, reject) => {
      API.del('general', '/person', init)
      .then(response => {
        this.item = {} // nullPerson may not be needed
        this.account = false
        this.isInitialized = false
        this.isLoading = false
        this.isSaving = false
        this.loadFailed = false
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
    })
  }

  async store() {
    return this.storeItem('general', '/person')
  }

  async delete() {
    return this.deleteItem('general', '/person')
  }

  mergeWith(targetEmail) {
    this.item.mergeWithEmail = targetEmail.toLowerCase()
    return this
  }

  undoCancellationRequest() {
    this.item.action = 'undo-cancellation-request'
    return this
  }

  addSecondary(secondaryItem) {
    this.item.action = 'add-secondary'
    this.item.secondaryItem = secondaryItem
    if (!this.item.cart.secondaryItems) {this.item.cart.secondaryItems = []}
    this.item.cart.secondaryItems.push(secondaryItem)
    this.calculatePrice()
    return this
  }

  removeSecondary(secondaryItem) {
    this.item.action = 'remove-secondary'
    this.item.secondaryItem = secondaryItem
    this.item.cart.secondaryItems = this.item.cart.secondaryItems.filter(item => item.sk !== secondaryItem.secondaryItemToRemove)
    this.calculatePrice()
    return this
  }

  confirmSecondaryPayment() {
    this.item.action = 'confirm-secondary-payment'
    return this
  }

  addComment(comment) {
    this.item.comment = comment
    this.item.comments.push({comment: comment, created_at: moment().format()}) // fake entry that will be sorted incorrectly because the timestamp is local time.
    return this
  }

  editPrimaryEmail(targetEmail) {
    this.item.editPrimaryEmail = targetEmail.toLowerCase()
    return this
  }

  registrationDelete() {
    this.item.registrationDelete = true
    return this
  }

  registrationDeleteCleanup() {
    this.item.cart = {
      pk: this.item.root.pk,
      sk: 'cart',
      type: 'cart',
    }
    this.item['cart-cancellation'] = {}
    this.item['cart-personal'] = {}
    this.item['cart-gov'] = {}
    delete this.item.registrationDelete
  }

  actionCleanUp() {
    delete this.item.action
    this.item.cart.status = 'complete'
    return this
  }

  async deleteMembership(membershipSk) {
    this.isSaving = true
    let init = {
      headers: {},
      response: true,
      body: {
        pk: this.item.root.pk,
        sk: membershipSk,
      }
    }

    return new Promise((resolve, reject) => {
      API.del('general', '/membership', init)
      .then(response => {
        this.isSaving = false
        this.item.membership = this.item.membership.filter(membership =>
          membership.sk !== membershipSk)
        this.item['membership-summary'] = Person.membershipSummary(this.item.membership)
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        this.isSaving = false
        reject(error)
      })
    })
  }

  async get(id) {

    this.item = {id: id}
    this.isInitialized = false
    this.isLoading = true

    let init = {
      headers: {},
      response: true,
      queryStringParameters: {
        id: 'person-' + this.item.id
      }
    }

    let that = this
    return new Promise(function(resolve, reject) {

      API.get('general', '/person', init)
        .then(response => {

          that.rawItems = response.data.items

          // a null response like this indicates that the record does not exist
          // in the database.
          if (response.data.items.length === 0) {
            that.isLoading = false
            that.isInitialized = true
            that.loadFailed = false
            that.isNew = true
            that.account = false
            that.item.root = {id: that.item.id, pk: 'person-' + that.item.id, sk: 'root'}
            resolve(that.item)
          }

          let root = that.filterItem(ITEM_SK_ROOT)[0]
          let address = that.filterItem(ITEM_SK_ADDRESS)[0]
          let phonePrimary = that.filterItem(ITEM_SK_PHONE_PRIMARY)[0]
          let phoneSecondary = that.filterItem(ITEM_SK_PHONE_SECONDARY)[0]
          let chapter = that.filterItem(ITEM_SK_CHAPTER)[0]
          let military = that.filterItem(ITEM_SK_MILITARY)[0]
          let civilian = that.filterItem(ITEM_SK_CIVILIAN)[0]
          const donation = that.filterItem(ITEM_SK_DONATION)
          let nationality = that.filterItem(ITEM_SK_NATIONALITY)[0]
          let membership = that.filterItem(ITEM_SK_MEMBERSHIP)
          let convention = that.filterItem(ITEM_SK_CONVENTION)
          let comments = that.filterItem(ITEM_SK_COMMENT)
          const registration = that.filterItem(ITEM_SK_REGISTRATION)[0]
          let cart = that.filterItem(ITEM_SK_CART)[0]
          let cartPersonal = that.filterItem(ITEM_SK_CART_PERSONAL)[0]
          let cartGov = that.filterItem(ITEM_SK_CART_GOV)[0]
          let cartIid = that.filterItem(ITEM_SK_CART_IID)[0]
          let cartCancellation = that.filterItem(ITEM_SK_CART_CANCELLATION)[0]

          // Special handling for corporate individual memberships
          membership = membership.filter(item => !(item.membershipType === 'c1' && !item.companyName))

          // Some required data components may not exist in migrated data.
          // Just create them with minimal data if they don't already exist.
          // Optional items include: membership, registration, donation, phone
          if (typeof(address) === 'undefined') {
            address = {state_or_province: 'none'}
          }
          if (typeof(military) === 'undefined') {military = {service: 'none'}}
          if (typeof(civilian) === 'undefined') {civilian = {service: 'none'}}
          if (typeof(nationality) === 'undefined') {
            nationality = {code: 'none'}
          }
          if (typeof(chapter) === 'undefined') {
            chapter = {name: 'none', base: 'none'}
          }
          if (typeof(phonePrimary) === 'undefined') {
            phonePrimary = {type: 'none', type_name: 'None'}
          }
          if (typeof(phoneSecondary) === 'undefined') {
            phoneSecondary = {type: 'none', type_name: 'None'}
          }
          if (typeof(cartPersonal) === 'undefined') {cartPersonal = {}}
          if (typeof(cartGov) === 'undefined') {cartGov = {}}
          if (typeof(cart) === 'undefined') {cart = {}}
          if (typeof(cartCancellation) === 'undefined') {cartCancellation = {}}

          let isNew = false
          if (typeof(root) === 'undefined') {
            root = {id: that.item.id, pk: 'person-' + that.item.id, sk: 'root'}
            isNew = true
          }

          that.isLoading = false
          that.isInitialized = true
          that.loadFailed = false
          that.isNew = isNew
          that.account = response.data.account
          that.id = root.pk.slice(root.pk.indexOf('-') + 1)
          that.item =
           {root: root,
            address: address,
            'phone-primary': phonePrimary,
            'phone-secondary': phoneSecondary,
            registration: registration,
            convention: convention,
            cart: cart,
            membership: membership,
            'cart-personal': cartPersonal,
            'cart-gov': cartGov,
            'cart-iid': cartIid,
            'cart-cancellation': cartCancellation,
            military: military,
            civilian: civilian,
            donation: donation,
            nationality: nationality,
            chapter: chapter,
            'membership-summary': Person.membershipSummary(membership),
            comments: comments }

          resolve(that.item)
        })
        .catch(error => {
          that.isLoading = false
          that.loadFailed = true
          console.log(error)
          reject(error)
      })
    })

  }

  emptyCart() {
    this.editItem.cart = {
      pk: this.item.root.pk,
      sk: 'cart',
      type: 'cart',
    }
    this.editItem['cart-cancellation'] = {}
    this.editItem['cart-personal'] = {}
    this.editItem['cart-gov'] = {}
    return this
  }

  cancellationRequest(cancellationReason) {
    this.editItem['cart-cancellation'] = {
      pk: this.item.root.pk,
      sk: 'cart-cancellation',
      type: 'cart-cancellation',
      cancellationReason: cancellationReason
    }

    this.editItem.cart.status = 'cancellation-requested'
    return this
  }

  removeItem(sk) {
    const secondaryItems =
      this.editItem.cart.secondaryItems.filter(item => item.sk !== sk)
    this.editItem.cart.secondaryItems = secondaryItems
    return this
  }

  applyCoupon(coupon) {
    this.editItem.cart.couponName = coupon.sk
    this.editItem.cart.couponIncludesGuest = coupon.includesGuest
    this.editItem.cart.couponIncludesIid = coupon.includesIid
    return this
  }

  removePartialGuestItems() {
    if (!this.editItem.cart.secondaryItems) {return this}
    this.editItem.cart.secondaryItems =
      this.editItem.cart.secondaryItems.filter(item =>
        !this.editItem.cart.relationships.otherGuestItems.includes(item.sk)
      )
    return this
  }

  endEdit(keep) {
    super.endEdit(keep)
    if (keep && this.item.cart && this.item.cart.primaryRegistrationType) {
      this.calculatePrice()
    }
    return this
  }

  // Handles a synthetic form event
   handleChange(event) {

    const target = event.target
    const name = target.name.split('.')
    let value = target.type === 'checkbox' ? target.checked : target.value

    if (target.type === 'map') {
      if (!this.editItem.cart.secondaryItems) {
        this.editItem.cart.secondaryItems = []
      }
      this.editItem.cart.secondaryItems.push(target.value)
    } else {
      const i = this.editItem.membership.findIndex(item => item.sk === name[0])
      if (i === -1) {
        if (typeof(this.editItem[name[0]]) === 'undefined') {
          this.editItem[name[0]] = {}
        }
        this.editItem[name[0]][name[1]] = value
      } else {
        this.editItem.membership[i][name[1]] = value
      }
    }

  }

  calculatePrice() {

    const annualMembershipPrice = 40

    let totalPrice = 0
    let govCardPrice = 0

    let guestPrice = 0
    let memberPricing = false
    let minFullGuestPrice = 0
    let limitGuestPrice = false

    let guestSecondaryTotalPrice = 0
    let nonGuestSecondaryTotalPrice = 0
    let includesGuest = false

    if (this.item.cart.primaryRegistrationType) {

      minFullGuestPrice = this.item.cart.primaryRegistrationType.fullPrice
      if (this.item.cart.isValidConventionMembership || this.item.cart.includeMembership) {
        minFullGuestPrice = this.item.cart.primaryRegistrationType.memberPrice
      }

      // If there is a coupon, reduce the price by the total amount of the
      // primary registration. Guest coupons are done below.
      if (!this.item.cart.couponName) {

        totalPrice = this.item.cart.primaryRegistrationType.fullPrice
        govCardPrice = this.item.cart.primaryRegistrationType.fullPrice

        if (this.item.cart.isValidConventionMembership || this.item.cart.includeMembership) {
          totalPrice = this.item.cart.primaryRegistrationType.memberPrice
          govCardPrice = this.item.cart.primaryRegistrationType.memberPrice
        }
      }

      // Add on membership if requeted
      if (this.item.cart.includeMembership) {
        totalPrice += annualMembershipPrice
      }

      if (this.item.cart.isValidConventionMembership || this.item.cart.includeMembership) {
        memberPricing = true
      }

      if (this.item.cart.secondaryItems) {
        this.item.cart.secondaryItems.forEach((item) => {
          if (item.isGuestActivity) {
            includesGuest = true
            if (memberPricing) {
              guestSecondaryTotalPrice += item.memberPrice
            } else {
              guestSecondaryTotalPrice += item.fullPrice
            }
          } else {
            // Assumed that all non guest secondary items are gov payment card eligible
            if (memberPricing) {
              nonGuestSecondaryTotalPrice += item.memberPrice
            } else {
              nonGuestSecondaryTotalPrice += item.fullPrice
            }
          }

          limitGuestPrice = limitGuestPrice || (item.isGuestActivity && item.priceMatchesRegistration)
        })
        if (limitGuestPrice) {
          guestPrice = minFullGuestPrice
          totalPrice = totalPrice + minFullGuestPrice + nonGuestSecondaryTotalPrice// assumes that nothing else has been added in the mean time
        } else {
          totalPrice = totalPrice + guestSecondaryTotalPrice + nonGuestSecondaryTotalPrice
          guestPrice = guestSecondaryTotalPrice
        }
      }
    }

    // If there is a guest coupon, reduce the price by the guest amount
    let unadjustedGuestPrice = guestPrice
    if (this.item.cart.couponIncludesGuest) {

      totalPrice -= guestPrice
      guestPrice = 0
    }

    if (this.item.cart.couponIncludesIid && this.item.cart.secondaryItems) {
          if (this.item.cart.secondaryItems.filter(item => item.includesIndustryDay).length > 0) {
            totalPrice -= 150 // To Do: allow flexibility in discount
            govCardPrice -= 150
          }
    }

    this.item.cart.totalPrice = totalPrice
    this.item.cart.nonGovCardPrice =
      totalPrice - govCardPrice - nonGuestSecondaryTotalPrice
    this.item.cart.govCardPrice = govCardPrice + nonGuestSecondaryTotalPrice
    this.item.cart.guestPrice = guestPrice
    this.item.cart.unadjustedGuestPrice = unadjustedGuestPrice
    this.item.cart.memberPricing = memberPricing
    this.item.cart.includesGuest = includesGuest
    this.item.cart.nonGovCardPriceIsMembershipOnly =
      (this.item.cart.includeMembership &&
       this.item.cart.nonGovCardPrice === annualMembershipPrice)
  }

  async completePurchase() {
    this.item.cart.receiptId = 'fake-receipt-id'
    return new Promise((resolve) => {
      resolve(this.item.cart.receiptId)
    })
  }


  setMembershipStartDate(membershipTimestamp) {
    if (!this.editItem) {return}
    const i = this.editItem.membership
              .findIndex(item => item.sk === 'membership-' + membershipTimestamp)

    if (i === -1) {return}
    if (this.editItem.membership[i].membershipStartDate) {
      this.editItem.membership[i].membershipStartDate = moment(this.editItem.membership[i].membershipStartDate, "YYYY-MM-DD")
    } else {
      if (this.editItem.membership[i].requestedStartDate) {
        this.editItem.membership[i].membershipStartDate = moment(this.editItem.membership[i].requestedStartDate, "YYYY-MM-DD")
      } else {
        let currentEndDate = false
        if (this.editItem['membership-summary'].isInGracePeriodMember || this.editItem['membership-summary'].isActiveMember) {
          currentEndDate = this.editItem['membership-summary'].lastDayOfMembership
        }

        if (currentEndDate) {
          this.editItem.membership[i].membershipStartDate = moment(currentEndDate)
        } else {
          this.editItem.membership[i].membershipStartDate = moment()
        }
      }
    }

    if (this.editItem.membership[i].membershipEndDate) {
      this.editItem.membership[i].termMonths = moment(this.editItem.membership[i].membershipEndDate).diff(this.editItem.membership[i].membershipStartDate, 'months')
    } else {
      const termMonths = this.editItem.membership[i].termMonths
      if (termMonths) {
        this.editItem.membership[i].membershipEndDate =
            this.editItem.membership[i].membershipStartDate.clone()
        this.editItem.membership[i].membershipEndDate.add(Number(termMonths), 'months')
      }
    }
  }

  static membershipSummary(memberships) {

    // General handling of multiple, overlapping and future memberships
    // The membership type is always a current membership with the latest
    // end date
    // The returned membership end date is the end date for the latest
    // contiguous future membership.
    // It is therefore possible to have a current membership type of
    // Annual, but an end date 2 years in the future.

    // ==============================================================
    // General Membership Military status

    // Only one of the next three flags will be true.
    // Any person with an active membership, could be a life membership
    let isActiveMember = false

    // Any person with an expired membership, that is an active membership
    // that expired in the last 6 months
    let isInGracePeriodMember = false

    // Any person with an expired membership that is not within the
    // grace period.
    let isExpiredMember = false

    // ==============================================================
    // More information about the type of membership

    // True if the membership is active and the membership type that takes
    // precedence is an individual corporate membership.
    let isIndividualCorporateMember = false

    // If the membership is associated with a company, the id of that company
    let companyId = null

    // True if the member has a life membership. Life memberships are assumed
    // to last forever that is, an end date is not relevant
    let isLifeMember = false

    // True if there are multiple overlapping active memberships. May not
    // be accurate if there is a life membership
    let currentOverlappingActiveMemberships = false

    // True if there is a break between the current membership and a
    // future membership. That is, there is not a contiguous membership
    let breakBeforeFutureMembership = false

    // ==============================================================
    // Dates that describe the validity of the membership

    // Holds the last day of active membership
    let lastDayOfMembership = null

    // If membership is in the grace period, identifies the last day
    // of the grace period
    let lastDayOfGraceMembership = null

    // ==============================================================
    // Other flags concerning the membership

    // The type of membership
    let membershipType = null
    let membershipTypeName = null

    // True if all of the memberships for the individual are paid. False if
    // there is a balance owed FOR MEMBERSHIPS. This does not indicate that the
    // currently active membership is paid.
    let isPaid = true

    // True if the individual has indicated active duty air force.
//    let isAirForceActiveDuty = false

    // True if the membership is valid for the convention
//    let isValidConventionMembership = false

    // False, of the entity associated with a corporate membership
    let associatedCompany = false

    // True if the person has never had a membership
    let neverBeenMember = true

    // ==============================================================
    // Other items

    // This is an internal variable that identifies the earliest start date of future memberships
    // Such a membership would exist if a member renews before the last day of the current
    // membership
    let earliestFutureMembershipStartDate = null
    let earliestFutureMembershipType = null
    let earliestFutureMembershipTypeName = ''

    // ==============================================================
    // Calculate the membership status

    const membershipList = memberships
    .filter(item => item.sk.split('-')[1] !== 'cart' && (item.status === 'complete' || item.status === ''))

    membershipList.map(individualMembership => {

      // To Do: compare this logic to how the is_paid flag is being used.
      // Does not seem that is_paid is actually communicating anything
      // useful about the current membership.
      isPaid = ((isPaid && individualMembership.is_active) ||
                individualMembership.is_liable_for_cost === false ||
                individualMembership.status === 'complete'
              )
      const currenMembershipTypeName =
        individualMembership.membershipTypeName ?
        individualMembership.membershipTypeName :
        { '706': 'Annual Membership',
          '707': '3 Year Membership',
          '708': 'Life Membership',
          '709': 'Annual Student Membership',
          '711': 'Corporate Individual Membership',
          '712': 'Discounted Annual Membership (Available to E1-E4s only)',
          '716': 'Award Winner Annual Membership'}[individualMembership.individual_membership_type_id];
      const currenMembershipType =
        individualMembership.membershipType ?
        individualMembership.membershipType :
        { '706': 'y1',
          '707': 'y3',
          '708': 'lf',
          '709': 's1',
          '711': 'c1',
          '712': 'd1',
          '716': 'a1'}[individualMembership.individual_membership_type_id];

      if (individualMembership.sk !== 'membership-summary') {neverBeenMember = false}
      // If there is a life membership, ignore all the others and return just that.
      if (individualMembership.is_life_membership || individualMembership.membershipType === 'lf') {
        isLifeMember = true
        isActiveMember = true
        isInGracePeriodMember = false
        isExpiredMember = false
        lastDayOfMembership = null
        lastDayOfGraceMembership = null
        membershipType = currenMembershipType
        membershipTypeName = currenMembershipTypeName

      } else {

        let membershipEndDate, membershipStartDate
        // The simplest case is when the membership has both start and end dates.
        if (individualMembership.membershipStartDate &&
            individualMembership.membershipEndDate) {

          membershipEndDate = moment(individualMembership.membershipEndDate)
          membershipStartDate = moment(individualMembership.membershipStartDate)

          // Review the currently active membership. There may be future
          // memberships (start in the future). Those are dealt with below.
          if (moment().isBetween(membershipStartDate, membershipEndDate ) ) {
            if (isLifeMember) {
              // For life members we don't need to make any changes to end dates
              // but we do need to track multiple active memberships
              currentOverlappingActiveMemberships = true;

            } else if (isActiveMember) {
              // If the membership type is already selected, that means
              // that this must be at least the second current membership
              currentOverlappingActiveMemberships = true;

              // The membership type used is the one with the latest end date.
              // last day or membership may be null if we previously saw a life membership
              if (membershipEndDate.isAfter(lastDayOfMembership)) {
                lastDayOfMembership = membershipEndDate;
                membershipType = currenMembershipType
                membershipTypeName = currenMembershipTypeName
                associatedCompany = individualMembership.company
              }
            } else {
              // If we don't have an active membership yet, use the type
              // for this membership (active membership types override any other type)
              lastDayOfMembership = membershipEndDate;
              membershipType = currenMembershipType
              membershipTypeName = currenMembershipTypeName
              associatedCompany = individualMembership.company
            }

            isActiveMember = true;
            isInGracePeriodMember = false;
            lastDayOfGraceMembership = null;
            isExpiredMember = false;
            if (individualMembership.companyId) {isIndividualCorporateMember = true}

            // Set above if the current end date takes precedence over the previously seen one.
            // $last_day_of_membership

          }

          // Look at future memberships
          if (moment().isBefore(membershipStartDate)) {

            // Keep track of all the earliest start date for a future membership. If this
            // matches the end of a current membership it will be contiguous and we can use that
            // as the membership type

            // To Do: This logic will fail if there is more than one future
            // membership since the order of reading the records is
            // undefined. If we read the second future one first
            // we will think it is non-contiguous.
            // A better way would be to build an array of start
            // and end memberships, sort the array by start date
            // and then look for any gaps.
            if (earliestFutureMembershipStartDate) {
              if (earliestFutureMembershipStartDate.isAfter(membershipStartDate)) {
                membershipStartDate = earliestFutureMembershipStartDate
                earliestFutureMembershipType = currenMembershipType
                earliestFutureMembershipTypeName = currenMembershipTypeName
                lastDayOfMembership = membershipEndDate
              }

            } else {
              earliestFutureMembershipStartDate = membershipStartDate
              earliestFutureMembershipType = currenMembershipType
              // To do: if the membership is not contiguous, why would be allow
              // the end date to be used?
              lastDayOfMembership = membershipEndDate
            }
          }

          // Expired memberships are good for 6 months
          // It is possible for the user to have both a grace period membership and an active membership
          // However, we do not set the grace period information if the person is already active. And
          // when the user is set as active, it clears any grace period information
          if (!isActiveMember && moment().subtract(6, 'months').isBetween(membershipStartDate, membershipEndDate)) {

            // Having an expired membership does not impact whether they person also has an active
            // membership
            // $active = <no change>
            isInGracePeriodMember = true
            lastDayOfMembership = membershipEndDate
            lastDayOfGraceMembership = membershipEndDate.clone().add(6, 'months')
            membershipType = currenMembershipType
            membershipTypeName = currenMembershipTypeName
            isExpiredMember = false
          }

          // The final possibility is that the person used to have a membership and it is now expired.
          if (!isActiveMember && moment().subtract(6, 'months').isAfter(membershipEndDate)) {

            lastDayOfMembership = membershipEndDate
            membershipType = currenMembershipType
            membershipTypeName = currenMembershipTypeName
            isExpiredMember = true
          }
        }
      }
    })

    return {
      isLifeMember: isLifeMember,
      isActiveMember: isActiveMember,
      isIndividualCorporateMember: isIndividualCorporateMember,
      companyId: companyId,
      isInGracePeriodMember: isInGracePeriodMember,
      isExpiredMember: isExpiredMember,
      lastDayOfGraceMembership: lastDayOfGraceMembership,
      lastDayOfMembership: lastDayOfMembership,
      currentOverlappingActiveMemberships: currentOverlappingActiveMemberships,
      breakBeforeFutureMembership: breakBeforeFutureMembership,
      membershipType: membershipType,
      membershipTypeName: membershipTypeName,
      associatedCompany: associatedCompany,
      neverBeenMember: neverBeenMember,

//      membershipTypeName: membershipTypeName,
//    'next_membership_start' => $next_membership_start,
// 'is_paid'=> $is_paid,
// 'is_air_force_active_duty'         => $is_air_force_active_duty,
// 'is_valid_convention_membership'   => $is_valid_convention_membership]
      }
  }

}

export default Person;
