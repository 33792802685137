import React, { Component } from 'react';
import { NavLink, useLocation, Link } from "react-router-dom";
import Loading from '../Loading';
import ErrorContainer from '../ErrorContainer';
import {navigation} from '../controls/menus'

import {ContentfulContext} from '../../classes/context'

const ReactMarkdown = require('react-markdown/with-html')

class IndustryPartnerNews extends Component {

    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        sections: null,
      };
    }

    componentDidMount() {
      const contentfulClient = this.context.contentfulClient

      contentfulClient.getEntries({'content_type': 'sectionProducts', 'order': '-sys.createdAt', 'limit': 60})
      .then(entries => {

        this.setState({
          isLoaded: true,
          sections: entries.items,
        });
      })
      .catch(error => {
        console.log(error)
        this.setState({
          error: error
        })
      })
    }

    render() {

      const { error, isLoaded } = this.state;

      if (error) {
        return <ErrorContainer title='Error' error={error} />
      } else if (!isLoaded) {
        return <Loading title=''
                        subtitle=''/>;
      }

      return (
        <div class="grid-container" style={{paddingLeft: 0}}>

        <div class="grid-row">
          <div class="tablet:grid-col-9">
            <main id="main-content">
              <PageContent sections={this.state.sections} />
            </main>
          </div>
          <div class="tablet:grid-col-3">
            <Sidebar />
          </div>
          </div>
        </div>
      )
    }
}

function PageContent(props) {
  const {sections} = props

  const regex = / /g
  return (
    <>
    <section class="usa-section" style={{paddingTop: 10, paddingBottom: 10}}>
      <div class="grid-container margin-y-0">
        <h2 class="margin-y-0" style={{paddingBottom: 10}}>Industry Partner News</h2>
        <div className="usa-prose">Product and Service announcements from our corporate partners.</div>
      </div>
    </section>
    {sections.map((section, i) =>
      <section id="test-section-id" className="usa-section margin-y-0" style={{paddingTop: 0, paddingBottom: 20}}>
      <div className="grid-container">
        <h2 className="font-heading-xl margin-y-0">{section.fields.title}</h2>
        <p className="usa-prose" >
        { section.fields.productImage && section.fields.productImage.fields.file &&
          <img alt={section.fields.industryPartner.fields.headline} style={{float: 'left', marginBottom: '20px', marginRight: '20px'}} src={section.fields.productImage.fields.file.url} width='200' />
        }
        <span>
        { section.fields.industryPartner.fields.image && section.fields.industryPartner.fields.image.fields.file &&
          <>
          <img alt={section.fields.industryPartner.fields.headline} style={{float: 'left', marginBottom: '20px', marginRight: '20px'}} src={section.fields.industryPartner.fields.image.fields.file.url} width='80' />
          <h3>
            <Link
              to={"/industry-partners/partners/" + section.fields.industryPartner.fields.headline.toLowerCase().replace(regex, '-')}
              title="Industry Partners"
              aria-label="industry-partner"
              className="usa-button--big usa-button--unstyled"
              style={{textDecoration: 'none'}}
            >
              {section.fields.industryPartner.fields.headline}
            </Link>
          </h3>
          </>
        }
        <ReactMarkdown source={section.fields.body} escapeHtml={false} style={{minHeight: 350}}/>
        </span>
        </p>
        <hr />
      </div>
    </section>

    )}
    </>
  )
}

function Sidebar(props) {

  const location = useLocation();
  // The sidebar to display depends on the current path

  let sidebar = navigation.filter(topMenu => (
    topMenu.items.filter(menuItem => (menuItem.link === location.pathname)).length > 0  )
  )[0]

  // Check to see if any of the second tier items match
  if (!sidebar) {
    navigation.forEach(topMenu => {
      console.log(topMenu)
      topMenu.items.forEach(item => {
        if (item.children && !sidebar) {
          console.log(item.children)
          if (item.children.filter(child => (child.link === location.pathname)).length > 0) {
            sidebar = topMenu
          }
        }
      })
    })
  }

  if (!sidebar) {
    return null
  }
  return (
  <>
    <h5 class="usa-heading-alt">{sidebar.parentLabel}</h5>
    <nav aria-label="Secondary navigation">
    <ul class="usa-sidenav">
    {sidebar.items.map((item, i) => {
      return(
      <>
      <li class="usa-sidenav__item">
        <NavLink to={item.link} activeClassName="usa-current">
          {item.label}
        </NavLink>
        {item.children && item.children.map((child, j) => {
          return (
            <>
            <li class="usa-sidenav__sublist">
            <NavLink to={child.link} activeClassName="usa-current">
              {child.label}
            </NavLink>
            </li>
            </>
          )
        })
        }
      </li>
      </>)
    })}
    </ul>
    </nav>
  </>
)
}

  //   <div className="usa-prose">
  //     <Link
  //       to={"/news/" + page.fields.headline.toLowerCase().replace(regex, '-')}
  //       title={page.fields.headline}
  //       aria-label="news-item"
  //       className="usa-button--big usa-button--unstyled"
  //       style={{textDecoration: 'none', fontSize: 'larger'}}
  //     >
  //       {page.fields.headline}
  //     </Link>
  //   </div>
  // }
  // {full &&
  //   <h2 class="margin-y-0" style={{paddingBottom: 10}}>
  //   {page.fields.headline}</h2>
  // }
  // <div >
  //   <strong>{page.fields.author}</strong> {page.fields.originalCreatedDate}
  // </div>
  // <div class="usa-prose">
  //   {full &&
  //     <>
  //     { page.fields.image && page.fields.image.fields.file &&
  //       <img style={{float: 'left', marginBottom: '20px', marginRight: '20px'}} src={page.fields.image.fields.file.url} width='200' />
  //     }
  //     <ReactMarkdown source={page.fields.body} escapeHtml={false}/>
  //     </>
  //   }
  // </div>



IndustryPartnerNews.contextType = ContentfulContext
export default IndustryPartnerNews;
