import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'

import {PersonContext} from '../../../classes/context.js';

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class BanquetInformation extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  nn(field) {
    return this.context.person ?
           this.context.person.item.cart[field] :
           ''
  }

  handleChange(event) {
    let person = this.context.person
    if (!person.editItem) {person.beginEdit()}
    const handleChange = person.handleChange.bind(person)
    handleChange(event)
  }

  render() {

    let person = this.context.person
    let attendeeSeating = false
    let attendeeDiet = false

    if (!person.item.cart.secondaryItems) {return null}

    attendeeSeating = person.item.cart.secondaryItems.filter(item => item.sk === 'secondaryAttendeeSeating')
    attendeeDiet = person.item.cart.secondaryItems.filter(item => item.sk === 'secondaryAttendeeDiet')

    if (attendeeDiet.length === 0 && attendeeSeating.length === 0) {return null}

    return (
      <>
      <h2 className="margin-y-1" style={{marginBottom: 0}}>Banquet Information</h2>

      {attendeeSeating.length > 0 &&
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-6">
            <div className="usa-label">Banquet Seating Request</div>
            <Form.Control
              className="usa-input"
              name="cart.secondaryAttendeeBanquetSeatingRequest"
              plaintext
              defaultValue={this.nn('secondaryAttendeeBanquetSeatingRequest')}
              style={editingStyle}
              onChange={this.handleChange}/>
          </div>
        </div>
      }

      {attendeeDiet.length > 0 &&
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-6">
            <div className="usa-label">Dietary Restrictions</div>
            <Form.Control
              className="usa-input"
              name="cart.secondaryAttendeeBanquetDietaryPreference"
              plaintext
              defaultValue={this.nn('secondaryAttendeeBanquetDietaryPreference')}
              style={editingStyle}
              onChange={this.handleChange}/>
          </div>
        </div>
      }
      </>
    )
  }
}

BanquetInformation.contextType = PersonContext
export default BanquetInformation
