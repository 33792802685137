import React, { Component } from 'react';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import PaymentCard from './PaymentCard'

const hostname = window && window.location && window.location.hostname
const stripePromise = (hostname === 'www.atalink.org' ||
                       hostname === 'atalink.org') ?
                       loadStripe('pk_live_0NIWTgcAcd9TuJKNmoCqxsX8') :
                       loadStripe('pk_test_TdYkfN3C0hFR8JvDmp5sDCbk')

class PaymentPersonalCard extends Component {

  render() {
    return (
      <>
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({elements, stripe}) => (
              <PaymentCard
                elements={elements}
                stripe={stripe}
                cardAvailable={this.props.cardAvailable}
                cardType="personal"/>
          )}
        </ElementsConsumer>
      </Elements>
    </>
    )
  }
}

export default PaymentPersonalCard;
