import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Form from 'react-bootstrap/Form'

class PhoneType extends Component {

  constructor(props) {
    super(props)

    // By default we provide the current value as the only option. We need to
    // wait for the picklist to appear form the componentDidMount below.
    this.state = {
      options: <option value={props.defaultValue} selected="true">{props.defaultDisplayValue}</option>,
      defaultValue: props.defaultValue
    }

    this.onChange = this.onChange.bind(this)

  }

  onChange(event) {

    const phoneSubType = this.props.subtype === 'Primary' ? 'phone-primary' : 'phone-secondary'
    const typeName = this.state.types.filter(item => item.sk === event.target.value)

    this.props.onChange(event)
    this.props.onChange({target: {name: phoneSubType+'.type_name',  value: typeName[0].name, type: 'input'}})

    if (event.target.value === 'none') {
      this.props.onChange({target: {name: phoneSubType+'.phone_number',  value: '', type: 'input'}})
    }

    this.props.stateChange()
  }

  componentDidMount() {
    API.get('general', '/reference/phone-type')
    .then(response => {

      const items = response.items.sort(function(a, b) {
        if (a.sk === 'none') {return -1}
        if (b.sk === 'none') {return 1}
        if (a.sk < b.sk) {return -1}
        if (a.sk > b.sk) {return 1}
        return 0
      })
      const options = items.map((item) =>
        <option key={item.sk} value={item.sk} selected={item.sk === this.state.defaultValue}>{item.name}</option>
      )
      this.setState({
        types: response.items,
        options: options
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  render() {
    return (
      <>
      <div className="usa-label">{this.props.subtype} Phone</div>
      <Form.Control className="usa-select" style={{backgroundColor: '#eeeeee'}} as="select" name={this.props.name}  onChange={this.onChange} >
        {this.state.options}
      </Form.Control>
      </>
    )
  }
}

export default PhoneType
