import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'

import {PersonContext} from '../../../classes/context.js';

const editingStyle = {
  backgroundColor: '#eeeeee',
};

class FirstTimeAttendee extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  nn(field) {
    return this.context.person ?
           this.context.person.item.cart[field] :
           ''
  }

  handleChange(event) {
    let person = this.context.person
    if (!person.editItem) {person.beginEdit()}
    const handleChange = person.handleChange.bind(person)
    handleChange(event)
  }

  render() {

    return (
      <>
      <h2 className="margin-y-5" style={{marginBottom: 0}}>First Time Attendee</h2>

      <div className="grid-row grid-gap margin-y-0">
        <div className="grid-col-6">
          <div className="usa-label">Is this your first time at an A/TA Convention?</div>

            <Form.Control
              className="usa-select"
              style={editingStyle}
              as="select"
              name={'cart.firstTimeAttendee'}
              defaultValue={this.nn('firstTimeAttendee')}
              onChange={this.handleChange}
            >
              <option key="-1" value="">Select One</option>
              <option key="Yes" value="Yes">Yes, this is my first A/TA Convention</option>
              <option key="No" value="No">No, I have attended previous A/TA Conventions</option>
            </Form.Control>
          </div>
        </div>
      </>
    )
  }
}

FirstTimeAttendee.contextType = PersonContext
export default FirstTimeAttendee
