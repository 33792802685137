import React, { Component } from 'react'
import { Redirect, Link, useLocation } from "react-router-dom"
import Loading from '../../Loading'
import ErrorContainer from '../../ErrorContainer'
import Modal from 'react-bootstrap/Modal'
import { API } from 'aws-amplify';

import Sidebar from './Sidebar'

import {ContentfulContext} from '../../../classes/context'

import  {Section, PageHeader, ButtonStatic} from '../../sections/Section';

import ButtonTimed from '../../sections/ButtonTimed'
import ConventionSponsors from '../../sections/ConventionSponsors'
import {PageLoad, sessionClick} from '../../controls/Events'

const ReactMarkdown = require('react-markdown/with-html')
var moment = require('moment')
// moment.now = function () {
//   let event = new Date()
//   event.setDate(27)
//   event.setMonth(9)
//   event.setHours(14)
// //  event.setMinutes(20)
//   return event;
// }

class Agenda extends Component {

    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        page: null,
        lastRefresh: false,
        redirect: false
      };

      this.showContent = this.showContent.bind(this)
      this.modalVisibility = this.modalVisibility.bind(this)
      this.refreshData = this.refreshData.bind(this)
      this.linkRedirect = this.linkRedirect.bind(this)
    }

    linkRedirect(params) {
      const {href, event} = params
      sessionClick(event)
      this.setState({
        redirect: href
      })
    }


    isUpNext(item) {

      const currentTime = moment()
      const twoHoursLater = moment().add(2, 'hours')
      const startTime = moment(item.fields.startTime)
      const endTime = moment(item.fields.startTime).add(item.fields.length, 'minutes')

      return startTime.isBetween(currentTime, twoHoursLater) ||
             endTime.isBetween(currentTime, twoHoursLater) ||
             currentTime.isBetween(startTime, endTime)
    }

    isComingSoon(item) {
      const currentTime = moment()
      const twoHoursLater = moment().add(2, 'hours')
      const startTime = moment(item.fields.startTime)

      return startTime.isAfter(currentTime) && !startTime.isBetween(currentTime, twoHoursLater)
    }

    isReplay(item) {
      const currentTime = moment()
      const endTime = moment(item.fields.startTime).add(item.fields.length, 'minutes')

      return endTime.isBefore(currentTime)
    }


    componentDidMount() {
      this.refreshData()
      this.refreshData = setInterval(this.refreshData, 300 * 1000); // every 5 minutes
    }

    refreshData() {

      let path = this.state.signature ? '/agenda?t=' + this.state.signature : '/agenda'
      if (document.visibilityState === 'visible' || !this.state.isLoaded ) {

        API.get('content', path)
        .then(response => {

          const {entries, signature} = response

          const upNext = entries.items.filter(this.isUpNext)
          const past = entries.items.filter(this.isReplay)
          const future = entries.items.filter(this.isComingSoon)

          this.setState({
            isLoaded: true,
            sessions: entries.items,
            signature: signature,
            past: past,
            future: future,
            upNext: upNext,
            refreshing: false,
            lastRefresh: moment().valueOf()
          })
        })
        .catch(error => {
          if (error.response && error.response.status === 304) {
            const upNext = this.state.sessions.filter(this.isUpNext)
            const past = this.state.sessions.filter(this.isReplay)
            const future = this.state.sessions.filter(this.isComingSoon)

            this.setState({
              past: past,
              future: future,
              upNext: upNext,
              isLoaded: true,
              refreshing: false,
              lastRefresh: moment().valueOf()
            })
          } else {
            console.log(error)
            this.setState({error: error, refreshing: false})
          }

        })
      }
      // const contentfulClient = this.context.contentfulClient
      //
      // this.setState({refreshing: true})
      // contentfulClient.getEntries({'content_type': 'pageSession', 'limit': 500, 'order': 'fields.startTime'})
      // .then(entries => {
      //
      //   const upNext = entries.items.filter(this.isUpNext)
      //   const past = entries.items.filter(this.isReplay)
      //   const future = entries.items.filter(this.isComingSoon)
      //
      //   this.setState({
      //     isLoaded: true,
      //     sessions: entries.items,
      //     past: past,
      //     future: future,
      //     upNext: upNext,
      //     refreshing: false,
      //     lastRefresh: moment().valueOf()
      //   });
      // })
      // .catch(error => {
      //   console.log(error)
      //   this.setState({error: error, refreshing: false})
      // })

    }

    modalVisibility(isVisible) {
      this.setState({modalIsVisible: isVisible})
    }

    showContent(type, item) {
      this.setState({
        modalType: type,
        modalItem: item,
        modalIsVisible: true,
      })
    }

    render() {
      const { error, isLoaded } = this.state;

      if (this.state.redirect) {
        window.location.assign(this.state.redirect)
        return null
      }

      if (error) {
        return <ErrorContainer title='Error' error={error} />
      } else if (!isLoaded ) { // || this.props.registrationStatus === 'loading') {
        return <Loading title='Convention - Agenda'
                        size='h1'
                        subtitle=''/>;
      } else {

        // if (this.props.registrationStatus !== 'complete') {
        //   return (
        //     <>
        //     <SearchRequest />
        //     <PageLoad />
        //     <Section>
        //       <div class="grid-container">
        //         <div class="grid-row grid-gap-2">
        //           <div class="tablet:grid-col-9">
        //             <ConventionSponsors type='s230x75' lastRefresh={this.state.lastRefresh}/>
        //             <PageHeader title='Convention - Agenda' />
        //             <div>
        //               Convention Time: {moment().format('YYYY-MM-DD HH:mm (ZZ)')}
        //             </div>
        //
        //             <div class="usa-alert usa-alert--error" >
        //               <div class="usa-alert__body">
        //                 <h3 class="usa-alert__heading">{this.state.message}</h3>
        //                 <p class="usa-alert__text">You must have an active registration to see the detailed Agenda.</p>
        //                 <Link to="/register">Register</Link> now.
        //               </div>
        //             </div>
        //           </div>
        //           <ModalDetail
        //             handleClose={this.modalVisibility}
        //             modalIsVisible={this.state.modalIsVisible}
        //             item={this.state.modalItem}
        //             modalType={this.state.modalType}
        //             />
        //
        //           <div class="tablet:grid-col-3">
        //             <Sidebar
        //               showContent={this.showContent}
        //             />
        //           </div>
        //         </div>
        //       </div>
        //     </Section>
        //     </>
        //
        //   )
        // }

        return (
          <>
          <SearchRequest />
          <PageLoad />
          <Section>
            <div class="grid-container">
              <div class="grid-row grid-gap-2">
                <div class="tablet:grid-col-9">
                <ConventionSponsors type='s230x75' lastRefresh={this.state.lastRefresh}/>
                  <PageHeader title='Convention - Agenda' />
                  <div>
                  <div class="usa-alert usa-alert--error" >
                    <div class="usa-alert__body">
                      <p class="usa-alert__text">
                      <Link to="/virtual2020/berlin-candy-bomber-petition">Sign the Petition</Link> for the Presidential Medal of Freedom for the Berlin Candy Bomber</p>
                    </div>
                  </div>

                  </div>
                 <UpNext
                    agenda={this.state.upNext}
                    showContent={this.showContent}
                    linkRedirect={this.linkRedirect}
                  />
                  <ComingSoon
                    agenda={this.state.future}
                    showContent={this.showContent}
                  />
                  <Replay
                    agenda={this.state.past}
                    showContent={this.showContent}
                    linkRedirect={this.linkRedirect}
                  />
                </div>
                <ModalDetail
                  handleClose={this.modalVisibility}
                  modalIsVisible={this.state.modalIsVisible}
                  item={this.state.modalItem}
                  modalType={this.state.modalType}
                  />
                <div class="tablet:grid-col-3">
                  <Sidebar
                    showContent={this.showContent}
                  />
                </div>
              </div>
            </div>
          </Section>
          </>
        )
      }
    }
}

function SearchRequest() {
  const {search, pathname} = useLocation();

  if (!search) return null
  const keywords = search.split('=');

  // If there are other parameters on the URL, this means somebody (facebook)
  // added the parameter. Remove.
  if (keywords[0] !== '?search') return <Redirect to={pathname} />

  return <Redirect to={"/?search=" + keywords[1]} />
}

function ModalDetail(props) {
  if (!props.modalIsVisible) return null

  let modalStyle = {
      background: '#fff',
      border: '#b40808',
      borderRadius: '5px',
      marginLeft: '-200px',
      textAlign:'center',
      position: 'fixed',
      left: '25%',
      top: '20%',
      zIndex: 11,
      width: '80%',
      minHeight: '250px',
      boxShadow:'0 5px 10px rgba(0,0,0,0.3)',
      transform: 'translate(0, 0)',
      transition: 'transform 0.3s ease-out',
      outline: 'none',
      pointEvents: 'auto',
      overflowY: 'initial' // !important'

    };

    const {item, modalType, handleClose} = props
  return (

    <Modal show={true} style={modalStyle} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className='modal-header-additional'>
        <h2 style={{float:'left', maxWidth: '80%', marginLeft: 10, textAlign: 'left'}}>{props.item.fields.headline}</h2>
      </Modal.Header>
      <Modal.Body style={{maxHeight: 500, overflowY: 'auto'}}>
        {modalType === 'news' &&
          <div class="usa-prose" style={{textAlign: 'left', margin: 20}}>
            { item.fields.image && item.fields.image.fields.file &&
              <img alt={item.fields.headline} style={{float: 'left', marginBottom: '20px', marginRight: '20px'}} src={item.fields.image.fields.file.url} width='200' />
            }
            <ReactMarkdown source={item.fields.body} escapeHtml={false}/>
              <hr />
          </div>

        }
        {modalType === 'session' &&
          <div class="usa-prose" style={{textAlign: 'left', margin: 20}}>
            { item.fields.image && item.fields.image.fields.file &&
              <img alt={item.fields.headline} style={{float: 'left', marginBottom: '20px', marginRight: '20px'}} src={item.fields.image.fields.file.url} width='200' />
            }
            <div>
              {moment().isBefore(moment(item.fields.startTime)) &&
                <div>
                  <strong>Start: </strong>{moment(item.fields.startTime).format('YYYY-MM-DD HH:mm (ZZ)')}
                </div>
              }              <strong>Scheduled Duration:</strong> {moment.duration(item.fields.length, 'minutes').humanize()}
              {moment().isAfter(moment(item.fields.startTime)) &&
                <div>
                  <strong>Started: </strong>{moment(item.fields.startTime).fromNow()}
                </div>
              }
            </div>
            <Tags tags={item.fields.tags} />
            <p className='usa-intro'>{item.fields.tagline}</p>
            <p className='usa-prose'>{item.fields.body}</p>
            <hr />

            {item.fields.speakers &&
              <>
              <h3>Speakers</h3>
              {item.fields.speakers.map(speaker => {
                return (
                  <div>
                    { speaker.fields.headshot && speaker.fields.headshot.fields.file &&
                      <img alt={speaker.fields.name} style={{float: 'left', marginBottom: '20px', marginRight: '20px'}} src={speaker.fields.headshot.fields.file.url} width='150' />
                    }
                    <h4> {speaker.fields.name}</h4>
                    <div style={{minHeight: 175}}>
                      <ReactMarkdown source={speaker.fields.bio} escapeHtml={false} />
                    </div>
                    <hr />
                  </div>
              )
              })}
              </>
            }
          </div>
        }

        <ButtonStatic
          onClick={() => handleClose(false)}
          style={{marginRight: 20, marginBottom:20, float: 'right'}}
          >
          Close
        </ButtonStatic>

      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  )

}


function ComingSoon(props) {

  // Temporary post convention
  return null
  return (
    <>
    <h2>Coming Soon</h2>
    {props.agenda.map(session => {
      return (
        <>
        <div class="grid-row">
          <div class="tablet:grid-col-2">
            <div style={{fontSize:'xx-large'}}>{moment(session.fields.startTime).format('MM-DD')}</div>
          </div>
          <div class="tablet:grid-col-10">
            <a style={{fontSize:'large', color: '#b40808', fontWeight: 'bold', cursor: 'pointer'}}
              onClick={() => props.showContent('session', session)}
              >
              {session.fields.headline}
            </a>
            <div>{session.fields.tagline}</div>
            <div><strong>Start:</strong> {moment(session.fields.startTime).format('HH:mm (ZZ)')}</div>
            <div><strong>Scheduled Duration:</strong> {moment.duration(session.fields.length, 'minutes').humanize({m: 100})}</div>
            <Tags tags={session.fields.tags} />
          </div>
        </div>
        <hr />
        </>

      )
    })
    }
    </>
  )
}

function UpNext(props) {
  // Temporary post convention
  return null
  return (
    <>
    <h2>Up Next</h2>
    {props.agenda.length === 0 &&
    <p className='usa-prose'>Nothing Scheduled</p>
  }
  {props.agenda.map(session => {
    return (
      <>
      <div class="grid-row">
        <div class="tablet:grid-col-3">
          <div style={{fontSize:'x-large'}}>Today</div>
          <span onClick={() => props.linkRedirect({event: {session: session.fields.headline, live: true}, href:session.fields.liveMeetingLink})} cursor='pointer'>
          <ButtonTimed
            style={{marginTop: 5}}
            startTime={session.fields.startTime}
            length={session.fields.length}
            preSessionLinkAvailable={session.fields.preSessionLinkAvailable}
          >
          Join Live</ButtonTimed>
          </span>
        </div>
        <div class="tablet:grid-col-9">
          <a style={{fontSize:'large', color: '#b40808', fontWeight: 'bold', cursor: 'pointer'}}
            onClick={() => props.showContent('session', session)}
            >
            {session.fields.headline}
          </a>
          <p className='usa-prose  margin-y-0'>{session.fields.tagline}</p>
          <div>
            <div><strong>Start:</strong> {moment(session.fields.startTime).format('HH:mm (ZZ)')}</div>
            {moment().isAfter(moment(session.fields.startTime)) &&
              <div>
                <strong>Started: </strong>{moment(session.fields.startTime).fromNow()}
              </div>
            }
            <div><strong>Scheduled Duration:</strong> {moment.duration(session.fields.length, 'minutes').humanize()}</div>
          </div>
          <Tags tags={session.fields.tags} />
          {session.fields.speakers &&
            <>
            <hr style={{color: '#cccccc'}}/>
            {session.fields.speakers.map(speaker => {
                return (
                  <div>
                    <img alt={speaker.fields.name} style={{display: 'inline-block', marginBottom: '5px', marginRight: '5px'}} src={speaker.fields.headshot.fields.file.url} width='30' />
                    <div style={{display: 'inline-block', verticalAlign: 'top'}}> {speaker.fields.name}</div>
                  </div>
              )
              })}
              </>
          }
        </div>
      </div>
      <hr />
      </>
    )
  })
  }
  </>
  )
}

function Replay(props) {

  return (
<>
  <h2>Replay</h2>
  {props.agenda.map(session => {
    return (
      <>
      <div class="grid-row">
        <div class="tablet:grid-col-2">
          <div style={{fontSize:'xx-large'}}>{moment(session.fields.startTime).format('MM-DD')}</div>

          <span onClick={() => props.linkRedirect({event: {session: session.fields.headline, live: false}, href:session.fields.recordingLink})} cursor='pointer'>

          <ButtonStatic
            style={{marginTop: 5, textDecoration: 'none'}}
            className='usa-button--unstyled'
          >
            Replay
          </ButtonStatic>
          </span>
        </div>
        <div class="tablet:grid-col-10">
          <a style={{fontSize:'large', color: '#b40808', fontWeight: 'bold', cursor: 'pointer'}}
            onClick={() => props.showContent('session', session)}
            >
            {session.fields.headline}
          </a>
          <div>{session.fields.tagline}</div>
          <div><strong>Duration:</strong> {moment.duration(session.fields.length, 'minutes').humanize()}</div>
        </div>
      </div>
      <hr />
      </>

    )
  })
  }
  </>
)

}


function Tags(props) {
  if (!props.tags) return null
  return (
    <div style={{marginTop: 5}}>
      {props.tags.map(tag => {
        return (
          <span class="usa-tag">{tag}</span>
        )
      })}
    </div>
  )
}

Agenda.contextType = ContentfulContext
export default Agenda;
